<script setup>
import { NDivider } from 'naive-ui'
import immutableLogo from 'src/asset/Immutable_login.webp'
import { useUserInfoStore } from 'src/stores/user'

// import google_activate_icon from 'src/asset/logo/google_activate_icon.webp'
import { useImmutable } from 'src/hooks/useImmutable'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
// import apply_login from 'src/asset/logo/apply_login.webp'
// import { hasPhone } from 'src/stores/media'
const props = defineProps({
  type: {
    type: String,
    default: 'login',
  },
  submitLoading: Boolean,
})
const { userInfo } = storeToRefs(useUserInfoStore())
const { loginImmutable } = useImmutable()
const { isLoading: loadingImmutable, execute: executeImmutable } =
  loginImmutable
const { bindImmutable } = useImmutable()
const { isLoading: loadingBindImmutable, execute: executeBindImmutable } =
  bindImmutable

const loginListRef = computed(() => {
  const result = []
  const hasLogin = props.type === 'login'
  if (hasLogin || !userInfo.value?.bindAccountInfo?.openid) {
    result.push({
      img: immutableLogo,
      click: props.type === 'login' ? executeImmutable : executeBindImmutable,
    })
  }
  return result
})

const loadingRef = computed(
  () => loadingImmutable.value || loadingBindImmutable.value,
)
defineExpose({
  loading: loadingRef,
})
</script>

<template>
  <div class="mb-7 md:mb-4" v-if="loginListRef.length">
    <NDivider class="mb-5!"
      ><span class="fz-3 text-#7C8487">or log in with</span></NDivider
    >
    <div class="flex-center">
      <template v-for="(item, index) in loginListRef" :key="index">
        <img
          :src="item.img"
          class="mouse_pointer w-[226px] md:w-[307px]"
          :class="`${index < loginListRef.length - 1 ? 'md:mr-7 mr-4' : ''} ${loadingRef ? 'disabled' : ''}`"
          alt=""
          @click="(!loadingRef || !submitLoading) && item.click()"
        />
      </template>
      <!-- <img
        src="/src/asset/logo/google_activate_icon.webp"
        class="mouse_pointer mr-4 w-[42px] md:mr-7 md:w-[57px]"
        alt=""
      />
      <img
        src="/src/asset/logo/apply_login.webp"
        class="mouse_pointer w-[27px] md:w-[37px]"
        alt=""
      /> -->
    </div>
  </div>
</template>
