<script setup>
import inventoryWebp from 'src/asset/softPledge/inventory.webp'
import triangleLeftWebp from 'src/asset/softPledge/triangle_left.webp'
import triangleTopWebp from 'src/asset/softPledge/triangle_top.webp'
import { gsap } from 'gsap'
import { onMounted, onUnmounted } from 'vue'

let inventorytTiangleTl, inventorytTiangleTl1
const initAnimation = () => {
  const options = {
    ease: 'linear',
    paused: true,
    repeat: -1,
    yoyo: true,
    duration: 0.8,
  }
  inventorytTiangleTl = gsap.to('.inventory-top-triangle', {
    ...options,
    x: '-6',
    onStart() {
      inventorytTiangleTl1.play()
    },
  })
  inventorytTiangleTl1 = gsap.to('.inventory-left-triangle', {
    ...options,
    x: '-2',
  })
  inventorytTiangleTl.play()
}

onMounted(() => {
  initAnimation()
})
onUnmounted(() => {
  inventorytTiangleTl?.kill()
  inventorytTiangleTl1?.kill()
})
</script>
<template>
  <div class="mouse_pointer relative flex">
    <img class="w-54px h-54px mr-23px" :src="inventoryWebp" alt="" />
    <div class="bg-box py-10px px-15px w-314px h-63px fz-5 text-#fff">
      <div>INVENTORY</div>
      <div class="h-1px w-full bg-#50585D mt-1"></div>
    </div>
    <img
      class="inventory-top-triangle w-19.5px absolute left-54px bottom-0"
      :src="triangleTopWebp"
      alt=""
    />
    <img
      class="inventory-left-triangle w-17px absolute left-70px bottom-0"
      :src="triangleLeftWebp"
      alt=""
    />
  </div>
</template>

<style lang="scss" scoped>
.bg-box {
  background: url('/src/asset/softPledge/right_bg.webp') no-repeat center / 100%
    100%;
}
</style>
