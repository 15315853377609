<script setup>
import Dropdown from 'src/components/Dropdown'
import { useRoute, useRouter } from 'vue-router'
import CollectionText from './CollectionText'
import { MY_COLLECTION_ASSETS_NAME } from 'src/constant'

const router = useRouter()
const route = useRoute()
const options = [
  {
    label: 'In-game Assets',
    key: MY_COLLECTION_ASSETS_NAME,
  },
  {
    label: 'NFTs',
    key: 'myCollectionNFTs',
  },
  {
    label: 'All Items',
    key: 'myCollectionAll',
  },
]
function handleSelect(key, item) {
  router.push({ name: item.key })
}
</script>

<template>
  <span class="gray-color mouse_pointer">
    <Dropdown
      v-if="!route.path.includes('/transfer_history')"
      :value="route.name"
      :options="options"
      @select="handleSelect"
    >
      <CollectionText></CollectionText>
    </Dropdown>
    <CollectionText v-else></CollectionText>
  </span>
</template>

<style scoped lang="scss">
@import './index';
</style>
