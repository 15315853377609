<script setup>
import { NLayout } from 'naive-ui'
import { AppMain, Sidebar, Header } from 'src/layout/PFPComponents'
import Filter from './components/Filter/index.vue'
import Content from './components/Content/index.vue'
import Detail from './components/Detail/index.vue'
import Tabs from './components/Header/index.vue'
import { storeToRefs } from 'pinia'
import { useThemeStore } from 'src/stores/theme'
import { useList, useDetail } from '.'
import cthulhuBg from 'src/asset/PFP/cthulhu_bg.webp'
import { ref } from 'vue'
const { currentTheme, PFPDomRef, hasThemeGreen } = storeToRefs(useThemeStore())
const pageSizeRef = ref(15)
const distanceRef = ref(100)
const {
  filterListRef,
  filterRef,
  filterListComputed,
  handleFilterClose,
  viewListRef,
  handleRandom,
  throttleListScroll,
  listFilterComputed,
} = useList(pageSizeRef, distanceRef)
const { detailShowRef, detailRef, handleDetail } = useDetail()
</script>

<template>
  <NLayout>
    <div class="flex item_tracking fz-2" :class="currentTheme" ref="PFPDomRef">
      <Sidebar
        class="w-1/4 min-w-sm flex flex-col h-100vh flex-shrink-0 bg-[--pfp-bg10] relative overflow-hidden"
      >
        <img
          :src="cthulhuBg"
          v-show="hasThemeGreen"
          class="absolute bg-[--pfp-bg10] opacity-15 w-150% translate-x-[-50%] left-50% top-[-23%]"
        />
        <div class="mx-auto mt-14 w-75.5% flex_h_full relative">
          <Filter ref="filterRef" :filterList="filterListRef"></Filter>
        </div>
      </Sidebar>
      <div class="flex-grow">
        <Header>
          <Tabs></Tabs>
        </Header>
        <AppMain @scroll="throttleListScroll">
          <Content
            @detail="handleDetail"
            @filterClose="handleFilterClose"
            @random="handleRandom"
            :filterList="filterListComputed"
            :list="viewListRef"
            :total="listFilterComputed.length"
          ></Content>
        </AppMain>
      </div>
      <Detail
        :theme="`dialog-${detailRef.color}`"
        :data="detailRef"
        v-model:show="detailShowRef"
      ></Detail>
    </div>
  </NLayout>
</template>

<style lang="scss">
@import './theme';
</style>
