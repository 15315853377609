import {
  getAccountNameModel,
  getAccountNameRules,
  getPasswordModel,
  getPasswordRules,
} from '../common'

export function getLoginModel() {
  return {
    ...getAccountNameModel(),
    ...getPasswordModel(),
  }
}

export function getLoginRules() {
  return {
    accountName: getAccountNameRules().accountName[0],
    password: getPasswordRules().password[0],
  }
}
