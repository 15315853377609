<script setup>
import BaseList from 'src/components/BaseList'
import Item from 'src/pages/PFP/components/Content/Item/index.vue'
import EggItem from 'src/pages/PFP/ethereal/components/EggItem.vue'
import EggDefault from 'src/pages/PFP/ethereal/components/EggDefault.vue'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
const emit = defineEmits(['detail', 'eggEnd'])
const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
  currentTime: Number,
  hasBroken: Boolean,
})
const { eggEndComputed, mintInfo } = storeToRefs(usePFPMintStore())
const progressComputed = computed(() => {
  const startTime = mintInfo.value?.timeline?.[0] * 1000
  return Math.min(
    Math.max(props.currentTime - startTime, 0) /
      (eggEndComputed.value - startTime) || 0,
    1,
  )
})
function handleClick(item) {
  emit('detail', item)
}
function handleEggEnd() {
  emit('eggEnd')
}
</script>
<template>
  <BaseList :list="list" :xGap="26" :yGap="26" :number2xl="6" :numberMD="4">
    <template #item="item">
      <EggDefault v-if="!item.viewName"></EggDefault>
      <EggItem
        v-else-if="item.hasEgg"
        :id="item.id"
        :name="item.viewName"
        :progress="progressComputed"
        :hasBroken="hasBroken"
        @end="handleEggEnd"
      ></EggItem>
      <Item
        v-else
        :id="item.id"
        :name="item.viewName"
        :image="item.image"
        @click="handleClick(item)"
      ></Item>
    </template>
  </BaseList>
</template>
