export function getAccountNameModel() {
  return {
    accountName: '',
  }
}

export function getAccountNameRules(
  accountMinLength = 5,
  accountMaxLength = 11,
) {
  return {
    accountName: [
      {
        required: true,
        message: 'Please enter your account.',
        trigger: ['input', 'blur'],
      },
      {
        min: accountMinLength,
        message: `Account must be at least ${accountMinLength} digits long.`,
        trigger: ['input', 'blur'],
      },
      // {
      //   max: accountMaxLength,
      //   message: `Account can have a maximum of ${accountMaxLength} digits.`,
      //   trigger: ['input', 'blur'],
      // },
    ],
  }
}
