<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { gsap } from 'gsap'
import { useUserInfoStore } from 'src/stores/user'
import { middleEllipsis } from 'src/sdk/blockchain'
import { storeToRefs } from 'pinia'
import { hasPhone } from 'src/stores/media'
import { lineAngleW, lineBottomConnectW } from '../constant'

const { userInfo } = storeToRefs(useUserInfoStore())
const hornDomRef = ref(null)
const domAttributeReactive = reactive({
  opacityLine: 1,
  lineBottomRect: 0,
  lineAngleRect: lineAngleW,
})

let hoverIng = false
let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to(hornDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to(hornDomRef.value, {
    ...options,
    x: '-20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  bottomLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineBottomRect: lineBottomConnectW,
    duration: 0.4,
  })
  obliqueLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineAngleRect: 0,
    duration: 0.1,
    onComplete() {
      bottomLineTl.play()
    },
  })

  opacityTl = gsap.to(domAttributeReactive, {
    opacityLine: 0,
    duration: 0.3,
    onComplete() {
      bottomLineTl.progress(0).pause()
      obliqueLineTl.progress(0).pause()
      opacityTl.progress(0).pause()
    },
  })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  obliqueLineTl.play()
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  opacityTl.play()
}
onMounted(() => {
  !hasPhone && initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  bottomLineTl?.kill()
  obliqueLineTl?.kill()
  opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer fixed right-0 top-0 z-2"
    @mouseenter="!hasPhone && handleMouseenter()"
    @mouseleave="!hasPhone && handleMouseleave()"
  >
    <div class="relative z-2 md:pr-12 md:pt-3">
      <img
        ref="hornDomRef"
        src="/src/asset/top_left_jiao.webp"
        class="relative w-[8px] rotate-y-180 opacity-100 md:w-[10px] md:opacity-0"
        alt=""
      />
      <span class="fz-3 ml-0.25 mr-1 md:ml-0.5 md:mr-1.5">
        {{
          userInfo?.wallet ? middleEllipsis(userInfo.wallet) : 'CONNECT'
        }}</span
      >
      <img src="/src/asset/wallet.webp" class="w-[14px] md:w-[24px]" alt="" />
    </div>
    <div
      class="line z-2 w-[192px] translate-x-[-10%]"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineBottomRect}px, auto, auto)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineBottomConnectW}px`,
      }"
    ></div>
    <div
      class="line oblique"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineAngleRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div>
  </div>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1;
}

.oblique {
  bottom: 22%;
  left: -14%;
  transform: rotate(-45deg);
}

.hidden-box {
  bottom: -6%;
  left: -15%;
}
</style>
