<script setup>
import { onMounted } from 'vue'
import { checkout } from '@imtbl/sdk'
import { connect, wallet, bridge, swap, onramp, sale } from 'src/sdk/immutable'
import { OrchestrationEventType, SaleEventType } from '@imtbl/sdk/checkout'

const props = defineProps({
  widgetType: {
    type: String,
    default: '',
  },
  orderData: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['close', 'getWalletAddress', 'success'])
function handleShow() {
  emit('close')
}

onMounted(async () => {
  if (!connect || !wallet || !bridge || !swap || !onramp) return

  connect.addListener(checkout.ConnectEventType.CLOSE_WIDGET, () => {
    handleShow()
    connect.unmount()
  })
  wallet.addListener(checkout.WalletEventType.CLOSE_WIDGET, () => {
    handleShow()
    wallet.unmount()
  })
  swap.addListener(checkout.SaleEventType.CLOSE_WIDGET, () => {
    // handleShow()
    swap.unmount()
    wallet.mount('widget-target')
  })
  bridge.addListener(checkout.BridgeEventType.CLOSE_WIDGET, () => {
    handleShow()
    bridge.unmount()
    // wallet.mount('widget-target')
  })
  onramp.addListener(checkout.OnRampEventType.CLOSE_WIDGET, () => {
    handleShow()
    onramp.unmount()
    // wallet.mount('widget-target')
  })
  sale?.addListener(checkout.SaleEventType.CLOSE_WIDGET, () => {
    handleShow()
    sale.unmount()
  })

  switch (props.widgetType) {
    case 'connect': {
      console.log('before', connect)
      connect.addListener(checkout.ConnectEventType.CONNECT, data => {
        console.log('CONNECT', data)
        // handleShow()
        // connect.unmount()
      })
      connect.addListener(checkout.ConnectEventType.SUCCESS, async data => {
        const provider = data.provider.provider
        const wallet =
          (
            await provider.request({ method: 'eth_accounts' })
          )[0].toLowerCase() ?? ''
        console.log('wallet', wallet)
        emit('getWalletAddress', wallet)
      })
      // Hack to get to render
      const render = Promise.resolve()
      render.then(() => connect.mount('widget-target'))
      break
    }
    case 'wallet': {
      wallet.addListener(checkout.WalletEventType.DISCONNECT_WALLET, data => {
        wallet.mount('widget-target')
        emit('getWalletAddress', '')
        // logout();
      })
      wallet.addListener(
        checkout.OrchestrationEventType.REQUEST_BRIDGE,
        data => {
          console.log('bridge', data)
          wallet.unmount()
          bridge.mount('widget-target', { ...data })
        },
      )
      wallet.addListener(checkout.OrchestrationEventType.REQUEST_SWAP, data => {
        console.log('swap', data)
        wallet.unmount()
        swap.mount('widget-target', { ...data })
      })
      wallet.addListener(
        checkout.OrchestrationEventType.REQUEST_ONRAMP,
        data => {
          console.log('onramp', data)
          wallet.unmount()
          onramp.mount('widget-target', { ...data })
        },
      )

      // Hack to get to render
      const render = Promise.resolve()
      render.then(() => wallet.mount('widget-target'))
      break
    }
    case 'sale': {
      sale?.addListener(SaleEventType.TRANSACTION_SUCCESS, data => {
        console.log(data)
      })
      sale?.addListener(SaleEventType.SUCCESS, data => {
        console.log('success', data)
        emit('success')
      })
      sale?.addListener(SaleEventType.REJECTED, data => {
        console.log(data)
      })
      sale?.addListener(SaleEventType.PAYMENT_METHOD, data => {
        console.log(data)
        if (data.paymentMethod !== 'crypto') {
          sale.unmount()
          onramp.mount('widget-target', { ...data })
        }
      })
      sale?.addListener(SaleEventType.FAILURE, data => {
        console.log(data)
      })

      sale?.addListener(checkout.OrchestrationEventType.REQUEST_SWAP, data => {
        sale.unmount()
        swap.mount('widget-target', { ...data })
      })
      sale?.addListener(OrchestrationEventType.REQUEST_BRIDGE, data => {
        console.log('REQUEST_BRIDGE')
        sale.unmount()
        bridge.mount('widget-target', { ...data })
      })
      sale?.addListener(OrchestrationEventType.REQUEST_ONRAMP, data => {
        console.log('REQUEST_ONRAMP')
        sale.unmount()
        onramp.mount('widget-target', { ...data })
      })

      // Hack to get to render
      const render = Promise.resolve()
      const params = {
        collectionName: 'BOGX',
        environmentId: import.meta.env.VITE_HUB_ENVIRONMENT_ID,
        excludeFiatCurrencies: [],
        excludePaymentTypes: [],
        items: [
          {
            name: props.orderData.name,
            description: props.orderData.description,
            productId: props.orderData.id.toString(),
            qty: props.orderData.qty ?? 1,
            image: props.orderData.metadata.image,
          },
        ],
        language: 'en',
      }
      console.log(params)
      render.then(() => sale?.mount('widget-target', { ...params }))
    }
  }
})
</script>

<template>
  <div id="widget-target"></div>
</template>
