<script setup>
import Video from 'src/components/Video'
import { hasPhone } from 'src/stores/media.js'
import { remToPx } from 'src/sdk/media'
import { useBox } from '../hooks/useBox'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  selectData: {
    type: Array,
    default() {
      return []
    },
  },
  singleChoiceData: {
    type: Object,
    default() {
      return []
    },
  },
})
const emit = defineEmits(['select', 'cancel', 'enlarge', 'check'])
const { hasSelectClassRef, handleEnlarge, handleCheck } = useBox(props, emit)
</script>

<template>
  <div
    class="item overflow-hidden bg-#0E0E0F"
    :class="{ select: hasSelectClassRef }"
    @click="handleCheck"
  >
    <div class="box_item">
      <Video
        :width="!hasPhone ? 300 : remToPx(10.34375)"
        :height="!hasPhone ? 300 : remToPx(10.34375)"
        :src="data.link"
        :controls="false"
        :poster="data.cover"
        autoplay
        loop
        muted
      ></Video>
      <img
        v-if="!hasPhone"
        src="/src/asset/enlarge.webp"
        class="mouse_pointer absolute bottom-1 right-1 w-[24px]"
        @click="handleEnlarge"
      />
      <!-- 视频点击事件会被吞，用这个遮着 -->
      <div v-else class="absolute inset-0"></div>
    </div>
    <div class="fz-3 border_style border-0 border-t px-1.5 py-1">
      <div class="flex-sb">
        <div class="flex-center">
          <span class="mr-2">{{ data.name }}</span>
          <!-- <span class="text-#C0C5CC">BOGX {{ data.tokenId }}</span> -->
        </div>
        <!-- <div
          v-if="!data.ReceiveUid"
          class="relative h-[15px] w-[15px] mouse_pointer border border-#A0A3A5 border-solid"
          @click="handleSelect"
        >
          <img
            v-show="hasSelectRef"
            src="/src/asset/white_tick.webp"
            class="w-[12px] align-super"
            alt=""
          />
        </div> -->
      </div>
      <div class="flex items-center tracking-1px">
        <span class="gray-color mr1">Type: </span>
        <span>NFT</span>
      </div>
      <div class="box_item_rarity tracking-1px">
        <div class="flex items-center pt-0.5">
          <span class="gray-color mr1">Rarity: </span>
          <span :style="{ color: data.quality.color }">{{
            data.quality.text
          }}</span>
        </div>
        <!-- <span>{{ data.name }}</span> -->
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../border';
</style>
