<script setup>
import { NGrid, NGridItem } from 'naive-ui'
import { hasPhone, useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'

const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
  internalKey: {
    type: String,
    default: 'id',
  },
  xGap: {
    type: Number,
    default: 12,
  },
  yGap: {
    type: Number,
    default: 12,
  },
  number2xl: {
    type: Number,
    default: 5,
  },
  numberMD: {
    type: Number,
    default: 3,
  },
  numberPhone: {
    type: Number,
    default: 2,
  },
})
const { min2XL } = storeToRefs(useMediaStore())
</script>

<template>
  <div>
    <NGrid
      :x-gap="xGap"
      :y-gap="yGap"
      :cols="min2XL ? number2xl : !hasPhone ? numberMD : numberPhone"
    >
      <NGridItem v-for="item in list" :key="item[internalKey]">
        <slot name="item" v-bind="item"></slot>
      </NGridItem>
    </NGrid>
  </div>
</template>
