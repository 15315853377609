<script setup>
import { computed, ref } from 'vue'
import Video from 'src/components/Video'
import ArrowBack from 'src/components/ArrowBack'
import { getAppropriateSize, minSize } from '@BOGX/utils'
import { storeToRefs } from 'pinia'
import { hasPhone, useMediaStore } from 'src/stores/media'
import { useScreenSizeChange } from 'src/hooks/useScreenSizeChange'

const props = defineProps({
  show: Boolean,
})

const emit = defineEmits(['update:show'])

const { min2XL } = storeToRefs(useMediaStore())

const currentIndexRef = ref(0)

function handleClose() {
  emit('update:show', false)
}
function handleSwitch(index) {
  currentIndexRef.value = index
}
const staticPath = `${import.meta.env.VITE_FILE_PATH}/soul/`
const list = [
  {
    title: 'HELA, THE EXECUTER',
    describe:
      'A young girl who has lost her memories of the past, seemingly bearing a deep connection to the Helheim.  She yearned to find answers in this illusory world, even though Audhum had said that nothing in it was real.',
    cover: `${staticPath}Soul1.webp`,
    video: `${staticPath}Soul1.mp4`,
  },
  {
    title: 'HIMBJORG',
    describe:
      'The divine sword "Himinbjorg," crafted from obsidian, is the favored weapon of Hela. Whenever she unsheathes the sword, it requires depleting her own spiritual essence to replenish the material of Himinbjorg.',
    cover: `${staticPath}Soul2.webp`,
    video: `${staticPath}/Soul2.mp4`,
  },
  {
    title: 'CHAOS, THE SOUL BLADE',
    describe: `A vengeful warrior that returned from the dead. After slaying the false king and avenging his parents, a hole appeared in Chaos' "heart" , and he no longer took interest in anything.`,
    cover: `${staticPath}Soul3.webp`,
    video: `${staticPath}/Soul3.mp4`,
  },
  {
    title: 'SOUL EATER',
    describe: `The favored battle-axe of Chaos, the handle of which can extend to alter the fighting style. The wielder channels their divine power into the blue soulstone embedded in the axe's core, storing it for a last-ditch effort, to be unleashed in a desperate final stand.`,
    cover: `${staticPath}Soul4.webp`,
    video: `${staticPath}/Soul4.mp4`,
  },
]
const currentItemRef = computed(() => list[currentIndexRef.value])
const videoSizeRef = ref({})
useScreenSizeChange(() => {
  if (hasPhone) {
    videoSizeRef.value.height = `${minSize() * 0.7}px`
    videoSizeRef.value.width = 'auto'
  } else {
    videoSizeRef.value = getAppropriateSize(
      1920,
      1080,
      min2XL.value ? 0.73 : 0.56,
    )
  }

  console.log(
    'videoSizeRef.value-souls',
    videoSizeRef.value.width,
    videoSizeRef.value.height,
  )
})
</script>

<template>
  <div class="mb-2 rounded-1 text-center md:mb-2.5 md:rounded-md">
    <ArrowBack
      v-if="hasPhone"
      class="left-3 top-3 absolute!"
      @click="handleClose"
    />
    <div class="fz-6 orange-color mb-1.5 md:mb-2">
      {{ currentItemRef.title }}
    </div>
    <div class="orange-gray-color fz-2">
      {{ currentItemRef.describe }}
    </div>
  </div>
  <div class="flex">
    <div
      class="mr-1.5 flex-shrink-0 overflow-x-hidden overflow-y-auto md:mr-2"
      :style="{ height: videoSizeRef.height }"
    >
      <template v-for="(item, index) in list" :key="index">
        <div
          class="item mouse_pointer 2xl:h-[198px] 2xl:w-[323px] relative mb-1.5 h-[83.5px] w-[140px] grow rounded-1 md:mb-2 md:h-[158px] md:w-[258px] md:rounded-2"
          :class="{ activate: index === currentIndexRef }"
          @click="handleSwitch(index)"
        >
          <img
            :src="item.cover"
            class="absolute top-0 h-full w-full rounded-1 object-cover md:rounded-2"
          />
          <div class="footer flex-center absolute bottom-0.2 left-0.2 w-full">
            <div
              class="flex-sb mr-1 grow rounded-1 bg-black py-0.4 pl-0.8 md:rounded-2 md:py-0.5 md:pl-1"
            >
              <div class="fz-2">{{ item.title }}</div>
              <div class="flex-center">
                <img
                  class="w-0.6 md:w-1"
                  src="/src/asset/triangle1.webp"
                  alt=""
                />
                <img
                  class="mx-0.4 w-0.8 md:mx-0.5 md:w-1.2"
                  src="/src/asset/triangle2.webp"
                  alt=""
                />
                <img
                  class="mx-0.4 w-1.1 md:mx-0.5 md:w-1.3"
                  src="/src/asset/triangle3.webp"
                  alt=""
                />
              </div>
            </div>
            <img
              class="mr-0.8 w-1.7 w-2 md:mr-1"
              src="/src/asset/triangle3.webp"
              alt=""
            />
          </div>
        </div>
      </template>
    </div>
    <div class="relative flex rounded-md">
      <Video
        :width="videoSizeRef.width"
        :height="videoSizeRef.height"
        :src="list[currentIndexRef].video"
        preload="none"
      ></Video>
      <div v-if="!hasPhone" class="btn" @click="handleClose"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item {
  opacity: 0.85;
  transition: opacity 0.3s;

  .footer {
    opacity: 0;
    transition: opacity 0.3s;
  }

  &.activate {
    border: 1px solid white;
    opacity: 1;

    .footer {
      opacity: 1;
    }
  }

  @include minMD {
    &:hover {
      opacity: 1;

      .footer {
        opacity: 1;
      }
    }
  }
}

.btn {
  position: absolute;
  top: 1%;
  right: -4%;
  background: url('src/asset/close.webp') no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);

  @apply w-[25px] h-[30px] bottom-[55px];
  @extend .mouse_pointer;
}
</style>
