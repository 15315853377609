<script setup>
import { middleEllipsis } from 'src/sdk/blockchain'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import ToggleWallet from 'src/components/ToggleWallet'

const { userInfo } = storeToRefs(useUserInfoStore())
</script>

<template>
  <ToggleWallet class="w-full">
    <div class="flex-sb">
      <img
        src="/src/asset/purse.webp"
        alt=""
        class="align-middl h-13px md:h-[20px]"
      />
      <span class="gray2-color">{{
        userInfo?.wallet
          ? middleEllipsis(userInfo?.wallet, 10, -10)
          : 'Connect Wallet'
      }}</span>
      <span
        :class="`${
          userInfo?.wallet ? 'bg-[#57E077]' : 'bg-[#E73636]'
        } md:w-[8px] md:h-[8px] w-[6px] h-[6px] rounded-[50%]`"
      ></span>
    </div>
  </ToggleWallet>
</template>
