<script setup>
import { useMusicStore } from 'src/stores/music.js'
import { storeToRefs } from 'pinia'
import { MUSIC_PATH } from 'src/constant'

const {
  progressLoadingCompletedAudioRef,
  buttonCaptureAudioRef,
  buttonClickAudioRef,
} = storeToRefs(useMusicStore())
const progress_loading_completed = `${MUSIC_PATH}progress_loading_completed.mp3`
const button_capture = `${MUSIC_PATH}button_capture.mp3`
const button_click = `${MUSIC_PATH}button_click.mp3`
</script>

<template>
  <audio
    ref="progressLoadingCompletedAudioRef"
    :src="progress_loading_completed"
  ></audio>
  <audio ref="buttonCaptureAudioRef" :src="button_capture"></audio>
  <audio ref="buttonClickAudioRef" :src="button_click"></audio>
</template>
