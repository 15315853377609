import { PFP_NAME, PFP_MINT_NAME, PFP_WHITELIST_NAME } from 'src/constant'
import PFPIndex from 'src/pages/PFP/index.vue'
import PFPMint from 'src/pages/PFP/mint/index.vue'
import PFPWhiteList from 'src/pages/PFP/whiteList/index.vue'
const pfpShow = import.meta.env.VITE_PFP_SHOW
const pfpRouter = []
if (pfpShow) {
  pfpRouter.push(
    {
      path: '/pfp',
      name: PFP_NAME,
      component: PFPIndex,
    },
    {
      path: '/pfp/mint',
      name: PFP_MINT_NAME,
      component: PFPMint,
    },
    {
      path: '/pfp/whiteList',
      name: PFP_WHITELIST_NAME,
      component: PFPWhiteList,
    },
  )
}
export { pfpRouter }
