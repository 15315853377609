<script setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import brackets from 'src/asset/nftSoftStaking/guide/step/3/brackets.webp'
import arrow1 from 'src/asset/nftSoftStaking/guide/step/3/arrow1.webp'
import arrow2 from 'src/asset/nftSoftStaking/guide/step/3/arrow2.webp'
import arrow3 from 'src/asset/nftSoftStaking/guide/step/3/arrow3.webp'
import arrow4 from 'src/asset/nftSoftStaking/guide/step/3/arrow4.webp'
import { useNFTSoftPledgeDomStore } from 'src/stores/softPledge'
import ModelBox from './ModelBox'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'
const { guideShow, guideCurrentIndex } = storeToRefs(useNFTSoftPledgeStore())
const {
  mainContainerLeft,
  mainContainerLeftDom,
  bounsHeaderDom,
  bounsBottomDom,
  totalStakingPointDom,
  redemptionDom,
  deleteDeckDom,
  panelDom,
} = storeToRefs(useNFTSoftPledgeDomStore())
const modelRef = ref(null)
const bracketsDomRef = ref(null)
const arrow2DomRef = ref(null)
const { dataRef: bracketsSizeRef } = useBoundingClientRect(bracketsDomRef)
const { dataRef: arrow2SizeRef } = useBoundingClientRect(arrow2DomRef)
const { dataRef: mainContainerLeftSize } =
  useBoundingClientRect(mainContainerLeftDom)
const { dataRef: bounsHeaderSize } = useBoundingClientRect(bounsHeaderDom)
const { dataRef: bounsBottomSize } = useBoundingClientRect(bounsBottomDom)
const { dataRef: totalStakingPointSize } =
  useBoundingClientRect(totalStakingPointDom)
const { dataRef: redemptionSize } = useBoundingClientRect(redemptionDom)
const { dataRef: deleteDeckSize } = useBoundingClientRect(deleteDeckDom)
const { dataRef: panelSize } = useBoundingClientRect(panelDom)
const topDistanceComputed = computed(() => {
  return panelSize.value.top
})
const modelStyleComputed = computed(() => {
  return {
    width:
      mainContainerLeftSize.value.right + mainContainerLeft.value / 2 + 'px',
  }
})
const arrow1BoxStyleComputed = computed(() => {
  return {
    top: bracketsSizeRef.value.height / 2 + 'px',
    right: bracketsSizeRef.value.width + 'px',
  }
})
const bracketsStyleComputed = computed(() => {
  return {
    height: bounsBottomSize.value.bottom - bounsHeaderSize.value.top + 'px',
  }
})
const guide1StyleComputed = computed(() => {
  return {
    top: bounsHeaderSize.value.top - topDistanceComputed.value + 'px',
  }
})

const guide2StyleComputed = computed(() => {
  return {
    top:
      totalStakingPointSize.value.bottom -
      totalStakingPointSize.value.height / 2 -
      arrow2SizeRef.value.height / 4 +
      topDistanceComputed.value +
      'px',
  }
})

const guide3StyleComputed = computed(() => {
  return {
    top: redemptionSize.value.top - topDistanceComputed.value + 'px',
  }
})

const guide4StyleComputed = computed(() => {
  return {
    top:
      deleteDeckSize.value.top -
      deleteDeckSize.value.height * 1.2 -
      topDistanceComputed.value +
      'px',
  }
})
</script>
<template>
  <ModelBox
    ref="modelRef"
    v-show="guideShow && guideCurrentIndex === 2"
    :style="modelStyleComputed"
    class="family_blk_bold fz-5 pr-0!"
  >
    <div class="absolute -right-7" :style="guide1StyleComputed">
      <div class="absolute -translate-y-35%" :style="arrow1BoxStyleComputed">
        <div class="absolute 2xl:-translate-y-25% -translate-y-40%">
          <div class="text-#69F093">BONUS</div>
          <span class="text-#fff"
            >Breakdown of current deck's staking point bonuses</span
          >
        </div>
        <img :src="arrow1" alt="" class="2xl:w-981px w-784px" />
      </div>
      <img
        :src="brackets"
        :style="bracketsStyleComputed"
        class="absolute right-0"
        ref="bracketsDomRef"
      />
    </div>
    <div
      class="absolute -right-4.5 -translate-y-85%"
      :style="guide2StyleComputed"
    >
      <div class="absolute 2xl:translate-y-65% translate-y-32%">
        <div class="text-#69F093">TOTAL STAKING POINT</div>
        <span class="text-#fff"
          >Total staking points of all NFTs within the current deck <br />For
          specific details, click on "Inventory"</span
        >
      </div>
      <img
        :src="arrow2"
        ref="arrow2DomRef"
        alt=""
        class="2xl:w-1042px w-833px"
      />
    </div>
    <div
      class="absolute -right-4.5 -translate-y-20%"
      :style="guide3StyleComputed"
    >
      <div class="absolute -translate-y-100%">
        <div class="text-#69F093">REDEMPTION</div>
        <span class="text-#fff"
          >Staking points can be exchanged for treasure chests in <br />
          the future, stay tuned for updates</span
        >
      </div>
      <img :src="arrow3" alt="" class="2xl:w-1042px w-833px" />
    </div>
    <div
      class="absolute -right-4.5 translate-y-55%"
      :style="guide4StyleComputed"
    >
      <div class="absolute 2xl:-translate-y-45% -translate-y-55%">
        <div class="text-#69F093">DELETE DECK</div>
        <span class="text-#fff"
          >When deleting a deck, all NFTs will be automatically removed <br />
          Staking point bonuses from the NFTs will be lost, <br />so proceed
          with caution</span
        >
      </div>
      <img :src="arrow4" alt="" class="2xl:w-1047px w-837px" />
    </div>
  </ModelBox>
</template>
