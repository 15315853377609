import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useMediaStore } from 'src/stores/media'

export function useListNumber() {
  const { isAdaptation } = storeToRefs(useMediaStore())
  const listNumberComputed = computed(() => {
    switch (isAdaptation.value) {
      case '4xl':
        return 6
      case '3xl':
        return 5
      case '2xl':
        return 4
      case 'xl':
      case 'lg':
        return 3
      default:
        return 2
    }
  })
  return { listNumberComputed }
}
