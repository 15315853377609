<script setup>
import { storeToRefs } from 'pinia'
import { useImmutableIncomeStore } from 'src/stores/immutableIncome'
import { middleEllipsis } from 'src/sdk/blockchain'
const { walletAddress } = storeToRefs(useImmutableIncomeStore())
</script>

<template>
  <div
    class="flex-center fz-2 w-142px h-28px bg-#241D19 border border-solid border-#4C4029 rounded-5px"
  >
    <img
      class="w-20.3px"
      src="src/asset/immutableIncome/Icon_wallet.webp"
      alt=""
    />
    <span class="ml-1.5 text-#A4886A tracking-0.2">{{
      walletAddress ? middleEllipsis(walletAddress) : 'Your Balances'
    }}</span>
  </div>
</template>
