<script setup>
import { ref } from 'vue'
import Loading from 'src/components/Loading'
import { useTentacle } from './utils/useTentacle'
const tentacleRef = ref(null)
const {
  hasLoadCompleteRef,
  handleActive,
  handleIframeLoad,
  stopTentacleUpdate,
  startTentacleUpdate,
} = useTentacle(tentacleRef)
defineExpose({
  hasLoadCompleteRef,
  handleActive,
  stopTentacleUpdate,
  startTentacleUpdate,
})
const src = import.meta.env.VITE_TENTACLE_PATH
</script>

<template>
  <div class="absolute pointer-events-none">
    <iframe
      ref="tentacleRef"
      @load="handleIframeLoad"
      :src="src"
      class="w-full h-full border-none"
      :style="{ opacity: Number(hasLoadCompleteRef) }"
    ></iframe>
    <Loading class="absolute-center" v-show="!hasLoadCompleteRef"></Loading>
  </div>
</template>
