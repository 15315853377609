<script setup>
import { onMounted, onUnmounted, ref, nextTick, watch } from 'vue'
import {
  initSmoothScrolling,
  scrollingDestruction,
} from 'src/helpers/smoothScrolling'
import { initElasticRolling } from './utils'
import { backersInfoMap } from 'src/constant'
import { useReceivingInteractions } from './Tentacle/utils/useReceivingInteractions'
import Tentacle from './Tentacle'
import waveTop from '/src/asset/about/wave_top.svg'
import waveBottom from '/src/asset/about/wave_bottom.svg'
import { gsap } from 'gsap'
import { useElementBounding } from '@vueuse/core'
import { getSmoothScrolling } from 'src/helpers/smoothScrolling'
const scrollTriggerDomRef = ref(null)
const contentDomRef = ref(null)
defineExpose({
  scrollTriggerDom: scrollTriggerDomRef,
})
const emit = defineEmits(['update:enterPage'])
const enterPage = ref(0)

// const leftGruops = [
//   [1, 2],
//   [5, 6],
//   [9, 10],
//   [13, 14],
//   [17, 18],
// ]
const leftGruops = [
  [1, 2],
  [5, 6],
  [9, 10],
  [13, 14],
  [17, 18],
]

const rightGroups = [
  [3, 4],
  [7, 8],
  [11, 12],
  [15, 16],
  [19, 20],
]

const getAssetsFile = url => {
  return new URL(`/src/asset/about/${url}`, import.meta.url).href
}

const getMarginLeft = groupIndex => {
  const skewAngle = -10
  const skewAngleRad = (skewAngle * Math.PI) / 180
  const width = 170
  const offset = Math.tan(skewAngleRad) * width
  return `${groupIndex * offset}px`
}

let pageAnimation
let waveTopAnimation
let triangleAnimation
let triangleGroupAnimation
let rightTriangleAnimation
let triangleBottomAnimation
let bgAnimation
let innerBoxAnimation
let LogoAnimation
let titleBgAnimation
let triangleWhiteAnimation
let descAnimation
let sectionBgAnimation

const backerRefs = ref([])
const tentacleRef = ref(null)
const { handleActive, initSmallParentElement } = useReceivingInteractions(
  tentacleRef,
  backerRefs,
)

function initScroll() {
  sectionBgAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: '.about-page',
      start: '10% 100%',
      end: 'bottom bottom',
      scrub: true,
      markers: false,
      onUpdate() {
        if (activeBacker.value === 0) return
        tentacleRef?.value?.hasLoadCompleteRef &&
          handleActive(activeBacker.value - 1)
        if (backersInfoMap?.[activeBacker.value]?.isPerson) {
          initSmallParentElement()
        }
      },
      onEnterBack() {
        backScroll()
      },
    },
  })
  sectionBgAnimation.fromTo(
    '.section-bg',
    {
      background: `radial-gradient(1% 1% at 50% 2%, #353535c4 0%, #353535c4 6.23%, #0d0c0cc4 96.19%, #0d0c0cc4 100%);`,
    },
    {
      duration: 3,
      ease: 'linear',
      background: `radial-gradient(76.58% 100% at 50% 50%,
        #353535c4 0%,
        #353535c4 6.23%,
        #0d0c0cc4 96.19%,
        #0d0c0cc4 100%)`,
    },
  )
  waveTopAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: '.about-page',
      start: '5% 100%',
      end: 'bottom bottom',
      scrub: true,
      markers: false,
    },
  })
  waveTopAnimation
    .fromTo(
      '.wave-top',
      {
        scaleY: 0,
        transformOrigin: 'bottom',
      },
      {
        scaleY: 1,
        transformOrigin: 'bottom',
        duration: 2,
        ease: 'elastic.out(1,0.6)', // 使用阻力效果的缓动函数
        onStart() {
          enterPage.value = 1
          tentacleRef?.value?.startTentacleUpdate()
          emit('update:enterPage', 1)
        },
        onReverseComplete() {
          tentacleRef?.value?.stopTentacleUpdate()
          emit('update:enterPage', 0)
        },
      },
    )
    .fromTo(
      '.wave-top',
      {
        scaleY: 1,
        transformOrigin: 'bottom',
      },
      {
        scaleY: 0,
        transformOrigin: 'bottom',
        duration: 6,
        ease: 'power1.out', // 使用阻力效果的缓动函数
      },
      '>',
    )
    .fromTo(
      '.wave-bottom',
      {
        scaleY: 0,
        opacity: 0,
        transformOrigin: 'top',
      },
      {
        scaleY: 0.3,
        opacity: 1,
        imagerendering: 'pixelated',
        transformOrigin: 'top',
        duration: 2,
        ease: 'linear', // 使用阻力效果的缓动函数
      },
      '>',
    )
    .fromTo(
      '.wave-bottom',
      {
        scaleY: 0.3,
        transformOrigin: 'top',
      },
      {
        scaleY: 0.1,
        opacity: 1,
        transformOrigin: 'top',
        ease: 'linear', // 使用阻力效果的缓动函数
      },
      '>',
    )
  pageAnimation = gsap.timeline({
    scrollTrigger: {
      trigger: '.about-page',
      start: 'top 80%',
      end: 'bottom bottom',
      scrub: true,
      markers: false,
    },
  })
  pageAnimation
    .fromTo(
      '.left-title',
      {
        x: -200,
        opacity: 0,
      },
      {
        x: 0,
        duration: 8,
        opacity: 1,
        ease: 'power1.out',
      },
    )
    .fromTo(
      '.right-title',
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        duration: 8,
        opacity: 1,
        ease: 'power1.out',
      },
      '-=7.8',
    )
    .fromTo(
      '.line1',
      {
        x: -100,
        y: '40%',
        opacity: 0,
      },
      {
        x: 0,
        y: 0,
        opacity: 1,
        duration: 4,
        ease: 'power1.out',
      },
      '-=4',
    )
    .fromTo(
      '.line3',
      {
        x: -30,
        y: 30,
        opacity: 0,
      },
      {
        x: 0,
        y: 0,
        duration: 2,
        opacity: 1,
        ease: 'power1.out',
      },
      '-=2',
    )
    .fromTo(
      '.line2',
      {
        x: 20,
        y: -20,
        opacity: 0,
      },
      {
        x: 0,
        y: 0,
        duration: 2,
        opacity: 1,
        ease: 'power1.out',
      },
      '-=1.5',
    )

    .from(
      '.left-box',
      {
        x: -150,
        opacity: 0,
        duration: 6,
        stagger: 0.2, // 每个元素之间的延迟
        ease: 'power1.out',
      },
      '-=1.5',
    )
    .from(
      '.right-box',
      {
        x: 150,
        opacity: 0,
        duration: 6,
        stagger: 0.2, // 每个元素之间的延迟
        ease: 'power1.out',
      },
      '-=8',
    )
    .from(
      '.left-logos',
      {
        x: -60,
        opacity: 0,
        duration: 1,
        stagger: 0.2, // 每个元素之间的延迟
        ease: 'power1.out',
      },
      '-=7.5',
    )
    .from(
      '.right-logos',
      {
        x: 60,
        opacity: 0,
        duration: 1,
        stagger: 0.2, // 每个元素之间的延迟
        ease: 'power1.out',
        onReverseComplete() {
          backScroll()
        },
      },
      '-=7.5',
    )
}

function backScroll() {
  const backerRef = backerRefs.value[activeBacker.value - 1]
  const innerBoxRef = backerRef?.getElementsByClassName('inner-box')[0]
  activeBacker.value = 0
  backerRef &&
    gsap.to(backerRef, {
      borderImage: '',
      background: '#070808',
      onStart() {
        initSmallParentElement()
      },
    })
  innerBoxRef &&
    gsap.to(innerBoxRef, {
      opacity: 0,
    })
  triangleGroupAnimation?.reverse()
  LogoAnimation?.reverse()
  titleBgAnimation?.reverse()
  descAnimation?.reverse()
  triangleWhiteAnimation?.reverse()
  triangleAnimation?.kill()
  bgAnimation?.kill()
  innerBoxAnimation?.kill()
  triangleGroupAnimation?.kill()
  rightTriangleAnimation?.kill()
  triangleBottomAnimation?.kill()
}
let activeBacker = ref(0)
// 激活卡片
async function hoverBacker(item) {
  bgAnimation?.reverse()
  if (activeBacker.value === item || !backersInfoMap?.[item]) return
  initSmallParentElement()
  triangleAnimation?.kill()
  triangleWhiteAnimation?.kill()
  descAnimation?.kill()
  LogoAnimation?.reverse()
  innerBoxAnimation?.reverse()
  const oldBackerRef = backerRefs.value[activeBacker.value - 1]
  if (oldBackerRef) {
    oldBackerRef.style.borderImage = ''
  }

  activeBacker.value = item
  nextTick(() => {
    const backerRef = backerRefs.value[item - 1]
    const innerBoxRef = backerRef.querySelector('div')
    const imgRef = backerRef.querySelector('img')
    bgAnimation = gsap.to(backerRef, {
      onStart() {
        backerRef.style.borderImage = `linear-gradient(180deg, #99d8e1, #d7b1da) 1`
      },
    })
    LogoAnimation = gsap.fromTo(
      imgRef,
      {
        filter: 'none',
      },
      {
        filter: 'invert(100%)',
        duration: 0.2,
        ease: 'linear',
      },
    )
    innerBoxAnimation = gsap.fromTo(
      innerBoxRef,
      {
        clipPath: 'polygon(-50% 50%, 50% 150%, 50% 150%, -50% 50%)',
      },
      {
        opacity: 0.6,
        clipPath: 'polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%)',
        duration: 0.4,
        ease: 'power1.out',
        onStart() {
          const length = Object.keys(backersInfoMap).length
          if (item <= length) {
            tentacleRef?.value?.hasLoadCompleteRef && handleActive(item - 1)
          }
        },
      },
    )
    triangleGroupAnimation = gsap.fromTo(
      '.triangle-groups',
      {
        x: 50, // 平移距离
        opacity: 0,
      },
      {
        x: 0,
        stagger: 0.05,
        opacity: 1,
        ease: 'power1.out', // 匀速运动
      },
    )
    rightTriangleAnimation = gsap.fromTo(
      '.triangle-right',
      {
        x: 0,
      },
      {
        x: 10, // 平移距离
        delay: 1,
        duration: 2,
        repeat: -1, // 无限循环
        yoyo: true, // 回到起始位置
        ease: 'linear', // 匀速运动
      },
    )
    triangleBottomAnimation = gsap.fromTo(
      '.triangle-bottom',
      {
        x: 0,
      },
      {
        x: 3, // 平移距离
        duration: 2,
        delay: 1,
        repeat: -1, // 无限循环
        yoyo: true, // 回到起始位置
        ease: 'linear', // 匀速运动
      },
    )
    titleBgAnimation = gsap.fromTo(
      '.title-bg',
      {
        x: 400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power1.out',
      },
    )
    triangleWhiteAnimation = gsap.fromTo(
      '.triangle-white',
      {
        x: -50,
        opacity: 0,
      },
      {
        x: 0,
        delay: 0.4,
        opacity: 1,
        ease: 'power1.out',
      },
    )
    descAnimation = gsap.fromTo(
      '.font-desc',
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        delay: 0.4,
        opacity: 1,
        ease: 'power1.out',
        onComplete() {
          // 创建新的循环平移动画实例
          triangleAnimation?.kill()
          triangleAnimation = gsap.to('.triangle-white', {
            x: -10, // 平移距离
            duration: 1,
            delay: 0,
            repeat: -1, // 无限循环
            yoyo: true, // 回到起始位置
            ease: 'linear', // 匀速运动
          })
        },
      },
    )
  })
}

async function hoverBackerPerson(item) {
  bgAnimation?.reverse()
  if (activeBacker.value === item || !backersInfoMap?.[item]) return
  initSmallParentElement()
  triangleAnimation?.kill()
  triangleWhiteAnimation?.kill()
  descAnimation?.kill()
  LogoAnimation?.reverse()
  innerBoxAnimation?.reverse()
  const oldBackerRef = backerRefs.value[activeBacker.value - 1]
  if (oldBackerRef) {
    oldBackerRef.style.borderImage = ''
  }

  activeBacker.value = item
  nextTick(() => {
    const backerRef = backerRefs.value[item - 1]
    const innerBoxRef = backerRef.querySelector('img:nth-child(2)')
    const imgRef = backerRef.querySelector('img:nth-child(1)')
    bgAnimation = gsap.to(backerRef, {
      onStart() {
        backerRef.style.borderImage = `linear-gradient(180deg, #99d8e1, #d7b1da) 1`
      },
    })
    LogoAnimation = gsap.fromTo(
      imgRef,
      {
        opacity: 1,
      },
      {
        opacity: 0,
        duration: 0.2,
        ease: 'linear',
      },
    )
    innerBoxAnimation = gsap.fromTo(
      innerBoxRef,
      {
        clipPath: 'polygon(-50% 50%, 50% 150%, 50% 150%, -50% 50%)',
      },
      {
        opacity: 1,
        clipPath: 'polygon(50% -50%, 150% 50%, 50% 150%, -50% 50%)',
        duration: 0.4,
        ease: 'power1.out',
        onStart() {
          if (backersInfoMap?.[item]) {
            tentacleRef?.value?.hasLoadCompleteRef && handleActive(item - 1)
            initSmallParentElement()
          }
        },
      },
    )
    triangleGroupAnimation = gsap.fromTo(
      '.triangle-groups',
      {
        x: 50, // 平移距离
        opacity: 0,
      },
      {
        x: 0,
        stagger: 0.05,
        opacity: 1,
        ease: 'power1.out', // 匀速运动
      },
    )
    rightTriangleAnimation = gsap.fromTo(
      '.triangle-right',
      {
        x: 0,
      },
      {
        x: 10, // 平移距离
        delay: 1,
        duration: 2,
        repeat: -1, // 无限循环
        yoyo: true, // 回到起始位置
        ease: 'linear', // 匀速运动
      },
    )
    triangleBottomAnimation = gsap.fromTo(
      '.triangle-bottom',
      {
        x: 0,
      },
      {
        x: 3, // 平移距离
        duration: 2,
        delay: 1,
        repeat: -1, // 无限循环
        yoyo: true, // 回到起始位置
        ease: 'linear', // 匀速运动
      },
    )
    titleBgAnimation = gsap.fromTo(
      '.title-bg',
      {
        x: 400,
        opacity: 0,
      },
      {
        x: 0,
        opacity: 1,
        duration: 0.5,
        ease: 'power1.out',
      },
    )
    triangleWhiteAnimation = gsap.fromTo(
      '.triangle-white',
      {
        x: -50,
        opacity: 0,
      },
      {
        x: 0,
        delay: 0.4,
        opacity: 1,
        ease: 'power1.out',
      },
    )
    descAnimation = gsap.fromTo(
      '.font-desc',
      {
        x: 200,
        opacity: 0,
      },
      {
        x: 0,
        delay: 0.4,
        opacity: 1,
        ease: 'power1.out',
        onComplete() {
          // 创建新的循环平移动画实例
          triangleAnimation?.kill()
          triangleAnimation = gsap.to('.triangle-white', {
            x: -10, // 平移距离
            duration: 1,
            delay: 0,
            repeat: -1, // 无限循环
            yoyo: true, // 回到起始位置
            ease: 'linear', // 匀速运动
          })
        },
      },
    )
  })
}
function getContentHeight() {
  return contentDomRef.value.offsetHeight
}
function getTotalScroll() {
  const totalScroll =
    document.getElementById('app').offsetHeight - getContentHeight()
  return totalScroll
}
function initElasticScroll() {
  const scroll = getSmoothScrolling()
  if (scroll) {
    function getQuitingMinHeight() {
      return getTotalScroll() - scrollTriggerDomRef.value.offsetHeight
    }
    function getEnteringMinHeight() {
      return getQuitingMinHeight() + getContentHeight() * 0.4
    }
    initElasticRolling(scroll, {
      getTotalScroll,
      getQuitingMinHeight,
      getEnteringMinHeight,
    })
  }
}

function goOffice(link) {
  window.open(link, '_blank')
}
const titleRef = ref(null)
const titleWidth = ref(0)
watch(activeBacker, val => {
  nextTick(() => {
    const { width } = useElementBounding(titleRef.value)
    titleWidth.value = parseInt(width.value + 140)
  })
})
onMounted(() => {
  // TODO 弹窗滚动问题
  initSmoothScrolling()
  initScroll()
  initElasticScroll()
})

onUnmounted(() => {
  scrollingDestruction()
  pageAnimation?.kill()
  waveTopAnimation?.kill()
  triangleAnimation?.kill()
  bgAnimation?.kill()
  innerBoxAnimation?.kill()
  triangleGroupAnimation?.kill()
  rightTriangleAnimation?.kill()
  triangleBottomAnimation?.kill()
  titleBgAnimation?.kill()
  triangleWhiteAnimation?.kill()
  sectionBgAnimation?.kill()
})
</script>

<template>
  <div class="about-page overflow-hidden" ref="scrollTriggerDomRef">
    <div class="relative w-full h-62px">
      <waveTop
        class="wave-top absolute h-40.8px w-429px bottom-0 left-50% -translate-x-50%"
      ></waveTop>
    </div>
    <div class="relative section overflow-hidden" ref="contentDomRef">
      <div class="section-bg absolute top-0 left-0 w-full h-full"></div>
      <waveBottom
        class="wave-bottom opacity-0 absolute h-44.8px w-429px top-0 left-50% -translate-x-50%"
      ></waveBottom>
      <Tentacle
        v-if="enterPage"
        ref="tentacleRef"
        class="absolute border-0 -right-20vw -bottom-40vw overflow-hidden w-120vw h-120vw"
      ></Tentacle>
      <div class="absolute top-[140px] right-0">
        <div
          class="title-bg relative flex justify-end flex-items-center opacity-0 fz-5 font-bold h-38px bg-#fff color-#000 pr-100px"
          :style="{ width: titleWidth + 'px' }"
        >
          <div
            ref="titleRef"
            class="font-desc h-full leading-44px family_blk whitespace-nowrap"
          >
            {{ backersInfoMap?.[activeBacker]?.title }}
          </div>
        </div>
        <div
          class="triangle-white w-14px h-14px opacity-0 bg-#fff absolute top-24px -left-4px"
        ></div>
      </div>
      <div class="absolute top-[178px] right-0">
        <div class="w-380px pr-100px flex flex-col flex-items-end">
          <img
            v-if="backersInfoMap?.[activeBacker]?.peopleImg"
            class="font-desc mt-6 w-151px h-154px"
            :src="getAssetsFile(backersInfoMap?.[activeBacker]?.peopleImg)"
            alt=""
          />
          <div
            class="font-desc w-390px text-14px font-bold text-right mt-5 color-#fff family_blk"
            v-if="backersInfoMap?.[activeBacker]?.name"
          >
            <div
              class="mb-2px"
              v-html="backersInfoMap?.[activeBacker]?.name"
            ></div>
            <div v-html="backersInfoMap?.[activeBacker]?.job"></div>
          </div>
          <div
            v-html="backersInfoMap?.[activeBacker]?.desc"
            class="font-desc mt-5 color-#fff text-xs text-right leading-24px tracking-[1.7px] family_th"
          ></div>
        </div>
      </div>
      <div
        class="line1 bg-#816A51 w-[1px] h-[110vh] absolute top-0 bottom-0 left-600px rotate-10 origin-top-center"
      ></div>
      <div
        class="line2 bg-#816A51 w-[70px] h-[1px] absolute top-101px left-568px -rotate-45 origin-top-center"
      ></div>
      <div
        class="line3 bg-#816A51 w-[70px] h-[1px] absolute top-151px left-518px -rotate-45 origin-top-center"
      ></div>
      <div
        class="left-title investors-logo w-[458px] h-[75px] absolute top-70px left-110px"
      ></div>
      <div
        class="right-title backers-logo w-[380px] h-[81px] absolute top-110px left-580px"
      ></div>
      <div class="flex flex-wrap w-[356px] absolute top-200px left-200px">
        <div
          v-for="(group, index) in leftGruops"
          :key="index"
          :style="{ marginLeft: getMarginLeft(index) }"
          class="flex w-[356px] mb-2.5 relative"
        >
          <div
            @mouseenter="
              !backersInfoMap?.[item]?.isPerson
                ? hoverBacker(item)
                : hoverBackerPerson(item)
            "
            @click="
              backersInfoMap?.[item] && goOffice(backersInfoMap?.[item]?.link)
            "
            :ref="el => (backerRefs[item - 1] = el)"
            v-for="item in group"
            :key="item"
            :style="{
              backgroundColor: backersInfoMap?.[item] ? '#070808' : '#10100f33',
              borderColor: backersInfoMap?.[item] ? '#BCC6C8' : '#a3a3a333',
            }"
            class="left-box mouse_pointer relative mr-5 w-[186px] h-[161px] border border-solid -skew-x-10 transform-gpu"
          >
            <div
              v-if="!backersInfoMap?.[item]?.isPerson"
              class="inner-box absolute top-1.5px left-1.5px w-98% h-98% bg-#fff opacity-0 filter"
            ></div>
            <div
              v-if="!backersInfoMap?.[item]"
              class="gray-box absolute bg-#00000029 w-full h-full"
            ></div>
            <img
              v-if="backersInfoMap?.[item]"
              class="left-logos w-192px absolute -left-5 z-3 -top-2.5 skew-x-10"
              :src="getAssetsFile(`logos${item}.webp`)"
              alt=""
            />
            <img
              class="inner-box w-185px h-161px absolute z-3 -left-3.7 -top-0.3 opacity-0 skew-x-9.5"
              :src="getAssetsFile(`active_logos${item}.webp`)"
              alt=""
            />
            <div
              v-if="item === activeBacker"
              class="absolute -right-16px -top-6px flex flex-items-center skew-x-10"
            >
              <div
                class="triangle-groups triangle-top w-5px h-5px bg-#fff"
              ></div>
              <div
                class="triangle-groups triangle-bottom w-5px h-5px bg-#fff/[.4]"
              ></div>
              <div
                class="triangle-groups triangle-top triangle-right w-5px h-5px bg-#fff/[.2]"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-[356px] absolute top-230px left-560px">
        <div
          v-for="(group, index) in rightGroups"
          :key="index"
          :style="{ marginLeft: getMarginLeft(index) }"
          class="flex w-[356px] mb-2.5"
        >
          <div
            @mouseenter="
              !backersInfoMap?.[item]?.isPerson
                ? hoverBacker(item)
                : hoverBackerPerson(item)
            "
            @click="
              backersInfoMap?.[item] && goOffice(backersInfoMap?.[item]?.link)
            "
            :ref="el => (backerRefs[item - 1] = el)"
            v-for="item in group"
            :key="item"
            :style="{
              backgroundColor: backersInfoMap?.[item] ? '#070808' : '#10100f33',
              borderColor: backersInfoMap?.[item] ? '#BCC6C8' : '#a3a3a333',
            }"
            class="right-box mouse_pointer relative mr-5 w-[186px] h-[161px] border border-solid -skew-x-10 transform-gpu"
          >
            <div
              class="inner-box absolute top-1.5px left-1.5px w-98% h-98% bg-#fff opacity-0 filter"
            ></div>
            <div
              v-if="!backersInfoMap?.[item]"
              class="gray-box absolute w-full h-full bg-#00000029"
            ></div>
            <img
              v-if="backersInfoMap?.[item]"
              class="right-logos w-192px absolute z-3 -left-5 -top-2.5 skew-x-10"
              :src="getAssetsFile(`logos${item}.webp`)"
              alt=""
            />
            <img
              class="inner-box w-185px h-161px absolute z-3 -left-3.7 -top-0.3 opacity-0 skew-x-9.5"
              :src="getAssetsFile(`active_logos${item}.webp`)"
              alt=""
            />
            <div
              v-if="item === activeBacker"
              class="absolute -right-16px -top-7px flex flex-items-center skew-x-10"
            >
              <div
                class="triangle-groups opacity-0 triangle-top w-5px h-5px bg-#fff"
              ></div>
              <div
                class="triangle-groups opacity-0 triangle-bottom -ml-1px w-5px h-5px bg-#fff/[.4]"
              ></div>
              <div
                class="triangle-groups opacity-0 triangle-top -ml-1px triangle-right w-5px h-5px bg-#fff/[.2]"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="absolute z-1 border-0 right-150px -bottom-100px overflow-hidden w-800px h-800px"
      ></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.wave-top {
  background: url('/src/asset/about/wave_top.webp');
  background-size: 100% 100%;
}

.wave-bottom {
  background: url('/src/asset/about/wave_bottom.webp');
  background-size: 100% 100%;
}

.section {
  position: relative;
  width: 100%;
  height: 100vh;
  background: #141415;
  box-shadow: 0 0 0 0.5px #cdb17a;

  .section-bg {
    background: radial-gradient(
      76.58% 100% at 50% 50%,
      #353535c4 0%,
      #353535c4 6.23%,
      #0d0c0cc4 96.19%,
      #0d0c0cc4 100%
    );
    filter: blur(4px);
  }

  .title-bg {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 20px 100%);
  }

  .triangle-white {
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }

  .triangle-top {
    clip-path: polygon(50% 0, 100% 100%, 0 100%);
  }

  .triangle-bottom {
    clip-path: polygon(0 0, 100% 0, 50% 100%);
  }

  .investors-logo {
    background-image: url('src/asset/about/Investors.webp');
    background-size: 100% 100%;
  }

  .backers-logo {
    background-image: url('src/asset/about/Backers.webp');
    background-size: 100% 100%;
  }

  .gray-box {
    backdrop-filter: blur(8px);
  }
}
</style>
