<script setup>
import eggImg from 'src/asset/PFP/egg.webp'
import Video from 'src/components/Video'
// import { NImage } from 'naive-ui'
import { computed, ref } from 'vue'
import { knockingEggsVideo } from 'src/pages/PFP/ethereal'
const emit = defineEmits('end')
const props = defineProps({
  id: [String, Number],
  name: {
    type: String,
    default: 'ETHEREAL',
  },
  progress: {
    type: Number,
    default: 0,
  },
  hasBroken: Boolean,
})
const boxDomRef = ref(null)
const widthComputed = computed(() => {
  if (boxDomRef.value) {
    return boxDomRef.value.getBoundingClientRect().width
  } else {
    return 0
  }
})
const progressDataComputed = computed(() => {
  const progress = props.progress
  let data
  if (progress === 1) {
    data = {
      progressBg: '#c5630d',
      borderColor: '#dc7520',
    }
  } else {
    data = {
      progressBg: '#A4DFE0',
      borderColor: '#515E60',
    }
  }
  return {
    width: progress * 100 + '%',
    ...data,
  }
})
const eggVideo =
  import.meta.env.VITE_FILE_PATH + '/Ethereal-Egg-Single-Render_x264.mp4'
const videoDataComputed = computed(() => {
  if (props.hasBroken) {
    return {
      src: knockingEggsVideo,
      loop: false,
      ended: () => emit('end'),
    }
  } else {
    return {
      src: eggVideo,
      loop: true,
    }
  }
})
</script>
<template>
  <div
    class="item rounded-1.25 bg-#1C2424 border border-solid border-[--pfp-border5] h-full"
  >
    <div class="w-full mb-2.75 overflow-hidden rounded-1.25">
      <div class="w-full relative" ref="boxDomRef">
        <Video
          class="w-full rounded-1.25"
          @ended="videoDataComputed.ended"
          :src="videoDataComputed.src"
          :poster="eggImg"
          :controls="false"
          :width="widthComputed"
          :loop="videoDataComputed.loop"
          autoplay
          muted
        ></Video>
        <!-- <NImage
          lazy
          :src="eggImg"
          :alt="name"
          :preview-disabled="true"
          width="100%"
          object-fit="cover"
          class="rounded-1.25"
        /> -->
        <div
          class="bg-#090C0C h-6px border border-solid absolute bottom-0 w-full"
          :style="{ borderColor: progressDataComputed.borderColor }"
        >
          <div
            class="h-full"
            :style="{
              width: progressDataComputed.width,
              backgroundColor: progressDataComputed.progressBg,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div class="flex-col flex items-center">
      <span class="block text-#4F6464 fz-1">{{ name }}</span>
      <span class="mb-3.5 fz-2 text-#73ABAB">NO.{{ id }}</span>
    </div>
  </div>
</template>

<style scoped lang="scss">
.item {
  box-shadow: 0 1px 12px 3px var(--pfp-shadow-color);
}
</style>
