<script setup>
import { ref } from 'vue'

const emit = defineEmits(['buy'])
const props = defineProps({
  productData: Object,
})

const count = ref(1)

function incrementQty() {
  count.value += 1
}

function decrementQty() {
  if (count.value > 1) {
    count.value -= 1
  }
}

function handleBuy() {
  emit('buy', {
    count: count.value,
    id: props.productData.id,
  })
}
</script>
<template>
  <div class="box px-7px relative w-330px h-539px mr-32px last:mr-0">
    <div
      class="flex pt-12px flex-col flex-items-center -mt-10px w-full border-1.5px border-solid border-#59443E bg-#090603"
    >
      <img
        class="w-285px h-274px border border-solid border-#5F574E"
        :src="productData.metadata.image"
        alt=""
      />
      <div
        class="bg-item pt-11px fz-5 w-full flex flex-col flex-items-center mt-13px border border-x-0 border-solid border-#59443E"
      >
        <div class="bg-name flex-center w-285px h-28px">
          <span class="text-#FFE1A1 mr-20px">{{ productData.name }}</span>
        </div>
        <div class="fz-3 mt-10px text-#FFE1A1">
          <span class="text-#A7A199 mr-1.5">{{
            productData.pricing[0].currency
          }}</span
          >{{ (productData.pricing[0].amount * 1000 * count) / 1000 }}
        </div>
        <div class="flex-center mt-20px">
          <img
            @click="decrementQty"
            class="w-51px h-40px mouse_pointer"
            src="src/asset/immutableIncome/Icon_minus.webp"
            alt=""
          />
          <div class="bg-num w-52px h-40px flex-center -mx-12px">
            {{ count }}
          </div>
          <img
            @click="incrementQty"
            class="w-51px h-40px mouse_pointer"
            src="src/asset/immutableIncome/Icon_add.webp"
            alt=""
          />
        </div>
        <div
          @click="handleBuy"
          class="btn-buy mouse_pointer w-full h-32px mt-20px flex-center border border-solid border-#C9771F text-#FFE1A1"
        >
          BUY NOW
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  background: linear-gradient(
    180deg,
    rgba(0 0 0 / 100%) 0%,
    rgba(6 4 4 / 63%) 86.43%,
    rgba(28 24 23 / 63%) 100%
  );

  .bg-item {
    background: linear-gradient(180deg, #331e0a 0%, #1b1208 100%);
  }

  .bg-name {
    @include bgAuto('src/asset/immutableIncome/bg_item.webp');
  }

  .bg-num {
    @include bgAuto('src/asset/immutableIncome/bg_num.webp');
  }

  .btn-buy {
    background: linear-gradient(90deg, #9b4c29 0%, #a55e2e 100%);

    &:hover {
      background: linear-gradient(90deg, #c3653c 0%, #d78b39 100%);
    }
  }
}
</style>
