<script setup>
import { constantCase } from 'change-case'
import { NSpin, NGrid, NGridItem } from 'naive-ui'
import WalletBox from 'src/components/WalletBox.vue'
import Modal from 'src/components/Modal'
import closeImg from 'src/asset/close.webp'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { addLoadingRef } = storeToRefs(useNFTSoftPledgeStore())
const { addNFTStakingCombination } = nftSoftPledgeStore

const emit = defineEmits(['update:show'])
const props = defineProps({
  list: Array,
})

const pfpList = computed(() => {
  props.list && props.list.unshift({ type: 'delete' })
  return props.list
})

const typeDict = {
  OLD_ONES: '#73FC90',
  FROST_GIANT: '#73FCF4',
  DEITY: '#73D7FC',
  DRAGON: '#FCA073',
  ELF: '#FFF799',
  MORTAL: '#c0c0c0',
}

function handleShow(b) {
  emit('update:show', b)
}

async function handleClick(tokenId) {
  try {
    await addNFTStakingCombination(0, tokenId)
    handleShow(false)
  } catch (error) {
    console.log(error)
  }
}

function handleDelete() {}
</script>
<template>
  <Modal @update:show="handleShow">
    <div
      class="bg-#040405 relative border border-solid border-#253D48 px-8 pt-5 pb-7 w-85vw item_container"
    >
      <div
        class="fz-7 mb-4.5 tracking-1 ml-4 flex items-center justify-between"
      >
        <span class="family_blk text-36px flex-1 flex-center">
          CLICK ICON TO REPLACE THE CURRENT GENESIS NFT
        </span>
        <img
          :src="closeImg"
          @click="handleShow(false)"
          class="w-24px mouse_pointer"
        />
      </div>
      <div
        class="w-full h-10px bg-#2A2D31 border border-solid border-#616568"
      ></div>
      <div
        class="w-full relative md:h-auto 2xl:h-760px pb-20px border border-t-0 border-solid border-#616568 overflow-y-auto"
      >
        <ul class="px-40px py-30px flex flex-wrap min-h-680px">
          <NGrid :x-gap="30" :y-gap="30" :cols="6">
            <NGridItem v-for="item in pfpList" :key="item?.id">
              <div class="mouse_pointer">
                <WalletBox
                  class="flex flex-col w-full"
                  :cd="() => !addLoadingRef && handleClick(item?.tokenId)"
                >
                  <img
                    v-if="item.type"
                    @click="handleDelete"
                    class="w-full h-auto"
                    src="src/asset/nftSoftStaking/del_pfp.webp"
                    alt=""
                  />
                  <img
                    v-else
                    :style="{
                      borderColor:
                        item && typeDict[constantCase(item?.Species)],
                    }"
                    class="w-full h-auto border border-solid overflow-hidden"
                    :src="item?.image"
                    alt=""
                  />
                  <div
                    v-if="!item.type"
                    class="flex flex-items-center justify-between md:text-12px 2xl:text-21px"
                  >
                    <div
                      class="md:w-88px md:h-18px 2xl:w-146px 2xl:h-31px px-2px flex-center family_blk md:text-12px 2xl:text-20px md:tracking-0.7px 2xl:tracking-0.8px"
                      :class="`bg-${constantCase(item?.Species)}`"
                    >
                      {{ item?.Species?.toUpperCase() }}
                    </div>
                    <div class="text-17px">
                      {{ item?.id }}
                    </div>
                  </div>
                </WalletBox>
              </div>
            </NGridItem>
          </NGrid>
        </ul>
        <div v-if="addLoadingRef" class="absolute left-0 top-0 h-full w-full">
          <NSpin :size="100" class="absolute-center"></NSpin>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.bg-OLD_ONES {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/OLD_ONES.webp');
}

.bg-FROST_GIANT {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/FROST_GIANT.webp');
}

.bg-DEITY {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/DEITY.webp');
}

.bg-DRAGON {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/DRAGON.webp');
}

.bg-ELF {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/ELF.webp');
}

.bg-MORTAL {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/MORTAL.webp');
}
</style>
