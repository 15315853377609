import { onMounted, onUnmounted, ref } from 'vue'
import { hasPhone } from 'src/stores/media'

export function useEnter() {
  const oneEnterRef = ref(false)
  const distance = 400
  function handleScroll() {
    const scrollTop = window.scrollY || document.documentElement.scrollTop
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight
    console.log('documentHeight', documentHeight, windowHeight)
    // TODO 偶发性首页未按需加载，怀疑是页面高度问题
    if (documentHeight === windowHeight) return
    if (documentHeight - (scrollTop + windowHeight) <= distance) {
      oneEnterRef.value = true
      window.removeEventListener('scroll', handleScroll)
    }
  }
  onMounted(() => {
    if (hasPhone) return
    window.addEventListener('scroll', handleScroll)
    handleScroll()
  })
  onUnmounted(() => {
    if (hasPhone) return
    window.removeEventListener('scroll', handleScroll)
  })
  return {
    oneEnterRef,
  }
}
