import { hasPhone } from 'src/stores/media'
import { gsap } from 'gsap'
import {
  lightLeftFrontPositions,
  lightRightFrontPositions,
  mirrorOperations,
} from './mirrorOperations'

export class Scroll {
  mirrorOperationsIndex = hasPhone ? 1 : 0
  tlLookAt
  tlPosition
  constructor() {
    this.currentCameraLookAt = JSON.parse(
      JSON.stringify(mirrorOperations[this.mirrorOperationsIndex].lookAt),
    )
    this.currentCameraPosition = JSON.parse(
      JSON.stringify(mirrorOperations[this.mirrorOperationsIndex].position),
    )
    this.currentLightLeftLookAt = JSON.parse(
      JSON.stringify(
        lightLeftFrontPositions[this.mirrorOperationsIndex].lookAt,
      ),
    )
    this.currentLightLeftPosition = JSON.parse(
      JSON.stringify(
        lightLeftFrontPositions[this.mirrorOperationsIndex].position,
      ),
    )
    this.currentLightRightLookAt = JSON.parse(
      JSON.stringify(
        lightRightFrontPositions[this.mirrorOperationsIndex].lookAt,
      ),
    )
    this.currentLightRightPosition = JSON.parse(
      JSON.stringify(
        lightRightFrontPositions[this.mirrorOperationsIndex].position,
      ),
    )
  }

  startMirrorOperation(trigger) {
    const options = {
      // ease: 'none',
      scrollTrigger: {
        trigger,
        start: 'top bottom',
        end: 'bottom bottom',
        scrub: true,
        // markers: true,
        // id: 'scrub',
      },
    }
    this.unload()
    // 镜头时间线
    this.tlLookAt = gsap.timeline(options)
    // 相机位置线
    this.tlPosition = gsap.timeline(options)
    // 左灯镜头时间线
    // const tlLightLeftLookAt = gsap.timeline(options)
    // // 左灯相机位置线
    // const tlLightLeftPosition = gsap.timeline(options)
    // // 右灯镜头时间线
    // const tlLightRightLookAt = gsap.timeline(options)
    // // 右灯相机位置线
    // const tlLightRightPosition = gsap.timeline(options)
    mirrorOperations.forEach((mirrorOperation, index) => {
      this.tlLookAt.to(this.currentCameraLookAt, {
        ...mirrorOperation.lookAt,
      })
      this.tlPosition.to(this.currentCameraPosition, {
        ...mirrorOperation.position,
      })
      // const lightLeftData = lightLeftFrontPositions[index]
      // tlLightLeftLookAt.to(currentLightLeftLookAt, {
      //   ...lightLeftData.lookAt,
      // })
      // tlLightLeftPosition.to(currentLightLeftPosition, {
      //   ...lightLeftData.position,
      // })
      // const lightRightData = lightRightFrontPositions[index]
      // tlLightRightLookAt.to(currentLightRightLookAt, {
      //   ...lightRightData.lookAt,
      // })
      // tlLightRightPosition.to(currentLightRightPosition, {
      //   ...lightRightData.position,
      // })
    })
    return this
  }

  unload() {
    this.tlLookAt?.kill()
    this.tlPosition?.kill()
    return this
  }
}
