import { useMessage } from 'naive-ui'
import { ref, watch, watchEffect } from 'vue'
import { codeToMessage, selectMax } from 'src/constant'

export function useSelectData() {
  const message = useMessage()
  const selectDataRef = ref([])
  const itemDetailShowRef = ref(false)
  const singleChoiceDataRef = ref(null)
  const currentUserIDRef = ref()
  const previewSrcRef = ref('')
  const transferShowRef = ref(false)
  const currentSelectDataRef = ref([])
  const previewTypeRef = ref(1)
  const previewShowRef = ref(false)
  const previewDataRef = ref(null)

  watchEffect(() => {
    if (selectDataRef.value.length) {
      singleChoiceDataRef.value = null
    }
  })
  watch(itemDetailShowRef, show => {
    if (!show) {
      singleChoiceDataRef.value = null
    }
  })
  function handleSelect(Id) {
    if (selectDataRef.value.length < selectMax) {
      selectDataRef.value.push(Id)
    }
  }
  function handleCancel(index) {
    selectDataRef.value.splice(index, 1)
  }
  function handleEnlarge(data, type = 1) {
    previewShowRef.value = true
    previewSrcRef.value = data.Icon || data.src || data.link
    previewTypeRef.value = type
    previewDataRef.value = data
  }
  function handleClose() {
    previewShowRef.value = false
  }
  function handleValidate(UserID, selectData) {
    if (!UserID) {
      message.warning('Please select a hero.')
    } else if (!selectData) {
      message.warning('Please select an item.')
    } else {
      currentUserIDRef.value = UserID
      currentSelectDataRef.value = selectData
      transferShowRef.value = true
    }
  }
  function handleTransferSuccess() {
    message.success('success')
    selectDataRef.value = selectDataRef.value.filter(
      item => !currentSelectDataRef.value.includes(item),
    )
    itemDetailShowRef.value = false
  }
  function handleTransferError(error) {
    message.error(codeToMessage(error?.data?.code))
  }
  function handleCheck(data) {
    if (!selectDataRef.value.length) {
      singleChoiceDataRef.value = data
      itemDetailShowRef.value = true
    }
  }
  function handleEmptySelect() {
    selectDataRef.value.length = 0
  }
  function handleItemDetailClose() {
    itemDetailShowRef.value = false
  }

  return {
    selectDataRef,
    itemDetailShowRef,
    singleChoiceDataRef,
    currentUserIDRef,
    previewSrcRef,
    transferShowRef,
    currentSelectDataRef,
    previewTypeRef,
    previewShowRef,
    previewDataRef,
    handleSelect,
    handleCancel,
    handleEnlarge,
    handleClose,
    handleValidate,
    handleTransferSuccess,
    handleTransferError,
    handleCheck,
    handleEmptySelect,
    handleItemDetailClose,
  }
}
