<script setup>
import { storeToRefs } from 'pinia'
import { NDivider, NForm, useMessage } from 'naive-ui'
import { useBindEmailStore } from 'src/stores/user'
import { ref } from 'vue'
import { fetchBindEmail } from 'src/api'
import { fetchUserInfoP } from 'src/helpers/user'
import { useAsyncState } from '@vueuse/core'
import { getEmailLoginModel, getEmailLoginRules } from 'src/model/user'
import { codeToMessage } from 'src/constant'
import { hasPhone } from 'src/stores/media'
import Modal from '../Modal.vue'
import Protocol from '../Protocol.vue'
import EmailCodeInput from './EmailCodeInput'
import ThreePartyLogin from './ThreePartyLogin'

const formRef = ref(null)

const modelData = getEmailLoginModel()
const modelRef = ref(modelData)
const agreeRef = ref(null)

const message = useMessage()

const bindEmailStore = useBindEmailStore()
const { success: bindEmailSuccess, errorClose: errorCloseBindEmail } =
  bindEmailStore
const { show: bindEmailShow } = storeToRefs(bindEmailStore)

const { isLoading, execute } = useAsyncState(
  async () => {
    await fetchBindEmail(modelRef.value)
    const userInfo = await fetchUserInfoP.run()
    handleSuccess(userInfo)
    return null
  },
  null,
  {
    immediate: false,
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
  },
)
function handleSuccess(userInfo) {
  // 绑定邮箱成功
  message.success('Email binding successful.')
  bindEmailSuccess(userInfo)
}

async function handleSubmit(e) {
  e.preventDefault()
  if (!agreeRef.value) return
  await formRef.value?.validate()
  await execute()
}
</script>

<template>
  <Modal :show="bindEmailShow" @update:show="errorCloseBindEmail">
    <div class="user_modal">
      <span class="modal_triangle_top top-0"></span>
      <img src="/src/asset/labs_logo.webp" alt="" class="logo" />
      <p class="fz-4 mb-9 mt-3 text-center md:mb-8 md:mt-8">
        <template v-if="!hasPhone">Please link your email</template>
        <NDivider v-else class="m-0!">
          <span class="mx-6">Please link your email</span>
        </NDivider>
      </p>
      <NForm
        ref="formRef"
        :model="modelRef"
        :rules="getEmailLoginRules()"
        :disabled="isLoading"
        :show-label="false"
      >
        <EmailCodeInput
          v-model:email="modelRef.mail"
          v-model:code="modelRef.verifyCode"
          countdown-key="BindEmailModal"
          :code-type="3"
        ></EmailCodeInput>
      </NForm>
      <div>
        <Protocol v-model:checked="agreeRef" class="mb-3"></Protocol>
        <div class="flex-sb">
          <div class="item-btn-black user_btn" @click="errorCloseBindEmail">
            Cancel
          </div>
          <div
            class="item-btn-gray user_btn"
            :class="{ disabled: !agreeRef }"
            @click="handleSubmit"
          >
            Link
          </div>
        </div>
      </div>
      <ThreePartyLogin ref="threePartyLoginRef" type="bind"></ThreePartyLogin>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import './common';
</style>
