export function getRem() {
  // 获取文档中的 HTML 元素
  const htmlElement = document.documentElement

  // 获取计算样式对象
  const computedStyle = window.getComputedStyle(htmlElement)

  // 获取字体大小属性值
  const fontSize = computedStyle.getPropertyValue('font-size')

  return Number.parseFloat(fontSize)
}

export function pxToRem(px) {
  return px / getRem()
}

export function ptr(px) {
  return `${pxToRem(px)}rem`
}

export function remToPx(rem) {
  return Number.parseFloat(rem) * getRem()
}
