<script setup>
import { watch, ref } from 'vue'
import { SPECIES } from 'src/constant'
import cardImg from 'src/asset/nftSoftStaking/deck/pfp/card.webp'
import { constantCase } from 'change-case'
const props = defineProps({
  pfp: Object,
})
const imgsList = import.meta.glob(
  'src/asset/nftSoftStaking/deck/pfp/species/*.webp',
)
const imgRef = ref('')
watch(
  () => props.pfp,
  pfp => {
    if (pfp) {
      imgsList[
        `/src/asset/nftSoftStaking/deck/pfp/species/${constantCase(pfp[SPECIES])}.webp`
      ]?.().then(img => {
        imgRef.value = img.default
      })
    } else {
      imgRef.value = ''
    }
  },
  {
    immediate: true,
  },
)
</script>
<template>
  <div class="relative">
    <img :src="cardImg" class="w-29px" />
    <img v-if="imgRef" :src="imgRef" class="w-29px absolute left-0" />
  </div>
</template>
