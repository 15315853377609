import * as THREE from 'three'
import { hasPhone } from 'src/stores/media'

export class GroundParticle {
  static lightNumber = hasPhone ? 4 : 10
  static updateCoefficient = hasPhone ? 3 : 6
  static intensity = hasPhone ? 3 : 10
  static geometrySize = hasPhone ? 1 : 10

  particles = []
  meshs = []
  constructor(scene) {
    this.scene = scene
  }

  init(color) {
    const geometry = new THREE.IcosahedronGeometry(
      0.04,
      GroundParticle.geometrySize,
    )
    const mtl = new THREE.MeshLambertMaterial({ color })
    for (let i = 0; i < GroundParticle.lightNumber; i++) {
      const particle = new THREE.PointLight(color, GroundParticle.intensity)
      const mesh = new THREE.Mesh(geometry, mtl)
      particle.add(mesh)
      this.particles.push({
        particle,
        x: Math.random(),
        y: Math.random(),
        z: Math.random(),
      })
      this.scene.add(particle)
      this.meshs.push(mesh)
    }
  }

  update() {
    const time = Date.now() * 0.0007
    this.particles.forEach(item => {
      const x = Math.sin(time * item.x) * 3 * GroundParticle.updateCoefficient
      const y = Math.cos(time * item.y) * 4 * GroundParticle.updateCoefficient
      const z = Math.cos(time * item.z) * 3 * GroundParticle.updateCoefficient
      // console.log('particles', x, y, z)
      item.particle.position.set(x, y, z)
    })
  }

  unload() {
    this.particles.forEach(item => {
      item.particle.dispose()
      if (item.particle.map) {
        item.particle.map.dispose()
      }
    })
    return this
  }
}
