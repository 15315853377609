import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import { computed } from 'vue'
export function useTime() {
  const pfpStore = usePFPMintStore()
  const { getPFPMintInfo } = pfpStore
  const {
    mintInfo: mintInfoRef,
    phaseComputed,
    wholeActivityStatusComputed,
  } = storeToRefs(pfpStore)
  const serverTimeComputed = computed(
    () => mintInfoRef.value?.serverTime * 1000,
  )
  const startTimeComputed = computed(() => {
    const phase = phaseComputed.value
    if (!isNaN(phase)) {
      return mintInfoRef.value?.timeline[phase] * 1000
    }
  })
  const endTimeComputed = computed(() => {
    return startTimeComputed.value
    // const phase = phaseComputed.value
    // const data = mintInfoRef.value
    // if (phase) {
    //   return data?.timeline[Math.max(phase, data.timeline.length - 1)] * 1000
    // }
  })
  function handleTimeEnd() {
    if (wholeActivityStatusComputed.value !== 3) {
      getPFPMintInfo()
    }
  }
  return {
    serverTimeComputed,
    startTimeComputed,
    endTimeComputed,
    handleTimeEnd,
  }
}
