import Inventory from 'src/pages/softPledge/Inventory/index.vue'
import NFTSoftStaking from 'src/pages/nftSoftStaking/index.vue'
import { INVENTORY, NFT_SOFT_STAKING } from 'src/constant'
const softPledgeShow = import.meta.env.VITE_SOFT_PLEDGE_SHOW
const softPledgeRouter = []
if (softPledgeShow) {
  softPledgeRouter.push(
    {
      path: '/inventory',
      component: Inventory,
      name: INVENTORY,
    },
    {
      path: '/nftSoftStaking',
      component: NFTSoftStaking,
      name: NFT_SOFT_STAKING,
    },
  )
}
export { softPledgeRouter }
