<script setup>
import { useToggleWallet } from 'src/hooks/useHandleWallet'
import { ref } from 'vue'
import { useMessage } from 'naive-ui'
import { errorCodeToMessage } from 'src/helpers/utils'
import WalletConfirmModal from './WalletConfirmModal'
import { accountRef } from 'src/sdk/blockchain'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
const props = defineProps(['cd'])
const message = useMessage()
const { bindAddressComputed } = storeToRefs(useUserInfoStore())

const walletConfirmShowRef = ref(false)
const walletUnbindShowRef = ref(false)
const handleWallet = useToggleWallet({
  unbind() {
    walletUnbindShowRef.value = true
  },
  notBound() {
    walletConfirmShowRef.value = true
  },
  invalid: props.cd,
})
function handleNewBind() {
  message.success('Wallet address binding successful.')
  walletConfirmShowRef.value = false
}

function handleUnbind() {
  // 加密
  message.success('Wallet address unbind successful.')
  walletUnbindShowRef.value = false
}
</script>

<template>
  <div @click="handleWallet">
    <slot></slot>
    <!-- 确认绑定钱包地址 -->
    <WalletConfirmModal
      v-model:show="walletConfirmShowRef"
      text="Confirm wallet address binding."
      :address="accountRef?.address"
      @success="handleNewBind"
      @error="errorCodeToMessage"
    ></WalletConfirmModal>
    <!-- 你想更改钱包地址吗？ -->
    <WalletConfirmModal
      v-model:show="walletUnbindShowRef"
      :hasBind="false"
      text="Do you want to unbind the wallet address?"
      :address="bindAddressComputed"
      @success="handleUnbind"
      @error="errorCodeToMessage"
    ></WalletConfirmModal>
  </div>
</template>
