<script setup>
import { onMounted } from 'vue'
import { passportInstance } from 'src/sdk/immutable'
import { useRoute, useRouter } from 'vue-router'
import { thirdPartyDiscord } from 'src/sdk/thirdParty'
import { HOME_PAGE_NAME } from 'src/constant'
import { useDiscord } from 'src/hooks/useDiscord'
const router = useRouter()
const route = useRoute()
function goToHome() {
  router.replace({ name: HOME_PAGE_NAME })
}
onMounted(async () => {
  switch (route.params.name) {
    case 'okx':
      break
    default:
      break
  }
  window.close()
})
</script>

<template>
  <div style="font-size: 20px; color: rgb(0 0 0); text-align: center">
    Loading... Please close this window if it hangs more than 30 seconds.
  </div>
</template>
