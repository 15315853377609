<script setup>
import { ref } from 'vue'

const props = defineProps(['checked'])
const emit = defineEmits(['update:checked'])
const checkedRef = ref(props.checked)
function handleChecked() {
  checkedRef.value = !checkedRef.value
  emit('update:checked', checkedRef.value)
}
</script>

<template>
  <div
    class="mouse_pointer relative h-[17px] w-[17px] border border-#BCC6CD border-solid md:h-[23px] md:w-[23px]"
    @click="handleChecked"
  >
    <img
      v-show="checkedRef"
      src="/src/asset/tick.webp"
      class="md:letf-[3px] absolute bottom-[3px] left-[1px] w-[22px] md:bottom-[5px] md:w-[30px]"
      alt=""
    />
  </div>
</template>
