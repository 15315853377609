<script setup>
import { storeToRefs } from 'pinia'
import { useAsyncState } from '@vueuse/core'
import { NDivider, useMessage } from 'naive-ui'
import { useLoginStore, useRegisterStore } from 'src/stores/user'
import { computed, ref, watch } from 'vue'
import { fetchRegister } from 'src/api'
import { codeToMessage } from 'src/constant'
import { hasPhone } from 'src/stores/media'
import Modal from '../Modal.vue'
import Protocol from '../Protocol.vue'

import Register from './Register'

const registerRef = ref(null)
const agreeRef = ref(false)
const completeRef = ref(false)
const message = useMessage()
const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())
const registerStore = useRegisterStore()
const { success: registerSuccess, errorClose: errorCloseRegister } =
  registerStore
const { show: registerShow } = storeToRefs(registerStore)
const disabledRef = computed(() => {
  return !agreeRef.value || isLoading.value || completeRef.value
})
const { isLoading, execute: executeregister } = useAsyncState(
  async data => {
    const res = await fetchRegister(data)
    handleRegisterSuccess(res)
    completeRef.value = true
    return null
  },
  null,
  {
    immediate: false,
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
  },
)

function handleRegisterSuccess(res) {
  message.success('Registration successful.')
  login()
  registerSuccess(res)
}

async function handleSubmit(e) {
  if (disabledRef.value) return
  const data = await registerRef.value.handleValidate(e)
  executeregister(0, data)
}

function openLogin() {
  login()
  errorCloseRegister()
}
watch(registerShow, show => {
  if (show) {
    agreeRef.value = false
    completeRef.value = false
  }
})
</script>

<template>
  <Modal :show="registerShow" @update:show="errorCloseRegister">
    <div class="user_modal">
      <span class="modal_triangle_top top-0"></span>
      <img
        src="/src/asset/labs_logo.webp"
        alt=""
        class="logo mb-2 md:mb-[18px]"
      />
      <div class="fz-2 gray-color mb-7 text-center md:mb-[27px]">
        <template v-if="!hasPhone"
          >CREATE YOUR
          <span class="text-white">VOIDLABS</span> ACCOUNT</template
        >
        <NDivider v-else class="m-0!">
          <div class="fz-4">
            CREATE YOUR <span class="text-white">VOIDLABS</span> ACCOUNT
          </div>
        </NDivider>
      </div>
      <Register ref="registerRef" class="mb-1" :disabled="isLoading" />
      <div class="mb-[34px]">
        <Protocol v-model:checked="agreeRef" class="mb-3"></Protocol>
        <div class="flex-sb">
          <div class="item-btn-black user_btn" @click="errorCloseRegister">
            Cancel
          </div>
          <div
            class="item-btn-gray user_btn"
            :class="{ disabled: disabledRef }"
            @click="handleSubmit"
          >
            Register
          </div>
        </div>
      </div>
      <div v-if="!hasLogin">
        <div class="fz-2 gray-color text-center">
          Already have
          <span class="text-white">VOIDLABS</span> account?
          <span class="orange-color mouse_pointer" @click="openLogin"
            >Login here</span
          >
          .
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import './common';
</style>
