<script setup>
import { computed } from 'vue'
import { ReceiveJobItemMap, getQuality } from 'src/constant'
import { formatDate } from '@BOGX/utils'
import { NCollapse, NCollapseItem } from 'naive-ui'
import ArrowBackGray from 'src/components/ArrowBackGray'
import Video from 'src/components/Video'
import { remToPx } from 'src/sdk/media'
import { getReceiveJobName, getType } from '../utils.js'
import TransferItem from './TransferItem.vue'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  roleList: {
    type: Array,
    default() {
      return []
    },
  },
})
const emit = defineEmits(['close', 'submit'])
function handleSubmit(...data) {
  emit('submit', ...data)
}
const hasNFTRef = computed(() => !!props.data?.tokenId)
const qualityRef = computed(() => {
  return (
    (hasNFTRef.value ? props.data?.quality : getQuality(props.data?.Quality)) ||
    {}
  )
})
const bottomContentRef = computed(
  () => !hasNFTRef.value && !props.data?.ReceiveUid,
)
</script>

<template>
  <div class="fixed left-0 top-0 z-3 h-full w-full bg-#131514">
    <ArrowBackGray
      class="top-3 ml-3 fixed!"
      @click="() => emit('close')"
    ></ArrowBackGray>
    <div class="px-8 pt-5.5">
      <div class="mb-4 border border-gray">
        <div class="h-[310px] border-0 border-b border-gray">
          <div v-show="hasNFTRef">
            <Video
              v-show="true"
              :height="remToPx(19.3125)"
              :width="remToPx(19.3125)"
              autoplay
              loop
              :src="data?.link"
              :controls="false"
              :poster="data?.cover"
            ></Video>
          </div>

          <div
            v-if="data?.Icon"
            class="cover_bg h-full w-full"
            :style="{
              backgroundImage: `url(${data?.Icon})`,
            }"
          ></div>
        </div>

        <div class="px-2 py-0.5">
          <div class="flex-sb mb-0.5 border-0 border-b border-gray pb-0.5">
            <span class="fz-4 ellipsis-1">{{
              data?.name ? data?.name : data?.Name
            }}</span>
            <div class="flex items-center">
              <span class="gray-color mr1">Type: </span>
              <span>{{
                hasNFTRef ? 'NFT' : getType(data?.ItemId, data?.ItemType)
              }}</span>
            </div>
          </div>
          <div class="flex-sb">
            <div class="flex items-center">
              <span class="gray-color mr1">Rarity: </span>
              <span :style="{ color: qualityRef.color }">{{
                qualityRef.text
              }}</span>
            </div>
            <span v-if="!hasNFTRef">{{
              ReceiveJobItemMap[data?.ReceiveJob]
            }}</span>
          </div>
        </div>
      </div>
      <div class="fz-2">
        <div v-if="data?.Id" class="mb-1.5 text-#C0C5CC">
          <span class="mr-1">CODE:</span>
          <span class="gray-color">BOGX {{ data?.Id }}</span>
        </div>
        <div v-if="data?.ReceiveName" class="mb-1.5">
          <span class="mr-1">Transfer HERO:</span>
          <span class="gray-color">{{
            getReceiveJobName(data?.ReceiveJob, data?.ReceiveName)
          }}</span>
        </div>
        <div v-if="data?.GainTime" class="mb-1.5">
          <span class="mr-1">Collection time:</span>
          <span class="gray-color">{{
            formatDate(data?.GainTime * 1000)
          }}</span>
        </div>
        <div v-if="data?.Desc || data?.content" class="mb-1.5">
          <NCollapse
            :default-expanded-names="[data?.ReceiveUid || hasNFTRef ? '1' : '']"
            class="item_collapse"
          >
            <NCollapseItem title="STORY" name="1">
              <template #header>
                <div class="text-[10px]">STORY</div>
              </template>
              <p
                v-if="data?.Desc"
                class="gray-color fz-2 m0"
                v-html="data.Desc"
              ></p>
              <p
                v-else-if="data?.content"
                class="gray-color fz-2 m0"
                v-html="data.content"
              ></p>
            </NCollapseItem>
          </NCollapse>
          <!-- <div>STORY</div> -->
        </div>
      </div>
    </div>
    <div
      v-if="bottomContentRef"
      class="mt-8 border-0 border-t border-gray px-8 pt-4"
    >
      <TransferItem
        class="translate-y-0!"
        :data="data"
        :select-data="[]"
        :role-list="roleList"
        @submit="handleSubmit"
      ></TransferItem>
    </div>
  </div>
</template>

<style scoped lang="scss">
.cover_bg {
  background-repeat: no-repeat;
  background-size: cover;
}

.item_collapse {
  ::v-deep {
    .n-collapse-item-arrow {
      font-size: 10px !important;
    }

    .n-collapse-item__content-inner {
      padding: 0 !important;
    }
  }
}

.border-gray {
  @apply border-#4A4F51 border-solid;
}
</style>
