import { pfpNFT } from 'src/helpers/blockchain'
import { computed, onUnmounted, ref } from 'vue'
import { useAsyncState, useTimeoutFn } from '@vueuse/core'
import { middleEllipsis } from 'src/sdk/blockchain'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { fetchPFPMintP, usePFPMintStore } from 'src/stores/pfp'
import { fetchMetadata, fetchSaveMetadata } from 'src/api'
import { PFP_METADATA_TYPE } from 'src/constant'
import { watchAccount } from '@wagmi/core'
import { omit } from 'lodash-es'
import { useSortList } from '../hooks/useSortList'
export function useEthereal() {
  const { wholeActivityStatusComputed } = storeToRefs(usePFPMintStore())
  const timeHeaderRef = ref(null)
  const knockingEggsShowRef = ref(false)
  const pfpTrueColoursIdListRef = ref([])
  const loadingEggRunRef = ref(false)
  const pfpMyListRef = ref([])
  const initDataRef = ref(false)
  const successEggListShowRef = ref(false)
  const successEggListRef = ref([])
  const { start: initDataStart } = useTimeoutFn(
    () => {
      initDataRef.value = true
    },
    1000,
    { immediate: false },
  )
  const { execute: executeTrueColoursList } = useAsyncState(
    async () => {
      const pfpTrueColoursData = await fetchMetadata({
        infoKey: PFP_METADATA_TYPE,
        infoType: 2,
      })
      try {
        const arr = JSON.parse(pfpTrueColoursData.infoValue)
        pfpTrueColoursIdListRef.value = Array.isArray(arr) ? arr : []
      } catch {
        pfpTrueColoursIdListRef.value = []
      }
    },
    [],
    {
      immediate: false,
    },
  )
  const { execute: executehMyPFPList, isLoading } = useAsyncState(
    async () => {
      executeTrueColoursList()
      try {
        await fetchPFPMintP.run()
        pfpMyListRef.value = await pfpNFT.fetchMyPFPList()
        if (!initDataRef.value) {
          initDataStart()
        }
      } catch (error) {
        throw error
      } finally {
      }
    },
    [],
    {},
  )

  // 敲蛋，需要找到所有没敲过的pfp
  const { execute: executeFetchSaveMetadata, isLoading: saveLoadingRef } =
    useAsyncState(
      async () => {
        loadingEggRunRef.value = true
        await executehMyPFPList()
        const eggIdList = eggListComputed.value.map(egg => egg.id)
        await fetchSaveMetadata({
          infoKey: PFP_METADATA_TYPE,
          infoType: 2,
          infoValue: JSON.stringify([
            ...new Set([...pfpTrueColoursIdListRef.value, ...eggIdList]),
          ]),
        })
        knockingEggsShowRef.value = true
      },
      [],
      {
        onError(error) {
          console.log('executeFetchSaveMetadata', error)
        },
        immediate: false,
      },
    )

  const listTypeComputed = computed(() => {
    const myList = pfpMyListRef.value.slice(0)
    const idList = pfpTrueColoursIdListRef.value.slice(0)
    let trueColoursList = []
    let eggList = []
    if (idList.length) {
      while (myList.length && idList.length) {
        const item = myList.splice(0, 1)[0]
        const index = idList.findIndex(id => item.id === id)
        if (index === -1) {
          eggList.push(createEggItem(item))
        } else {
          trueColoursList.push(item)
          idList.splice(index, 0)
        }
      }
    }
    eggList = [...eggList, ...myList.map(createEggItem)]
    return {
      trueColoursList,
      eggList,
    }
  })
  const unitRef = ref(15)
  const trueColoursListComputed = computed(
    () => listTypeComputed.value.trueColoursList,
  )
  const { sortListComputed } = useSortList(trueColoursListComputed, unitRef)
  // 展示列表
  const viewListComputed = computed(() => {
    const eggList = listTypeComputed.value.eggList
    const sortList = sortListComputed.value
    let result = knockingEggsShowRef.value
      ? [...eggList, ...sortList]
      : [...sortList, ...eggList]
    if (!result.length) {
      result = new Array(12).fill('').map((item, index) => {
        return { id: -(index + 1) }
      })
    }
    return result
  })
  // 蛋列表
  const eggListComputed = computed(() => listTypeComputed.value.eggList)
  // 4宫格
  const topLevelListComputed = computed(() => {
    return sortListComputed.value.slice(0, 4)
  })
  const showTimeHeaderComputed = computed(() => {
    let hasEnd = timeHeaderRef.value?.hasEnd
    return !hasEnd || eggListComputed.value.length
  })
  const hasTokenComputed = computed(
    () => !!eggListComputed.value.length || !!topLevelListComputed.value.length,
  )
  const { userInfo } = storeToRefs(useUserInfoStore())
  const walletAddressComputed = computed(() => {
    if (userInfo.value?.wallet) return middleEllipsis(userInfo.value?.wallet)
  })
  // 敲蛋，需要找到所有没敲过的pfp
  function updateInfo() {
    executehMyPFPList()
  }
  async function handleEggEnd() {
    if (!saveLoadingRef.value) {
      try {
        successEggListRef.value = eggListComputed.value.map(getEggOriginItem)
        console.log('handleEggEnd')
        changeSuccessEggList(true)
        await executehMyPFPList()
      } finally {
        knockingEggsShowRef.value = false
        loadingEggRunRef.value = false
      }
    }
  }
  function changeSuccessEggList(show) {
    successEggListShowRef.value = show
  }
  const watchAccountStop = watchAccount(() => {
    executehMyPFPList()
  })
  onUnmounted(() => {
    watchAccountStop()
  })

  return {
    timeHeaderRef,
    knockingEggsShowRef,
    viewListComputed,
    topLevelListComputed,
    showTimeHeaderComputed,
    eggListComputed,
    executeFetchSaveMetadata,
    hasTokenComputed,
    walletAddressComputed,
    wholeActivityStatusComputed,
    loadingEggRunRef,
    updateInfo,
    isLoading,
    initDataRef,
    successEggListRef,
    successEggListShowRef,
    handleEggEnd,
  }
}

function createEggItem(item) {
  return {
    ...item,
    hasEgg: true,
  }
}

function getEggOriginItem(item) {
  return omit(item, ['hasEgg'])
}

export const knockingEggsVideo =
  import.meta.env.VITE_FILE_PATH + '/Ethereal-Egg-Break-Render_x264.mp4'
