<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { gsap } from 'gsap'
import { useMusicStore } from 'src/stores/music'
import { lineAngleW, lineBottomSoulsW } from '../constant'
import SoulsSubModal from './SoulsSubModal.vue'
import soulsLogo from '/src/asset/shui_di.webp'
import soulsActive from '/src/asset/soul_active.webp'

const { buttonClickAudioPlay, buttonCaptureAudioPlay } = useMusicStore()
const hornDomRef = ref(null)
const soulsLogoRef = ref(null)
const domAttributeReactive = reactive({
  opacityLine: 1,
  lineBottomRect: lineBottomSoulsW,
  lineAngleRect: 0,
})

const soulsSubShowRef = ref(false)
let hoverIng = false
let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function handleOpenSouls() {
  buttonClickAudioPlay()
  soulsSubShowRef.value = true
}

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to(hornDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to(hornDomRef.value, {
    ...options,
    x: '20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  bottomLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineBottomRect: 0,
    duration: 0.4,
  })
  obliqueLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineAngleRect: lineAngleW,
    duration: 0.1,
    onComplete() {
      bottomLineTl.play()
    },
  })
  opacityTl = gsap.to(domAttributeReactive, {
    opacityLine: 0,
    duration: 0.3,
    onComplete() {
      bottomLineTl.progress(0).pause()
      obliqueLineTl.progress(0).pause()
      opacityTl.progress(0).pause()
    },
  })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  obliqueLineTl.play()
  buttonCaptureAudioPlay()
  soulsLogoRef.value.style.backgroundImage = `url(${soulsActive})`
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  opacityTl.play()
  soulsLogoRef.value.style.backgroundImage = `url(${soulsLogo})`
}
onMounted(() => {
  initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  bottomLineTl?.kill()
  obliqueLineTl?.kill()
  opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer fixed left-0 top-0 z-2"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    @click="handleOpenSouls"
  >
    <div class="relative z-2 pl-12 pt-3 flex flex-items-baseline">
      <div ref="soulsLogoRef" class="souls-logo w-[10px] h-[15px]"></div>
      <span class="fz-3 ml-1.5 mr-0.5">SOULS</span>
      <img
        ref="hornDomRef"
        src="/src/asset/top_left_jiao.webp"
        class="relative w-[10px] opacity-0"
        alt=""
      />
    </div>
    <div
      class="line z-2 w-full"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineBottomRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineBottomSoulsW}px`,
      }"
    ></div>
    <div
      class="line w-[26px] oblique"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineAngleRect}px, auto, auto)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div>
  </div>
  <SoulsSubModal v-model:show="soulsSubShowRef"> </SoulsSubModal>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1;
}

.oblique {
  right: -14%;
  bottom: 22%;
  transform: rotate(45deg);
}

.souls-logo {
  background: url('/src/asset/shui_di.webp');
  background-size: 100% 100%;
  transition: background-image ease-in-out 0.2s;
}
</style>
