import { LOCALES_ENUM } from './common'

export const DEFAULT_LOCALE = LOCALES_ENUM.EN_US

export const LANG_FLAG = 'lang_flag'

export const MD_WIDTH = 950 // 考虑到横屏

export const LG_WIDTH = 1200

export const XL_WIDTH = 1470

export const XL2_WIDTH = 1536

export const XL3_WIDTH = 1801

export const XL4_WIDTH = 2048

export const ease = 'power4.out'
