<script setup>
import { ref } from 'vue'
import { getRoleCodeModel } from 'src/model'
import { NForm, NFormItem, NSelect } from 'naive-ui'
import { selectTheme } from 'src/pages/myCollection/utils'
const props = defineProps([
  'readOnly',
  'selectData',
  'data',
  'roleList',
  'submitText',
])
const emit = defineEmits(['submit'])

const formRef = ref(null)
const modelData = getRoleCodeModel()
const modelRef = ref(modelData)
function handleValidate() {
  emit(
    'submit',
    modelRef.value.UserID,
    props.selectData.length ? props.selectData : [props.data?.Id],
  )
}
</script>

<template>
  <NForm
    v-if="!readOnly"
    v-show="selectData?.length || (data && data.Id && !data?.ReceiveUid)"
    ref="formRef"
    label-placement="left"
    :model="modelRef"
    class="mb6"
    @keyup.enter="handleValidate"
  >
    <NFormItem path="UserID" label="HERO">
      <NSelect
        v-model:value="modelRef.UserID"
        :options="roleList"
        :theme-overrides="selectTheme"
      ></NSelect>
    </NFormItem>

    <slot name="btn" :handleValidate="handleValidate">
      <div
        class="green_button flex-center fz-3 h-[35px]"
        @click="handleValidate"
      >
        {{ submitText || 'Transfer Item' }}
      </div>
    </slot>
  </NForm>
</template>

<style scoped lang="scss">
@import './btn';
</style>
