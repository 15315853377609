import { useDecorator } from '../blockchain'
import { immutableParams, verifyWallet } from '../immutable'
import { NFTPool } from '../NFTPool'
import { getImmutableInfo } from '../utils'
export class ImmutableNFTPool extends NFTPool {
  constructor() {
    super()
    const { chainName, chainId } = getImmutableInfo()
    this.chainName = chainName
    this.setChainId(chainId)
  }
  lock(data) {
    return super.lock(data)
  }
  unlock(data) {
    return super.unlock(data)
  }
}

const ImmutableNFTPoolProty = ImmutableNFTPool.prototype
useDecorator(ImmutableNFTPoolProty, 'lock', immutableParams)
useDecorator(ImmutableNFTPoolProty, 'lock', verifyWallet)
useDecorator(ImmutableNFTPoolProty, 'unlock', immutableParams)
useDecorator(ImmutableNFTPoolProty, 'unlock', verifyWallet)

export const immutableNFTPool = new ImmutableNFTPool()
