<script setup>
import { computed, ref, watch } from 'vue'
import { useRestBGM } from 'src/hooks/useRestBGM'
import { scrollLock } from 'src/helpers/smoothScrolling'
import { useLandscapeScreenDom } from 'src/hooks/useLandscapeScreenDom'
import { useMediaStore } from 'src/stores/media'
import TrailerSubContent from './TrailerSubContent'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const { keepDefaultRem, recoverDynamicRem } = useMediaStore()
const domRef = ref(null)
const showRef = computed(() => props.show)
useRestBGM(showRef)
useLandscapeScreenDom(domRef)
watch(
  () => props.show,
  value => {
    scrollLock(value)
    if (value) {
      keepDefaultRem()
    } else {
      recoverDynamicRem()
    }
  },
)
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <div v-if="show" ref="domRef" class="item-bg-color z-2011 p-3 fixed!">
    <TrailerSubContent
      :show="props.show"
      @update:show="handleClose"
    ></TrailerSubContent>
  </div>
</template>
