<script setup>
import { NLayoutHeader } from 'naive-ui'
import Connect from 'src/pages/home/components/Connect'
import { useUserInfoStore } from 'src/stores/user'
import { hasPhone, useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'
import LogoHome from 'src/components/LogoHome'
import BogxWallet from 'src/components/BogxWallet'
import GameType from './GameType'
import CollectionTabs from './CollectionTabs'
import MyCollectionTabs from './MyCollectionTabs'
import ImmutableCollectionTabs from './ImmutableCollectionTabs'
import { getHasRouterActivation } from 'src/helpers/utils'
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import {
  IMMUTABLE_COLLECTION_NAME,
  MY_COLLECTION_NAME,
  PFP_ETHEREAL_NAME,
} from 'src/constant'
const route = useRoute()
const { isAdaptation } = storeToRefs(useMediaStore())
const hasMyRouterRef = computed(() =>
  getHasRouterActivation(route.matched, MY_COLLECTION_NAME),
)

const hasEtherealRouterRef = computed(() =>
  getHasRouterActivation(route.matched, PFP_ETHEREAL_NAME),
)

const hasImmutableRouterRef = computed(() =>
  getHasRouterActivation(route.matched, IMMUTABLE_COLLECTION_NAME),
)

const { userInfo } = storeToRefs(useUserInfoStore())
const minPCComputed = computed(() =>
  ['md', 'lg', 'xl', '2xl'].includes(isAdaptation.value),
)
</script>

<template>
  <NLayoutHeader
    class="header mb:pr-6 relative border-0 border-b border-#404753 border-solid"
    :class="`fz-${hasPhone ? 4 : 5}`"
  >
    <div
      :class="{ 'flex-sb': hasPhone, 'pl-2.5': hasPhone }"
      class="mb:border-b-0 md:h-full border-0 border-t border-#65696F border-dashed bg-#1A1B1C md:bg-inherit md:pr-6"
    >
      <div class="flex items-center md:h-full py-2 md:p-0">
        <LogoHome
          :class="{ 'mr-6': !minPCComputed }"
          class="md:w-290px md:h-full md:pl-6.5 md:border-0 md:border-r md:border-solid md:border-#404753 flex items-center"
        ></LogoHome>
        <GameType v-if="!minPCComputed" class="2xl:mr-6 md:mr-3"></GameType>
        <div v-if="!hasPhone" class="h-full">
          <CollectionTabs></CollectionTabs>
          <div class="flex md:ml-8 2xl:ml-12">
            <MyCollectionTabs v-if="hasMyRouterRef"></MyCollectionTabs>
            <ImmutableCollectionTabs
              v-if="hasImmutableRouterRef"
            ></ImmutableCollectionTabs>
          </div>
        </div>
        <!-- TODO 后期功能 -->
        <!-- <SearchInput></SearchInput> -->
      </div>
      <Connect
        v-if="userInfo?.wallet && (hasMyRouterRef || hasEtherealRouterRef)"
        class="relative! md:absolute! md:top-[18px]!"
      ></Connect>
      <BogxWallet
        v-if="!hasMyRouterRef && !hasEtherealRouterRef"
        class="md:top-[45px]!"
      ></BogxWallet>
    </div>
    <template v-if="hasPhone">
      <CollectionTabs></CollectionTabs>
      <div class="py-2">
        <div class="flex pl-11" v-if="hasMyRouterRef">
          <MyCollectionTabs></MyCollectionTabs>
        </div>
        <div class="flex-sb px-5" v-else>
          <ImmutableCollectionTabs></ImmutableCollectionTabs>
        </div>
      </div>
    </template>
    <!-- <div v-if="hasPhone" class="flex-sb h-[32px] bg-#0F0F10 px-10">
      <CollectionTabs></CollectionTabs> -->
    <!-- <div>
        <MyCollectionTabs v-if="hasMyRouterRef"></MyCollectionTabs>
        <ImmutableCollectionTabs v-else></ImmutableCollectionTabs>
      </div> -->
    <!-- <Collection></Collection>
      <router-link
        :to="{ name: 'myCollectionTransferHistory' }"
        class="gray-color router-link flex-center relative"
      >
        <span>Transfer History</span>
        <span class="text-length">{{ `<${transferList.length}>` }}</span>
      </router-link> -->
    <!-- <RedPopover>
        <template #trigger>
          <span class="red-color flex-center">Active Listing</span>
        </template>
        <div>Coming Soon ......</div>
      </RedPopover> -->
    <!-- </div> -->
  </NLayoutHeader>
</template>

<style scoped lang="scss">
@import './index';

.header {
  @include underMD {
    height: var(--header-phone-height);
  }

  @include minMD {
    height: var(--header-height);
  }

  // .xfu {
  //   @apply md:re md:bottom-[10px]
  // }
  // @apply h-[42px];
}
</style>
