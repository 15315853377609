import { watchNetwork } from '@wagmi/core'
import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch } from 'vue'
import { fetchMintUserInfo, fetchNFTInfo } from 'src/api'
import { fetchUserInfoP } from 'src/helpers/user.js'
import {
  accountRef,
  fetchGenesisNFTList,
  getUserContractData,
} from 'src/sdk/blockchain'
import { useAsyncState } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { WEB3_ROUTERS } from 'src/constant'
import { SinglePromise } from 'src/helpers/singlePromise'
import { useUserInfoStore } from './user'

let fetchMintInfo
export const fetchMintInfoP = new SinglePromise(() => fetchMintInfo?.())

export const useWeb3Store = defineStore('web3', () => {
  const { userInfo } = storeToRefs(useUserInfoStore())
  const nftList = ref([])
  const mintInfo = ref(null)
  const contractData = ref(null)
  const hasFreeMint = ref(false)
  const addressIdentical = computed(
    () => Number(accountRef.value?.address) === Number(userInfo.value?.wallet),
  )
  // 优先以网站账号地址获取数据
  const web3Address = computed(
    () => userInfo.value?.wallet || accountRef.value?.address,
  )
  async function getNFTList() {
    if (!userInfo.value?.wallet) return
    const idList = await fetchGenesisNFTList({
      chainId: mintInfo.value?.chainId,
      contractAddress: mintInfo.value?.contract,
      accountAddress: userInfo.value?.wallet,
    })
    const pList = idList.map(id => fetchNFTInfo(id))
    console.log('pList', pList, idList)
    const list = await Promise.all(pList)
    console.log('list', list)
    nftList.value = list
    return list
  }
  function reset() {
    nftList.value = []
    mintInfo.value = null
    contractData.value = null
    hasFreeMint.value = null
  }
  const { execute: getMintUserInfo } = useAsyncState(
    async () => {
      const _mintInfo = await fetchMintUserInfo()
      mintInfo.value = _mintInfo
      getNFTList()
      const _contractData = await getUserContractData({
        chainId: _mintInfo.chainId,
        contractAddress: _mintInfo.contract,
        accountAddress: web3Address.value,
      })
      contractData.value = _contractData
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('useWeb3Store', error)
      },
    },
  )
  fetchMintInfo = getMintUserInfo
  const route = useRoute()
  // 保证先请求用户信息
  fetchUserInfoP.run().finally(() => {
    let stop
    let one = false
    stop = watch(
      () => route.name,
      value => {
        // 只有nft范围内才执行
        if (nftRange(value)) {
          if (stop) {
            stop()
            stop = []
          }
          if (one) {
            return
          }
          one = true
          // 先初始化nft相关信息
          fetchMintInfoP.fetch().finally(() => {
            // 绑定下相关触发逻辑
            watch(web3Address, () => {
              if (nftRange(route.name)) {
                fetchMintInfoP.fetch()
              }
            })
            watch(
              () => route.name,
              name => {
                if (nftRange(name)) {
                  fetchMintInfoP.run()
                }
              },
            )
            watchNetwork(() => {
              if (nftRange(route.name) && web3Address.value) {
                fetchMintInfoP.fetch()
              }
            })
          })
        }
      },
      {
        immediate: true,
      },
    )
  })

  return {
    reset,
    nftList,
    getNFTList,
    mintInfo,
    contractData,
    getMintUserInfo: () => fetchMintInfoP.fetch(),
    web3Address,
    addressIdentical,
  }
})

function nftRange(name) {
  return WEB3_ROUTERS.includes(name)
}
