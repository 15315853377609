<script setup>
import CheckBoxPFP from 'src/components/CheckBoxPFP'
import { useUpdateCssVar } from 'src/pages/PFP/useThemeOverrides'
import { ref } from 'vue'
const emit = defineEmits(['change', 'update:change'])
const props = defineProps({
  checked: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
  },
  number: {
    type: Number,
    default: 0,
  },
})

const { valueRef: colorRef } = useUpdateCssVar('--pfp-border2')
const checkedRef = ref(false)
function handleToggle() {
  const value = !checkedRef.value
  setChecked(value)
  emit('change', value, setChecked)
  emit('update:change', value, setChecked)
}
function setChecked(value) {
  checkedRef.value = value
}
defineExpose({
  checked: checkedRef,
})
</script>
<template>
  <div
    class="bg-[--pfp-bg4] h-45px flex items-center px-4 mouse_pointer"
    @click="handleToggle"
  >
    <CheckBoxPFP
      class="mr-4"
      @update:checked="handleToggle"
      :checked="checkedRef"
      :color="colorRef"
    ></CheckBoxPFP>
    <div>
      <span class="text-[--pfp-color] mr-0.5">{{ name }}</span>
      <span class="text-[--pfp-color3]">({{ number }})</span>
    </div>
  </div>
</template>
