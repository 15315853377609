const QualityMap = {
  1: {
    text: 'Common',
    color: '#b1b5bc',
  },
  2: {
    text: 'Rare',
    color: '#7baaff',
  },
  3: {
    text: 'Epic',
    color: '#947bff',
  },
  4: {
    text: 'Legendary',
    color: '#f58022',
  },
  5: {
    text: 'Primal',
    color: '#f7c265',
  },
  6: {
    text: 'Mythic',
    color: '#f92c2c',
  },
  7: {
    text: 'Immortal',
    color: '#f869b3',
  },
}
export function getQuality(id) {
  return QualityMap[id]
}

export const ReceiveJobItemMap = {
  1003: 'HELA',
  1006: 'ESTHER',
  1009: 'CHAOS',
}

export const ReceiveJobSelectMap = {
  1003: 'hela',
  1006: 'esther',
  1009: 'chaos',
}

export const selectMax = 5
