<script setup>
import { onMounted, onUnmounted, reactive, ref, watch } from 'vue'
import { gsap } from 'gsap'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { storeToRefs } from 'pinia'
import { hasPhone } from 'src/stores/media'
import SvgIcon from 'src/components/SvgIcon'
import TBogxModel from 'src/pages/myCollection/components/TBogxModel'
import TBogxDetail from 'src/pages/myCollection/components/TBogxDetail'
import { lineAngleW, lineBottomConnectW } from 'src/pages/home/constant'

const { tBogxCounts } = storeToRefs(useImmutableInfoStore())
const domAttributeReactive = reactive({
  opacityLine: 1,
  lineBottomRect: 0,
  lineAngleRect: lineAngleW,
})

let hoverIng = false
let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to('.pfp-connect', {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to('.pfp-connect', {
    ...options,
    x: '-20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  bottomLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineBottomRect: lineBottomConnectW,
    duration: 0.4,
  })
  obliqueLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineAngleRect: 0,
    duration: 0.1,
    onComplete() {
      bottomLineTl.play()
    },
  })

  opacityTl = gsap.to(domAttributeReactive, {
    opacityLine: 0,
    duration: 0.3,
    onComplete() {
      bottomLineTl.progress(0).pause()
      obliqueLineTl.progress(0).pause()
      opacityTl.progress(0).pause()
    },
  })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  obliqueLineTl.play()
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  opacityTl.play()
}

const walletShowRef = ref(false)
function handleShow() {
  walletShowRef.value = true
}
function handleTBogxDetailClose() {
  walletShowRef.value = false
}

onMounted(() => {
  !hasPhone && initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  bottomLineTl?.kill()
  obliqueLineTl?.kill()
  opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer relative z-2"
    @mouseenter="!hasPhone && handleMouseenter()"
    @mouseleave="!hasPhone && handleMouseleave()"
  >
    <div @click="handleShow" class="relative z-2">
      <SvgIcon
        name="PFP-CONNECT"
        width="11.5px"
        height="12px"
        class="pfp-connect relative opacity-0 color-[--pfp-bg13]"
      ></SvgIcon>
      <span class="fz-3 ml-0.25 mr-1 md:ml-1.5 md:mr-1.5 color-[--pfp-color10]"
        >$tBOGX</span
      >
      <SvgIcon
        name="PFP-TBOGX"
        width="22.5px"
        height="26px"
        class="align-text-bottom md:w-[22.5px] color-[--pfp-bg12]"
      ></SvgIcon>
      <span class="fz-3 ml-0.25 text-[--pfp-color7] md:ml-1.5">{{
        tBogxCounts
      }}</span>
    </div>
    <div
      class="line z-2 w-[185px] translate-x-[-10%]"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineBottomRect}px, auto, auto)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineBottomConnectW}px`,
      }"
    ></div>
    <div
      class="line oblique"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineAngleRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div>
    <TBogxModel
      v-if="!hasPhone"
      v-model:show="walletShowRef"
      :counts="tBogxCounts"
    ></TBogxModel>
    <TBogxDetail
      v-if="hasPhone"
      v-show="walletShowRef"
      :counts="tBogxCounts"
      @close="handleTBogxDetailClose"
    ></TBogxDetail>
  </div>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1 border-[--pfp-color7];
}

.oblique {
  bottom: 30%;
  left: -16%;
  transform: rotate(-45deg);
}
</style>
