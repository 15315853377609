<script setup>
import { NEmpty, useMessage, NSelect, NFormItem } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { errorCodeToMessage } from 'src/helpers/utils'

import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { useBaseSelectData } from 'src/pages/myCollection/hooks/useBaseSelectData'

import { hasPhone } from 'src/stores/media'
import SoulBox from '../components/SoulBox.vue'
import PanelImmutable from '../components/PanelImmutable.vue'
import TransferImmutableModal from '../components/TransferImmutableModal'
import Empty from '../components/Empty'
import List from '../components/List'
import ImmutableItemDetail from '../components/ImmutableItemDetail'
import ImmutablePreviewModal from '../components/ImmutablePreviewModal'
import { useSoulList } from '../hooks/useSoulList'
import { useMintSoulCard } from '../hooks/useMintSoulCard'
import { useUserSoulList } from '../hooks/useUserSoulList'
import { useUnlockImmutableSoulCard } from '../hooks/useUnlockImmutableSoulCard'
import { getBtnName, idsToListTokenIdOne } from '../utils'
import Mint from './components/Mint/index.vue'
import Modal from 'src/components/Modal'
const immutableInfoStore = useImmutableInfoStore()
const { executeSoulCoreInfoList } = immutableInfoStore
const { soulCoreInfoLockList } = storeToRefs(immutableInfoStore)
const roleListRef = useRoleList()
const { soulListComputed } = useSoulList(soulCoreInfoLockList, roleListRef)
const { mintShowRef } = useMintSoulCard()
const { userSoulListComputed, currentUserIdRef } =
  useUserSoulList(soulListComputed)
const {
  selectDataRef,
  itemDetailShowRef,
  singleChoiceDataRef,
  previewDataRef,
  transferShowRef,
  currentSelectDataRef,
  previewShowRef,
  handleSelect,
  handleCancel,
  handleEnlarge,
  handleClose,
  handleCheck,
  handleEmptySelect,
  handleItemDetailClose,
} = useBaseSelectData()
const { isLoading: unlockLoading, execute } = useUnlockImmutableSoulCard()
const message = useMessage()
async function handleConfirmUnlock() {
  try {
    const tokenId = idsToListTokenIdOne(
      currentSelectDataRef.value,
      userSoulListComputed.value,
    )
    const { newState: state } = await execute(0, tokenId)
    executeSoulCoreInfoList()
    // const message = getStateMessage(state)
    message.success('success')
    transferShowRef.value = false
  } catch (error) {
    console.log(error)
    errorCodeToMessage(error)
  }
}
function handleUnlockId(userId, ids) {
  handleUnlock(ids)
}
function handleUnlock(ids) {
  const selectData = Array.isArray(ids)
    ? ids
    : selectDataRef.value.length
      ? selectDataRef.value
      : [singleChoiceDataRef.value.Id]
  if (!selectData) {
    message.warning('Please select an item.')
  } else {
    currentSelectDataRef.value = selectData
    transferShowRef.value = true
  }
}
function handleDetails(data) {
  previewShowRef.value = true
  previewDataRef.value = data
}
</script>

<template>
  <div class="h-full flex">
    <PanelImmutable
      v-if="!hasPhone"
      class="flex-shrink-0"
      :data="singleChoiceDataRef"
      @details="handleDetails"
    >
    </PanelImmutable>
    <div class="w-full flex flex-col px-3.5 py-8 md:py-10 md:pl-6">
      <NFormItem label="HERO" label-placement="left" class="md:w-350px">
        <NSelect
          v-model:value="currentUserIdRef"
          :options="roleListRef"
        ></NSelect>
      </NFormItem>
      <div class="flex flex-grow">
        <List
          v-if="userSoulListComputed.length"
          :list="userSoulListComputed"
          :box="false"
          class="px-0! py-2!"
        >
          <template #item="item">
            <SoulBox
              :data="item"
              :select-data="selectDataRef"
              :single-choice-data="singleChoiceDataRef"
              :role-list="roleListRef"
              @check="handleCheck"
              @select="handleSelect"
              @cancel="handleCancel"
              @enlarge="handleEnlarge"
              @submit="handleUnlockId"
            >
              <template #btn="{ handleSubmit }">
                <div
                  class="flex-center fz-3 h-32px rounded-0!"
                  :class="`${item.responseWait ? 'item_btn_disabled' : 'green_button'}`"
                  @click="!item.responseWait && handleSubmit()"
                >
                  {{ getBtnName(item) }}
                </div>
              </template>
            </SoulBox>
          </template>
        </List>
        <NEmpty v-else class="m-auto"></NEmpty>
        <Empty
          v-if="!hasPhone"
          v-show="selectDataRef.length"
          @click="handleEmptySelect"
        />
      </div>
    </div>
  </div>
  <ImmutableItemDetail
    v-if="hasPhone"
    v-show="itemDetailShowRef"
    :data="singleChoiceDataRef"
    :role-list="roleListRef"
    @close="handleItemDetailClose"
    @submit="handleUnlock"
  ></ImmutableItemDetail>
  <ImmutablePreviewModal
    v-model:show="previewShowRef"
    @close="handleClose"
    :data="previewDataRef"
  ></ImmutablePreviewModal>
  <TransferImmutableModal
    v-model:show="transferShowRef"
    :loading="unlockLoading"
    text="Are you sure you want to transferthis item?"
    confirm-text="Transfer"
    @confirm="handleConfirmUnlock"
  ></TransferImmutableModal>
  <Modal v-model:show="mintShowRef">
    <Mint></Mint>
  </Modal>
</template>

<style scoped lang="scss">
@import '../border';
@import '../components/btn';
</style>
