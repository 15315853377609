<script setup>
import CthulhuMythos from './CthulhuMythos/index.vue'
import Search from './Search/index.vue'
import FilterItem from './FilterItem/index.vue'
import { useFilterList } from './index.js'
const props = defineProps({
  filterList: {
    type: Array,
    default() {
      return []
    },
  },
})
const { searchValueRef, handleFilterItem, filterItemListRef, handleSearch } =
  useFilterList()
defineExpose({
  filterItemList: filterItemListRef,
  handleFilterItem,
})
</script>
<template>
  <div class="container flex flex-col h-full">
    <div
      class="fz-5 text-[--pfp-color2] border-solid border-[--pfp-border] border-b border-0 pl-4 pb-1 mb-1px"
    >
      FILTER
    </div>
    <div class="overflow-y-auto pt-6 scroll flex_h_full">
      <CthulhuMythos class="item_box"></CthulhuMythos>
      <Search
        class="item_box"
        :value="searchValueRef"
        @input="handleSearch"
      ></Search>
      <template v-for="item in filterList" :key="item.name">
        <FilterItem
          @change="handleFilterItem"
          class="item_box"
          :data="item"
        ></FilterItem>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import './index';

.scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}
</style>
