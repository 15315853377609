import { useAsyncState } from '@vueuse/core'
import { useMessage } from 'naive-ui'
import { storeToRefs } from 'pinia'

import { codeToMessage } from 'src/constant'
import { fetchUserInfoP } from 'src/helpers/user'
import { fetchAuth, loginOut } from 'src/sdk/immutable'
import { fetchImmutableBind, fetchImmutableLogin } from 'src/api'
import { useLoginStore, useUserInfoStore } from 'src/stores/user'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { ref, computed } from 'vue'

export function useImmutable() {
  const message = useMessage()

  const loginStore = useLoginStore()
  const immutableStore = useImmutableInfoStore()
  const { logOut: immutableInfoLoginOut } = immutableStore
  const { immutableInfo } = storeToRefs(immutableStore)
  const { success: loginSuccess } = loginStore

  const { userInfo } = storeToRefs(useUserInfoStore())
  const userImmutableWallet = computed(
    () => userInfo.value?.bindAccountInfo?.immutableWallet,
  )

  const loginImmutable = useAsyncState(
    async () => {
      immutableInfo.value = await fetchAuth()
      if (immutableInfo.value) {
        await fetchImmutableLogin({
          token: immutableInfo.value.accessToken,
        })
        const userInfo = await fetchUserInfoP.run()
        message.success('Login successful.')
        loginSuccess(userInfo)
        return null
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log(error)
        message.error(codeToMessage(error?.data?.code))
      },
    },
  )

  const bindImmutable = useAsyncState(
    async () => {
      try {
        if (!immutableInfo.value) {
          immutableInfo.value = await fetchAuth()
        }
        if (immutableInfo.value) {
          await fetchImmutableBind({
            token: immutableInfo.value.accessToken,
          })
          await fetchUserInfoP.run()
          message.success('binding successful.')
          return null
        }
      } catch (error) {
        await immutableInfoLoginOut()
        message.error(codeToMessage(error?.data?.code))
      }
    },
    null,
    {
      immediate: false,
      // async onError(error) {
      //   await immutableInfoLoginOut()
      //   message.error(codeToMessage(error?.data?.code))
      // },
    },
  )

  return {
    userImmutableWallet,
    loginImmutable,
    bindImmutable,
  }
}
