import {
  MY_COLLECTION_NAME,
  MY_COLLECTION_ALL_NAME,
  MY_COLLECTION_ASSETS_NAME,
  MY_COLLECTION_NFTS_NAME,
  MY_COLLECTION_TRANSFER_NAME,
  IMMUTABLE_COLLECTION_NAME,
  IMMUTABLE_COLLECTION_ASSETS_NAME,
  PFP_ETHEREAL_NAME,
  IMMUTABLE_COLLECTION_MARKETPLACE_NAME,
  NFT_CARDS,
  GAME_BANEFITS_NAME,
} from 'src/constant'
import MyCollection from 'src/pages/myCollection/index.vue'
import NFTs from 'src/pages/myCollection/nfts/index.vue'
import Assets from 'src/pages/myCollection/assets/index.vue'
import TransferHistory from 'src/pages/myCollection/transferHistory/index.vue'
import ImmutableAssets from 'src/pages/myCollection/assets/ImmutableAssets.vue'
import ImmutableMarketplace from 'src/pages/myCollection/immutable/marketplace/index.vue'
import PFPEthereal from 'src/pages/PFP/ethereal/index.vue'
import NFTCards from 'src/pages/nftCards/index.vue'

const pfpShow = import.meta.env.VITE_PFP_SHOW
const collectionRouter = [
  {
    path: 'my',
    name: MY_COLLECTION_NAME,
    children: [
      {
        path: 'all',
        component: MyCollection,
        name: MY_COLLECTION_ALL_NAME,
      },
      {
        path: 'assets',
        component: Assets,
        name: MY_COLLECTION_ASSETS_NAME,
      },
      {
        path: 'NFTs',
        component: NFTs,
        name: MY_COLLECTION_NFTS_NAME,
      },
      {
        path: 'transfer_history',
        component: TransferHistory,
        name: MY_COLLECTION_TRANSFER_NAME,
      },
    ],
  },
  {
    path: 'immutable',
    name: IMMUTABLE_COLLECTION_NAME,
    children: [
      {
        path: 'assets',
        component: ImmutableAssets,
        name: IMMUTABLE_COLLECTION_ASSETS_NAME,
      },
      {
        path: 'marketplace',
        component: ImmutableMarketplace,
        name: IMMUTABLE_COLLECTION_MARKETPLACE_NAME,
      },
    ],
  },
  {
    path: 'game_banefits',
    name: GAME_BANEFITS_NAME,
    redirect: { name: NFT_CARDS },
    children: [
      {
        path: 'nft_cards',
        component: NFTCards,
        name: NFT_CARDS,
      },
    ],
  },
]
if (pfpShow) {
  collectionRouter.push({
    path: 'ethereal',
    name: PFP_ETHEREAL_NAME,
    component: PFPEthereal,
  })
}
export { collectionRouter }
