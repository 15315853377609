<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { gsap } from 'gsap'
import { getSmoothScrolling } from 'src/helpers/smoothScrolling'
import { useLoginStore, useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { ease } from 'src/constant'
import { hasPhone } from 'src/stores/media'
import { playDeshaedLine, reverseDeshaedLine } from '../constant.js'
import Dashedline from './Dashedline'
import SetupModal from './SetupModal'

const props = defineProps(['scrollTriggerDom'])

const triangleDomRef = ref(null)
const triangleContentDomRef = ref(null)
const startDomRef = ref(null)
const startImgDomRef = ref(null)
const loopDomRef = ref(null)
const LoopImgDomRef = ref(null)
const lineBoxDomRef = ref(null)
const lineRectRef = ref({ r: 0 })
const setupModalShowRef = ref(false)
const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())
const { userInfo } = storeToRefs(useUserInfoStore())
let hoverStartTl,
  hoverLineMovementTl,
  hoverLoopShowTl,
  hoverLoopTl,
  hoverLineHideTl,
  hoverLineDashedTl,
  scrollTween
function initScroll() {
  const scrollTrigger = {
    trigger: props.scrollTriggerDom,
    start: '78% 78%',
    end: '88% 88%',
    scrub: true,
    // markers: true,
  }
  scrollTween = gsap.to(triangleDomRef.value, {
    y: '-100%',
    scrollTrigger,
  })
}
function initHover() {
  const options = {
    ease,
    paused: true,
    onUpdate() {
      if (getSmoothScrolling()?.isScroll) {
        handleMouseleave()
      }
    },
  }
  hoverLineMovementTl = gsap.timeline(options)

  const startSteps = 39 - 1
  const topStartImgx =
    startSteps * startDomRef.value.getBoundingClientRect().width * -1
  const loopSteps = 42
  const topLoopImgx =
    loopSteps * loopDomRef.value.getBoundingClientRect().width * -1

  // -7574
  // 三角形冒出
  hoverStartTl = gsap.to(triangleContentDomRef.value, {
    ...options,
    y: '30%',
    duration: 0.3,
    onComplete() {
      if (getSmoothScrolling()?.isScroll) {
        handleMouseleave()
      } else {
        // hoverLineMovementTl.play()
        !hasPhone && hoverLineDashedTl.play()
      }
    },
  })
  // 线条出现
  hoverLineMovementTl
    .to(startDomRef.value, {
      opacity: 1,
      duration: 0.01,
    })
    .to(startImgDomRef.value, {
      x: topStartImgx,
      duration: 1,
      ease: `steps(${startSteps})`,
      onComplete() {
        hoverLoopShowTl.play()
        hoverLineHideTl.play()
      },
    })
  // 线条隐藏
  hoverLineHideTl = gsap.to(startDomRef.value, {
    ...options,
    opacity: 0,
    duration: 0.01,
  })

  // 呼吸线条显示
  hoverLoopShowTl = gsap.to(loopDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.01,
    onComplete() {
      hoverLoopTl.play()
      // hoverLineHideTl.play()
    },
  })

  // 呼吸线条运动
  hoverLoopTl = gsap.to(LoopImgDomRef.value, {
    ...options,
    x: topLoopImgx,
    duration: 1,
    ease: `steps(${loopSteps})`,
    onComplete() {
      hoverLoopTl.reverse()
    },
    onReverseComplete() {
      hoverLoopTl.play()
    },
  })
  if (!hasPhone) {
    // 虚线出现
    hoverLineDashedTl = gsap.to(lineRectRef.value, {
      ...options,
      duration: 2,
      r: 190,
      onReverse() {
        hoverLineDashedTl.duration(playDeshaedLine)
      },
    })
  }
}
onMounted(() => {
  !hasPhone && initScroll()
  initHover()
  hasPhone && handleMouseenter()
})
function handleMouseenter() {
  if (getSmoothScrolling()?.isScroll) {
    handleMouseleave()
  } else {
    !hasPhone && hoverLineDashedTl.duration(playDeshaedLine)
    hoverStartTl.play()
    hoverLineMovementTl.play()
  }
}
function handleMouseleave() {
  if (!setupModalShowRef.value && !hasPhone) {
    hoverStartTl.reverse()
    hoverLineMovementTl.reverse()
    hoverLineHideTl.reverse()
    hoverLoopShowTl.reverse()
    hoverLoopTl.reverse()
    !hasPhone && hoverLineDashedTl.duration(reverseDeshaedLine).reverse()
  }
}

function handleClick() {
  if (hasLogin.value || hasPhone) {
    setupModalShowRef.value = !setupModalShowRef.value
  } else {
    // 登录
    login()
  }
}
watch(setupModalShowRef, handleMouseleave)
onUnmounted(() => {
  hoverStartTl?.kill()
  hoverLineMovementTl?.kill()
  hoverLoopShowTl?.kill()
  hoverLoopTl?.kill()
  hoverLineHideTl?.kill()
  hoverLineDashedTl?.kill()
  scrollTween?.kill()
})
</script>

<template>
  <div
    ref="triangleDomRef"
    class="triangle floating_button fixed left-[50%] top-0 z-2"
  >
    <div
      ref="triangleContentDomRef"
      class="relative"
      @mouseenter="!hasPhone && handleMouseenter()"
      @mouseleave="!hasPhone && handleMouseleave()"
      @click="handleClick"
    >
      <Dashedline
        v-if="!hasPhone"
        ref="lineBoxDomRef"
        class="line-box"
        :style="{ clip: `rect(auto, ${lineRectRef.r}vh, auto, auto)` }"
      ></Dashedline>
      <div
        class="triangle_img relative z-2 flex flex-col items-center text-center"
        :class="{ 'pt-4': hasPhone }"
      >
        <span v-if="!hasPhone" class="fz-3 mt-5"
          >{{ userInfo ? 'MENU' : 'SIGN IN' }}
        </span>
        <img
          v-if="hasLogin"
          src="/src/asset/loginIng.webp"
          alt=""
          class="mt-0.5 w-[33px] md:w-[44px]"
        />
        <img
          v-else
          src="/src/asset/not_login.webp"
          alt=""
          class="w-[12px] md:w-[16px]"
        />
      </div>
      <div ref="startDomRef" class="triangle_item">
        <img
          ref="startImgDomRef"
          src="/src/asset/sprite/top_start.png"
          alt=""
        />
      </div>
      <div ref="loopDomRef" class="triangle_item">
        <img ref="LoopImgDomRef" src="/src/asset/sprite/top_loop.png" alt="" />
      </div>
    </div>
  </div>

  <SetupModal v-model:show="setupModalShowRef"></SetupModal>
</template>

<style scoped lang="scss">
.triangle {
  transform: translate(-50%, -55%);

  .triangle_img {
    @include bg100('src/asset/top_triangle.webp');
    @apply md:w-[468px] md:h-[74px] w-[234px] h-[37px];
  }

  .triangle_item {
    transform: translate(-50%, -55%);

    @apply pointer-events-none absolute md:w-[365px] md:h-[85px] w-[182.5px] h-[42.5px] overflow-hidden left-[50%] opacity-0;

    & > img {
      @apply md:h-[85px] h-[42.5px];
    }
  }

  .line-box {
    left: 50%;
    transform: rotateZ(90deg) translateX(-50%);
    transform-origin: left;

    @apply w-[190vh] bottom-0 absolute;
  }
}
</style>
