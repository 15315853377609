import { defineStore, storeToRefs } from 'pinia'
import { computed, ref, watch, watchEffect } from 'vue'
import { baseTokenStorage } from 'src/helpers/request/index'
import { useShowControl } from 'src/hooks/useShowControl'
import { useRoute, useRouter } from 'vue-router'
import { getChangePasswordModel } from 'src/model/user'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { useSoftPledgeInfoStore } from 'src/stores/softPledge'
import { useWeb3Store } from './web3'
import { getAddress } from 'viem'
import { thirdPartyDiscord } from 'src/sdk/thirdParty'
import { signatureDataStorage } from 'src/helpers/store'

export const useUserInfoStore = defineStore('userInfo', () => {
  const userInfo = ref(null)
  const assetsArray = computed(() =>
    Object.values(userInfo.value?.AccountArchive?.itemList || {}),
  )
  const assetsNotReceiveList = computed(() =>
    assetsArray.value.filter(item => !item.ReceiveUid),
  )
  const transferList = computed(() => {
    return assetsArray.value.filter(item => item.ReceiveUid)
  })
  const assetsList = computed(() => [
    ...assetsNotReceiveList.value,
    ...transferList.value,
  ])

  const bindAddressComputed = computed(() => {
    const wallet = userInfo.value?.wallet
    if (wallet) {
      return getAddress(wallet)
    }
  })

  return {
    userInfo,
    transferList,
    assetsList,
    bindAddressComputed,
  }
})

export const useLoginStore = defineStore('login', () => {
  const { reset: web3Reset } = useWeb3Store()
  const { userInfo } = storeToRefs(useUserInfoStore())
  const { open: openBindEmail } = useBindEmailStore()
  const { logOut: immutableInfoLoginOut } = useImmutableInfoStore()
  const { resetSoftPledgeStore } = useSoftPledgeInfoStore()
  const hasLogin = computed(() => !!userInfo.value?.AccountId)
  const showControl = useShowControl()
  watch(hasLogin, value => {
    if (value) {
      showControl.success(userInfo.value)
      if (!userInfo.value.mail) {
        openBindEmail()
      }
    }
  })

  async function logOut() {
    await immutableInfoLoginOut()
    baseTokenStorage.remove()
    thirdPartyDiscord.loginOut()
    signatureDataStorage.removeItem()
    web3Reset()
    resetSoftPledgeStore()
    resetSoftPledgeStore()
    userInfo.value = null
  }

  return {
    ...showControl,
    hasLogin,
    logOut,
  }
})

export const useRegisterStore = defineStore('register', () => {
  const showControl = useShowControl()
  return showControl
})

export const useBindEmailStore = defineStore('bindEmail', () => {
  const showControl = useShowControl()
  return showControl
})

export const useBindImmutableStore = defineStore('bindImmutable', () => {
  const showControl = useShowControl()
  return showControl
})

export const useChangePasswordLinkStore = defineStore(
  'changePasswordLink',
  () => {
    const showControl = useShowControl()
    return showControl
  },
)

export const useChangePasswordStore = defineStore('changePassword', () => {
  const showControl = useShowControl()
  const route = useRoute()
  const router = useRouter()
  watchEffect(() => {
    if (route.query.changePassword) {
      showControl.open()
    }
  })
  function detection() {
    const query = { ...route.query }
    if (query.changePassword) {
      delete query.changePassword
      const result = {}
      const keys = Object.keys(getChangePasswordModel())
      keys.forEach(key => {
        const value = query[key]
        if (value) {
          result[key] = value
          delete query[key]
        }
      })
      // 接口模型用了mail，所以单独领出来
      result.mail = query.email
      delete query.email
      router.replace({ path: route.path, query })
      return result
    }
    return null
  }
  return { detection, ...showControl }
})
