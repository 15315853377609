<script setup>
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue'
import { storeToRefs } from 'pinia'
import {
  useNFTSoftPledgeStore,
  useNFTSoftPledgeDomStore,
} from 'src/stores/softPledge'
import CombinationSlotInit from '../CombinationSlotInit'
import NFTCombination from '../NFTCombination'
import ArrowClick from '../ArrowClick'
const emit = defineEmits(['add', 'active'])
const {
  deckList,
  activeDeckIndex,
  notDeck,
  activeDeckIndexComputed,
  guideShow,
} = storeToRefs(useNFTSoftPledgeStore())
const { panelDom, contentDom, setp1NFT1Dom, setp1NFT2Dom, setp1NFTAddDom } =
  storeToRefs(useNFTSoftPledgeDomStore())
const panelDomRef = ref(null)
const contentDomRef = ref(null)
const nftDomListRef = ref([])
const addDomRef = ref(null)

function handleAdd() {
  // 点击添加组
  emit('add')
}
function handleActive(index) {
  // 点击编辑组
  activeDeckIndex.value = index
  emit('active', index)
}

watch(
  [guideShow, () => nftDomListRef.value.length, addDomRef],
  ([guideShow, nftDomListLength, addDom]) => {
    if (guideShow && nftDomListLength >= 2 && addDom) {
      const nftDomList = nftDomListRef.value
      setp1NFT1Dom.value = nftDomList[0].dom
      setp1NFT2Dom.value = nftDomList[1].dom
      setp1NFTAddDom.value = addDom
    }
  },
  {
    immediate: true,
  },
)
onMounted(() => {
  panelDom.value = panelDomRef.value
  contentDom.value = contentDomRef.value
})
</script>
<template>
  <div
    class="w-304px h-full border-0 border-r border-solid border-#363D40 overflow-y-auto scroll relative"
    ref="panelDomRef"
  >
    <div class="flex flex-col items-center pt-7 pb-4" ref="contentDomRef">
      <template v-if="notDeck">
        <CombinationSlotInit
          class="card btn-add transition-all"
          @click="handleAdd"
        ></CombinationSlotInit>
        <ArrowClick class="card_w"></ArrowClick>
        <div class="family_blk_bold fz-5 mt-2px">CLICK TO START</div>
      </template>
      <template v-else>
        <template v-for="(item, index) in deckList" :key="item.id">
          <NFTCombination
            ref="nftDomListRef"
            :nft="item.nft"
            :pfpList="item.pfpList"
            :select="activeDeckIndexComputed === index"
            @click="handleActive(index)"
            class="card"
          ></NFTCombination>
        </template>
        <div
          ref="addDomRef"
          class="box_slot btn-add h-230.5px! card transition-all"
          @click="handleAdd"
        ></div>
      </template>
    </div>
  </div>
</template>
<style scoped lang="scss">
.card_w {
  @apply w-230px;
}

.card {
  @extend .mouse_pointer;
  @extend .card_w;
  @apply w-230px h-267px mb-4;
}

.btn-add {
  @apply hover:scale-105;
}

.scroll {
  &::-webkit-scrollbar {
    /* 滚动条整体样式 */
    width: 4.5px; /* 高宽分别对应横竖滚动条的尺寸 */
    height: 4.5px;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    background: #a5a29b;
    border-radius: 0;
  }

  &::-webkit-scrollbar-track {
    /* 滚动条里面轨道 */
    background: #4b4b48;
    border-radius: 0;
  }
}

.box_slot {
  @include bg100('src/asset/nftSoftStaking/deck/new/card.webp');
}
</style>
