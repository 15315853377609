<script setup>
import { computed } from 'vue'
import Modal from 'src/components/Modal'
import { NVirtualList, NEmpty } from 'naive-ui'

const props = defineProps({
  show: Boolean,
  counts: String,
  list: Array,
})
const emit = defineEmits(['update:show'])

function handleClose() {
  emit('update:show', false)
}

const getStatusText = computed(() => {
  return status => {
    if (status === 4) {
      return 'Mint consume'
    } else if (status === 2) {
      return 'Unlock completed'
    } else {
      return 'Unlock failed'
    }
  }
})
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="flex-center xl:scale-80 xl:origin-[20%_50%] 2xl:scale-100 4xl:scale-110 5xl:scale-130 4xl:origin-[50%_50%]! 4xl:ease-linear! 4xl:duration-200!"
    >
      <div class="flex p-[1px]">
        <div
          class="bogx-bg flex flex-items-center flex-col h-[778px] w-[399px] fz-4 mr-4 inline-block"
        >
          <img
            src="/src/asset/logo/bogx_icon.png"
            class="w-32.5 h-41.5 mt-13"
          />
          <div class="fz-4 mt-3 text-#95E1E8">$tBOGX x{{ counts }}</div>
          <div
            class="bogx-bg-3 w-[373px] h-[436px] flex flex-items-center flex-col mt-17 px-10 py-4"
          >
            <p class="fz-3 text-#B8EBF4 text-center">
              1. It costs $tBOGX to mint a Soul Core into a Soul Card NFT.
            </p>
            <p class="fz-3 text-#B8EBF4 text-center">
              2. Ways to acquire $tBOGX: Obtain $tvBOGX through various in-game
              activities and convert $tvBOGX to $tBOGX through AI Agent
              features.
            </p>
            <p class="fz-3 text-#B8EBF4 text-center">
              3. Mint requires time and may fail if exceeded.
            </p>
          </div>
        </div>
        <div
          class="bogx-bg h-[778px] w-[1269px] px-7.5 py-8 fz-4 inline-block text-#9FD7DF"
        >
          <div
            class="w-full bg-#192229 h-15 py-3 flex flex-justify-around flex-items-center border border-solid border-#648188 rounded-[4px]"
          >
            <div
              class="fz-6 w-[410px] flex-center h-full border-0 border-r border-solid border-#648188"
            >
              TIME
            </div>
            <div class="fz-6 flex-1 flex-center">OPERATION RECORD</div>
          </div>
          <div v-if="list.length">
            <NVirtualList
              style="max-height: 644px"
              :item-size="65"
              :items="list"
            >
              <template #default="{ item }">
                <div
                  :key="item.id"
                  class="h-[64px] w-full border-0 border-b border-solid border-#648188 py-3 flex"
                >
                  <div
                    class="fz-4 w-[410px] flex justify-center flex-items-end h-full border-0 border-r border-solid border-#648188"
                  >
                    {{ item.time }} (UTC+8)
                  </div>
                  <div
                    class="fz-4 flex justify-center flex-items-end h-full flex-1"
                  >
                    <span>{{ getStatusText(item.status) }}</span>

                    <img
                      class="w-36[px] h-[34px] mx-2"
                      src="/src/asset/bogx_assets.png"
                    />
                    <span>$tBOGX x{{ item.token_count }}</span>
                  </div>
                </div>
              </template>
            </NVirtualList>
          </div>
          <NEmpty v-else class="m-auto mt-200px"></NEmpty>
        </div>
        <div class="bogx-bg-2 relative inline-block w-[63px] h-[63px] mt-4">
          <div class="btn" @click="handleClose"></div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.bogx-bg {
  @include bogx-bg;

  clip-path: polygon(
    15px 0,
    calc(100% - 15px) 0,
    100% 15px,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 calc(100% - 15px),
    0 15px
  );
}

.bogx-bg-2 {
  @include bogx-bg;

  clip-path: polygon(
    0 0,
    calc(100% - 15px) 0,
    100% 15px,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 100%,
    0 0
  );
}

.bogx-bg-3 {
  @include bogx-bg(#52747c, #040608);

  clip-path: polygon(
    15px 0,
    calc(100% - 15px) 0,
    100% 15px,
    100% calc(100% - 15px),
    calc(100% - 15px) 100%,
    15px 100%,
    0 calc(100% - 15px),
    0 15px
  );
}

.btn {
  position: absolute;
  top: 6px;
  left: 32%;
  background: url('/src/asset/close.webp') no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);

  @apply w-[25px] h-[30px] bottom-[55px];
  @extend .mouse_pointer;
}
</style>
