export function getAccountCodeModel() {
  return {
    cdkey: '',
  }
}
export function getAccountCodeRules() {
  return {
    cdkey: [
      {
        required: true,
        message: 'Please enter the redemption code.',
        trigger: ['input', 'blur'],
      },
    ],
  }
}
