<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { useEventListener } from '@vueuse/core'
import { hasPhone } from 'src/stores/media'

import FiguerLoading from '../FiguerLoading'
import { initFloor } from './capacity/initFloor'
import { initLight } from './capacity/initLight'

// import { Controls } from './capacity/controls'
import { Character } from './capacity/character'
import { Bubble } from './capacity/bubble'
import { GroundParticle } from './capacity/groundParticle'
import { BigScreen } from './capacity/bigScreen'
import { EffectFilter } from './capacity/effectFilter'
import { particleLight, platformY, sceneBg } from './constant'
import { controlDirection } from './controlDirection'
import { Scroll } from './scroll'

const props = defineProps(['scrollTriggerDom'])
const progressRef = ref(0)
const figureProgressRef = ref(0)
const showModelRef = ref(true)
defineExpose({
  handleActivate,
  progress: progressRef,
  getAnimationGroup() {
    return character.animationGroup
  },
  noneModel,
  showModel,
})
const canvasRef = ref(null)

let bigScreen,
  effectFilter,
  bubble,
  groundParticle,
  character,
  scrollTriggerDomWatch,
  scroll,
  floor,
  lightResult,
  requestAnimationFrameId
function getControlDirection() {
  return controlDirection
}
function onWindowResize() {
  bigScreen.adapt()
  effectFilter.adapt()
}
function handleProgress(value) {
  progressRef.value = value
}
function handleFigureProgress(value) {
  figureProgressRef.value = value
}
// 修改镜头位置
function setCameraPosition(camera) {
  camera.position.set(
    scroll.currentCameraPosition.x + getControlDirection().applyMousePosition.x,
    scroll.currentCameraPosition.y + getControlDirection().applyMousePosition.y,
    scroll.currentCameraPosition.z,
  )
}
// 修改相机位置
function setCameraLookAt(camera) {
  camera.lookAt(
    scroll.currentCameraLookAt.x,
    scroll.currentCameraLookAt.y,
    scroll.currentCameraLookAt.z,
  )
}
function setLightPosition() {
  lightResult?.setLightLeft({
    position: scroll.currentLightLeftPosition,
    // position: {
    //   x: currentLightLeftPosition.x + applyMousePosition.x,
    //   y: currentLightLeftPosition.y + applyMousePosition.y,
    //   z: currentLightLeftPosition.z,
    // },
    lookAt: scroll.currentLightLeftLookAt,
  })
  lightResult?.setLightRight({
    position: scroll.currentLightRightPosition,
    // position: {
    //   x: currentLightRightPosition.x + applyMousePosition.x,
    //   y: currentLightRightPosition.y + applyMousePosition.y,
    //   z: currentLightRightPosition.z,
    // },
    lookAt: scroll.currentLightRightLookAt,
  })
}
// 切换动作
function handleActivate() {
  return character.animationGroup.start()
}
function initScroll() {
  if (scrollTriggerDomWatch) {
    scrollTriggerDomWatch()
    scrollTriggerDomWatch = null
  }
  scrollTriggerDomWatch = watch(
    () => props.scrollTriggerDom,
    dom => {
      if (dom) {
        scroll.startMirrorOperation(dom)
      }
    },
    {
      immediate: true,
    },
  )
}
/** 初始化 */
async function init() {
  if (!hasPhone) {
    window.addEventListener(
      'mousemove',
      controlDirection._handleEventListener,
      { passive: true },
    )
  }
  scroll = new Scroll()
  // 场景、相机、渲染器
  bigScreen = new BigScreen()
  bigScreen.initScene(sceneBg)
  bigScreen.initCamera()
  setCameraPosition(bigScreen.camera)
  bigScreen.initRenderer(canvasRef.value)
  bigScreen.adapt()
  window.camera = bigScreen.camera
  // 滤镜
  effectFilter = new EffectFilter(
    bigScreen.scene,
    bigScreen.camera,
    bigScreen.renderer,
  )
  effectFilter.init(particleLight)
  effectFilter.adapt()
  // 地面
  floor = initFloor(sceneBg)
  floor.position.y = platformY
  bigScreen.scene.add(floor)

  // 人物
  character = new Character()
  character.on('progress', handleFigureProgress)
  await character.init()
  character.model.scale.set(10, 10, 10)
  character.model.position.y = platformY
  bigScreen.scene.add(character.model)
  character.animationGroup.on('progress', handleProgress)
  // 黑珠
  bubble = new Bubble(bigScreen.scene)
  bubble.init()

  // 粒子
  groundParticle = new GroundParticle(bigScreen.scene)
  groundParticle.init(particleLight)
  effectFilter.outlinePass.selectedObjects = groundParticle.meshs

  // 灯光
  lightResult = initLight(bigScreen.scene, false)
  setLightPosition()
  // 控制
  // controls = new Controls(bigScreen.camera, bigScreen.renderer)

  // 滚动运动
  initScroll()
}

function update() {
  character?.update()
  groundParticle?.update()
  bubble?.update()
  // setLightPosition()

  setCameraPosition(bigScreen.camera)
  setCameraLookAt(bigScreen.camera)

  effectFilter.composer.render()
  requestAnimationFrameId = requestAnimationFrame(update)
}
function stopUpdate() {
  cancelAnimationFrame(requestAnimationFrameId)
}
function noneModel() {
  stopUpdate()
  showModelRef.value = false
}
function showModel() {
  update()
  showModelRef.value = true
}
// window.noneModel = noneModel
// window.showModel = showModel
useEventListener(window, 'resize', onWindowResize, { passive: true })
onMounted(async () => {
  await init()
  update()
})
onUnmounted(() => {
  stopUpdate()
  if (!hasPhone) {
    window.removeEventListener(
      'mousemove',
      controlDirection._handleEventListener,
    )
    controlDirection.unload()
  }
  scroll?.unload()
  bigScreen?.unload()
  effectFilter?.unload()
  bubble?.unload()
  character?.unload()
  groundParticle?.unload()
  lightResult?.dispose()
  floor?.geometry?.dispose()
  floor?.material?.dispose()
  character?.animationGroup?.off('progress', handleProgress)
  character?.off('progress', handleFigureProgress)
})
</script>

<template>
  <Transition>
    <canvas ref="canvasRef" v-show="showModelRef"></canvas>
  </Transition>
  <FiguerLoading
    v-if="figureProgressRef !== 1"
    :progress="figureProgressRef"
  ></FiguerLoading>
  <!-- <FiguerLoading :progress="figureProgressRef"></FiguerLoading> -->
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
