import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useImmutableIncomeStore = defineStore(
  'immutableIncomeInfo',
  () => {
    const walletAddress = ref('')
    return {
      walletAddress,
    }
  },
)
