<script setup>
import { ref } from 'vue'
import Modal from 'src/components/Modal'
import boss from 'src/asset/mint/boss.webp'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
  emit('cancel')
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="flex-center h-[98vh] w-[100vw] border-0 border-y border-#476F80 border-solid bg-#091720"
    >
      <div class="flex">
        <div class="fz-4 mr-45px inline-block w-[430px]">
          <span class="fz-6 mb-10 block text-#D3A671"
            >SKOLL THE FROSTER HUNTER</span
          >
          The ancient kingdom of "Froster" is a significant realm within Blade
          of God X, homeland of the frost giants. Deep within this kingdom lies
          the Well of Urd, which has existed since the mythological era.
          <br />
          <br />
          <br />
          The collective subconscious of generations of frost giants rests in
          these waters. Over time, this Lake of Consciousness became tainted by
          celestial outsiders, endowing it with the divine power to fulfill the
          wishes of visitors in exchange for their prized possessions. This
          encapsulates the game's central themes of "Sacrifice and Redemption,"
          marking the beginning of all stories.
          <br />
          <br />
          <br />
          This set of three NFTs features Skoll's helmet as the primary element,
          with the legendary-tier NFTs incorporating a mutated human face.
        </div>
        <div class="relative inline-block">
          <img :src="boss" alt="" class="w-[60vw]" />
          <div class="btn" @click="handleClose"></div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.btn {
  position: absolute;
  top: 0;
  right: -6%;
  background: url('/src/asset/close.webp') no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);

  @apply w-[25px] h-[30px] bottom-[55px];
  @extend .mouse_pointer;
}
</style>
