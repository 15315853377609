import { getCssVar } from '@BOGX/utils'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useThemeStore = defineStore(
  'theme',
  () => {
    const PFPDomRef = ref(null)
    const currentTheme = ref('theme-normal')
    const hasThemeGreen = computed(() => currentTheme.value === 'theme-green')
    function toggleTheme() {
      currentTheme.value =
        currentTheme.value === 'theme-normal' ? 'theme-green' : 'theme-normal'
    }

    function getPFPCssVar(cssVar) {
      if (PFPDomRef.value instanceof Element) {
        return getCssVar(cssVar, PFPDomRef.value)
      }
    }

    return {
      currentTheme,
      toggleTheme,
      PFPDomRef,
      getPFPCssVar,
      hasThemeGreen,
    }
  },
  {
    persist: true, // 启用持久化
  },
)
