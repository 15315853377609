<script setup>
import { middleEllipsis } from 'src/sdk/blockchain'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { useImmutable } from 'src/hooks/useImmutable'
import { computed } from 'vue'

const { userInfo } = storeToRefs(useUserInfoStore())
const { bindImmutable } = useImmutable()
const { isLoading: loadingImmutable, execute: executeImmutable } = bindImmutable

const bind = async function () {
  if (userInfo.value?.bindAccountInfo?.openid) return
  await executeImmutable()
}

const loadingRef = computed(() => {
  return loadingImmutable.value
})
defineExpose({
  loading: loadingRef,
})
</script>

<template>
  <div class="w-full" @click="bind">
    <div class="flex-sb">
      <img
        src="src/asset/passport_logo.svg?url"
        alt=""
        class="ml-[-2px] h-22px md:h-[34px]"
      />
      <span class="gray2-color">{{
        userInfo?.bindAccountInfo?.openid
          ? `Immutable ${middleEllipsis(userInfo?.bindAccountInfo?.immutableWallet ?? '')}`
          : 'Connect Immutable'
      }}</span>
      <span
        :class="`${
          userInfo?.bindAccountInfo?.openid ? 'bg-[#57E077]' : 'bg-[#E73636]'
        } md:w-[8px] md:h-[8px] w-[6px] h-[6px] rounded-[50%]`"
      ></span>
    </div>
  </div>
</template>
