import { fetchPFPMintInfo } from 'src/api'
import { pfpNFT } from 'src/helpers/blockchain'
import { ref, computed } from 'vue'

export async function getPFPMintInfo(walletAddress) {
  const mintInfoRef = ref(null)
  const data = await fetchPFPMintInfo(walletAddress)
  pfpNFT.setChainId(data.chainId)
  pfpNFT.setContractAddress(data.contract)
  const genesisTokens = await pfpNFT.getGenesisNFTList({
    accountAddress: walletAddress,
  })
  mintInfoRef.value = {
    ...data,
    isGenesis: !!genesisTokens.length,
  }
  const phaseComputed = computed(() => mintInfoRef.value?.phase)
  // 白名单
  const currentPhaseWLComputed = computed(() => {
    const phase = phaseComputed.value
    if (phase === 1) {
      return mintInfoRef.value?.phase1WL || 0
    } else if (phase > 1) {
      return mintInfoRef.value?.phase2WL || 0
    } else {
      return 0
    }
  })
  return {
    mintInfoRef,
    currentPhaseWLComputed,
  }
}
