import { ref } from 'vue'

export function useSoftPledge() {
  const inventoryModalShowRef = ref(false)
  const openPointHistoryModalShowRef = ref(false)
  const openEventsHistoryModalShowRef = ref(false)
  const openWalletHistoryModalShowRef = ref(false)
  const aboutGameAccountModalShowRef = ref(false)
  const aboutWalletModalShowRef = ref(false)
  const testModalShowRef = ref(false)
  function openInventoryModal() {
    inventoryModalShowRef.value = true
  }
  function openPointHistory() {
    openPointHistoryModalShowRef.value = true
  }
  function openEventsHistory() {
    openEventsHistoryModalShowRef.value = true
  }
  function openWalletHistory() {
    openWalletHistoryModalShowRef.value = true
  }
  function openGameAccount() {
    aboutGameAccountModalShowRef.value = true
  }
  function openAboutWallet() {
    aboutWalletModalShowRef.value = true
  }
  function openTest() {
    testModalShowRef.value = true
  }
  return {
    openInventoryModal,
    inventoryModalShowRef,
    openPointHistoryModalShowRef,
    openEventsHistoryModalShowRef,
    openWalletHistoryModalShowRef,
    aboutGameAccountModalShowRef,
    aboutWalletModalShowRef,
    testModalShowRef,
    openPointHistory,
    openEventsHistory,
    openWalletHistory,
    openGameAccount,
    openAboutWallet,
    openTest,
  }
}
