<script setup>
import { computed } from 'vue'
import { RouterView, useRoute } from 'vue-router'

const route = useRoute()
const key = computed(() => {
  return route.path
})
</script>

<template>
  <div class="flex">
    <div class="main relative w-full">
      <RouterView v-slot="{ Component }">
        <component :is="Component" :key="key" />
      </RouterView>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main {
  @include underMD {
    min-height: calc(100vh - var(--header-phone-height));
  }

  @include minMD {
    min-height: calc(100vh - var(--header-height));
  }
}
</style>
