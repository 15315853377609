<script setup>
import { onMounted } from 'vue'
import { passportInstance } from 'src/sdk/immutable'
import { useRoute, useRouter } from 'vue-router'
import { thirdPartyDiscord } from 'src/sdk/thirdParty'
import { HOME_PAGE_NAME } from 'src/constant'
import { useDiscord } from 'src/hooks/useDiscord'
import { useMessage } from 'naive-ui'
const router = useRouter()
const route = useRoute()
const message = useMessage()
function goToHome() {
  router.replace({ name: HOME_PAGE_NAME })
}
onMounted(async () => {
  switch (route.params.type) {
    case 'discord':
      if (route.query.error) {
        message.error('discord binding failed')
        goToHome()
        return
      }
      thirdPartyDiscord.setCodeData(route.query)
      await thirdPartyDiscord.fetchToken()
      const { bindDiscord } = useDiscord()
      try {
        await bindDiscord.execute()
      } catch (error) {
        console.log('bindDiscord', error)
      } finally {
        goToHome()
      }
      return
    default:
      console.log(passportInstance.loginCallback)
      passportInstance.loginCallback()
      return
  }
})
</script>

<template>
  <div style="font-size: 20px; color: rgb(0 0 0); text-align: center">
    Loading... Please close this window if it hangs more than 30 seconds.
  </div>
</template>
