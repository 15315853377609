<script setup>
import { useTheme } from './useTheme'
import { ref } from 'vue'
const props = defineProps({
  phase: Number,
})
const { classComputed } = useTheme(props)
const hoverRef = ref(false)
function handleMouseenter() {
  hoverRef.value = true
}
function handleMouseleave() {
  hoverRef.value = false
}
</script>
<template>
  <div class="box" :class="classComputed">
    <div
      class="text-color flex-sb bg-color rounded px-2 py-1 relative mouse_pointer"
    >
      <div>Description</div>
      <div
        @mouseenter="handleMouseenter"
        @mouseleave="handleMouseleave"
        class="flex items-center"
      >
        <span :class="{ show: hoverRef }" class="triangle mr-1.5"></span>
        <span>Show All</span>
      </div>
      <div
        v-show="hoverRef"
        class="left-0 bottom-0 translate-y-[100%] absolute w-full bg-description-color border-solid border border-color py-2 px-4"
      >
        The Odyssey Pass - Last Odyssey's exclusive Genesis collection - is
        amust-have with only 888 NFTs in circulation. This limited offerin9
        promises unparalin-game advantages and intriguing This limited offerin9
        promises tages
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import './themen';

.triangle {
  @include triangle($color: var(--text-color));
}

.show {
  transform: rotate(180deg);
}
</style>
