import * as THREE from 'three'

export class Bubble {
  spheres = []
  constructor(scene) {
    this.scene = scene
  }

  init() {
    const geometry = new THREE.SphereGeometry(0.2, 32, 16)
    const material = new THREE.MeshBasicMaterial({
      color: 0x000000,
    })

    for (let i = 0; i < 5; i++) {
      const mesh = new THREE.Mesh(geometry, material)

      mesh.position.x = Math.random() * 10 - 5
      mesh.position.y = Math.random() * 10 - 5
      mesh.position.z = Math.random() * 10 - 20

      mesh.scale.x = mesh.scale.y = mesh.scale.z = Math.random() * 3 + 1

      this.scene.add(mesh)

      this.spheres.push(mesh)
    }
  }

  update() {
    const timer = 0.0001 * Date.now()

    for (let i = 0, il = this.spheres.length; i < il; i++) {
      const sphere = this.spheres[i]

      sphere.position.x = 11 * Math.cos(timer + i)
      sphere.position.y = 11 * Math.sin(timer + i * 1.1)
    }
    return this
  }

  unload() {
    this.spheres.forEach(item => {
      item?.geometry?.dispose()
      item?.material?.dispose()
    })
    return this
  }
}
