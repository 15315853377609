import { fetchWalletaddr, fetchUnbindWalletaddr } from 'src/api'
import { useWeb3Store } from 'src/stores/web3'
import { storeToRefs } from 'pinia'
import { fetchUserInfoP } from 'src/helpers/user'
import { accountRef, hasBlockchainLogin } from 'src/sdk/blockchain'
import { useMessage } from 'naive-ui'
import { computed } from 'vue'
import { getWalletSign } from 'src/helpers/blockchain'
import { useUserInfoStore } from 'src/stores/user'

export function useWalletBind() {
  const { addressIdentical } = storeToRefs(useWeb3Store())
  const { userInfo } = storeToRefs(useUserInfoStore())
  const message = useMessage()
  const addressRef = computed(() => accountRef.value?.address)
  async function handleWalletadder(web3Signed) {
    await fetchWalletaddr({
      walletaddr: addressRef.value,
      web3Signed,
    })
    await fetchUserInfoP.fetch()
  }

  async function unbindWallet() {
    await handleBlockchainLogin()
    const web3Signed = await getWalletSign()
    if (userInfo.value?.wallet) {
      await fetchUnbindWalletaddr({
        web3Signed,
        walletaddr: userInfo.value?.wallet,
      })
      await fetchUserInfoP.fetch()
    }
  }

  async function bindWallet() {
    // 有地址，并且地址不一样
    if (addressRef.value && !addressIdentical.value) {
      let web3Signed = await getWalletSign()
      await handleWalletadder(web3Signed)
    } else {
      await handleBlockchainLogin()
    }
  }

  async function handleBlockchainLogin() {
    if (!addressRef.value) {
      message.warning('Please log in to your wallet first.')
      // 登录钱包
      await hasBlockchainLogin()
    }
  }
  return {
    bindWallet,
    unbindWallet,
    handleBlockchainLogin,
    addressRef,
  }
}
