<script setup>
import { computed } from 'vue'
import { getBtnName } from '../utils'
const props = defineProps({
  device: {
    type: String, // ['pc', 'phone', 'item']
    default: 'item',
  },
  type: Number,
  responseWait: Boolean,
})
const emit = defineEmits(['lock', 'publish'])
function handleLock() {
  emit('lock')
}
function handlePublish() {
  emit('publish')
}
const styleComputed = computed(() => {
  switch (props.device) {
    case 'pc':
      return { height: '35px', width: '100%' }
    // return { height: '35px', width: '49%' }
    case 'phone':
      return { height: '36px', width: '100%', borderRadius: 0 }
    // return { height: '36px', width: '49%', borderRadius: 0 }
    case 'item':
      return { height: '32px', width: '100%', borderRadius: 0 }
    // return { height: '32px', width: '50%', borderRadius: 0 }
  }
})
</script>

<template>
  <div class="flex justify-between">
    <div
      class="flex-center fz-3 rounded-0!"
      :class="`${responseWait ? 'item_btn_disabled' : 'green_button'}`"
      :style="styleComputed"
      @click="!responseWait && handleLock()"
    >
      {{ getBtnName({ type, responseWait }) }}
    </div>
    <!-- <div
      class="item_btn_disabled flex-center fz-3"
      :style="styleComputed"
      @click="handlePublish"
    >
      Publish
    </div> -->
  </div>
</template>
<style scoped lang="scss">
@import './btn';
</style>
