<script setup>
import Modal from 'src/components/Modal'
import prompt_arrow from 'src/asset/mint/prompt_arrow.webp'
import tbogxImg from '/src/asset/logo/bogx_icon.png'

const props = defineProps({
  show: Boolean,
  counts: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="px-64px py-34px flex flex-col flex-items-center bg-#0E0E0F border border-solid border-#696B6B rounded-5px"
    >
      <div class="fz-6 text-#73ABAB">UNLOCK SUCCESSFUL</div>
      <div class="flex">
        <div class="mt-75px flex flex-col w-200px">
          <img class="w-full" :src="tbogxImg" alt="" />
          <div class="flex-1 flex-center flex-col">
            <div class="mt-2 fz-4 text-#73ABAB">$tBOGX x{{ counts }}</div>
          </div>
        </div>
      </div>
      <div class="flex justify-center w-full mt-40px">
        <div
          @click="handleClose"
          class="mouse_pointer btn-confirm fz-3 flex-center bg-#050404 w-186px h-38px border border-solid border-#696B6B rounded-5px"
        >
          Confirm
        </div>
      </div>
    </div>
  </Modal>
</template>
