<script setup>
import LoginModal from 'src/components/user/LoginModal.vue'
import BindEmailModal from 'src/components/user/BindEmailModal.vue'
import RegisterModal from 'src/components/user/RegisterModal.vue'
import ForgetPasswordEmailLinkModal from 'src/components/user/ForgetPasswordEmailLinkModal.vue'
import ChangePasswordModal from 'src/components/user/ChangePasswordModal.vue'
import { useMessage, useLoadingBar } from 'naive-ui'
import { fetchUserInfoP } from 'src/helpers/user.js'
import { computed } from 'vue'

const hasWhiteListRef = computed(() => location.pathname === '/pfp/whiteList')
!hasWhiteListRef && fetchUserInfoP.run()
window.$message = useMessage()
window.$loadingBar = useLoadingBar()
</script>

<template>
  <RouterView />
  <template v-if="!hasWhiteListRef">
    <LoginModal></LoginModal>
    <BindEmailModal></BindEmailModal>
    <RegisterModal></RegisterModal>
    <ForgetPasswordEmailLinkModal></ForgetPasswordEmailLinkModal>
    <ChangePasswordModal></ChangePasswordModal>
  </template>
</template>
