import { hasPhone } from 'src/stores/media'

const variableZ = hasPhone ? 3 : 0
export const mirrorOperations = [
  // 关键帧
  {
    position: {
      x: 8,
      y: 9,
      z: 11 + variableZ,
    },
    lookAt: {
      x: 0,
      y: 8.5,
      z: 0,
    },
  },
  {
    position: {
      x: 18,
      y: 9,
      z: -13 + variableZ,
    },
    lookAt: {
      x: 0,
      y: 8,
      z: 0,
    },
  },
  {
    position: {
      x: -8,
      y: 9,
      z: -30 + variableZ,
    },
    lookAt: {
      x: 0,
      y: 8,
      z: 0,
    },
  },
  {
    position: {
      x: -35,
      y: 8.5,
      z: -8 + variableZ,
    },
    lookAt: { x: 0, y: 5, z: 0 },
  },
  // 关键
  {
    position: { x: -28, y: 8, z: 17 + variableZ },
    lookAt: { x: 0, y: 2, z: 0 },
  },
  {
    position: {
      x: -20,
      y: 13,
      z: 32 + variableZ,
    },
    lookAt: {
      x: 0,
      y: 1.4,
      z: 0,
    },
  },
  {
    position: {
      x: -20,
      y: -3,
      z: 30 + variableZ,
    },
    lookAt: {
      x: 0,
      y: -8,
      z: 0,
    },
  },
]

const mirrorOperationsInterval = getMirrorOperationsInterval()

export const lightLeftFrontPositions = mixinMirrorOperationsInterval({
  position: {
    x: -7,
    y: 0,
    z: 3 + variableZ,
  },
  lookAt: {
    x: 0,
    y: 0,
    z: -3,
  },
})

export const lightRightFrontPositions = mixinMirrorOperationsInterval({
  position: {
    x: 7,
    y: 5,
    z: 0 + variableZ,
  },
  lookAt: {
    x: 0,
    y: 5,
    z: 2,
  },
})

function getMirrorOperationsInterval() {
  const result = []
  for (let i = 0; i < mirrorOperations.length - 1; i++) {
    const current = mirrorOperations[i]
    const next = mirrorOperations[i + 1]
    result.push({
      position: {
        x: next.position.x - current.position.x,
        y: next.position.y - current.position.y,
        z: next.position.z - current.position.z,
      },
      lookAt: {
        x: next.lookAt.x - current.lookAt.x,
        y: next.lookAt.y - current.lookAt.y,
        z: next.lookAt.z - current.lookAt.z,
      },
    })
  }
  return result
}
function mixinMirrorOperationsInterval(data) {
  let current = data
  const result = mirrorOperationsInterval.map(item => {
    const obj = {
      position: {
        x: item.position.x + current.position.x,
        y: item.position.y + current.position.y,
        z: item.position.z + current.position.z,
      },
      lookAt: {
        x: item.lookAt.x + current.lookAt.x,
        y: item.lookAt.y + current.lookAt.y,
        z: item.lookAt.z + current.lookAt.z,
      },
    }
    current = obj
    return obj
  })
  return [data, ...result]
}
