<script setup>
import { gsap } from 'gsap'
import { onMounted, onUnmounted, ref } from 'vue'
import { scrollTriggerBottom } from '../constant.js'
import SwitchCircle from './SwitchCircle'
import BGM from './BGM'

const props = defineProps(['scrollTriggerDom', 'progress'])
const emit = defineEmits(['activate'])
const xLogoDomRef = ref(null)
let tween
function initScroll() {
  const scrollTrigger = {
    ...scrollTriggerBottom,
    trigger: props.scrollTriggerDom,
  }
  // 底部logo
  tween = gsap.to(xLogoDomRef.value, {
    y: '160%',
    scrollTrigger,
  })
}

onMounted(() => {
  initScroll()
})
onUnmounted(() => {
  tween?.kill()
})
</script>

<template>
  <div ref="xLogoDomRef" class="flex-sb relative px-[30px]">
    <img src="/src/asset/x_logo.webp" alt="" class="w-[141px]" />
    <div class="flex-center">
      <div class="circle mr-[12px]">
        <SwitchCircle
          :progress="progress"
          @click="() => emit('activate')"
        ></SwitchCircle>
      </div>
      <div class="circle">
        <BGM class="flex"></BGM>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.circle {
  @apply rounded-[50%] bg-black w-[33px] h-[33px];
  @extend .flex-center;
}
</style>
