import {
  useResizeObserver,
  useEventListener,
  useMutationObserver,
} from '@vueuse/core'
export function useDomChange(domRef, cd) {
  useResizeObserver(domRef, cd)
  useMutationObserver(domRef, cd, {
    attributes: true,
    childList: true,
    subtree: true,
  })
  useEventListener(window, 'resize', cd)
}
