<script setup>
import { NGrid, NGridItem } from 'naive-ui'
import Box from './Box.vue'
import { useListNumber } from 'src/hooks/useListNumber'

const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
  box: {
    type: Boolean,
    default: true,
  },
})
const { listNumberComputed } = useListNumber()
</script>

<template>
  <div class="flex-grow px-3.5 py-8 md:mr-[11%] md:py-10 md:pl-6 md:pr-4">
    <NGrid :x-gap="12" :y-gap="12" :cols="listNumberComputed">
      <slot></slot>
      <NGridItem v-for="item in list" :key="item.Id">
        <Box v-if="box">
          <slot name="item" v-bind="item"></slot>
        </Box>
        <slot name="item" v-bind="item" v-else></slot>
      </NGridItem>
    </NGrid>
  </div>
</template>
