<script setup>
import { NLayout } from 'naive-ui'
import { gsap } from 'gsap'
import Wallet from '../mint/components/Wallet.vue'
import Checker from './components/Checker'
import { onMounted, onUnmounted, ref } from 'vue'

let containerTL
const pfpWhiteRef = ref(null)
function initAnimation() {
  const options = {
    ease: 'power1.out',
    duration: 1,
    opacity: 0,
  }
  containerTL = gsap.from(pfpWhiteRef.value, {
    x: -400,
    ...options,
  })
}

onMounted(() => {
  initAnimation()
})

onUnmounted(() => {
  containerTL?.kill()
})
</script>

<template>
  <NLayout class="wrap">
    <div class="bg-box"></div>
    <Wallet></Wallet>
    <div ref="pfpWhiteRef" class="absolute top-246px left-340px">
      <Checker></Checker>
    </div>
  </NLayout>
</template>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100vh;
  padding-left: 500px;
  background-color: #000 !important;

  .bg-box {
    height: 100vh;

    @include minMD {
      @include bgAuto('src/asset/mint/bg.webp');

      background-size: cover;
    }
  }
}
</style>
