import { SPECIES, SPECIES_LIST } from 'src/constant'
import { computed } from 'vue'
//TODO 优化到本地缓存
export function useSortList(listImmutableValue, unitImmutableValue) {
  // 优先级高的往前，分别每种出现一个，优先级低的补充列表，可以理解问5个高优先级，10个低优先级为一组
  const sortListComputed = computed(() => {
    const unitLength = unitImmutableValue.value
    const list = listImmutableValue.value
    const listLength = list.length
    const typesMap = new Map()
    SPECIES_LIST.forEach(key => {
      typesMap.set(key, [])
    })
    list.forEach(item => {
      const species = item[SPECIES]
      if (species) {
        const arr = typesMap.get(species)
        arr.push(item)
      }
    })
    const result = []
    const length = Math.ceil(listLength / unitLength)
    const types = Array.from(typesMap.entries())

    for (let i = 0; i < length; i++) {
      let j = 0
      while (j < unitLength) {
        if (!j) {
          // 高稀有度种族分别各一个前排
          types.forEach(([key, arr]) => {
            const item = arr.shift()
            if (item) {
              j++
              result.push(item)
            }
          })
        } else {
          // 低稀有度补充
          if (i === length - 1) {
            // 最后一组直接按稀有度塞进去
            types.forEach(([key, arr]) => {
              if (arr.length) {
                const _arr = arr.splice(0)
                j += _arr.length
                result.push(..._arr)
              }
            })
            break
          } else {
            // 不是最后一组,先使用最低稀有度补充
            const fillLength = unitLength - j
            let typesIndex = types.length - 1
            while (j < unitLength) {
              const [key, arr] = types[typesIndex--]
              if (arr.length) {
                const _arr = arr.splice(0, fillLength)
                j += _arr.length
                result.push(..._arr)
              }
            }
          }
        }
      }
    }

    return result
  })
  return {
    sortListComputed,
  }
}
