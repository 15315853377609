<script setup>
import { gsap } from 'gsap'
import { useElementBounding } from '@vueuse/core'
import { ref, watch, nextTick, onMounted, onUnmounted } from 'vue'
import ProfileModal from '../Profile/index.vue'
import DownloadModal from '../Download/index.vue'
import SvgIcon from 'src/components/SvgIcon'
import { useUpdateCssVar } from 'src/pages/PFP/useThemeOverrides'
import { useLoginStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import MyCollection from 'src/components/MyCollection'

const { hasLogin } = storeToRefs(useLoginStore())
const { open: login } = useLoginStore()

const { valueRef: borderColorRef } = useUpdateCssVar('--pfp-border9')
const { valueRef: backgroundColorRef } = useUpdateCssVar('--pfp-bg11')

const profileModalRef = ref(null)
const downloadModalRef = ref(null)

let profileModalTl, profileTriangleTl, downloadTriangleTl, downloadModalTl

const profileModalShowRef = ref(false)
const downloadModalShowRef = ref(false)

const profileModalDom = ref([])
const downloadModalDom = ref([])

const options = {
  ease: 'linear',
  paused: true,
  duration: 0.1,
}
function initAnimation() {
  profileTriangleTl = gsap.to('.pfp-profile-triangle', {
    rotate: 180,
    ...options,
  })
  downloadTriangleTl = gsap.to('.pfp-download-triangle', {
    rotate: 180,
    ...options,
  })
}

function handleProfileClick() {
  downloadModalTl.reverse()
  if (hasLogin.value) {
    profileModalShowRef.value = !profileModalShowRef.value
  } else {
    login()
  }
}

function handleProfileMouseenter() {
  handleProfileClick()
  // downloadModalTl.reverse()
  // profileModalTl?.play()
}

function handleProfileMouseleave() {
  profileModalShowRef.value = !profileModalShowRef.value
  // if (profileModalShowRef.value) return
  // profileModalTl?.reverse()
}

function handleDownloadClick() {
  profileModalTl?.reverse()
  downloadModalShowRef.value = !downloadModalShowRef.value
}
function handleDownloadMouseenter() {
  handleDownloadClick()
  // profileModalTl?.reverse()
  // downloadModalTl?.play()
}

function handleDownloadMouseleave() {
  handleDownloadClick()
  // if (downloadModalShowRef.value) return
  // downloadModalTl?.reverse()
}

watch(profileModalShowRef, value => {
  if (value) {
    nextTick(() => {
      profileTriangleTl?.play()
      profileModalTl?.play()
    })
  } else {
    profileTriangleTl?.reverse()
    profileModalTl?.reverse()
  }
})

watch(downloadModalShowRef, value => {
  if (value) {
    nextTick(() => {
      downloadTriangleTl?.play()
      downloadModalTl?.play()
    })
  } else {
    downloadTriangleTl?.reverse()
    downloadModalTl?.reverse()
  }
})

function initProfileModalDom() {
  const { x, width } = useElementBounding(profileModalRef.value)
  watch([x, width], dom => {
    profileModalDom.value = dom
  })
}

function initDownloadModalDom() {
  const { x, width } = useElementBounding(downloadModalRef.value)
  watch([x, width], dom => {
    downloadModalDom.value = dom
  })
}

onMounted(() => {
  watch(
    [borderColorRef, backgroundColorRef],
    ([borderColor, backgroundColor]) => {
      profileModalTl?.reverse()
      downloadModalTl?.reverse()
      profileModalTl = gsap.to(profileModalRef.value, {
        borderColor,
        backgroundColor,
        borderRadius: 5,
        ...options,
      })
      downloadModalTl = gsap.to(downloadModalRef.value, {
        borderColor,
        backgroundColor,
        borderRadius: 5,
        ...options,
      })
    },
    {
      immediate: true,
    },
  )
  initAnimation()
  initProfileModalDom()
  initDownloadModalDom()
})

onUnmounted(() => {
  profileModalTl?.kill()
  profileTriangleTl?.kill()
  downloadModalTl?.kill()
  downloadTriangleTl?.kill()
})
</script>

<template>
  <div class="ml-216px flex fz-3 relative color-[--pfp-color7]">
    <MyCollection></MyCollection>
    <div
      @mouseenter.stop="handleProfileMouseenter"
      @mouseleave.stop="handleProfileMouseleave"
      ref="profileModalRef"
      class="mouse_pointer mx-7 px-3 py-1 flex flex-items-center border border-solid border-[rgba(0,0,0,0.01)] transition-all"
    >
      PROFILE
      <SvgIcon
        name="PFP-DOWMARROW"
        width="12px"
        class="pfp-profile-triangle ml-2"
      ></SvgIcon>
    </div>
    <div
      @mouseenter.stop="handleDownloadMouseenter"
      @mouseleave.stop="handleDownloadMouseleave"
      ref="downloadModalRef"
      class="mouse_pointer hover flex flex-items-center px-3 py-1 border border-solid border-[rgba(0,0,0,0.01)] transition-all"
    >
      DOWNLOAD BOGX
      <SvgIcon
        name="PFP-DOWMARROW"
        width="12px"
        class="pfp-download-triangle ml-2"
      ></SvgIcon>
    </div>
  </div>
  <transition name="fade">
    <ProfileModal
      v-model:show="profileModalShowRef"
      :profileModalDom="profileModalDom"
    ></ProfileModal>
  </transition>
  <transition name="fade">
    <DownloadModal
      v-model:show="downloadModalShowRef"
      :downloadModalDom="downloadModalDom"
    ></DownloadModal>
  </transition>
</template>

<style lang="scss" scoped>
.hover {
  @apply hover:border hover:border-solid hover:rounded-5px hover:border-[--pfp-border9] hover:bg-[--pfp-bg11];
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
