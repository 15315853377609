<script setup>
import Header from './Header/index.vue'
import InventoryModal from '../components/inventoryModal/index.vue'
import AboutModal from '../components/AboutModal.vue'
import HistoryModal from '../components/HistoryModal.vue'
import GameAccountText from '../components/GameAccountText.vue'
import TestModal from '../components/TestModal'
import WalletText from '../components/WalletText.vue'
import { useSoftPledge } from '../index'
const {
  openPointHistoryModalShowRef,
  openEventsHistoryModalShowRef,
  openWalletHistoryModalShowRef,
  aboutGameAccountModalShowRef,
  aboutWalletModalShowRef,
  testModalShowRef,
  openPointHistory,
  openEventsHistory,
  openWalletHistory,
  openGameAccount,
  openAboutWallet,
  openTest,
} = useSoftPledge()
</script>
<template>
  <div class="wrap bg-#1e1e1e item_container">
    <Header>
      <InventoryModal
        class="-mt-2px"
        @openPointHistory="openPointHistory"
        @openEventsHistory="openEventsHistory"
        @openWalletHistory="openWalletHistory"
        @openGameAccount="openGameAccount"
        @openAboutWallet="openAboutWallet"
        @openTest="openTest"
      ></InventoryModal>
    </Header>
    <HistoryModal
      v-model:show="openPointHistoryModalShowRef"
      headerText="POINT DETAILS"
      :type="1"
    ></HistoryModal>
    <HistoryModal
      v-model:show="openEventsHistoryModalShowRef"
      headerText="EVENTS REWARD"
      :type="1"
    ></HistoryModal>
    <HistoryModal
      v-model:show="openWalletHistoryModalShowRef"
      headerText="WALLET"
      hasNFT
      :type="2"
    ></HistoryModal>
    <AboutModal
      v-model:show="aboutGameAccountModalShowRef"
      headerText="GAME ACCOUNT"
    >
      <GameAccountText></GameAccountText>
    </AboutModal>
    <AboutModal
      v-model:show="aboutWalletModalShowRef"
      headerText="WALLET & STAKING POINTS"
    >
      <WalletText></WalletText>
    </AboutModal>
    <TestModal v-model:show="testModalShowRef"></TestModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  // background: url('/src/asset/softPledge/bg.webp') no-repeat;

  // background-position-x: -4.16vw;
  // background-size: 100% 100%;

  .main {
    height: calc(100vh - var(--header-height));
  }
}
</style>
