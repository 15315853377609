import { useWeb3ModalEvents } from '@web3modal/wagmi/vue'
import { onBeforeUnmount, watchEffect } from 'vue'
import { watchAccount, watchNetwork } from '@wagmi/core'
import { logout } from './store'

export async function onEvent() {
  watchAccount(() => logout())
  watchNetwork(() => logout())
}

export function useModalEvent(fn) {
  const events = useWeb3ModalEvents()
  const stop = watchEffect(async () => {
    const newEvent = events.data.event
    // 钱包模块加载好、已链接、关闭
    const eventArr = ['MODAL_LOADED', 'CONNECT_SUCCESS', 'MODAL_CLOSE']
    if (eventArr.includes(newEvent)) {
      fn()
    }
  })
  onBeforeUnmount(() => {
    stop()
  })
}
