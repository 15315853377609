<script setup>
import {
  addressEllipsisRef,
  hasConnectWalletRef,
  middleEllipsis,
} from 'src/sdk/blockchain'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import wallet_bottom from 'src/asset/mint/pfp_wallet_bottom.webp'
import wallet_top from 'src/asset/mint/pfp_wallet_top.webp'
import { computed } from 'vue'

const { userInfo } = storeToRefs(useUserInfoStore())

const { open } = useWeb3Modal()

function triggerConnect() {
  open()
}

const walletAddress = computed(() => {
  if (userInfo.value?.wallet) return middleEllipsis(userInfo.value?.wallet)
  if (hasConnectWalletRef.value) return addressEllipsisRef.value
  return 'CONNECT'
})
</script>

<template>
  <div
    class="mouse_pointer absolute right-30px top-2 z-2"
    @click="triggerConnect"
  >
    <img
      :src="wallet_bottom"
      class="absolute right-[-153px] top-[-2px] h-[15.5px] md:right-[-84%] md:top-6.5 md:h-18px"
    />
    <img
      :src="wallet_top"
      class="absolute top-[-5px] h-14.5px md:top-3 md:h-24px"
    />
    <div
      class="relative z-2 mt-[-10px] translate-x-[10%] pr-4 md:mt-0 md:pr-12 md:pt-3"
    >
      <img
        src="/src/asset/top_left_jiao.webp"
        class="relative w-[7px] rotate-y-180 md:w-[10px]"
        alt=""
      />
      <span class="fz-3 ml-1.5 mr-1.5 text-#8397A0"> {{ walletAddress }}</span>
      <img src="/src/asset/wallet.webp" class="w-[14px] md:w-[24px]" alt="" />
    </div>
  </div>
</template>
