import { getContract } from 'viem'
import { waitForTransactionReceipt } from '../utils'
import {
  newCreatePublicClient,
  newCreateWalletClient,
  newSwitchChain,
} from './walletConnect'
export async function baseMint({
  chainId,
  contractAddress,
  accountAddress,
  abi,
  functionName = 'mint',
  args,
  value,
  transport,
}) {
  const walletClient = newCreateWalletClient(chainId, accountAddress, transport)
  await newSwitchChain(walletClient)
  const publicClient = newCreatePublicClient(chainId, transport)

  const { request } = await publicClient.simulateContract({
    address: contractAddress,
    abi,
    functionName,
    args,
    account: accountAddress,
    value,
  })
  const txhash = await walletClient.writeContract(request)

  console.log(`txHash${txhash}`)

  const transaction = await waitForTransactionReceipt(publicClient, {
    hash: txhash,
  })
  return transaction
}

export async function baseGetContractItemData({
  chainId,
  contractAddress,
  accountAddress,
  abi,
  functionName = 'totalSupply',
}) {
  console.log('baseGetContractItemData', {
    chainId,
    contractAddress,
    accountAddress,
    abi,
    functionName,
  })

  const walletClient = newCreateWalletClient(chainId, accountAddress)
  await newSwitchChain(walletClient)
  const publicClient = newCreatePublicClient(chainId)

  const params = {
    address: contractAddress,
    abi,
  }
  const data = await publicClient.readContract({
    ...params,
    functionName,
  })
  console.log('baseGetContractItemData:result', data)
  return data
}
// 获取最新的数个nft id，mint完成展示
export async function baseGetNewTokenIds({
  chainId,
  contractAddress,
  accountAddress,
  abi,
  number,
  transport,
}) {
  console.log('baseGetNewTokenIds', {
    chainId,
    contractAddress,
    accountAddress,
    abi,
    number,
  })
  const list = await baseGetTokenids({
    chainId,
    contractAddress,
    accountAddress,
    abi,
    transport,
  })
  const tokenIds = list.slice(-number)
  return tokenIds
}
// 获取最新的nft id，mint完成展示
export async function baseGetNewTokenId({
  chainId,
  contractAddress,
  accountAddress,
  abi,
  transport,
}) {
  console.log('baseGetNewTokenId', {
    chainId,
    contractAddress,
    accountAddress,
    abi,
  })
  const list = await baseGetNewTokenIds({
    chainId,
    contractAddress,
    accountAddress,
    abi,
    transport,
    number: 1,
  })
  return list[0]
}
// 获取nft list
export async function baseGetTokenids({
  chainId,
  contractAddress,
  accountAddress,
  abi,
  transport,
}) {
  console.log('baseGetTokenids', {
    chainId,
    contractAddress,
    accountAddress,
    abi,
  })

  const walletClient = newCreateWalletClient(chainId, accountAddress, transport)
  await newSwitchChain(walletClient)
  const publicClient = newCreatePublicClient(chainId, transport)

  const contract = getContract({
    address: contractAddress,
    abi,
    publicClient,
  })
  const list = await contract.read.tokensOfOwner([accountAddress])
  return list
}
// 获取钱包余额
export async function baseGetBalance({ chainId, accountAddress }) {
  console.log('baseGetTokenids', {
    chainId,
    accountAddress,
  })

  const walletClient = newCreateWalletClient(chainId, accountAddress)
  await newSwitchChain(walletClient)
  const publicClient = newCreatePublicClient(chainId)

  const balance = await publicClient.getBalance({
    address: accountAddress,
  })
  return balance
}
