<script setup>
import PanelContainer from './PanelContainer'
import LimitText from './LimitText'
import RoleForm from './RoleForm'
import BoxInfo from './BoxInfo'
const props = defineProps([
  'readOnly',
  'selectData',
  'data',
  'roleList',
  'singleChoiceData',
])
const emit = defineEmits(['submit'])

function handleValidate(...data) {
  emit('submit', ...data)
}
</script>

<template>
  <PanelContainer>
    <RoleForm
      @submit="handleValidate"
      :readOnly="readOnly"
      :selectData="selectData"
      :data="data"
      :roleList="roleList"
    ></RoleForm>
    <LimitText :selectData="selectData"></LimitText>
    <BoxInfo :data="data"></BoxInfo>
  </PanelContainer>
</template>
