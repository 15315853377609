import { fetchExchangeCodeForAccountToCharacter } from 'src/api'
import { useAsyncState } from '@vueuse/core'
import { fetchUserInfoP } from 'src/helpers/user'

export function useItemTransfer() {
  const { isLoading, execute } = useAsyncState(
    async (receiveItem, UserID) => {
      await fetchExchangeCodeForAccountToCharacter({
        receiveItem: receiveItem.join(','),
        UserID,
      })
      await fetchUserInfoP.run()
    },
    null,
    {
      immediate: false,
      onError(error) {
        throw error
      },
    },
  )
  return {
    isLoading,
    execute,
  }
}
