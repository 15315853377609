<template>
  <div
    class="bg-[--pfp-bg9] border-b-0 border-solid border-[--pfp-border7] b-rounded main relative w-full overflow-y-auto"
  >
    <slot></slot>
  </div>
</template>

<style scoped lang="scss">
.main {
  @include underMD {
    height: calc(100vh - var(--header-phone-height));
  }

  @include minMD {
    height: calc(100vh - var(--header-height));
  }
}
</style>
