<script setup>
import { computed, defineProps } from 'vue'
import { useTimeSlotCountDown } from 'src/hooks/useTimeSlotCountDown'
import Svg0 from 'src/asset/PFP/mint/time/0.svg'
import Svg1 from 'src/asset/PFP/mint/time/1.svg'
import Svg2 from 'src/asset/PFP/mint/time/2.svg'
import Svg3 from 'src/asset/PFP/mint/time/3.svg'
import Svg4 from 'src/asset/PFP/mint/time/4.svg'
import Svg5 from 'src/asset/PFP/mint/time/5.svg'
import Svg6 from 'src/asset/PFP/mint/time/6.svg'
import Svg7 from 'src/asset/PFP/mint/time/7.svg'
import Svg8 from 'src/asset/PFP/mint/time/8.svg'
import Svg9 from 'src/asset/PFP/mint/time/9.svg'
import Svg_ from 'src/asset/PFP/mint/time/_.svg'

const emit = defineEmits(['end'])
const props = defineProps({
  startTime: {
    type: [Number, Date],
  },
  endTime: {
    type: [Number, Date],
  },
  currentTime: {
    type: [Number, Date],
  },
  dayColor: {
    type: String,
    default: '#EDC58D',
  },
  yColor: {
    type: String,
    default: '#fff',
  },
  hmsColor: {
    type: String,
    default: '#F8A52F',
  },
})
const numberSvgList = [
  Svg0,
  Svg1,
  Svg2,
  Svg3,
  Svg4,
  Svg5,
  Svg6,
  Svg7,
  Svg8,
  Svg9,
]
const currentTimeComputed = computed(() => props.currentTime)
const startTimeComputed = computed(() => props.startTime)
const endTimeComputed = computed(() => props.endTime)
function handleEnd() {
  emit('end')
}
const { timeListRef, statusRef, currentTimeRef } = useTimeSlotCountDown(
  currentTimeComputed,
  startTimeComputed,
  endTimeComputed,
  {
    onEnd: handleEnd,
  },
)
const formattedTimeComputed = computed(() =>
  timeListRef.value.replace(/,/g, '').split(''),
)
defineExpose({
  status: statusRef,
  currentTime: currentTimeRef,
})
</script>

<template>
  <div class="flex items-center">
    <div class="flex items-end">
      <div class="number_box mr-1" v-if="Number(formattedTimeComputed[0])">
        <component
          :is="numberSvgList[formattedTimeComputed[0]]"
          :color="yColor"
        ></component>
      </div>
      <div class="number_box">
        <component
          :is="numberSvgList[formattedTimeComputed[1]]"
          :color="yColor"
        ></component>
      </div>
      <i class="ml-1 mr-2" :style="{ color: dayColor }">Days</i>
    </div>
    <div class="flex box">
      <div class="number_box">
        <component
          :is="numberSvgList[formattedTimeComputed[2]]"
          :color="hmsColor"
        ></component>
      </div>
      <div class="number_box ml-1">
        <component
          :is="numberSvgList[formattedTimeComputed[3]]"
          :color="hmsColor"
        ></component>
      </div>
    </div>
    <Svg_ :color="hmsColor" class="mx-4"></Svg_>
    <div class="flex box">
      <div class="number_box">
        <component
          :is="numberSvgList[formattedTimeComputed[4]]"
          :color="hmsColor"
        ></component>
      </div>
      <div class="number_box ml-1">
        <component
          :is="numberSvgList[formattedTimeComputed[5]]"
          :color="hmsColor"
        ></component>
      </div>
    </div>
    <Svg_ :color="hmsColor" class="mx-4"></Svg_>
    <div class="flex box">
      <div class="number_box">
        <component
          :is="numberSvgList[formattedTimeComputed[6]]"
          :color="hmsColor"
        ></component>
      </div>
      <div class="number_box ml-1">
        <component
          :is="numberSvgList[formattedTimeComputed[7]]"
          :color="hmsColor"
        ></component>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.box {
  @apply bg-#0E0E0F border border-solid border-#696b6b w-64px h-58px;
  @extend .flex-center;
}

.number_box {
  @apply w-25px flex items-center justify-center;
}
</style>
