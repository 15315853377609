<script setup>
import { gsap } from 'gsap'
import { onMounted, onUnmounted, ref } from 'vue'

const boxDomRef = ref(null)
const imgDomRef = ref(null)
let tween
function start() {
  const steps = 68 - 1
  const x = steps * boxDomRef.value.offsetWidth * -1
  tween = gsap.to(imgDomRef.value, {
    repeat: -1,
    duration: 2,
    x,
    ease: `steps(${steps})`,
  })
}
onMounted(start)
onUnmounted(() => {
  tween?.kill()
})
</script>

<template>
  <div ref="boxDomRef" class="loading">
    <img ref="imgDomRef" src="/src/asset/sprite/loading.png" alt="" />
  </div>
</template>

<style scoped lang="scss">
.loading {
  @apply pointer-events-none w-[300px] h-[300px] overflow-hidden;

  & > img {
    @apply h-[300px];
  }
}
</style>
