<script setup>
import { computed } from 'vue'
import { RouterView, useRoute } from 'vue-router'

const route = useRoute()
const key = computed(() => {
  return route.path
})
</script>

<template>
  <div class="relative">
    <RouterView v-slot="{ Component }">
      <component :is="Component" :key="key" />
    </RouterView>
  </div>
</template>
