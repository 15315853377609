<script setup>
import { NLayoutHeader } from 'naive-ui'
import { HOME_PAGE_NAME } from 'src/constant'
import { hasPhone } from 'src/stores/media'
import Balance from '../Balance/index.vue'
import Menu from 'src/components/user/Menu.vue'
import UserInfoUtils from 'src/components/user/UserInfoUtils'

const emit = defineEmits(['handleConnect'])

function handleConnect() {
  emit('handleConnect')
}
</script>
<template>
  <NLayoutHeader
    class="header mb:px-6 relative"
    :class="`fz-${hasPhone ? 4 : 5}`"
  >
    <div
      :class="{ 'flex-sb': hasPhone }"
      class="header-box flex mb:border-b-0 md:h-full border-0 border-t border-#534740 border-dashed pl-2.5 md:px-6"
    >
      <div
        class="flex items-center md:h-full w-280px border-0 border-r border-solid border-#534740 md:p-0 py-2"
      >
        <router-link :to="{ name: HOME_PAGE_NAME }" class="flex-center">
          <img
            v-if="!hasPhone"
            src="/src/asset/labs_logo.webp"
            class="mb-7px h-[39px]"
            alt=""
          />
        </router-link>
      </div>
      <div
        class="title relative ml-40px mt-28px flex flex-items-center w-407px h-28.5px"
      >
        <img
          class="w-30.5px absolute -bottom-2px left-158px"
          src="src/asset/immutableIncome/font.webp"
          alt=""
        />
      </div>
    </div>
    <div class="absolute right-30px bottom-3 z-2">
      <div class="flex flex-items-center">
        <Balance @click="handleConnect" class="mouse_pointer"></Balance>

        <Menu class="mouse_pointer fz-3 ml-50px mb-2px text-#A4886A"></Menu>
      </div>
    </div>
    <!-- <UserInfoUtils class="absolute right-30px bottom-3 z-2"></UserInfoUtils> -->
  </NLayoutHeader>
</template>

<style lang="scss" scoped>
@import './index';

.header {
  @include underMD {
    height: var(--header-phone-height);
  }

  @include minMD {
    height: var(--header-height);
  }

  .header-box {
    background: linear-gradient(
      90deg,
      rgb(21 16 14 / 100%) 0%,
      rgb(47 36 27 / 100%) 99.41%,
      rgb(47 36 27 / 100%) 100.41%
    );

    .title {
      @include bg100('src/asset/immutableIncome/title.webp');
    }
  }
}
</style>
