import { useAsyncState } from '@vueuse/core'
import { fetchRoleList } from 'src/api'
import { getReceiveJobName } from '../utils'

export function useRoleList() {
  const { state } = useAsyncState(async () => {
    const data = await fetchRoleList()
    const list = data.RoleList.map(item => {
      return {
        ...item,
        label: getReceiveJobName(item.Job, item.Name),
        value: item.UserID,
      }
    })
    return [
      {
        label: 'Please select.',
        value: '',
      },
      ...list,
    ]
  }, [])
  return state
}
