<script setup>
import Loading from 'src/components/Loading'
import { NProgress } from 'naive-ui'

const props = defineProps({
  progress: {
    type: Number,
    default: 0,
  },
})
</script>

<template>
  <div class="fixed left-50% top-50% translate-x-[-50%] translate-y-[-50%]">
    <NProgress
      class="w-[340px]!"
      type="dashboard"
      :gap-offset-degree="180"
      :gap-degree="200"
      :percentage="props.progress * 100"
      :stroke-width="1"
      color="rgb(211 166 113 / 100%)"
      rail-color="#555350"
    >
      <Loading></Loading>
    </NProgress>
    <div class="fz-5 orange-color mt-1 text-center">
      {{ Math.floor(props.progress * 100) }}%
    </div>
  </div>
</template>
