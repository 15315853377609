import { gsap } from 'gsap'

export function animationPosition() {
  let data = { x: 0, y: 0, z: 0 }
  let tl
  return {
    setAnimation(pathList) {
      tl?.kill()
      tl = gsap.to(data, {
        repeat: -1,
        paused: true,
        duration: 10,
        motionPath: {
          path: pathList,
          curviness: 2,
        },
        ease: 'none',
      })
    },
    getAnimationPositionData() {
      return data
    },
    getTl() {
      return tl
    },
  }
}
const defaultPositionoPerationData = [
  {
    x: 0,
    y: 0.025,
    z: 0.05,
  },
  {
    x: 0.025,
    y: 0.0125,
    z: 0.075,
  },
  {
    x: 0.025,
    y: 0.0125,
    z: 0.075,
  },
  {
    x: 0.075,
    y: -0.0125,
    z: 0.025,
  },
  {
    x: 0,
    y: 0,
    z: 0,
  },
]
export function getPositionoPerationData(xyz = { x: 1, y: 1, z: 1 }) {
  return defaultPositionoPerationData.map(item => {
    return {
      x: item.x * xyz.x,
      y: item.y * xyz.y,
      z: item.z * xyz.z,
    }
  })
}
