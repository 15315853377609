import { hasPhone } from 'src/stores/media'
import { computed } from 'vue'
import { useLandscapeScreen } from 'src/hooks/useLandscapeScreen'

export function useLandscapeScreenAttr() {
  const { widthRef, heightRef, topRef, leftRef, translateXRef, rotateRef } =
    useLandscapeScreen()
  const widthValueRef = computed(() => {
    return !hasPhone ? 'auto' : `${widthRef.value}px`
  })
  const heightValueRef = computed(() => {
    return !hasPhone ? 'auto' : `${heightRef.value}px`
  })
  const topValueRef = computed(() => {
    return !hasPhone ? 'auto' : `${topRef.value}px`
  })
  const leftValueRef = computed(() => {
    return !hasPhone ? 'auto' : `${leftRef.value}px`
  })
  const rotateValueRef = computed(() => {
    return `rotate(${rotateRef.value}deg)`
  })
  const translateValueRef = computed(() => {
    return `translate(${translateXRef.value}px, 0px)`
  })
  const transformValueRef = computed(() => {
    return `${translateValueRef.value} ${rotateValueRef.value}`
  })
  return {
    widthValueRef,
    heightValueRef,
    topValueRef,
    leftValueRef,
    rotateValueRef,
    translateValueRef,
    transformValueRef,
  }
}
