<script setup>
import { useRoute } from 'vue-router'
import { computed } from 'vue'
import { useUserInfoStore } from 'src/stores/user'
import { useWeb3Store } from 'src/stores/web3'
import { storeToRefs } from 'pinia'
import { hasPhone } from 'src/stores/media'

const route = useRoute()
const { nftList } = storeToRefs(useWeb3Store())
const { assetsList } = storeToRefs(useUserInfoStore())

const totalRef = computed(() => nftList.value.length + assetsList.value.length)
</script>

<template>
  <router-link
    class="gray-color router-link"
    :class="`${!hasPhone ? 'fz-h5' : 'flex-center'}`"
    :to="{
      name: route.path.includes('/transfer_history')
        ? 'myCollectionAll'
        : route.name,
    }"
  >
    <span>Collection</span>
    <span class="text-length">{{ `<${totalRef}>` }}</span>
  </router-link>
</template>

<style scoped lang="scss">
@import './index';
</style>
