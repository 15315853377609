<script setup>
import Modal from 'src/components/Modal'

const props = defineProps({
  show: Boolean,
  rewardInfo: {
    type: Object,
    default: null,
  },
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal class="mask" :show="props.show" @update:show="handleClose">
    <div
      class="px-54px pt-50px pb-20px flex flex-col flex-items-center bg-#0E0E0F border border-solid border-#696B6B rounded-5px"
    >
      <div class="fz-6 text-#FFFFFF">CONGRATULATIONS!</div>
      <div
        class="mt-30px w-408.5px h-51.5px flex-center bg-#2A2D31/40 text-#99A7B0 rounded-5px"
      >
        You've got the following rewards from the wheel!
      </div>
      <div
        :style="`background-image:url(${rewardInfo?.item_icon})`"
        class="mt-20px w-303px h-303px bg-[length:100%_100%] bg-no-repeat border border-solid border-#587277"
      ></div>
      <div
        class="mt-25px w-408.5px h-31px flex-center bg-#2A2D31/40 rounded-5px text-#fff text-18px"
      >
        <span>{{ rewardInfo?.item_name }}</span>
        <span class="text-#34D259 ml-10px">x{{ rewardInfo?.item_count }}</span>
      </div>
      <div class="flex mt-35px">
        <div
          @click="handleClose"
          class="mouse_pointer mr-37px flex-center text-#8397A0 bg-#171A1C w-186px h-38px border border-solid border-#6B7B87 rounded-5px"
        >
          Back
        </div>
        <router-link :to="{ name: 'Inventory' }">
          <div
            class="mouse_pointer flex-center text-#fff bg-#FF7D31 w-186px h-38px border border-solid border-#FFC662 rounded-5px"
          >
            {{ rewardInfo?.gameItem ? 'Get Redeem Code' : 'View History' }}
          </div>
        </router-link>
      </div>
    </div>
  </Modal>
</template>
