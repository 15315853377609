<script setup>
import { NModal } from 'naive-ui'
import { watch } from 'vue'
import { scrollLock } from 'src/helpers/smoothScrolling'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  height: {
    type: String,
    default: '75vh',
  },
  close: {
    type: Boolean,
    default: true,
  },
  transformOrigin: {
    type: String,
    default: 'center',
  },
})
const emit = defineEmits(['update:show', 'close'])
function handleClose() {
  emit('update:show', false)
  emit('close')
}
watch(
  () => props.show,
  value => {
    scrollLock(value)
  },
)
function handleUpdateShow(show) {
  emit('update:show', show)
}
</script>

<template>
  <NModal
    :show="props.show"
    :close-on-esc="false"
    :transform-origin="props.transformOrigin"
    :show-icon="false"
    @update:show="handleUpdateShow"
    @close="handleClose"
  >
    <slot></slot>
  </NModal>
  <!-- <NDrawer v-else :height="props.height" :show="props.show" placement="bottom">
    <div v-if="props.close" class="relative mb-12">
      <img
        :src="closeImg"
        class="width-[1.2rem] absolute right-6 top-4"
        @click="handleClose"
      />
    </div>

    <slot></slot>
  </NDrawer> -->
</template>
