<script setup>
import { computed, onMounted, onUnmounted, ref, watchEffect } from 'vue'
import { gsap } from 'gsap'
import { getSmoothScrolling } from 'src/helpers/smoothScrolling'
import { ease } from 'src/constant'
import {
  playDeshaedLine,
  reverseDeshaedLine,
  scrollTriggerBottom,
} from '../constant.js'

import Dashedline from './Dashedline'

const props = defineProps(['scrollTriggerDom', 'progress', 'active'])
const hasMouseEnterRef = ref(false)
const scrollDomRef = ref(null)
const triangleDomRef = ref(null)
const startDomRef = ref(null)
const startImgDomRef = ref(null)
const loopDomRef = ref(null)
const LoopImgDomRef = ref(null)
const lineBoxDomRef = ref(null)
const switchPeopleRef = ref(null)
const switchRotateRef = ref(null)
const lineRectRef = ref({ l: 80 })

const progressRef = computed(() => 50 - (props.progress / 2) * 100)
let hoverStartTl,
  hoverLineMovementTl,
  hoverLoopShowTl,
  hoverLoopTl,
  hoverLineHideTl,
  hoverLineDashedTl,
  hoverRotateTl,
  // hoverPeopleTl,
  scrollTween
function initScroll() {
  const scrollTrigger = {
    ...scrollTriggerBottom,
    trigger: props.scrollTriggerDom,
  }
  scrollTween = gsap.to(scrollDomRef.value, {
    x: '110%',
    scrollTrigger,
  })
}
function initHover() {
  const options = {
    paused: true,
    ease,
    onUpdate() {
      if (getSmoothScrolling()?.isScroll) {
        fnMouseleave()
      }
    },
  }
  // 三角形冒出
  hoverStartTl = gsap.to(triangleDomRef.value, {
    ...options,
    x: '28%',
    duration: 0.3,
    onComplete() {
      if (getSmoothScrolling()?.isScroll) {
        fnMouseleave()
      } else {
        hoverLineDashedTl.play()
      }
    },
  })

  hoverLineMovementTl = gsap.timeline(options)

  const startSteps = 62 - 1
  const topStartImgx = startSteps * startDomRef.value.offsetWidth * -1
  const loopSteps = 73 - 1
  const topLoopImgx = loopSteps * loopDomRef.value.offsetWidth * -1

  // 线条出现
  hoverLineMovementTl
    .to(startDomRef.value, {
      opacity: 1,
      duration: 0.01,
    })
    .to(startImgDomRef.value, {
      x: topStartImgx,
      duration: 1,
      ease: `steps(${startSteps})`,
      onComplete() {
        hoverLoopShowTl.play()
        hoverLineHideTl.play()
      },
    })
  // 线条隐藏
  hoverLineHideTl = gsap.to(startDomRef.value, {
    ...options,
    opacity: 0,
    duration: 0.01,
  })

  // 呼吸线条显示
  hoverLoopShowTl = gsap.to(loopDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.01,
    onComplete() {
      hoverLoopTl.play()
      // hoverLineHideTl.play()
    },
  })

  // 呼吸线条运动
  hoverLoopTl = gsap.to(LoopImgDomRef.value, {
    ...options,
    x: topLoopImgx,
    duration: 1,
    ease: `steps(${loopSteps})`,
    onComplete() {
      hoverLoopTl.reverse()
    },
    onReverseComplete() {
      hoverLoopTl.play()
    },
  })
  // 虚线出现
  hoverLineDashedTl = gsap.to(lineRectRef.value, {
    ...options,
    duration: 2,
    l: 0,
    onReverse() {
      hoverLineDashedTl.duration(playDeshaedLine)
    },
  })

  hoverRotateTl = gsap.to(switchRotateRef.value, {
    ...options,
    rotate: 180,
    duration: 0.5,
    ease: 'power1.inOut',
  })
}
onMounted(() => {
  initScroll()
  initHover()
})
function fnMouseenter() {
  if (getSmoothScrolling()?.isScroll) {
    fnMouseleave()
  } else {
    hoverLineDashedTl.duration(playDeshaedLine)
    hoverStartTl.play()
    hoverLineMovementTl.play()
    hoverRotateTl.play()
  }
}
function handleMouseenter() {
  hasMouseEnterRef.value = true
  fnMouseenter()
}
function fnMouseleave() {
  if (props.active) return
  hoverStartTl?.reverse()
  hoverLineMovementTl?.reverse()
  hoverLineHideTl?.reverse()
  hoverLoopShowTl?.reverse()
  hoverLoopTl?.reverse()
  hoverRotateTl?.reverse()
  hoverLineDashedTl?.duration(reverseDeshaedLine)?.reverse()
}
function handleMouseleave() {
  hasMouseEnterRef.value = false
  fnMouseleave()
}
watchEffect(() => {
  if (!props.active && !hasMouseEnterRef.value) {
    fnMouseleave()
  }
})
onUnmounted(() => {
  hoverStartTl?.kill()
  hoverLineMovementTl?.kill()
  hoverLoopShowTl?.kill()
  hoverLoopTl?.kill()
  hoverLineHideTl?.kill()
  hoverLineDashedTl?.kill()
  hoverRotateTl?.kill()
  // hoverPeopleTl?.kill()
  scrollTween?.kill()
})
</script>

<template>
  <div ref="scrollDomRef" class="fixed right-0 top-[50%] z-[2]">
    <div
      ref="triangleDomRef"
      class="triangle mouse_pointer relative"
      @mouseenter="handleMouseenter"
      @mouseleave="handleMouseleave"
    >
      <div class="triangle_img flex items-center">
        <img
          ref="switchRotateRef"
          src="/src/asset/right_switch.webp"
          class="ml-7.5 w-[20px]"
        />
        <div class="ml-3 mr-1 flex flex-col items-center justify-center">
          <span class="fz-3">SWITCH</span>
          <div
            v-if="props.progress !== 1"
            class="progress w-full"
            :style="{
              clipPath: `inset(0 ${progressRef}% 0 ${progressRef}% round 0)`,
            }"
          ></div>
        </div>
        <div
          ref="switchPeopleRef"
          class="action-people action-people-hover w-[14px] h-[23px]"
        ></div>
      </div>
      <div ref="startDomRef" class="triangle_item">
        <img
          ref="startImgDomRef"
          src="/src/asset/sprite/right_start.png"
          alt=""
        />
      </div>
      <div ref="loopDomRef" class="triangle_item">
        <img
          ref="LoopImgDomRef"
          src="/src/asset/sprite/right_loop.png"
          alt=""
        />
      </div>
      <Dashedline
        ref="lineBoxDomRef"
        class="line-box"
        :style="{ clip: `rect(auto, auto, auto, ${lineRectRef.l}vw)` }"
      ></Dashedline>
    </div>
  </div>
</template>

<style scoped lang="scss">
.triangle {
  transform: translate(70%, -50%);

  .triangle_img {
    @include bg100('src/asset/right_triangle.webp');
    @apply w-[204px] h-[130.5px];
  }

  .triangle_item {
    transform: translate(-73%, -104%);

    @apply pointer-events-none absolute w-[210px] h-[120px] overflow-hidden left-[50%] opacity-0;

    & > img {
      @apply h-[120px];
    }
  }

  .line-box {
    top: 50%;
    right: 250px;
    width: 80vw;
    transform: translateY(-50%);

    @apply absolute;
  }

  .progress {
    height: 1px;
    background-color: #fff;
  }

  .action-people {
    background-image: url('/src/asset/switch_logo.webp');
    background-size: 100% 100%;
    transition: background-image ease-in-out 0.3s;
  }

  .action-people-hover {
    @apply hover:bg-[url(/src/asset/switch_active.webp)];
  }
}
</style>
