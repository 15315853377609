import { omit } from 'lodash-es'
import { computed } from 'vue'
import BBCode from 'nbbcjs'
import { bbCodeParseHtml } from '../../../helpers/bbCode'

export function useSoulList(listRef, roleListRef) {
  const soulListComputed = computed(() => {
    return listRef.value.map(info => {
      const data = info.info
      return {
        // TODO 1：mint，2 unlock, 3 lock
        // type: 2,
        // TODO mint 或者 unlock 后等待服务器返回中
        // responseWait: false,
        ...data,
        ...omit(info, ['info']),
        Desc: data.Desc && bbCodeParseHtml(new BBCode().parse(data.Desc)),
        ReceiveJob: roleListRef.value.find(role => role.UserID === info.UserID)
          ?.Job,
        ReceiveName: roleListRef.value.find(role => role.UserID === info.UserID)
          ?.Name,
      }
    })
  })
  // const unlockResponseWaitComputed = computed(() =>
  //   soulListComputed.value.some(item => item.type === 1 && item.responseWait),
  // )
  // const mintResponseWaitComputed = computed(() =>
  //   soulListComputed.value.some(item => item.type === 2 && item.responseWait),
  // )
  return {
    soulListComputed,
    // mintResponseWaitComputed,
    // unlockResponseWaitComputed,
  }
}
