<script setup>
import About from './About.vue'
import { onMounted, onUnmounted, ref } from 'vue'
import {
  initSmoothScrolling,
  scrollingDestruction,
} from 'src/helpers/smoothScrolling'

import { hasPhone } from 'src/stores/media'
import { useEnter } from './useEnter'
const scrollTriggerDomRef = ref(null)

const emit = defineEmits(['update:enterPage'])
const { oneEnterRef } = useEnter()
function getEnterPage(val) {
  emit('update:enterPage', val)
}
// const wrapperRef = ref(null)
// const contentRef = ref(null)
defineExpose({
  scrollTriggerDom: scrollTriggerDomRef,
  oneEnter: oneEnterRef,
})
onMounted(() => {
  // TODO 弹窗滚动问题
  initSmoothScrolling()
  // initSmoothScrolling({z
  // wrapper: wrapperRef.value,
  // content: contentRef.value,
  // })
})

onUnmounted(() => {
  scrollingDestruction()
})
</script>

<template>
  <!-- <div ref="wrapperRef" class="footer h-[100vh] overflow-auto"> -->
  <div class="footer">
    <!-- <div ref="contentRef"> -->
    <div ref="scrollTriggerDomRef" class="box2 h-[700vh]"></div>
    <div
      class="footer-content gray-color relative z-[3] border border-t-solid bg-black/[.4] px-6 pt-3 md:px-0 md:pt-4"
    >
      <div class="fz-5 text-center tracking-[3px] text-white md:tracking-[8px]">
        <template v-if="hasPhone">
          <div>BLADE OF GOD X:</div>
          <div>PLAY ARPG TO TRAIN AI AGENT</div>
        </template>
        <template v-else>
          BLADE OF GOD X: PLAY ARPG TO TRAIN AI AGENT
        </template>
      </div>
      <div class="flex-center fz-3 py-4 text-white">
        <div class="mr-8">
          <span class="orange-gray-color mr-1">- 2024 -</span>
          <span>BLADE OF GOD X</span>
        </div>
        <div>
          <span class="orange-gray-color mr-1">DEVELOPED BY</span>
          <span>VOID LABS</span>
        </div>
      </div>
      <div
        class="orange-gray-color m-auto tracking-[1px] md:w-[70%] md:flex md:flex-col md:items-center"
        :class="`fz-${hasPhone ? 2 : 3}`"
      >
        <div class="mb-2.5 text-center">
          Available on PC and mobile, Blade of God X (BOG X) offers
          cross-platform play and intuitive ways to train AI Agent with a
          dedicated fan base of 6 million.
        </div>
        <div class="mb-2.5 text-center">
          Our vision is to reshape how humans interact with digital realm and AI
          through gaming.
        </div>
        <div class="mb-2.5 text-center">
          The game constructs a world that spans the four-dimensional space of
          spatial and temporal civilizations across the nine realms.
        </div>
        <div class="mb-2.5 text-center">
          Stay tuned for the upcoming test that will empower the players with AI
          Agent.
        </div>
      </div>
      <About v-if="oneEnterRef" @update:enterPage="getEnterPage"></About>
    </div>
    <!-- </div> -->
  </div>
</template>

<style scoped lang="scss">
.footer {
  position: relative;
  z-index: auto;
}
</style>
