import { createRouter, createWebHistory } from 'vue-router'

import { NOT_404_NAME } from 'src/constant'
// import { preloadRoutersFile } from 'src/helpers/preload'
import { hasIPWhitelist } from 'src/helpers/ip'
import { routes } from './model'

export const router = createRouter({
  history: createWebHistory(),
  routes,
})
router.beforeEach(async (to, from, next) => {
  window.$loadingBar.start()
  if (!hasIPWhitelist && to.name !== NOT_404_NAME) {
    // 将用户重定向到登录页面
    return { name: NOT_404_NAME }
  } else {
    next()
  }
})

router.afterEach(to => {
  window.$loadingBar.finish()
  // requestAnimationFrame
  // if (preloadRoutersFile[to.name]?.length) {
  //   preloadRoutersFile[to.name].forEach(cd => cd())
  // }
  window.scrollTo(0, 0) // 切换路由之后滚动条始终在最顶部
})
