<script setup>
import eyeImg from 'src/asset/eye.webp'
import close_eyeImg from 'src/asset/close_eye.webp'
const props = defineProps({
  checked: Boolean,
  width: {
    type: String,
    default: '24px',
  },
})
const emit = defineEmits(['update:checked'])
function handleChange() {
  emit('update:checked', !props.checked)
}
</script>

<template>
  <img
    :src="checked ? eyeImg : close_eyeImg"
    class="w-[29px] mouse_pointer"
    @click="handleChange"
  />
</template>
