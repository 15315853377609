import md5 from 'crypto-js/md5'

// 排序函数，按键升序排序
function sortObject(obj) {
  const keys = Object.keys(obj).sort()
  const sortedObj = {}
  for (const key of keys) {
    sortedObj[key] = obj[key]
  }
  return sortedObj
}

// 将对象转换为字符串
function stringifyObject(obj) {
  let str = ''
  for (const key in obj) {
    str += key + obj[key]
  }
  return str
}

// 生成 hashkey
function generateHashKey() {
  return 'xw@Ynk*V95tY9r$%KLHBe9V%' // 这里假设你已经有了一个 hashkey
}

// 主函数
export function encryption(params) {
  const sortedParams = sortObject(params)
  const paramString = stringifyObject(sortedParams)
  const hashKey = generateHashKey()
  const dataToHash = paramString + hashKey
  return md5(dataToHash).toString()
}
