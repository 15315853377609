import { useMessage } from 'naive-ui'
import { storeToRefs } from 'pinia'
import {
  getBlockchainErrorCode,
  getBlockchainNeedErrorMessage,
} from 'src/sdk/blockchain'
import { usePFPMintStore } from 'src/stores/pfp'
import { computed, ref } from 'vue'

export function useMint() {
  const pfpStore = usePFPMintStore()
  const message = useMessage()
  const { currentPhaseWLComputed } = storeToRefs(pfpStore)
  const quantityRef = ref(0)
  const mintRef = ref(null)
  const quantityMaxComputed = computed(() =>
    currentPhaseWLComputed.value > 5 ? 5 : currentPhaseWLComputed.value,
  )
  const successShowRef = ref(false)
  const errorShowRef = ref(false)
  const mintListRef = ref([])
  function handleMintSuccess(list) {
    successShowRef.value = true
    mintListRef.value = list
    quantityRef.value = 0
    console.log('handleMintSuccess', list)
  }
  function handleMintError(list, error) {
    const code = getBlockchainErrorCode(error)
    if (code === -1) {
      errorShowRef.value = true
      mintListRef.value = list
    } else {
      message.error(getBlockchainNeedErrorMessage(error))
    }
    console.log('handleMintError', list, error, code)
  }
  return {
    quantityRef,
    successShowRef,
    errorShowRef,
    mintListRef,
    quantityMaxComputed,
    handleMintSuccess,
    handleMintError,
    mintRef,
  }
}
