import {
  creasteRequest,
  getResponseData,
  requestSuccess,
  validateSuccess,
} from './utils'

export const requestWall = creasteRequest()
// export fu
requestWall.interceptors.response.use(response => {
  if (validateSuccess(response.data.code)) {
    return getResponseData(response)
  } else {
    return Promise.reject(response)
  }
})

requestWall.interceptors.request.use(requestSuccess)
