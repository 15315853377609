import { ref, computed } from 'vue'

export function useUserSoulList(listRef) {
  const currentUserIdRef = ref('')
  const userSoulListComputed = computed(() => {
    if (currentUserIdRef.value) {
      return listRef.value.filter(
        item => item.UserID === currentUserIdRef.value,
      )
    } else {
      return listRef.value
    }
  })
  return {
    currentUserIdRef,
    userSoulListComputed,
  }
}
