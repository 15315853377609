<script setup>
import { computed, watch, onUnmounted, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import { gsap } from 'gsap'
import GrandSvg from 'src/asset/PFP/ethereal/grand.svg?url'
import AddressSvg from 'src/asset/PFP/ethereal/address.svg?url'
import Banner1Webp from '/src/asset/PFP/ethereal/banner1.webp'
import Banner2Webp from '/src/asset/PFP/ethereal/banner2.webp'

const props = defineProps({
  hasToken: {
    type: Boolean,
    default() {
      return false
    },
  },
  wallet: {
    type: String,
    default() {
      return ''
    },
  },
  status: {
    type: Number,
    default() {
      return 0
    },
  },
  isLoading: {
    type: Boolean,
    default() {
      return true
    },
  },
})

const isEnd = computed(() => props.status === 3)
let textTL
function initAnimation() {
  const options = {
    ease: 'power1.out',
    duration: 1,
    opacity: 0,
  }
  textTL = gsap.from('.ethereal-text', {
    x: -850,
    delay: 0.2,
    // stagger: 0.1,
    ...options,
  })
}

const router = useRouter()
function handleClick() {
  if (isEnd.value) return
  router.push({
    name: 'PFPMint',
  })
}
let firstLoading = false
watch(
  () => props.isLoading,
  val => {
    if (!val && !firstLoading) {
      firstLoading = true
      nextTick(() => {
        initAnimation()
      })
    }
  },
  { immediate: true },
)

onUnmounted(() => {
  textTL?.kill()
})
</script>
<template>
  <div class="relative xl:scale-100 scale-80 transform-origin-top-left">
    <div v-if="!firstLoading" class="h-682px w-full"></div>
    <img
      v-else
      class="w-88.75%"
      :src="!hasToken ? Banner1Webp : Banner2Webp"
      alt=""
    />
    <div class="absolute top-135px left-120px text-#ffffff">
      <div v-show="!hasToken && firstLoading">
        <div class="ethereal-text text-64px leading-64px">YOU DON'T HAVE</div>
        <div class="ethereal-text text-64px leading-64px">ANY TOKENS</div>
        <div class="ethereal-text mt-10px mb-57px text-#C9C7C7 fz-5 w-700px">
          {{
            isEnd
              ? `Embark on a journey to Immutable and become part of the
        mystical realm of Ethereal.`
              : 'Make your way to acquire the coveted Ethereal collection.'
          }}
        </div>
        <div
          @click="handleClick"
          :class="
            !isEnd
              ? 'bg-#FF7D31 border-#FFC662'
              : 'bg-#CDD8E0 border-0 text-#000'
          "
          class="flex-center mouse_pointer w-186px h-38px border border-solid rounded-5px"
        >
          {{ isEnd ? 'Immutable' : 'Mint' }}
        </div>
      </div>
    </div>
    <div
      class="flex-center absolute bottom-0 left-32px 2xl:scale-100 5xl:scale-130 md:scale-80 transform-origin-bl"
    >
      <div
        class="btn flex flex-items-center px-22px mr-32px bg-#2E2E31 border-#3C3C3E"
      >
        <img :src="GrandSvg" alt="" class="h-44px" />
        <div class="h-44px w-1px bg-#4B4B4F/60 mx-20px"></div>
        <div class="flex flex-col text-#4B4B4F">
          <span class="family_blk">GRAND</span>
          <span>Coming Soon</span>
        </div>
      </div>
      <div
        class="btn mouse_pointer flex flex-items-center px-22px mr-32px bg-#323C44 border-#6B7B87"
      >
        <img :src="AddressSvg" alt="" class="h-44px" />
        <div class="h-44px w-1px bg-#666F7B/60 mx-20px"></div>
        <div class="flex flex-col text-#CDD8E0">
          <span class="family_blk">ADDRESS</span>
          <span>{{ wallet }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.btn {
  @apply w-246px h-75px border border-solid rounded-35px;
}
</style>
