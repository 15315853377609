<script setup>
import { computed } from 'vue'
const props = defineProps({
  num: {
    type: String,
    default() {
      return '0%'
    },
  },
  max: {
    type: String,
    default() {
      return '100%'
    },
  },
})

const numComputed = computed(() => (props.num ? parseInt(props.num, 10) : 0))
const maxComputed = computed(() => (props.max ? parseInt(props.max, 10) : 100))
const numFloatComputed = computed(() =>
  props.num.includes('.')
    ? parseFloat(props.num).toFixed(2)
    : parseInt(props.num, 10),
)

const percentComputed = computed(() => {
  if (numComputed.value / maxComputed.value > 3) return 3
  return numComputed.value / maxComputed.value
})
const colorComputed = computed(() => {
  if (percentComputed.value === 0) return '#8699A3'
  if (percentComputed.value === 1) return '#F7CC6E'
  if (percentComputed.value > 0 && percentComputed.value < 1) return '#4ECA56'
  if (percentComputed.value > 1 && percentComputed.value < 2) return '#fc5d47'
  if (percentComputed.value >= 2) return '#ff90e4'
})
</script>

<template>
  <div class="flex flex-1 flex-items-baseline justify-between">
    <div class="relative w-70% h-1px 5xl:h-2px">
      <div
        class="progress-bar absolute bottom-0 right-0 w-full h-full bg-#26282A"
      ></div>
      <div
        v-if="percentComputed < 1"
        :style="{ width: `${100 * percentComputed}%` }"
        class="progress-bar absolute bottom-0 right-0 bg-#FFFFFF w-full h-full transition-all"
      ></div>
      <div
        v-else-if="percentComputed === 1"
        :style="{ width: `${100 * percentComputed}%` }"
        class="progress-bar bg absolute bottom-0 right-0 w-full h-full transition-all"
      ></div>
      <div
        v-else-if="percentComputed > 1 && percentComputed <= 2"
        :style="{ width: `${100 * (percentComputed - 1)}%` }"
        class="progress-bar bg1 absolute bottom-0 right-0 w-full h-full transition-all"
      ></div>
      <div
        v-else
        :style="{ width: `${100 * (percentComputed - 2)}%` }"
        class="progress-bar bg2 absolute bottom-0 right-0 bg-#FFFFFF w-full h-full transition-all"
      ></div>
    </div>
    <span :style="{ color: `${colorComputed}` }"> {{ numComputed }}% </span>
  </div>
</template>

<style lang="scss" scoped>
.progress-bar {
  clip-path: polygon(10px 0, 100% 0, 100% 100%, 0 100%);
}

.bg {
  background: linear-gradient(270deg, #eeac5d 0%, #f8cd70 100%);
}

.bg2 {
  background: linear-gradient(
    270deg,
    #f59f9f 0%,
    #adefe8 25%,
    #6cd4f9 49.88%,
    #aa95f8 75%,
    #75d2f5 100%
  );
}

.bg1 {
  background: linear-gradient(270deg, #db361b 0%, #f56859 100%);
}
</style>
