<template>
  <div class="line pointer-events-none"></div>
</template>

<style scoped>
.line {
  height: 0;
  background: linear-gradient(
    46deg,
    rgb(188 198 205 / 10%) 0%,
    rgb(188 198 205 / 1%) 100%
  );
  background-origin: border-box;
  border-bottom: 1px dashed var(--body-color);
}
</style>
