import { watch } from 'vue'
import { useLandscapeScreenAttr } from './useLandscapeScreenAttr'

export function useLandscapeScreenDom(domRef) {
  const {
    widthValueRef,
    heightValueRef,
    topValueRef,
    leftValueRef,
    transformValueRef,
  } = useLandscapeScreenAttr()
  watch(
    [
      widthValueRef,
      heightValueRef,
      topValueRef,
      leftValueRef,
      transformValueRef,
      domRef,
    ],
    ([w, h, t, l, transform, dom]) => {
      if (dom) {
        dom.style.cssText = `width:${w};height:${h};top:${t};left:${l};transform:${transform};transform-origin:0 0;`
      }
    },
  )
}
