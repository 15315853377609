<script setup>
import List from './List/index.vue'
import Fiilters from './Fiilters/index.vue'
import Tabs from './Tabs/index.vue'
import ScrollTop from 'src/components/ScrollTop.vue'
import { ref, onMounted, onUnmounted } from 'vue'
import { NEmpty } from 'naive-ui'

const props = defineProps({
  filterList: {
    type: Array,
    default() {
      /**
       * {
       *  type: 'type' | 'input'
       *  id: 1,
       *  name: 'asda asdasd'
       * },
       */
      return []
    },
  },
  list: {
    type: Array,
    default() {
      return []
    },
  },
  total: {
    type: Number,
    default: 0,
  },
})
const emit = defineEmits(['detail', 'random'])
function handleDetail(item) {
  emit('detail', item)
}
function handleFilterClose(item) {
  emit('filterClose', item)
}
function handleRandom() {
  emit('random')
}
const contentRef = ref(null)
const scrollRef = ref(null)
const showScrollRef = ref(false)

function scrollToTop() {
  scrollRef.value.scrollTo({ top: 0, behavior: 'smooth' })
}

function handleScroll() {
  showScrollRef.value = scrollRef.value.scrollTop > 200
}
onMounted(() => {
  scrollRef.value = contentRef.value.parentElement
  scrollRef.value.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  scrollRef.value.removeEventListener('scroll', handleScroll)
})
</script>
<template>
  <div class="pt-34px h-100% flex flex-col" ref="contentRef">
    <div class="px-46px pb-36px">
      <Tabs :total="total" @refresh="handleRandom"></Tabs>
    </div>
    <div class="px-46px">
      <Fiilters
        class="mb-3"
        :list="filterList"
        @close="handleFilterClose"
      ></Fiilters>
    </div>
    <div
      class="pb-38px flex-grow"
      :class="`${!list.length ? 'flex items-center justify-center' : ''}`"
    >
      <List
        v-show="list.length"
        class="px-46px"
        @detail="handleDetail"
        :list="list"
      ></List>
      <NEmpty v-show="!list.length"></NEmpty>
    </div>
    <div
      v-if="showScrollRef"
      @click="scrollToTop"
      class="mouse_pointer fixed right-20px bottom-30px border border-solid border-[--pfp-border5] bg-[--pfp-bg7] text-[var(--pfp-color11)] w-51px h-51px rounded-50%"
    >
      <ScrollTop></ScrollTop>
    </div>
  </div>
</template>
