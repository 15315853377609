<script setup>
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { NCollapse, NCollapseItem } from 'naive-ui'
import { nextTick, ref } from 'vue'

const roleListRef = useRoleList()

const triangleRef = ref([])
const activeTab = ref(-1)
function handleClick({ name, expanded }) {
  activeTab.value = name
  const dom = triangleRef.value[activeTab.value]
  nextTick(() => {
    dom.style.transform = expanded ? 'rotate(-180deg)' : 'rotate(0deg)'
    dom.style.transition = 'transform 0.3s ease'
  })
}
</script>

<template>
  <div class="mt-1 pb-1 item-li h-auto!" v-if="roleListRef?.length > 1">
    <NCollapse
      class="item_collapse"
      accordion
      arrow-placement="right"
      :on-item-header-click="handleClick"
    >
      <NCollapseItem :name="key" v-for="(item, key) in roleListRef?.slice(1)">
        <template #header>
          <div
            class="flex w-full px-2.5 py-2 flex-items-center justify-between"
          >
            <div class="text-#5D636B text-xs">User{{ key + 1 }}</div>
            <div
              :class="{ active: activeTab === key }"
              class="text-#A79274 fz-3"
            >
              {{ item.Name }}
            </div>
            <div :ref="el => (triangleRef[key] = el)" class="triangle"></div>
          </div>
        </template>
        <template #arrow>
          <div class="triangle opacity-0"></div>
        </template>

        <div
          class="py-1 px-2 bg-#17191C/55 border border-solid border-#616568 rounded-5px"
        >
          <ul class="text-#5D636B text-xs">
            <li class="flex flex-items-center justify-between">
              <div class="w-20%">Uid</div>
              <div class="text-#fff fz-3 flex-1 text-center">
                {{ item.UserID }}
              </div>
              <div></div>
            </li>
            <li class="flex flex-items-center justify-between">
              <div class="w-20%">Hero</div>
              <div class="text-#fff fz-3 flex-1 text-center">
                {{ item.label }}
              </div>
              <div></div>
            </li>
            <li class="flex flex-items-center justify-between">
              <div class="w-20%">ServerName</div>
              <div class="text-#fff fz-3 flex-1 text-center">
                {{ item.ServerName }}
              </div>
              <div></div>
            </li>
            <li class="flex flex-items-center justify-between">
              <div class="w-20%">LoginTime</div>
              <div class="text-#8B959B fz-3 flex-1 text-center">
                {{ item.LoginTime }}
              </div>
              <div></div>
            </li>
          </ul>
        </div>
      </NCollapseItem>
    </NCollapse>
  </div>
</template>

<style lang="scss" scoped>
.triangle {
  @include triangle($color: #818a93);
}

.active {
  color: #ddad68;
}

.item-li {
  @apply md:h-[38px] md:leading-[38px] leading-[31px] rounded px-1;
  @apply mt-1.5;
  @apply border-1 border-solid border-[#212224] bg-[#212224];
  @extend .mouse_pointer;

  @include minMD {
    &:not(.item-li-not-hover):hover {
      @apply border-[#616568] text-white;
    }
  }
}

.item_collapse {
  ::v-deep {
    .n-collapse-item {
      margin: 0 !important;
    }

    .n-collapse-item__header {
      padding: 0 !important;
    }

    .n-collapse-item__content-inner {
      padding: 0 !important;
    }

    .n-collapse-item:not(:first-child) {
      border: none !important;
    }
  }
}
</style>
