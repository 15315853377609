<script setup>
import { ref } from 'vue'
import { NForm, NFormItem, NInput } from 'naive-ui'
import { getLoginModel, getLoginRules } from 'src/model/user'
import { fetchLogin } from 'src/api'
import PasswordInput from '../PasswordInput'

const props = defineProps(['disabled'])
defineExpose({
  handleValidate,
  handleSubmit,
})
const formRef = ref(null)

const modelData = getLoginModel()
const modelRef = ref(modelData)

function handleValidate(e) {
  e.preventDefault()
  return formRef.value?.validate()
}
function handleSubmit() {
  return fetchLogin(modelRef.value)
}
</script>

<template>
  <NForm
    ref="formRef"
    :model="modelRef"
    :rules="getLoginRules()"
    :disabled="props.disabled"
    :show-label="false"
  >
    <NFormItem path="accountName">
      <NInput v-model:value="modelRef.accountName" placeholder="Username" />
    </NFormItem>
    <NFormItem path="password">
      <PasswordInput v-model:value="modelRef.password"></PasswordInput>
    </NFormItem>
  </NForm>
</template>
