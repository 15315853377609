import * as THREE from 'three'
import { EventEmitter } from 'eventemitter3'

export class AnimationGroup extends EventEmitter {
  static coordinationTime = 0.5
  requestAnimationFrameId
  /**
   * @param {AnimationMixer} mixer
   * @param {Array<AnimationAction[]>} animationsGroup
   * @param {AnimationAction} playAnimation
   */
  constructor(mixer, animationsGroup, playAnimation) {
    super()
    this.mixer = mixer
    this.animationsGroup = animationsGroup
    if (playAnimation) {
      playAnimation.play()
    }
    this.prevAnimation = playAnimation
    this.animationsIndex = 0
    this.animationIndex = 0
    this.active = false
    this._continuousAnimation = this.continuousAnimation.bind(this)
    this._startMonitorProgress = this.startMonitorProgress.bind(this)
  }

  get currentAnimations() {
    return this.animationsGroup[this.animationsIndex]
  }

  get currentAnimation() {
    return this.currentAnimations[this.animationIndex]
  }

  get hasLoopAnimation() {
    return (
      this.currentAnimation === this.currentAnimations.at(-1) &&
      this.currentAnimation === this.prevAnimation
    )
  }

  get totalDuration() {
    return this.currentAnimations.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.getClip().duration
    }, 0)
  }

  get currentProgress() {
    let accumulator = 0
    for (let i = 0; i < this.animationIndex; i++) {
      accumulator += this.currentAnimations[i].getClip().duration
    }
    return (accumulator += this.active
      ? this.currentAnimation.time
      : this.currentAnimation.getClip().duration)
  }

  get progress() {
    if (this.active) {
      return this.currentProgress / this.totalDuration
    } else {
      return 1
    }
  }

  get loopMode() {
    // 这组动画的最后一个循环
    return this.hasLoopAnimation ? THREE.LoopRepeat : THREE.LoopOnce
  }

  start() {
    if (this.active) {
      return this
    } else {
      this.active = true
    }
    this.nextAnimations().fusionRun()
    this.startMonitorProgress()
    return this
  }

  sustain() {
    return this.nextAnimation().run()
  }

  nextAnimations() {
    this.animationIndex = 0
    if (this.animationsIndex >= this.animationsGroup.length - 1) {
      this.animationsIndex = 0
    } else {
      this.animationsIndex++
    }
    return this
  }

  nextAnimation() {
    if (this.animationIndex < this.currentAnimations.length - 1) {
      this.animationIndex++
    }
    return this
  }

  startMonitorProgress() {
    this.emit('progress', this.progress)
    this.requestAnimationFrameId = requestAnimationFrame(
      this._startMonitorProgress,
    )
    return this
  }

  stopMonitorProgress() {
    this.emit('progress', this.progress)
    cancelAnimationFrame(this.requestAnimationFrameId)
    return this
  }

  startMonitorFinished() {
    if (!this.hasLoopAnimation) {
      this.mixer.addEventListener('finished', this._continuousAnimation)
    }
    return this
  }

  stopMonitorFinished() {
    this.mixer.removeEventListener('finished', this._continuousAnimation)
    return this
  }

  actionsComplete() {
    this.active = false
    return this.stopMonitorProgress()
  }

  continuousAnimation(event) {
    // 动画名一致判断
    if (this.currentAnimation._clip.name === event.action._clip.name) {
      this.prevAnimation = this.currentAnimation
      this.stopMonitorFinished()
      if (this.hasLoopAnimation) {
        this.actionsComplete()
      }
      this.sustain()
    }
    return this
  }

  // 让上一个动画跟现有动画融合，保证切换动作的衔接
  fusionRun() {
    const prevAnimationDuration = this.prevAnimation
      .setLoop(THREE.LoopOnce)
      .getClip().duration
    // 获取动画剩余时间
    const remainingTime = prevAnimationDuration - this.prevAnimation.time
    return this.fusion(Math.min(remainingTime, AnimationGroup.coordinationTime))
  }

  fusion(fusionTime) {
    this.startMonitorFinished()
    this.currentAnimation
      .setLoop(this.loopMode)
      .stop()
      .play()
      // 融合动作
      .crossFadeFrom(this.prevAnimation, fusionTime)
    return this
  }

  run() {
    this.prevAnimation.stop()
    this.startMonitorFinished()
    this.currentAnimation.setLoop(this.loopMode).stop().play()
    return this
  }

  unload() {
    cancelAnimationFrame(this.requestAnimationFrameId)
    this.stopMonitorFinished()
    return this
  }
}
