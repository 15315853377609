<script setup>
import arrow from 'src/asset/nftSoftStaking/guide/step/2/arrow.webp'
import ModelBox from './ModelBox'
import { storeToRefs } from 'pinia'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
const { guideShow, guideCurrentIndex } = storeToRefs(useNFTSoftPledgeStore())
</script>
<template>
  <ModelBox v-show="guideShow && guideCurrentIndex === 1">
    <div class="absolute h-full w-full pt-4 pl-6">
      <div class="relative family_blk_bold fz-5 inline-block w-80%">
        <span class="text-#69F093">ENESIS NFT SLOTS</span>
        <div class="text-#fff">
          NFTs of different rarities will provide different bonuses.
        </div>
      </div>
      <img
        :src="arrow"
        alt=""
        class="w-82px absolute z-1 top-40px -right-4px"
      />
    </div>
  </ModelBox>
</template>
