<script setup>
import { useImmutableInfoStore } from 'src/stores/immutable'
import { storeToRefs } from 'pinia'
// import RedPopover from 'src/components/RedPopover'
import MarketplaceLinks from './MarketplaceLinks'
import { hasPhone } from 'src/stores/media'
import {
  IMMUTABLE_COLLECTION_ASSETS_NAME,
  IMMUTABLE_COLLECTION_MARKETPLACE_NAME,
} from 'src/constant'
const { soulCoreInfoLockList, soulCoreInfoMarketplaceList } = storeToRefs(
  useImmutableInfoStore(),
)
</script>
<template>
  <router-link
    :to="{ name: IMMUTABLE_COLLECTION_ASSETS_NAME }"
    :class="{ 'fz-h5': !hasPhone }"
    class="gray-color router-link 2xl:mr-10 relative md:mr-8"
  >
    <span>In-game Items</span>
    <span class="text-length">{{ `<${soulCoreInfoLockList.length}>` }}</span>
  </router-link>
  <router-link
    :to="{ name: IMMUTABLE_COLLECTION_MARKETPLACE_NAME }"
    :class="{ 'fz-h5': !hasPhone }"
    class="gray-color router-link 2xl:mr-10 relative md:mr-8"
  >
    <span>Wallet Items</span>
    <span class="text-length">{{
      `<${soulCoreInfoMarketplaceList.length}>`
    }}</span>
  </router-link>
  <MarketplaceLinks>
    <div
      :class="{ 'fz-h5': !hasPhone }"
      class="gray-color router-link 2xl:mr-10 relative md:mr-8"
    >
      <span>Marketplace</span>
    </div>
  </MarketplaceLinks>
</template>

<style scoped lang="scss">
@import './index';
</style>
