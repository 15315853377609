import { useWindowSize } from '@vueuse/core'
import { defineStore } from 'pinia'
import { computed, ref, watch } from 'vue'
import {
  MD_WIDTH,
  XL_WIDTH,
  XL2_WIDTH,
  XL3_WIDTH,
  XL4_WIDTH,
  LG_WIDTH,
} from 'src/constant'
import { isPhone } from '@BOGX/utils'

const defaultFontSize = '16px'
export const useMediaStore = defineStore('media', () => {
  const { width, height } = useWindowSize()
  const minMD = ref(false)
  const minLG = ref(false)
  const minXL = ref(false)
  const min2XL = ref(false)
  const min3XL = ref(false)
  const min4XL = ref(false)
  const keepDefaultRemRef = ref(false)
  const hasHorizontalScreenRef = computed(
    () => !minMD.value && width.value > height.value,
  )
  watch(
    [width, height, keepDefaultRemRef],
    ([width]) => {
      minMD.value = width >= MD_WIDTH
      minLG.value = width >= LG_WIDTH
      minXL.value = width >= XL_WIDTH
      min2XL.value = width >= XL2_WIDTH
      min3XL.value = width >= XL3_WIDTH
      min4XL.value = width >= XL4_WIDTH
      changeRem()
    },
    {
      immediate: true,
    },
  )
  function keepDefaultRem() {
    keepDefaultRemRef.value = true
  }
  function recoverDynamicRem() {
    keepDefaultRemRef.value = false
  }
  const isAdaptation = computed(() => {
    if (min4XL.value) {
      return '4xl'
    } else if (min3XL.value) {
      return '3xl'
    } else if (min2XL.value) {
      return '2xl'
    } else if (minXL.value) {
      return 'xl'
    } else if (minLG.value) {
      return 'lg'
    } else {
      return 'md'
    }
  })
  function changeRem() {
    if (!keepDefaultRemRef.value && !minMD.value) {
      setRem(`${getRem()}px`)
    } else {
      setRem(defaultFontSize)
    }
  }
  return {
    width,
    height,
    minMD,
    min2XL,
    isAdaptation,
    keepDefaultRem,
    recoverDynamicRem,
    hasHorizontalScreenRef,
  }
})
export function getRem() {
  // 375 / 16 = 23.4375
  return document.documentElement.clientWidth / 23.4375
}
export function setRem(rem) {
  document.documentElement.style.fontSize = rem
}

export const hasPhone = isPhone()
