<script setup>
import Modal from 'src/components/Modal'
import { ref, onMounted, watch, onUpdated } from 'vue'
import CheckoutWidget from '../../checkoutWidget/index.vue'

const props = defineProps({
  widgetType: {
    type: String,
    default: '',
  },
  orderData: {
    type: Object,
    default: null,
  },
})

const emit = defineEmits(['update:show', 'getWalletAddress', 'success'])
function handleShow(show = false) {
  emit('update:show', show)
}
function getWalletAddress(wallet) {
  emit('getWalletAddress', wallet)
}
</script>
<template>
  <Modal @update:show="handleShow">
    <CheckoutWidget
      :widgetType="props.widgetType"
      :orderData="props.orderData"
      @close="handleShow"
      @getWalletAddress="getWalletAddress"
      @success="emit('success')"
    ></CheckoutWidget>
  </Modal>
</template>
