<script setup>
import { ref, watch } from 'vue'
import { getRoleCodeModel } from 'src/model'
import { NForm, NFormItem, NSelect, useMessage } from 'naive-ui'
import ArrowBackGray from 'src/components/ArrowBackGray'
import { selectMax } from 'src/constant'
import { selectTheme } from 'src/pages/myCollection/utils'
const props = defineProps(['selectData', 'roleList', 'singleChoiceData'])
const emit = defineEmits(['submit', 'empty'])

const formRef = ref(null)
const modelData = getRoleCodeModel()
const modelRef = ref(modelData)
const message = useMessage()
function handleValidate() {
  emit('submit', modelRef.value.UserID, props.selectData)
}

watch(
  () => props.selectData,
  arr => {
    if (arr?.length === selectMax) {
      message.info('You can only select up to five items at a time.')
    }
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <div
    v-show="selectData?.length"
    class="fixed left-0 top-0 z-3 w-full bg-#202723"
  >
    <NForm
      ref="formRef"
      label-placement="left"
      :model="modelRef"
      @keyup.enter="handleValidate"
    >
      <div class="flex items-center py-2 pl-3 pr-6">
        <ArrowBackGray
          class="mr-3 flex-shrink-0"
          @click="() => emit('empty')"
        ></ArrowBackGray>
        <NFormItem
          path="UserID"
          label="HERO"
          class="flex-grow"
          :show-feedback="false"
        >
          <NSelect
            v-model:value="modelRef.UserID"
            :options="roleList"
            :theme-overrides="selectTheme"
          ></NSelect>
        </NFormItem>
      </div>
      <slot name="btn" :handleValidate="handleValidate">
        <div
          class="green_button flex-center fz-3 h-[36px] rounded-0!"
          @click="handleValidate"
        >
          Transfer Item
        </div>
      </slot>
    </NForm>
    <!-- <div v-if="selectData?.length === selectMax" class="mb6 text-#dc553a">
      You can only select up to five items at a time.
    </div> -->
  </div>
</template>

<style scoped lang="scss">
@import './btn';

.Panel {
  --n-height: 27px;

  width: 290px;
  background-color: #18181c;
}
</style>
