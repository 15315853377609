<script setup>
import { NTable } from 'naive-ui'
import { computed } from 'vue'
const props = defineProps({
  themeOverrides: Object,
})
const tablethemeOverridesComputed = computed(() => {
  const thColor = '#443822'
  const tdColor = '#15130f'
  // const padding = 0
  return {
    // borderColor: '#DCAC81',
    thColor: thColor,
    tdColorStriped: '#1F1C18',
    tdColor,
    tdTextColor: '#A39883',
    // tdPaddingSmall: padding,
    ...props.themeOverrides,
  }
})
</script>
<template>
  <NTable striped size="small" :theme-overrides="tablethemeOverridesComputed">
    <slot></slot>
  </NTable>
</template>
