<script setup>
import { ref, watch } from 'vue'
import TickSvg from 'src/asset/tick.svg'
const props = defineProps({
  checked: Boolean,
  color: {
    type: String,
    default: '#A0A3A5',
  },
})
const emit = defineEmits(['update:checked'])
const checkedRef = ref(props.checked)
function handleChecked(e) {
  e.stopPropagation()
  const value = !checkedRef.value
  setChecked(value)
  emit('update:checked', value)
}
function setChecked(value) {
  checkedRef.value = value
}
watch(props, p => {
  setChecked(p.checked)
})
</script>

<template>
  <div
    class="mouse_pointer flex-center h-17px w-17px border border-solid"
    :style="{ borderColor: color }"
    @click="handleChecked"
  >
    <TickSvg v-show="checkedRef" class="w-13px" alt="" :color="color" />
  </div>
</template>
