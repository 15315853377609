export function getPasswordModel() {
  return {
    password: '',
  }
}

export function getPasswordRules(passwordMinLength = 6) {
  return {
    password: [
      {
        required: true,
        message: 'Please enter a password.',
        trigger: ['input', 'blur'],
      },
      {
        min: passwordMinLength,
        message: `Password must be at least ${passwordMinLength} characters long.`,
        trigger: ['input', 'blur'],
      },
    ],
  }
}
