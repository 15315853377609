import * as THREE from 'three'

export class BigScreen {
  scene = null
  camera = null
  renderer = null
  /** 创建场景 */
  initScene(bg) {
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(bg)
    this.scene.fog = new THREE.Fog(bg, 60, 100) // 雾化效果
  }

  /** 创建相机 */
  initCamera() {
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1,
    )
    return this
  }

  /** 创建渲染器 */
  initRenderer(canvas) {
    this.renderer = new THREE.WebGLRenderer({
      canvas,
      antialias: true, // 抗齿距
    })
    this.renderer.shadowMap.enabled = true // 投射阴影
    this.renderer.setPixelRatio(window.devicePixelRatio)
    return this
  }

  adapt() {
    const width = window.innerWidth
    const height = window.innerHeight
    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(width, height)
    return this
  }

  unload() {
    this.scene.remove(...this.scene.children)
    this.renderer?.dispose?.()
    return this
  }
}
