<script setup>
import openSea from 'src/asset/socializeLinks/openSea.webp'
import element from 'src/asset/socializeLinks/element.webp'
import blur from 'src/asset/socializeLinks/blur.webp'
import okx from 'src/asset/socializeLinks/okx.webp'
import discord from 'src/asset/socializeLinks/discord.webp'
import twitter from 'src/asset/socializeLinks/twitter.webp'
import xterio from 'src/asset/socializeLinks/xterio.webp'
import epicStore from 'src/asset/socializeLinks/epicStore.webp'
import {
  DISCORD_PATH,
  TWITTER_PATH,
  XTERIO_PATH,
  EPIC_STORE_PATH,
  OPEN_SEA_PATH,
  OKX_PATH,
  BLUR_PATH,
  ELEMENT_PATH,
} from 'src/constant'
const list = [
  {
    img: openSea,
    link: OPEN_SEA_PATH,
  },
  {
    img: element,
    link: ELEMENT_PATH,
  },
  {
    img: blur,
    link: BLUR_PATH,
  },
  {
    img: okx,
    link: OKX_PATH,
  },
  {
    img: discord,
    link: DISCORD_PATH,
  },
  {
    img: twitter,
    link: TWITTER_PATH,
  },
  {
    img: xterio,
    link: XTERIO_PATH,
  },
  {
    img: epicStore,
    link: EPIC_STORE_PATH,
  },
]
</script>

<template>
  <ul>
    <template v-for="item in list" :key="item.name">
      <li>
        <a
          class="flex-center bg-#141516 md:hover:bg-#757a7e block md:h-[38px] h-51px w-243px md:rounded rounded-2 md:w-180px"
          :href="item.link"
          target="_blank"
        >
          <img :src="item.img" alt="" class="md:h-23px h-28px" />
        </a>
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">
ul {
  li {
    &:not(:last-child) {
      @apply mb-1.5;
    }
  }
}
</style>
