import { requestBase } from 'src/helpers/request'
import product1Webp from 'src/asset/immutableIncome/product1.webp'
import product2Webp from 'src/asset/immutableIncome/product2.webp'
/**
 * NFT市场展示魂卡属性
 * @param {*} param0 {nfttype, tokenid}
 * @returns
 */
export function fetchCardMetadataDetail({ nfttype = 'soulcore', tokenId }) {
  return fetch(
    `${import.meta.env.VITE_METADATA_PATH}/index.php/${nfttype}/${tokenId}`,
  ).then(response => {
    if (!response.ok) {
      throw new Error('Network response was not ok ' + response.statusText)
    }
    return response.json()
  })
}
/**
 * 生成魂卡
 * @param {*} params {walletaddr,tokenId}
 * @returns
 */
export function fetchCreateSoulCard(params) {
  return requestBase({
    params: {
      ...params,
      code: 62,
    },
  })
}

function packageData(data) {
  return {
    result: {
      chain: {
        id: 'eip155:13473',
        name: 'imtbl-zkevm-testnet',
      },
      contract_address: '0x8a90cab2b38dba80c64b7734e58ee1db38b8992e',
      created_at: '2022-08-16T17:43:26.991388Z',
      updated_at: '2022-08-16T17:43:26.991388Z',
      description: '2022-08-16T17:43:26.991388Z',
      external_url: 'https://some-url',
      animation_url: 'https://some-url',
      youtube_url: 'https://some-url',
      ...data,
    },
  }
}
const arr = [
  {
    image: product1Webp,
    attributes: [
      {
        trait_type: 'Body',
        value: 'Humen',
      },
      {
        trait_type: 'Clothing',
        value: 'Black Dress',
      },
      {
        trait_type: 'Mouth',
        value: 'Calm',
      },
      {
        trait_type: 'Eye',
        value: 'Closed',
      },
      {
        trait_type: 'Hair',
        value: 'Bob Pink',
      },
    ],
    name: 'Bogx PFP #1',
    id: 1,
    viewName: 'Bogx PFP',
    Species: 'Mortal',
  },
  {
    image: product2Webp,
    attributes: [
      {
        trait_type: 'Body',
        value: 'Humen',
      },
      {
        trait_type: 'Clothing',
        value: 'Black Dress',
      },
      {
        trait_type: 'Mouth',
        value: 'Yawning',
      },
      {
        trait_type: 'Eye',
        value: 'Decadent',
      },
      {
        trait_type: 'Hair',
        value: 'Goat',
      },
      {
        trait_type: 'Hairgear',
        value: 'Golden Headband',
      },
    ],
    name: 'Bogx PFP #2',
    id: 2,
    viewName: 'Bogx PFP',
    Species: 'Mortal',
  },
  {
    image:
      'https://voidlabs.bladeofgod.net/webres/pfp/img/frost_giant_female/100.webp',
    attributes: [
      {
        trait_type: 'Body',
        value: 'Giant',
      },
      {
        trait_type: 'Clothing',
        value: 'Pearl Dress',
      },
      {
        trait_type: 'Ear',
        value: 'Butterfly',
      },
      {
        trait_type: 'Mouth',
        value: 'Ridicule',
      },
      {
        trait_type: 'Eye',
        value: 'Glance',
      },
      {
        trait_type: 'Hair',
        value: 'Long Wavy Cyan',
      },
      {
        trait_type: 'Hand',
        value: 'Snake',
      },
    ],
    name: 'Bogx PFP #3',
    id: 3,
    viewName: 'Bogx PFP',
    Species: 'Frost Giant',
  },
]

const dataArr = arr.map(packageData)
const baseUrl = import.meta.env.VITE_PRIMARY_SALE_BACKEND_URL

export async function getProducts() {
  const products = await (
    await fetch(`${baseUrl}/products`, {
      method: 'GET',
    })
  ).json()
  const productArr = [
    {
      name: '6 Soul Jade',
    },
    {
      name: '30 Soul Jade',
    },
  ]

  const metadataCalls = products.productData.map(product => {
    return Promise.resolve(dataArr[Number(product.metadata_id) - 1])
  })

  const metadataResults = await Promise.all(metadataCalls)

  // add metadata to product
  const productsWithMetadata = products.productData.map((product, index) => {
    const metadata = metadataResults.find(
      metadata => String(metadata.result.id) === product.metadata_id,
    )
    return {
      ...product,
      metadata: metadata?.result,
      qty: 1,
      name: productArr[index].name,
    }
  })
  return productsWithMetadata
}

/**
 * game mint
 * @param {*} params
 * @returns
 */
export function fetchGameMint(params) {
  return requestBase({
    params: {
      ...params,
      code: 101,
    },
  })
}

/**
 * 轮询订单接口状态
 * @param {*} params
 * @returns
 */
export function fetchOrderStatus(params) {
  return requestBase({
    params: {
      ...params,
      code: 102,
    },
  })
}
