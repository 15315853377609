import {
  cthulhuSwitchThemeOverridesc,
  useUpdateCssVar,
} from 'src/pages/PFP/useThemeOverrides'
import { computed } from 'vue'

export function useCthulhuMythos() {
  const { valueRef: buttonColorRef } = useUpdateCssVar('--pfp-bg')

  const switchThemeOverridescComputed = computed(() => {
    return {
      ...cthulhuSwitchThemeOverridesc,
      buttonColor: buttonColorRef.value,
    }
  })
  return {
    switchThemeOverridescComputed,
  }
}
