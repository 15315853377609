<script setup>
import { onMounted, onUnmounted, ref, computed } from 'vue'
import { gsap } from 'gsap'
import { addressEllipsisRef, hasConnectWalletRef } from 'src/sdk/blockchain'
import { hasPhone } from 'src/stores/media'
// import { lineAngleW, lineBottomConnectW } from 'src/pages/home/constant'
// import { storeToRefs } from 'pinia'
// import { useUserInfoStore } from 'src/stores/user'
import { useWeb3Modal } from '@web3modal/wagmi/vue'
import walletWebp from 'src/asset/wallet.webp'

// const { userInfo } = storeToRefs(useUserInfoStore())

const { open } = useWeb3Modal()

function triggerConnect() {
  open()
}

const walletAddress = computed(() => {
  // if (userInfo.value?.wallet) return middleEllipsis(userInfo.value?.wallet)
  if (hasConnectWalletRef.value) return addressEllipsisRef.value
  return 'CONNECT'
})

const hornDomRef = ref(null)

// const domAttributeReactive = reactive({
//   opacityLine: 1,
//   lineBottomRect: 0,
//   lineAngleRect: lineAngleW,
// })

let hoverIng = false
let hornXTl, hornOpacityTL
// let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to(hornDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to(hornDomRef.value, {
    ...options,
    x: '-20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  // bottomLineTl = gsap.to(domAttributeReactive, {
  //   ...options,
  //   lineBottomRect: lineBottomConnectW,
  //   duration: 0.4,
  // })
  // obliqueLineTl = gsap.to(domAttributeReactive, {
  //   ...options,
  //   lineAngleRect: 0,
  //   duration: 0.1,
  //   onComplete() {
  //     bottomLineTl.play()
  //   },
  // })

  // opacityTl = gsap.to(domAttributeReactive, {
  //   opacityLine: 0,
  //   duration: 0.3,
  //   onComplete() {
  //     bottomLineTl.progress(0).pause()
  //     obliqueLineTl.progress(0).pause()
  //     opacityTl.progress(0).pause()
  //   },
  // })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  // obliqueLineTl.play()
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  // opacityTl.play()
}

onMounted(() => {
  !hasPhone && initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  // bottomLineTl?.kill()
  // obliqueLineTl?.kill()
  // opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer"
    @mouseenter="!hasPhone && handleMouseenter()"
    @mouseleave="!hasPhone && handleMouseleave()"
  >
    <div @click="triggerConnect" class="relative z-2 md:pr-9 md:pt-3">
      <img
        ref="hornDomRef"
        src="/src/asset/top_left_jiao.webp"
        class="relative w-[8px] rotate-y-180 opacity-100 -ml-0.5 md:w-[10px] md:opacity-0"
        alt=""
      />
      <span class="fz-3 ml-0.25 mr-1 md:ml-1 md:mr-0.5">
        {{ walletAddress }}</span
      >
      <img :src="walletWebp" class="w-24px" alt="" />
    </div>
    <!-- <div
      class="line z-2 w-[192px] translate-x-[-10%]"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineBottomRect}px, auto, auto)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineBottomConnectW}px`,
      }"
    ></div>
    <div
      class="line oblique"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineAngleRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div> -->
  </div>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1;
}

.oblique {
  bottom: 22%;
  left: -14%;
  transform: rotate(-45deg);
}

.hidden-box {
  bottom: -6%;
  left: -15%;
}
</style>
