<script setup>
import { useRouter, useRoute } from 'vue-router'
import { getHasRouterActivation } from 'src/helpers/utils'
import {
  MY_COLLECTION_ALL_NAME,
  IMMUTABLE_COLLECTION_ASSETS_NAME,
  PFP_ETHEREAL_NAME,
  IMMUTABLE_COLLECTION_NAME,
  MY_COLLECTION_NAME,
  NFT_CARDS,
  GAME_BANEFITS_NAME,
} from 'src/constant'
const tabs = [
  {
    label: 'Meta Collections',
    indexName: MY_COLLECTION_NAME,
    key: MY_COLLECTION_ALL_NAME,
  },
  {
    label: 'In-Game Collections',
    indexName: IMMUTABLE_COLLECTION_NAME,
    key: IMMUTABLE_COLLECTION_ASSETS_NAME,
  },
  {
    label: 'Game Banefits',
    indexName: GAME_BANEFITS_NAME,
    key: NFT_CARDS,
    class: 'gold',
  },
]
if (import.meta.env.VITE_PFP_SHOW) {
  tabs.unshift({
    label: 'Ethereal',
    indexName: PFP_ETHEREAL_NAME,
    key: PFP_ETHEREAL_NAME,
  })
}
const router = useRouter()
const route = useRoute()
function handleChange(name) {
  if (route.name !== name) {
    router.push({ name })
  }
}
</script>
<template>
  <div class="flex gray-color">
    <template v-for="tab in tabs" :key="tab.key">
      <div
        @click="handleChange(tab.key)"
        class="flex-center md:w-240px 2xl:w-280px w-50% h-28.5px md:h-45px mouse_pointer border md:border-t-0 border-solid"
        :class="{
          select: getHasRouterActivation(route.matched, tab.indexName),
          gold_box: tab.class === 'gold',
          gray_box: !tab.class || tab.class === 'gray',
        }"
      >
        {{ tab.label }}
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.gray_box {
  @apply hover:text-white border-#2E3235;

  &.select {
    @apply border-#869CAF text-white;

    box-shadow: inset 0 -20px 20px -23px var(--gray-color);
  }
}

.gold_box {
  @apply hover:text-#EED095 border-#35322E text-#70695A;

  &.select {
    @apply border-#C29B6D text-#EED095;

    box-shadow: inset 0 -20px 20px -23px #eed095;
  }
}
</style>
