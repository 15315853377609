export class PContainer {
  promiseObject = {
    p: null,
    resolve: null,
    reject: null,
  }

  start(cd) {
    if (!this.promiseObject.p) {
      cd()
      this.promiseObject.p = new Promise((resolve, reject) => {
        this.promiseObject.resolve = resolve
        this.promiseObject.reject = reject
      }).finally(() => {
        this.reset()
      })
    }
    return this.promiseObject.p
  }

  reset() {
    this.promiseObject.resolve = null
    this.promiseObject.reject = null
    this.promiseObject.p = null
  }

  success(data) {
    this.promiseObject.resolve?.(data)
    return this
  }

  fail(error) {
    this.promiseObject.reject?.(error)
    return this
  }
}
