<template>
  <ul class="text_box">
    <li>
      <span>1.&nbsp;</span>
      <div>
        <span>Each </span>
        <span class="text-#BD84F8">NFT</span>
        <span> in a wallet has staking points. </span>
        <span> Transactions reset an </span>
        <span class="text-#BD84F8">NFT's</span>
        <span> points but do not affect others. </span>
      </div>
    </li>
    <li>
      <span>2.&nbsp;</span>
      <div>
        <span class="text-#BD84F8">Staking points</span>
        <span> increase by specific rules. </span>
        <span class="text-#BD84F8">Genesis NFTs</span>
        <span> have an initial </span>
        <span class="text-#BD84F8">growth rate,</span>
        <span> influenced by NFT combinations.</span>
      </div>
    </li>
    <li>
      <span>3.&nbsp;</span>
      <div>
        <span class="text-#BD84F8">Staking points</span>
        <span>
          are settled hourly. After a reset, the new owner starts accumulating
          points from the next hour.
        </span>
      </div>
    </li>
    <li>
      <span>4.&nbsp;</span>
      <div>
        <span class="text-#57C478">Rebinding</span>
        <span> a wallet does not affect an </span>
        <span class="text-#BD84F8">NFT's staking points</span>
        <span>. The </span>
        <span class="text-#BD84F8">NFT</span>
        <span> will appear in the </span>
        <span class="text-#57C478">newly bound wallet's</span>
        <span> repository.</span>
      </div>
    </li>
    <li>
      <span>5.&nbsp;</span>
      <div>
        <span>When the </span>
        <span class="text-#BD84F8">staking system starts,</span>
        <span> players holding NFTs will receive </span>
        <span class="text-#BD84F8">initial staking points</span>
        <span>.</span>
      </div>
    </li>
    <li>
      <span>6.&nbsp;</span>
      <div>
        <span class="text-#BD84F8">Staking points</span>
        <span> can be exchanged for </span>
        <span class="text-#57C478">treasure chests</span>
        <span> with rewards.</span>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="scss">
@import './text';
</style>
