import { ref, watch } from 'vue'
import { useEventListener, useTimeoutFn } from '@vueuse/core'

// import { getHorizontalScreen } from '@BOGX/utils'
import { useScreenSizeChange } from 'src/hooks/useScreenSizeChange'

export function useLandscapeScreen() {
  // const sizeDirtyRef = ref(true)
  // const screenDirtyRef = ref(true)
  // useEventListener(screen.orientation, 'change', () => {
  //   screenDirtyRef.value = true
  // })
  const widthRef = ref(0)
  const heightRef = ref(0)
  const translateXRef = ref(0)
  const topRef = ref(0)
  const leftRef = ref(0)
  const rotateRef = ref(0)
  const { start, stop } = useTimeoutFn(handleOrientation, 300)
  function run() {
    stop()
    start()
  }
  useScreenSizeChange(run)

  // watch(
  //   () => [sizeDirtyRef.value, screenDirtyRef.value],
  //   ([sizeDirty, screenDirty]) => {
  //     if (sizeDirty && screenDirty) {
  //       handleOrientation()
  //       sizeDirtyRef.value = false
  //       screenDirtyRef.value = false
  //     }
  //   },
  //   {
  //     immediate: true,
  //   },
  // )
  // 根据手机旋转的角度来判断
  function handleOrientation() {
    const width = document.documentElement.clientWidth
    const height = document.documentElement.clientHeight
    // if (getHorizontalScreen()) {
    if (width > height) {
      // 旋转到 90 或 -90 度，即竖屏到横屏
      widthRef.value = width
      heightRef.value = height
      translateXRef.value = 0
      rotateRef.value = 0 // 不旋转；
    } else {
      // 旋转到 180 或 0 度，即横屏到竖屏
      widthRef.value = height
      heightRef.value = width
      translateXRef.value = width
      rotateRef.value = 90 // 旋转90度
    }
    console.log('handleOrientation', width, height)
  }

  return {
    start: handleOrientation,
    widthRef,
    heightRef,
    translateXRef,
    topRef,
    leftRef,
    rotateRef,
  }
}
