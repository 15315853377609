<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useBoundingClientRect } from 'src/hooks/useBoundingClientRect'
import common_arrow from 'src/asset/nftSoftStaking/guide/step/2/common_arrow.webp'
import brackets from 'src/asset/nftSoftStaking/guide/step/2/brackets.webp'
import ModelBox from './ModelBox'
import {
  useNFTSoftPledgeDomStore,
  useNFTSoftPledgeStore,
} from 'src/stores/softPledge'
const { guideShow, guideCurrentIndex } = storeToRefs(useNFTSoftPledgeStore())
const { pfpBoxDom, deckDom, leftGapTop } = storeToRefs(
  useNFTSoftPledgeDomStore(),
)

const pfpBoxSizeRef = useBoundingClientRect(pfpBoxDom).dataRef
const deckSizeRef = useBoundingClientRect(deckDom).dataRef
const guide1StyleComputed = computed(() => {
  return {
    top: pfpBoxSizeRef.value.top - leftGapTop.value - 3 + 'px',
    left:
      Math.min(0, (pfpBoxSizeRef.value.right - deckSizeRef.value.left) * 0.75) +
      'px',
  }
})
const bracketsStyleComputed = computed(() => {
  return {
    height: pfpBoxSizeRef.value.height + 'px',
  }
})
</script>
<template>
  <ModelBox v-show="guideShow && guideCurrentIndex === 1" class="p0!">
    <div class="family_blk_bold fz-5 absolute h-full w-full">
      <div
        class="absolute flex items-center w-full"
        :style="guide1StyleComputed"
      >
        <img :src="brackets" alt="" :style="bracketsStyleComputed" />
        <div class="relative ml-1.5 w-full">
          <div class="flex items-center">
            <img :src="common_arrow" alt="" class="w-38px mr-10px" />
            <span class="text-#69F093">ETHEREAL NFT SLOTS</span>
          </div>
          <div class="text-#fff absolute ml-48px">
            Players can freely configure decks <br />based on the bonuses.
          </div>
        </div>
      </div>
    </div>
  </ModelBox>
</template>
