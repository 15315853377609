<script setup>
import { NLayout } from 'naive-ui'
import TimeHeader from 'src/pages/PFP/ethereal/components/TimeHeader.vue'
import List from 'src/pages/PFP/ethereal/components/List.vue'
import Banner from './components/Banner.vue'
import { useEthereal } from './index.js'
import { useScrollTop } from 'src/hooks/useScrollTop'
import Grand from './components/Grand.vue'
import { usePFPMintStore } from 'src/stores/pfp'
import { storeToRefs } from 'pinia'
import { useDetail } from '../index.js'
import Detail from '../components/Detail/index.vue'
import ScrollTop from 'src/components/ScrollTop.vue'
import SuccessEggListModal from 'src/pages/PFP/ethereal/components/SuccessEggListModal.vue'
const { eggEndComputed, serverTimeComputed } = storeToRefs(usePFPMintStore())
const {
  timeHeaderRef,
  knockingEggsShowRef,
  viewListComputed,
  topLevelListComputed,
  showTimeHeaderComputed,
  hasTokenComputed,
  walletAddressComputed,
  wholeActivityStatusComputed,
  updateInfo,
  executeFetchSaveMetadata,
  handleEggEnd,
  eggListComputed,
  isLoading,
  initDataRef,
  loadingEggRunRef,
  successEggListRef,
  successEggListShowRef,
} = useEthereal()
const { detailShowRef, detailRef, handleDetail } = useDetail()
const { isVisible, scrollToTop } = useScrollTop()
</script>

<template>
  <NLayout class="theme-normal item_tracking">
    <div class="bg-#1E1E1E xl:mb-0 -mb-30">
      <Banner
        :hasToken="hasTokenComputed"
        :wallet="walletAddressComputed"
        :status="wholeActivityStatusComputed"
        :isLoading="isLoading"
      ></Banner>
    </div>
    <div class="absolute top-30px right-0">
      <Grand :topLevelList="topLevelListComputed"></Grand>
    </div>
    <div class="px-10% bg-#1F1F21">
      <div class="pt-5 pb-4">
        <Transition name="height">
          <TimeHeader
            v-show="showTimeHeaderComputed"
            @confirm="executeFetchSaveMetadata"
            @end="updateInfo"
            :loading="loadingEggRunRef"
            :endTime="eggEndComputed"
            :currentTime="serverTimeComputed"
            :showBtn="initDataRef"
            ref="timeHeaderRef"
            class="mb-4 overflow-y-hidden"
          ></TimeHeader>
        </Transition>
        <List
          :list="viewListComputed"
          :currentTime="timeHeaderRef?.currentTime"
          :hasBroken="knockingEggsShowRef"
          :hasEgg="!!eggListComputed.length"
          @eggEnd="handleEggEnd"
          @detail="handleDetail"
          class="mb-4"
        ></List>
      </div>
    </div>
    <div
      v-if="isVisible"
      @click="scrollToTop"
      class="mouse_pointer fixed right-30px bottom-30px border border-solid border-#4A4F51 bg-#2E2E2E text-#FFFFFF w-51px h-51px rounded-50%"
    >
      <ScrollTop></ScrollTop>
    </div>
    <SuccessEggListModal
      v-model:show="successEggListShowRef"
      :list="successEggListRef"
      @detail="handleDetail"
    ></SuccessEggListModal>
    <Detail
      :theme="`dialog-${detailRef.color}`"
      :data="detailRef"
      v-model:show="detailShowRef"
    ></Detail>
  </NLayout>
</template>
<style scoped lang="scss">
@import '../theme';

.height-enter-active,
.height-leave-active {
  transition:
    height 0.5s ease,
    opacity 0.5s ease;
}

.height-enter-from,
.height-leave-to {
  height: 0;
  opacity: 0;
}
</style>
