<script setup>
import Modal from 'src/components/Modal'
import { hasPhone } from 'src/stores/media'

const props = defineProps({
  show: Boolean,
  confirmText: {
    default: 'Confirm',
    type: String,
  },
  cancelText: {
    default: 'Cancel',
    type: String,
  },
  width: String,
  confirmClass: {
    default: '',
    type: String,
  },
  loading: Boolean,
})
const emit = defineEmits(['update:show', 'confirm', 'cancel'])
function handleClose() {
  emit('update:show', false)
  emit('cancel')
}
function handleConfirm() {
  if (props.loading) return
  emit('confirm')
}
function handleUpdateShow(show) {
  emit('update:show', show)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleUpdateShow">
    <div
      class="item-bg w-[500px] p-3"
      :style="{ width: !hasPhone ? width : '95vw' }"
    >
      <div class="mb-5 rounded bg-#2A2D31">
        <slot></slot>
      </div>
      <div class="flex-sb">
        <div
          class="item-btn-black h-[37px] w-[153px] md:h-[38px] md:w-[186px]"
          @click="handleClose"
        >
          {{ props.cancelText }}
        </div>
        <div
          class="item-btn-gray h-[37px] w-[153px] md:h-[38px] md:w-[186px]"
          :class="`${confirmClass}${loading ? ' disabled' : ''}`"
          @click="handleConfirm"
        >
          {{ props.confirmText }}
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
@import './user/common';
</style>
