import { requestBase } from 'src/helpers/request/index'

/**
 * 获取角色列表
 * @param {*} params
 * @returns
 */
export function fetchRoleList(params) {
  return requestBase({
    params: {
      ...params,
      code: 47,
    },
  })
}

/**
 * 发账号兑换码内容 到 具体某个角色
 * @param {*} params {receiveItem,UserID}
 * @returns
 */
export function fetchExchangeCodeForAccountToCharacter(params) {
  return requestBase({
    params: {
      ...params,
      code: 48,
    },
  })
}

/**
 * 兑换码 发到账号背包
 * @param {*} params {cdkey}
 * @returns
 */
export function fetchRedeemCodeToAccountInventory(params) {
  return requestBase({
    params: {
      ...params,
      code: 49,
    },
  })
}

/**
 * 获取某个账号下 t-BOGX 产出消耗
 * @param {*} params
 * @returns
 */
export function fetchTBogxConaumeList(params) {
  return requestBase({
    params: {
      ...params,
      code: 60,
    },
  })
}

/**
 * 获取 某个账号下所有角色的已经mint后锁定的闪卡信息
 * @param {*} params {cdkey}
 * @returns
 */
export function fetchLockedFlashcardsForAllRolesInAccount(params) {
  return requestBase({
    params: {
      ...params,
      code: 59,
    },
  })
}

/**
 * 账号存储通用信息接口
 * @param {object} params
 * @param {string} infoKey 要存储的元数据键
 * @param {string} infoValue 要存储的元数据值
 * @param {1|2} ​​infoType 1 :与账号绑定的元数据 | 2:不与账号绑定的元数据
 * @returns
 */
export function fetchSaveMetadata(params) {
  return requestBase({
    params: {
      ...params,
      code: 70,
    },
  })
}

/**
 * 获取元数据信息
 * @param {object} params
 * @param {string} infoKey 要存储的元数据键
 * @param {1|2} ​​infoType 1 :与账号绑定的元数据 | 2:不与账号绑定的元数据
 * @returns
 */
export function fetchMetadata(params) {
  return requestBase({
    params: {
      ...params,
      code: 71,
    },
  })
}

/**
 * 获取账号下的nft使用状态相关数据列表：
 * @param {object} params
 * @param {string} wallet 玩家钱包
 * @param {number} token_id
 * @param {string} nft_type "获取的nft类型",//nft pfp 不传时默认全部返回 ，此为预留字段，根据后面内容调整
 * @returns {
        "nft": {
            "bind_list": {
                "nft的token_id": "角色id"//已绑定的nft列表 key=>value key为nft的tokenId value为角色id
            },
            "unbind": []//未绑定的nft列表
        },
        "pfp":{
           "bind_list":{
               "pfp的token_id":"角色id"//已绑定的pfp列表 key=>value key为nft的tokenId value为角色id
           },
           "unbind":[]//未绑定的pfp列表
        }
    }
 */
export function fetchNFTCardList(params) {
  return requestBase({
    params: {
      ...params,
      code: 84,
    },
  })
}

/**
 * nft绑定到角色
 * @param {object} params
 * @param {string} wallet 玩家钱包
 * @param {number} token_id
 * @param {number} char_id 绑定的角色
 * @param {string} nft_type "获取的nft类型",//nft pfp 不传时默认全部返回 ，此为预留字段，根据后面内容调整
 */
export function fetchNFTCardBind(params) {
  return requestBase({
    params: {
      ...params,
      code: 85,
    },
  })
}

/**
 * nft绑定到角色
 * @param {object} params
 * @param {string} wallet 玩家钱包
 * @param {number} token_id
 * @param {number} char_id 绑定的角色
 * @param {string} nft_type "获取的nft类型",//nft pfp 不传时默认全部返回 ，此为预留字段，根据后面内容调整
 */
export function fetchNFTCardUnbind(params) {
  return requestBase({
    params: {
      ...params,
      code: 86,
    },
  })
}

/**
 * 获取盲盒信息
 * @returns
 */
export function fetchSoulCardMintInfo(params) {
  return requestBase({
    params: {
      ...params,
      code: 87,
    },
  })
}

/**
 * bogx魂核状态,各种订单状态
 */
export const BOGXSoulCoreState = {
  MAX_MINT_BLOCK_TIME: 15 * 60, //5分钟

  MINT_BLOCK: -1, //MINT 链上阻塞，15分钟后无法上链则视为失败
  GAME_DRAW: 0, //游戏内刚产出
  MINTING: 1, //正在mint
  MINT_SUCCESS: 2, //mint完成，链上已确认 已回调,可解锁
  MINT_FAIL: 3, //mint失败重试中
  MINT_FAIL_NO_TRY: 4, //mint失败不再重试
  MINT_LOCKED: 5, //已经锁定

  UNLOCKING: 10, //正在解锁中
  UNLOCKED: 11, //已经解锁
  LOCKIND: 15, //正在锁定中
  STATE_NFT_MARKET_FREE: 20, //nft 市场创建，游离态
}

/**
 * 闪卡解锁Immutable
 * @param {*} params {itemToken: number}
 * @returns {
 *  newState: BOGXSoulCoreState[key]
 * }
 */
export function fetchUnlockImmutableSoulCard(params) {
  return requestBase({
    params: {
      ...params,
      code: 88,
    },
  })
}

/**
 * 锁定Immutable到角色
 * @param {*} params {itemToken,UserID}
 * @returns
 */
export function fetchLockImmutableSoulCard(params) {
  return requestBase({
    params: {
      ...params,
      code: 90,
    },
  })
}

/**
 * 下发铸币页面详情
 * @param {object} params
 * @param {number} char_id 角色id
 * @returns
 */
export function fetchTokenInfo(params) {
  return requestBase({
    params: {
      ...params,
      code: 101,
    },
  })
}

/**
 * 创建BoGxTokenWeb订单
 * @param {object} params
 * @param {number} char_id 角色id
 * @param {number} serverId 区服id
 * @param {number} count 转换数量
 * @returns
 */
export function fetchTokenOrderDraw(params) {
  return requestBase({
    params: {
      ...params,
      code: 102,
    },
  })
}

/**
 * 请求BoGx订单状态
 * @param {object} params
 * @param {number} char_id 角色id
 * @param {number} order 订单号
 * @returns
 */
export function fetchTokenOrderInfo(params) {
  return requestBase({
    params: {
      ...params,
      code: 103,
    },
  })
}

/**
 * 发放nft时装道具
 * @param {object} params
 * @param {number} char_id 角色id
 * @param {number} level nft等级
 * @returns
 */
export function fetchNFTPropsSend(params) {
  return requestBase({
    params: {
      ...params,
      code: 104,
    },
  })
}
