<script setup>
import CloseSvg from 'src/asset/close.svg'
const emit = defineEmits(['close'])
const props = defineProps({
  list: {
    type: Array,
    default() {
      /**
       * {
       *  type: 'type' | 'input'
       *  id: 1,
       *  name: 'asda asdasd'
       * },
       */
      return []
    },
  },
})
function handleClose(item) {
  emit('close', item)
}
</script>
<template>
  <div class="overflow-x-auto">
    <div class="flex items-center content h-33px">
      <span class="text-[--pfp-color7]">FILTERS</span>
      <span
        class="px-2 py-0.25 border border-solid border-[--pfp-border5] bg-[--pfp-bg7] rounded-0.5 text-[--pfp-color7]"
        >{{ list.length }}</span
      >
      <template v-for="item in list" :key="item.id">
        <div
          class="bg-#040505 border border-[--pfp-border6] border-dashed pl-6.5 py-1.25 rounded-1.25 flex-shrink-0"
        >
          <span class="text-[--pfp-color7]">{{ item.name }}</span>
          <CloseSvg
            class="mx-3.5 mouse_pointer icon"
            @click="handleClose(item)"
          ></CloseSvg>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.content {
  & > * {
    @apply mr-2.5;
  }

  .icon {
    :deep(path) {
      fill: var(--pfp-color7);
    }
  }
}
</style>
