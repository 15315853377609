<script setup>
import { NForm, NFormItem } from 'naive-ui'
import { ref } from 'vue'
import { getChangePasswordModel, getChangePasswordRules } from 'src/model/user'
import { useChangePasswordStore } from 'src/stores/user'
import PasswordInput from '../PasswordInput'
import EmailCodeInput from './EmailCodeInput'

const props = defineProps(['disabled'])
defineExpose({
  handleValidate,
})
const modelData = getChangePasswordModel()
const { detection } = useChangePasswordStore()
const formRef = ref(null)
const hrefData = detection()
const modelRef = ref(hrefData || modelData)

function handleValidate(e) {
  e.preventDefault()
  const data = { ...modelRef.value }
  delete data.confirmPassword
  return formRef.value?.validate().then(() => data)
}
</script>

<template>
  <NForm
    ref="formRef"
    :model="modelRef"
    :rules="getChangePasswordRules(modelRef)"
    :disabled="props.disabled"
    :show-label="false"
  >
    <EmailCodeInput
      v-model:email="modelRef.mail"
      v-model:code="modelRef.verifyCode"
      countdown-key="ChangePassword"
      :code-type="5"
    ></EmailCodeInput>
    <NFormItem path="password">
      <PasswordInput v-model:value="modelRef.password"></PasswordInput>
    </NFormItem>
    <NFormItem path="confirmPassword">
      <PasswordInput
        v-model:value="modelRef.confirmPassword"
        placeholder="Confirm Password"
      ></PasswordInput>
    </NFormItem>
  </NForm>
</template>
