import {
  getWallet,
  getCurrentProvider,
  defaultWallet,
  setCurrentWallet,
} from '@abmao/wallet-store'
import { createWalletClient, custom } from 'viem'

const okxName = 'OKX Wallet'
const immutable = 'Immutable Passport'
export function getOKX() {
  return getWallet(okxName)
}
export function getImmutable() {
  return getWallet(immutable)
}
export function getOKXProvider() {
  return getWallet(okxName)?.provider
}

export function getCurrentTransport() {
  return custom(getCurrentProvider())
}
export function getWalletClient({ chainId, accountAddress }) {
  const walletClient = createWalletClient({
    chain: typeof chainId === 'number' ? { id: chainId } : chainId,
    transport: getCurrentTransport(),
    account: accountAddress,
  })
  return walletClient
}
export async function loginWallet(wallet) {
  let _wallet = wallet ? wallet : defaultWallet()
  const accounts = await _wallet.provider.request({
    method: 'eth_requestAccounts',
  })
  setCurrentWallet(_wallet)
  return accounts
}

// 验证签名
export async function verifySignature({ chainId, accountAddress, message }) {
  const signature = await getWalletClient({
    chainId,
    accountAddress,
  }).signMessage({
    message,
  })
  return signature
}
