<script setup>
import { ref } from 'vue'
import { NForm } from 'naive-ui'
import { getEmailLoginModel, getEmailLoginRules } from 'src/model/user'
import { fetchEmailLogin } from 'src/api'
import EmailCodeInput from './EmailCodeInput'

const props = defineProps(['disabled'])
defineExpose({
  handleValidate,
  handleSubmit,
})
const formRef = ref(null)

const modelData = getEmailLoginModel()
const modelRef = ref(modelData)
function handleValidate(e) {
  e.preventDefault()
  return formRef.value?.validate()
}
function handleSubmit() {
  return fetchEmailLogin(modelRef.value)
}
</script>

<template>
  <NForm
    ref="formRef"
    :model="modelRef"
    :rules="getEmailLoginRules()"
    :disabled="props.disabled"
    :show-label="false"
  >
    <EmailCodeInput
      v-model:email="modelRef.mail"
      v-model:code="modelRef.verifyCode"
      countdown-key="EmailLogin"
      :code-type="2"
    ></EmailCodeInput>
  </NForm>
</template>
