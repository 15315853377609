import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import * as THREE from 'three'
import { EventEmitter } from 'eventemitter3'
import { hasPhone } from 'src/stores/media'
import { AnimationGroup } from '../animation'

/** 创建网格模型对象 */
export class Character extends EventEmitter {
  loadingManager = null
  loader = null
  mixer = null
  model = null
  animationGroup = null
  clock = new THREE.Clock()
  init() {
    return new Promise((resolve, reject) => {
      this.loadingManager = new THREE.LoadingManager()
      this.loadingManager.onError = error => {
        reject(error)
      }
      const dracoLoader = new DRACOLoader()
      dracoLoader.setDecoderPath(`${import.meta.env.VITE_FILE_PATH}/draco/`)
      // dracoLoader.setDecoderConfig({ type: 'wasm' }) // 显式设置使用 WASM 解码器
      dracoLoader.preload()
      this.loader = new GLTFLoader(this.loadingManager)
      this.loader.setDRACOLoader(dracoLoader)
      this.loader.load(
        `${import.meta.env.VITE_FILE_PATH}/${
          hasPhone ? 'min_' : ''
        }character_draco.glb`,
        gltf => {
          this.model = gltf.scene
          this.model.traverse(o => {
            if (o.isMesh) {
              // 启用投射和接收阴影的能力
              o.castShadow = true
              o.receiveShadow = true
              // o.material = material
            }
          })

          // 创建模型动作混合器
          this.mixer = new THREE.AnimationMixer(this.model)

          // 拿到模型中自带的动画数据
          const clips = gltf.animations.slice(0, -1)

          // 遍历动画列表并生成操作对象存储起来
          const possibleAnims = clips.map(val => {
            let clip = THREE.AnimationClip.findByName(clips, val.name)

            clip = this.mixer.clipAction(clip)
            // clip.setLoop(THREE.LoopOnce)
            return clip
          })
          /**
           * 初始为走路
           * 按键以后甩剑并进入站立idle
           * 再按键通过walk_read回到walk(走路)的状态
           * walk  -  walk_swor  -  idle  -  walk_read  - walk
           */
          // 按键以后甩剑并进入站立idle
          const possibleAnimsIdle = [possibleAnims[3], possibleAnims[0]]
          // 再按键通过walk_read回到walk(走路)的状态
          const possibleAnimsWalk = [possibleAnims[2], possibleAnims[1]]
          const currentAnim = possibleAnimsWalk[1]
          this.animationGroup = new AnimationGroup(
            this.mixer,
            [possibleAnimsWalk, possibleAnimsIdle],
            currentAnim,
          )
          resolve()
        },
        ({ loaded, total }) => {
          this.emit('progress', loaded / total)
        },
      )
    })
  }

  update() {
    if (this.mixer) {
      // 更新动画 根据clock确保不会因为帧率导致动画变快或变慢
      this.mixer.update(this.clock.getDelta())
    }
  }

  unload() {
    this.model?.traverse(obj => {
      if (obj.material) {
        obj.material.dispose()
        if (obj.material.map) {
          obj.material.map.dispose()
        }
        if (obj.material.lightMap) {
          obj.material.lightMap.dispose()
        }
        if (obj.material.aoMap) {
          obj.material.aoMap.dispose()
        }
        if (obj.material.emissiveMap) {
          obj.material.emissiveMap.dispose()
        }
        if (obj.material.bumpMap) {
          obj.material.bumpMap.dispose()
        }
        if (obj.material.normalMap) {
          obj.material.normalMap.dispose()
        }
        if (obj.material.displacementMap) {
          obj.material.displacementMap.dispose()
        }
        if (obj.material.roughnessMap) {
          obj.material.roughnessMap.dispose()
        }
        if (obj.material.metalnessMap) {
          obj.material.metalnessMap.dispose()
        }
        if (obj.material.alphaMap) {
          obj.material.alphaMap.dispose()
        }
      }
      if (obj.geometry) {
        obj.geometry.dispose()
      }
    })
    this.model = null
    this.loadingManager = null
    this.loader = null
    this.animationGroup?.unload()
    return this
  }
}
