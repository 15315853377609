import { useThemeStore } from 'src/stores/theme'
import { storeToRefs } from 'pinia'
import { computed, onMounted, ref, watch } from 'vue'

const border = '1px dashed'
export function useThemeOverrides() {
  const { valueRef: textColorRef } = useUpdateCssVar('--pfp-color')
  const { valueRef: placeholderColorRef } = useUpdateCssVar('--pfp-color6')
  const { valueRef: colorRef } = useUpdateCssVar('--pfp-bg6')
  const { valueRef: borderColorRef } = useUpdateCssVar('--pfp-border3')
  const { valueRef: playBorderRef } = useUpdateCssVar('--pfp-border4')
  const inputThemeOverridescComputed = computed(() => {
    return {
      textColor: textColorRef.value,
      placeholderColor: placeholderColorRef.value,
      color: colorRef.value,
      border: `${border} ${borderColorRef.value}`,
      borderActive: `${border} ${playBorderRef.value}`,
      borderFocus: `${border} ${playBorderRef.value}`,
      borderHover: `${border} ${playBorderRef.value}`,
      heightMedium: '32px',
    }
  })
  return {
    inputThemeOverridescComputed,
  }
}
const switchbuttonSize = '23px'
const railColor = '#05180DFF'
export const cthulhuSwitchThemeOverridesc = {
  buttonHeightSmall: switchbuttonSize,
  buttonWidthSmall: switchbuttonSize,
  buttonWidthPressedSmall: switchbuttonSize,
  railHeightSmall: '16px',
  railWidthSmall: '43px',
  railColor: railColor,
  railColorActive: railColor,
  // boxShadowFocus: '0 0 8px 0 rgba(158, 79, 232, 0.3)',
}

export function useUpdateCssVar(cssVar) {
  const themeStore = useThemeStore()
  const { getPFPCssVar } = themeStore
  const { currentTheme } = storeToRefs(themeStore)
  const valueRef = ref()
  function updateCssVar() {
    valueRef.value = getPFPCssVar(cssVar)
  }
  onMounted(() => {
    updateCssVar()
    watch(currentTheme, () => {
      updateCssVar()
    })
  })
  return {
    valueRef,
  }
}
