<script setup>
import Checkbox from 'src/components/Checkbox'

const emit = defineEmits(['update:checked'])
function handleChecked(value) {
  emit('update:checked', value)
}
</script>

<template>
  <div class="flex items-center">
    <Checkbox class="mr-2 md:mr-3" @update:checked="handleChecked"></Checkbox>
    <div class="fz-2 h-full flex items-center">
      I agree to the terms of the
      <a
        href="https://bogx.orisols.com/privacy-VoidLabs.html"
        target="_blank"
        class="orange-color mouse_pointer mx-0.5"
        >Terms of Use</a
      >
      and the
      <a
        href="https://bogx.orisols.com/privacy-VoidLabs.html"
        target="_blank"
        class="orange-color mouse_pointer mx-0.5"
        >Privacy Policy</a
      >
    </div>
  </div>
</template>
