<script setup>
import { NLayout } from 'naive-ui'
import { useMint } from '.'
import Quantity from './components/Quantity.vue'
import Progress from './components/Progress.vue'
import Collection from './components/Collection'
import Mint from './components/Mint/index.vue'
import Stage from './components/stage/Stage'
import StatusTitle from './components/StatusTitle'
import Stage1 from './components/Stage1'
import Stage1End from './components/Stage1End'
import Market from './components/Market'
import FrostedGlassPanel from './components/FrostedGlassPanel.vue'
import { usePFPMintStore } from 'src/stores/pfp'
import More from './components/More.vue'
import Wallet from './components/Wallet.vue'
import MintSuccessModal from './components/MintSuccessModal.vue'
import MintFailedModal from './components/MintFailedModal.vue'
import CheckoutModal from './components/CheckoutModal.vue'
import Video from 'src/components/Video'
import { storeToRefs } from 'pinia'
import bottomBg from 'src/asset/PFP/mint/bottom_bg.webp'
import { fetchUserInfoP } from 'src/helpers/user.js'
import { useLoginStore } from 'src/stores/user'
const {
  quantityRef,
  successShowRef,
  errorShowRef,
  mintListRef,
  quantityMaxComputed,
  widgetTypeRef,
  orderDataRef,
  mintRef,
  checkoutModalRef,
  handleMintSuccess,
  handleMintError,
  handleMint,
  getWalletAddress,
} = useMint()
const { open: login } = useLoginStore()
fetchUserInfoP.run().catch(() => login())
const { totalMintCountComputed, supplyComputed, phaseComputed } =
  storeToRefs(usePFPMintStore())
const mintVideo = import.meta.env.VITE_FILE_PATH + '/Mint-SoulcardsBG.mp4'
</script>

<template>
  <NLayout class="wrap item_tracking fz-2">
    <img
      class="absolute left-27.5px top-14px w-185px z-1"
      src="/src/asset/x_logo.webp"
    />
    <Video
      class="fixed left-0 top-0 w-full h-full"
      :src="mintVideo"
      :controls="false"
      autoplay
      loop
      muted
    ></Video>
    <Wallet></Wallet>
    <div class="absolute left-0 top-89px md:scale-80 2xl:scale-100 origin-tl">
      <More :supply="supplyComputed"></More>
    </div>
    <!-- 右侧面板 -->
    <FrostedGlassPanel class="right-0 2xl:top-50px md:top-40px">
      <!-- <StatusTitle class="right_box"></StatusTitle> -->
      <Stage1 v-if="phaseComputed === 0" class="right_box"></Stage1>
      <Stage1End v-else class="right_box"></Stage1End>
      <Stage
        class="right_box z-2"
        :phase="1"
        :lineTargeDom="mintRef?.btnDom"
      ></Stage>
      <Stage :phase="2" :lineTargeDom="mintRef?.btnDom"></Stage>
      <Market></Market>
    </FrostedGlassPanel>
    <!-- 底部 -->
    <div class="absolute bottom-0 translate-x-[-50%] left-50% w-full">
      <Quantity
        class="absolute z-2 x-center"
        :maxNumber="5"
        v-model:number="quantityRef"
      ></Quantity>
      <div class="relative">
        <img
          :src="bottomBg"
          class="5xl:w-110% 2xl:w-120% md:w-125% absolute md:bottom-[-100%] 3xl:bottom-[-110%] 5xl:bottom-[-140%] translate-x-[-50%] left-50%"
          alt=""
        />
        <div class="relative flex items-end mb-2 justify-center">
          <Collection
            class="w-27% relative 2xl:right-[-25px] md:right-[-40px]"
          ></Collection>
          <Mint
            @success="handleMintSuccess"
            @error="handleMintError"
            @handleMint="handleMint"
            ref="mintRef"
            :quantity="quantityRef"
          ></Mint>
          <Progress
            class="w-27% left-[-25px] relative"
            :value="totalMintCountComputed"
            :total="supplyComputed"
          ></Progress>
        </div>
      </div>
    </div>
    <CheckoutModal
      :widgetType="widgetTypeRef"
      :orderData="orderDataRef"
      v-model:show="checkoutModalRef"
      @getWalletAddress="getWalletAddress"
      @success="handleMintSuccess"
    ></CheckoutModal>
    <MintSuccessModal
      :mintList="mintListRef"
      v-model:show="successShowRef"
    ></MintSuccessModal>
    <MintFailedModal
      :mintList="mintListRef"
      v-model:show="errorShowRef"
    ></MintFailedModal>
  </NLayout>
</template>

<style scoped lang="scss">
.wrap {
  position: relative;
  height: 100vh;

  // background-color: rgb(0 0 0) !important;

  @include minMD {
    @include bgAuto('src/asset/soulCardMint/bg.webp');
  }
}

.video-js .vjs-tech {
  object-fit: cover !important;
}

.right_box {
  @apply 2xl:mb-5 md:mb-3;
}
</style>
