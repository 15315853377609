import { PContainer } from 'src/helpers/pContainer'
import { ref } from 'vue'

export function useShowControl() {
  const show = ref(false)

  const pContainer = new PContainer()

  function open() {
    return pContainer.start(() => {
      show.value = true
    })
  }

  function errorClose() {
    fail(new Error('close'))
    pContainer.reset()
    close()
  }

  function close() {
    show.value = false
  }

  function success(userInfo) {
    pContainer.success(userInfo)
    close()
  }

  function fail(error) {
    pContainer.fail(error)
  }

  return {
    show,
    open,
    success,
    fail,
    errorClose,
    close,
  }
}
