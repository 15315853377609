<script setup>
import { ref } from 'vue'
import Video from 'src/components/Video'
import PFP from './PFP'
const props = defineProps({
  nft: Object,
  pfpList: {
    type: Array,
    default() {
      return []
    },
  },
  select: Boolean,
})
const domRef = ref(null)
defineExpose({
  dom: domRef,
})
</script>
<template>
  <div class="box" ref="domRef">
    <div
      class="relative border border-#5A707F border-solid overflow-hidden"
      v-if="nft"
    >
      <Video
        :width="229"
        :height="229"
        :src="nft.link"
        :controls="false"
        :poster="nft.cover"
        autoplay
        loop
        muted
      ></Video>
      <div
        class="absolute bottom-0 text-center fz-3 w-full bg-#080909 border-0 border-t border-solid border-#5A707F"
      >
        <span class="text-#F2C87F mr-1">{{
          nft.name.replace(/BOGX (Genesis) - (Orisols) #\d+/, '$1 #$2')
        }}</span>
        <span class="text-#fff">#{{ nft.tokenId }}</span>
      </div>
    </div>
    <div
      class="relative flex items-center justify-between flex-grow px-2.5 mt-2px"
    >
      <template v-for="(item, index) in 5" :key="index">
        <PFP :pfp="pfpList[index]" class="z-1"></PFP>
      </template>
      <div
        v-if="pfpList.length"
        class="pfp_line absolute left-10% top-44% w-80% h-1px"
      ></div>
    </div>
    <div
      class="select_box w-full h-full absolute top-0 left-0"
      v-show="select"
    ></div>
  </div>
</template>

<style scoped lang="scss">
.box {
  @apply flex flex-col relative;
  @include bg100('src/asset/nftSoftStaking/deck/data/box.webp');
}

.select_box {
  @include bg100('src/asset/nftSoftStaking/deck/data/select.webp');
}

.pfp_line {
  background: linear-gradient(
    90deg,
    rgb(233 165 165 / 100%) 0%,
    rgb(184 193 192 / 100%) 25%,
    rgb(101 192 224 / 100%) 49.88%,
    rgb(174 162 219 / 100%) 75%,
    rgb(129 193 217 / 100%) 100%
  );
}
</style>
