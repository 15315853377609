<script setup>
import { NLayout } from 'naive-ui'
import { watch } from 'vue'
import { useRoute } from 'vue-router'
import { fetchUserInfoP } from 'src/helpers/user'
import { AppMain, Header } from './myComponents/index'

const route = useRoute()
watch(
  () => route.name,
  () => {
    fetchUserInfoP.run()
  },
  {
    immediate: true,
  },
)
</script>

<template>
  <NLayout>
    <Header></Header>
    <div>
      <AppMain />
    </div>
  </NLayout>
</template>
