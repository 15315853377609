<script setup>
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
import { storeToRefs } from 'pinia'
import ArrowSvg from 'src/asset/arrow.svg'
import Step from './Step'
import { computed } from 'vue'

const stepMax = 3
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { guideCurrentIndex } = storeToRefs(nftSoftPledgeStore)
const hasPrevComputed = computed(() => !!guideCurrentIndex.value)
const hasNextComputed = computed(() => guideCurrentIndex.value < stepMax - 1)
function handlePrev() {
  if (hasPrevComputed.value) {
    --guideCurrentIndex.value
  }
}
function handleNext() {
  if (hasNextComputed.value) {
    ++guideCurrentIndex.value
  }
}
</script>

<template>
  <div class="flex items-center">
    <ArrowSvg
      @click="handlePrev"
      class="arrow_box rotate-z-180"
      :class="{ arrow_active: hasPrevComputed }"
    ></ArrowSvg>
    <div class="flex justify-between items-center px-4">
      <template v-for="(item, index) in stepMax" :key="item">
        <Step :index="index" class="[&:not(:first-child)]:pl-3"></Step>
      </template>
    </div>
    <ArrowSvg
      @click="handleNext"
      class="arrow_box"
      :class="{ arrow_active: hasNextComputed }"
    ></ArrowSvg>
  </div>
</template>
<style scoped lang="scss">
.arrow_box {
  @apply w-32px h-45px text-#454B4F;

  &.arrow_active {
    @extend .mouse_pointer;
    @apply text-white;
  }
}
</style>
