export const AppHttpCode = {
  SUCCESS: 0,
  INVALID_OPERATION: 10, // 未知的操作/未实现的接口

  TOKEN_EXPIRED: 50, // 无效的token，需要重新登陆
  INVALID_PARAM: 51, // 参数错误（缺少参数或者参数值不合法）
  CHARACTER_NOT_EXIST: 52, // 角色不存在

  ACC_NAME_ALREADY_EXITS: 100, // 名字已经存在了
  INVALID_NAME: 101, // 账号昵称包含敏感字或者长度不符合要求
  INVALID_LENGTH: 102, // 长度不符合要求或包含特殊字符
  INVALID_NAME_PWD: 103, // 错误的账号或者密码
  BLOCKED_DEVICE: 104, // 在设备黑名单中

  NOT_VALID_MAIL: 105, // 不是有效的邮箱格式
  MAIL_ALREADY_REGED: 106, // 邮箱已经被注册了
  MAIL_VERIFY_CODE_NOT_VALID: 107, // 邮箱注册验证码无法匹配
  LOGIN_MAIL_NOT_EXISTS: 108, // 邮箱登录发验证码，邮箱未注册

  WALLET_USED: 120, // 钱包已经绑定其他账号
  WALLET_ADDR_SIGNED_FAIL: 121, // web3验签失败

  PWD_TOO_SHORT: 130, // 密码太短

  CDKEY_NOT_EXIST: 140, // 不存在的兑换码
  CDKEY_NOT_EFFECT: 141, // 兑换码不在有效期
  CDKEY_EXPIRED: 142, // 兑换码已经过期
  CDKEY_USED: 143, // 兑换码已经兑换过
  CDKEY_USE_LIMIT: 144, // 同一批次兑换码达到兑换上限
  CDKEY_NOT_SUPPORT: 145, // 不支持的兑换码类型
  ITEM_NOT_FOUND: 150, // 物品（cdkey等）不存在
  CHECKED_IN: 161, // 今日已签到
  NO_COUNT: 162, // 今日已签到
  COMBINATION_ALEADY_EXIST: 163, // 当前nft已创建组合
  COMBINATION_NOT_EXIST: 164, // 当前组合不存在

  UNBIND_IMMUTABLE_WALLET: 170, // 未绑定immutable钱包
  UNFINISH_ORDER: 171, // 有未完成订单
  NO_UNLOCKED_TOKEN: 172, // 无已解锁代币
  DATA_NO_BOTH_END: 173, // 两端数据不对等

  INTERNAL_ERROR: 1000, // 内部错误
  OAUTH_FAIL: 1004, // 登录鉴权失败
  BOUND: 1005, // 已绑定
}

export const AppHttpCodeMessage = {
  [AppHttpCode.CHARACTER_NOT_EXIST]: 'Character does not exist.',
  [AppHttpCode.ACC_NAME_ALREADY_EXITS]: 'The name already exists.',
  [AppHttpCode.INVALID_NAME]:
    'The account nickname contains sensitive words or does not meet the length requirement.',
  [AppHttpCode.INVALID_LENGTH]:
    'The length does not meet the requirement or contains special characters.',
  [AppHttpCode.INVALID_NAME_PWD]: 'Incorrect account or password.',
  [AppHttpCode.BLOCKED_DEVICE]: 'Device is blacklisted.',
  [AppHttpCode.NOT_VALID_MAIL]: 'Not a valid email format.',
  [AppHttpCode.MAIL_ALREADY_REGED]: 'The email has already been registered.',
  [AppHttpCode.MAIL_VERIFY_CODE_NOT_VALID]:
    'The email registration verification code does not match.',
  [AppHttpCode.LOGIN_MAIL_NOT_EXISTS]: 'email is not registered.',
  [AppHttpCode.WALLET_USED]: 'The wallet is already linked to another account.',
  [AppHttpCode.WALLET_ADDR_SIGNED_FAIL]: 'Web3 signature verification failed.',
  [AppHttpCode.PWD_TOO_SHORT]: 'The password is too short.',
  [AppHttpCode.CDKEY_NOT_EXIST]: 'Invalid redemption code.',
  [AppHttpCode.CDKEY_NOT_EFFECT]:
    'The redemption code is not within the valid period.',
  [AppHttpCode.CDKEY_EXPIRED]: 'The redemption code has expired.',
  [AppHttpCode.CDKEY_USED]: 'The redemption code has already been redeemed.',
  [AppHttpCode.CDKEY_USE_LIMIT]:
    'The redemption limit for the same batch of codes has been reached.',
  [AppHttpCode.CDKEY_NOT_SUPPORT]: 'Unsupported redemption code type.',
  [AppHttpCode.ITEM_NOT_FOUND]: 'The redeemed item does not exist.',
  [AppHttpCode.OAUTH_FAIL]: 'Authentication failed.',
  [AppHttpCode.BOUND]: 'The Immutable account has been bound.',
  [AppHttpCode.CHECKED_IN]: 'You have checked in today',
  [AppHttpCode.NO_COUNT]: 'No spin times',
  [AppHttpCode.COMBINATION_ALEADY_EXIST]:
    'The current genesis NFT has created a combination',
  [AppHttpCode.COMBINATION_NOT_EXIST]: 'The current combination does not exist',

  [AppHttpCode.UNBIND_IMMUTABLE_WALLET]: 'The immutable account is not bound',
  [AppHttpCode.UNFINISH_ORDER]: 'The last order is not completed',
  [AppHttpCode.NO_UNLOCKED_TOKEN]: 'There are no unlocked $tvBOGX',
}

export function codeToMessage(code) {
  return AppHttpCodeMessage[code] || `fail: ${code}`
}

// PFP记录敲到成功的pfp
export const PFP_METADATA_TYPE =
  import.meta.env.VITE_PFP_METADATA_TYPE + import.meta.env.VITE_MODE
export const NEW_MESSAGE_NFT_SOFT_PLEDGE =
  import.meta.env.VITE_NEW_MESSAGE_NFT_SOFT_PLEDGE + import.meta.env.VITE_MODE
