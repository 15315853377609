<script setup>
import Countdown from 'src/pages/PFP/mint/components/Countdown.vue'
import { useTime } from 'src/pages/PFP/hooks/time'
import { computed } from 'vue'
import { ref } from 'vue'
import { NSpin } from 'naive-ui'
const emit = defineEmits(['confirm', 'end'])
const props = defineProps({
  endTime: Number,
  currentTime: Number,
  loading: Boolean,
  hasEgg: Boolean,
  showBtn: Boolean,
})
const countdownRef = ref(null)
const isEndComputed = computed(() => countdownRef.value?.status === 3)
const currentTimeComputed = computed(() => countdownRef.value?.currentTime)
const textComputed = computed(() => {
  if (isEndComputed.value) {
    return 'After long ages, your "Cocoon" has fully hatched.'
  } else {
    return 'Your "Cocoon" is about to hatch in the soothing embrace of Essences.'
  }
})

function handleConfirm() {
  emit('confirm')
}

function handleTimeEnd() {
  emit('end')
}

defineExpose({
  hasEnd: isEndComputed,
  currentTime: currentTimeComputed,
})
</script>
<template>
  <div
    class="bg-#323C44 border border-solid border-#6B7B87 rounded-2 relative flex-center py-2.5"
  >
    <span
      class="text-#CDD8E0 absolute left-3.5 bottom-3 md:w-250px 2xl:w-full"
      >{{ textComputed }}</span
    >
    <Countdown
      ref="countdownRef"
      :start-time="endTime"
      :end-time="endTime"
      :current-time="currentTime"
      dayColor="#8397A0"
      @end="handleTimeEnd"
    ></Countdown>
    <div
      class="right-3.5 absolute w-185px h-38px"
      v-if="showBtn && (isEndComputed || hasEgg)"
    >
      <div
        @click="handleConfirm"
        class="bg-#FF7D31 hover:bg-#FFA36C hover:border-#FFC662 border border-solid border-#FFC662 flex-center rounded-2 h-full"
      >
        Confirm
      </div>
      <div v-if="loading" class="absolute h-full w-full top-0">
        <NSpin :size="24" class="absolute-center"></NSpin>
      </div>
    </div>
  </div>
</template>
