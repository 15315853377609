export const scrollTextList = [
  {
    big: ['ENTER THE', "COLOSSUS'", 'LIBRARY'],
    small: [
      'After a living being dies, the body will turn',
      'into the nourishment of the world tree.',
      'Their memories and souls will turn into',
      '"Voidom Books".',
      "They were eventually stored in the Colossus'",
      'Library in Nibelheim.',
    ],
  },
  {
    big: ['THE ORIGIN', "THE'", 'WORLD TREE'],
    small: [
      'A grand nexus connecting realms, it',
      "weaves life's cycles and destinies.",
      'It\'s both the "root" and the terminal of',
      "fate's threads. As the universe's core",
      'and protector, it anchors creation and',
      'guards the world.',
    ],
  },
  {
    big: ['START', "YOUR'", 'JOURNEY'],
    small: [
      'Inheritors, the time has come to embark on a',
      'journey of sacrifice and redemption.',
      'Under the witness of the World Tree, we',
      'shall together unveil the hidden myths.',
      'Facing the lost gods, we will uncover the',
      'truth behind Ragnarök',
    ],
  },
]

export const scrollTriggerBottom = {
  start: '85% 85%',
  bottom: '85.5% 85.5%',
  scrub: true,
  // markers: true,
}

export const lineAngleW = 26
export const lineBottomConnectW = 185
export const lineBottomSoulsW = 135
export const playDeshaedLine = 4
export const reverseDeshaedLine = 0.3

export function mergeStrings(arr) {
  const newArr = []
  for (let i = 0; i < arr.length; i += 2) {
    if (i + 1 < arr.length) {
      newArr.push(arr[i] + arr[i + 1])
    } else {
      newArr.push(arr[i])
    }
  }
  return newArr
}
