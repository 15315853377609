<script setup>
import { NEmpty, NGridItem } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useUserInfoStore } from 'src/stores/user'
import PreviewModal from 'src/components/PreviewModal'
import { useWeb3Store } from 'src/stores/web3'
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { useSelectData } from 'src/pages/myCollection/hooks/useSelectData'
import { hasPhone } from 'src/stores/media'
import ItemBox from './components/ItemBox.vue'
import Panel from './components/Panel.vue'
import PanelHedaer from './components/PanelHedaer'
import TransferModal from './components/TransferModal'
import Empty from './components/Empty'
import NFTBox from './components/NFTBox.vue'
import List from './components/List'
import Box from './components/Box'
import ItemDetail from './components/ItemDetail'

const { nftList } = storeToRefs(useWeb3Store())
const { assetsList } = storeToRefs(useUserInfoStore())
const {
  selectDataRef,
  itemDetailShowRef,
  singleChoiceDataRef,
  currentUserIDRef,
  previewSrcRef,
  transferShowRef,
  currentSelectDataRef,
  previewTypeRef,
  previewShowRef,
  handleSelect,
  handleCancel,
  handleEnlarge,
  handleClose,
  handleValidate,
  handleTransferSuccess,
  handleTransferError,
  handleCheck,
  handleEmptySelect,
  handleItemDetailClose,
} = useSelectData()

const roleListRef = useRoleList()
const isThereDataRef = computed(
  () => assetsList.value.length || nftList.value.length,
)
</script>

<template>
  <div class="relative h-full flex">
    <Panel
      v-if="!hasPhone"
      class="flex-shrink-0"
      :select-data="selectDataRef"
      :data="singleChoiceDataRef"
      :role-list="roleListRef"
      @submit="handleValidate"
    ></Panel>
    <PanelHedaer
      v-else
      :select-data="selectDataRef"
      :role-list="roleListRef"
      @empty="handleEmptySelect"
      @submit="handleValidate"
    ></PanelHedaer>
    <List v-if="isThereDataRef" :list="assetsList">
      <NGridItem v-for="item in nftList" :key="item.tokenId">
        <Box>
          <NFTBox
            :data="item"
            :single-choice-data="singleChoiceDataRef"
            @enlarge="data => handleEnlarge(data, 2)"
            @check="handleCheck"
          ></NFTBox>
        </Box>
      </NGridItem>

      <template #item="item">
        <ItemBox
          :data="item"
          :select-data="selectDataRef"
          :single-choice-data="singleChoiceDataRef"
          :role-list="roleListRef"
          @check="handleCheck"
          @select="handleSelect"
          @cancel="handleCancel"
          @enlarge="data => handleEnlarge(data, 1)"
          @submit="handleValidate"
        >
        </ItemBox>
      </template>
    </List>
    <NEmpty v-else class="m-auto"></NEmpty>
    <Empty
      v-if="!hasPhone"
      v-show="selectDataRef.length"
      @click="handleEmptySelect"
    />
  </div>
  <ItemDetail
    v-if="hasPhone"
    v-show="itemDetailShowRef"
    :data="singleChoiceDataRef"
    :role-list="roleListRef"
    @close="handleItemDetailClose"
    @submit="handleValidate"
  ></ItemDetail>
  <PreviewModal
    v-if="!hasPhone"
    :show="previewShowRef"
    :src="previewSrcRef"
    :type="previewTypeRef"
    @close="handleClose"
  ></PreviewModal>
  <TransferModal
    v-model:show="transferShowRef"
    :UserID="currentUserIDRef"
    :select-data="currentSelectDataRef"
    @success="handleTransferSuccess"
    @error="handleTransferError"
  ></TransferModal>
</template>

<style scoped lang="scss">
@import './border';
</style>
