<script setup>
import { hasPhone } from 'src/stores/media'
import { computed, ref } from 'vue'

const props = defineProps({
  textList: {
    type: Array,
    default: () => [''],
  },
})
const textArrayRef = computed(() => {
  return props.textList.map(text => {
    return text.split('')
  })
})
const textsRef = ref(null)
const startDomRef = ref([])
const endDomRef = ref([])
defineExpose({
  textsRef,
  startDomRef,
  endDomRef,
})
</script>

<template>
  <div ref="textsRef" class="vessel">
    <template v-for="(texts, i) in textArrayRef" :key="i">
      <div ref="startDomRef">
        <template v-if="hasPhone">
          {{ texts.join('') }}
        </template>
        <template v-for="(text, j) in texts" v-else :key="j">
          <template v-if="text === ' '">&nbsp;</template>
          <span v-else ref="endDomRef">{{ text }}</span>
        </template>
      </div>
    </template>
  </div>
</template>

<style scoped>
.vessel {
  user-select: none;
}

.vessel > div {
  @apply flex;
}
</style>
