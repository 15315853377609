<script setup>
import ItemModal from 'src/components/ItemModal'
import { useWalletBind } from 'src/hooks/useWalletBind'
import { useAsyncState } from '@vueuse/core'

const props = defineProps({
  show: Boolean,
  text: String,
  address: String,
  hasBind: {
    type: Boolean,
    default: true,
  },
})

const emit = defineEmits(['update:show', 'success', 'error'])

const { bindWallet, unbindWallet } = useWalletBind()
const { isLoading, execute } = useAsyncState(
  async () => {
    if (props.hasBind) {
      await bindWallet()
    } else {
      await unbindWallet()
    }
    emit('success')
  },
  null,
  {
    immediate: false,
    onError(error) {
      console.error(error)
      emit('error', error)
    },
  },
)
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <ItemModal
    :show="props.show"
    :loading="isLoading"
    @update:show="handleClose"
    @cancel="handleClose"
    @confirm="execute"
  >
    <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
      {{ props.text }}
      <div class="orange-color break-all">{{ address }}</div>
    </div>
  </ItemModal>
</template>
