<script setup>
import dayjs from 'dayjs'
import PanelContainer from './PanelContainer'
import BoxInfo from './BoxInfo'
import { computed } from 'vue'
const props = defineProps(['data'])
const emit = defineEmits(['details'])

function handleDetails() {
  emit('details', props.data)
}
const createdTimeRef = computed(() =>
  dayjs(props?.CreateTime).format('MMM YYYY').toLocaleUpperCase(),
)
</script>

<template>
  <PanelContainer>
    <template v-if="data">
      <div class="mb-6">CREATED {{ createdTimeRef }}</div>
      <div class="mb-4 relative">
        <div class="item-btn-black h-35px" @click="handleDetails">
          Item Details
        </div>
        <span
          class="triangle absolute rotate-z-[-90deg] translate-y-[-50%]"
        ></span>
      </div>
      <BoxInfo :data="data"></BoxInfo>
    </template>
  </PanelContainer>
</template>
<style lang="scss" scoped>
@import './btn';

.triangle {
  @include triangle($color: #c4ebff);

  top: 50%;
  right: -20px;
  border-top-width: 8px;
}
</style>
