import { ref } from 'vue'
import { useDomChange } from './useDomChange'
export function useBoundingClientRect(domRef) {
  const dataRef = ref(defaultBoundingClientRect())
  useDomChange(domRef, handleResize)
  function handleResize() {
    const dom = domRef.value
    if (dom) {
      dataRef.value = defaultBoundingClientRect(dom)
    }
  }
  return {
    dataRef,
    handleResize,
  }
}

export function defaultBoundingClientRect(dom) {
  if (dom) {
    return dom.getBoundingClientRect()
  }
  return {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  }
}
