<script setup>
import { gsap } from 'gsap'
import { NSpin, useMessage } from 'naive-ui'
import { useElementBounding, useThrottleFn } from '@vueuse/core'
import { computed, ref, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
import { imgsPreloader } from '@BOGX/utils'
import PrizeTooltips from './PrizeTooltips'
import PrizeModal from './PrizeModal'
import BindImmutableModal from './BindImmutableModal.vue'
import priceBgWebp from 'src/asset/softPledge/price_bg.webp'
import selectedWebp from 'src/asset/softPledge/selected.webp'
import drawedWebp from '/src/asset/softPledge/btn_drawed.webp'
import { useSoftPledgeInfoStore } from 'src/stores/softPledge'
import { useLoginStore } from 'src/stores/user'
import { useImmutable } from 'src/hooks/useImmutable'

const { reawardList, playingInfo, drawLoading } = storeToRefs(
  useSoftPledgeInfoStore(),
)
const { executeDrawLuckWheel, executeReawardList } = useSoftPledgeInfoStore()
const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())

const message = useMessage()
const { userImmutableWallet, bindImmutable } = useImmutable()
const { isLoading: loadingBindRef, execute: executeBindImmutable } =
  bindImmutable

const prizeWrap = ref(null)
const outerBox = ref(null)
const prizeListRef = ref([])
const prizeImgList = ref([])
const prizeList = computed(() => reawardList.value)

const isRunning = ref(false)
const baseRunAngle = 360 * 6 // 总共转动角度 至少5圈
const prizeId = ref(0) // 中奖id
// 平均每个奖品角度
const rotateAngle = computed(() => {
  const _degree = 360 / prizeList.value?.length
  return _degree
})

// 要执行总角度数
const totalRunAngle = computed(() => {
  return (
    baseRunAngle +
    360 -
    prizeId.value * rotateAngle.value -
    rotateAngle.value / 2
  )
})

// 每个奖品布局
const prizeStyle = computed(() => {
  const _degree = rotateAngle.value
  return i => {
    return `
    width: ${2 * 285 * Math.sin(((_degree / 2) * Math.PI) / 180)}px;
          transform: rotate(${_degree * i + _degree / 2}deg);
          transform-origin: 50% 100%;
        `
  }
})

const showBindImmutableModalRef = ref(false)

async function handleBind() {
  executeBindImmutable()
}

async function startDraw() {
  if (!hasLogin.value) {
    await login()
    executeReawardList()
    return
  }
  if (!userImmutableWallet.value) {
    message.warning('Please bind your immutable account first.')
    showBindImmutableModalRef.value = true
    return
  }
  if (!isRunning.value) {
    // 请求返回的奖品编号 这里使用随机数
    try {
      const id = await executeDrawLuckWheel()
      if (!id) return
      isRunning.value = true
      prizeId.value = prizeList.value?.findIndex(item => item.id === id)
      startRun()
    } catch (error) {
      isRunning.value = false
      console.log(error)
    }
  }
}

const startRun = () => {
  lockTl.play()
  // 设置动效
  prizeWrap.value.style = `
        transform: rotate(${totalRunAngle.value}deg);
        transition: all 6s ease-in-out;
      `
  outerBox.value.style = `
  z-index: 1;
        transform: rotate(${-totalRunAngle.value}deg);
        transition: all 6s ease-in-out;
        `
  // 监听transition动效停止事件
  prizeWrap.value.addEventListener('transitionend', stopRun, { once: true })
}

const resetPrizeBg = () => {
  const _degree = rotateAngle.value
  prizeListRef.value[prizeId.value].style = `
    width: ${2 * 285 * Math.sin(((_degree / 2) * Math.PI) / 180)}px;
    background-image: url(${priceBgWebp});
    transform: rotate(${_degree * prizeId.value + _degree / 2}deg);
    transform-origin: 50% 100%;
    transition: background 0.05s ease-in-out
    `
  // prizeWrap.value.style = `transform: rotate(${-rotateAngle.value}deg);`
}
const stopRun = e => {
  const _degree = rotateAngle.value
  isRunning.value = false
  prizeWrap.value.style = `
        transform: rotate(${totalRunAngle.value - baseRunAngle}deg);
      `

  outerBox.value.style = `
        transform: rotate(${-totalRunAngle.value + baseRunAngle}deg);
      `
  prizeListRef.value[prizeId.value].style = `
      width: ${2 * 285 * Math.sin(((_degree / 2) * Math.PI) / 180)}px;
      background-image: url(${selectedWebp});
      transform: rotate(${_degree * prizeId.value + _degree / 2}deg);
      transform-origin: 50% 100%;
      transition: background 0.2s ease-in-out;
    `
  lockTl.reverse()
  setTimeout(() => {
    rewardShowRef.value = true
    resetPrizeBg()
  }, 500)
  // setTimeout(() => {
  //   reset()
  // }, 700)
}

const showToolTips = ref(false)
const prizeInfo = ref(null)
const toolTipStyle = ref('')

const handleMouseenter = (item, index, event) => {
  const imgRef = prizeImgList.value[index]
  const { x, y, top, left } = useElementBounding(imgRef)
  prizeInfo.value = item
  toolTipStyle.value = `position: fixed;top: ${top.value + 60}px;left: ${left.value + 100}px;`
  useThrottleFn(() => {
    showToolTips.value = true
  }, 100)()
}

const handleMouseleave = () => {
  useThrottleFn(() => {
    showToolTips.value = false
  }, 100)()
}

const rewardShowRef = ref(false)
const lockRef = ref(null)

let triangleTl, triangleTl1, lockTl
const initAnimation = () => {
  triangleTl = gsap.to('.triangle', {
    ease: 'power1.out',
    opacity: 0,
    paused: true,
    duration: 0.3,
    // repeat: -1,
    stagger: {
      each: 0.1,
      from: 'end', // 逆序开始动画
    },
    repeatDelay: 0, // 确保没有重复延迟
    onStart() {
      triangleTl1?.kill()
    },
    onComplete() {
      // 动画完成后重置状态
      triangleTl1?.restart()
    },
  })
  triangleTl1 = gsap.to('.triangle', {
    ease: 'power1.out',
    opacity: 1,
    paused: true,
    duration: 0.3,
    stagger: {
      each: 0.15,
      from: 'end', // 逆序开始动画
    },
    onStart() {
      triangleTl?.kill()
    },
    onComplete() {
      // 动画完成后重置状态
      triangleTl?.restart()
    },
  })
  lockTl = gsap.to(lockRef.value, {
    ease: 'power1.out',
    y: -10,
    paused: true,
  })
  triangleTl.play()
}

const imgsList = [priceBgWebp, selectedWebp, drawedWebp]
onMounted(() => {
  initAnimation()
  imgsPreloader(imgsList)
  // prizeWrap.value.style = `transform: rotate(-${rotateAngle.value}deg)`
})

onUnmounted(() => {
  triangleTl?.kill()
  triangleTl1?.kill()
  lockTl?.kill()
  prizeWrap?.value?.removeEventListener('transitionend', stopRun)
})
</script>

<template>
  <div
    class="outer-border xl:scale-85 2xl:scale-100 3xl:scale-100 4xl:scale-100 5xl:scale-130 transform-origin-bl relative w-977px h-928px rounded-50% flex-center"
  >
    <div
      class="flex-center mt-50px relative z-2 w-624px h-624px bg-#F7CB6D rounded-50%"
      ref="prizeWrap"
    >
      <div
        class="prize-item absolute left-0 right-0 -top-30px w-220px m-auto h-342px z-2"
        v-for="(item, index) in prizeList"
        :key="item.id"
        ref="prizeListRef"
        :style="prizeStyle(index)"
      >
        <div
          v-if="item.item_count"
          class="family_blk absolute bottom-120px left-50% -translate-x-50% flex flex-col flex-items-center text-#FFBC46 text-18px"
        >
          <span>{{ item.item_count }}</span>
          <span>x</span>
        </div>
        <div class="m-[60px_auto_10px] flex-center">
          <img
            ref="prizeImgList"
            @mouseover="e => handleMouseenter(item, index, e)"
            @mouseout="handleMouseleave(item)"
            class="mouse_pointer relative block w-110px"
            :src="item.item_icon"
            alt=""
          />
        </div>
      </div>
    </div>

    <img
      ref="outerBox"
      class="absolute w-707px mt-44px"
      src="src/asset/softPledge/wheel.webp"
      alt=""
    />
    <!-- 指针 -->
    <div class="arrow absolute top-230px w-68px h-193px m-auto z-2"></div>
    <!-- 锁扣 -->
    <img
      ref="lockRef"
      class="w-80px absolute z-2 mx-auto top-170px"
      src="src/asset/softPledge/lock.webp"
      alt=""
    />

    <!-- 开始按钮 -->
    <div
      @click="startDraw"
      class="mouse_pointer flex-center btn absolute z-3 w-206px h-205px m-auto top-42% -translate-y-50% transition-all"
      :class="isRunning ? 'btn_drawed' : 'btn_draw'"
    >
      <div class="btn-arrow absolute top-30px flex flex-col">
        <div v-for="item in 4" :class="`triangle triangle${item}`"></div>
      </div>

      <div
        class="flex-center absolute bottom-50px w-86px h-27px bg-#6D3026 border border-solid border-#A85234 rounded-5px"
      >
        <div
          class="family_blk w-55px h-22px leading-22px bg-#88392C/20 text-center text-#FFE56A text-18px rounded-50%/50%"
        >
          {{ playingInfo?.today_count || 0 }}
        </div>
      </div>
    </div>
    <div v-if="drawLoading" class="absolute h-full w-full z-99 top-0">
      <NSpin :size="100" class="absolute-center"></NSpin>
    </div>
  </div>
  <PrizeModal
    v-model:show="rewardShowRef"
    :rewardInfo="prizeList?.[prizeId]"
  ></PrizeModal>
  <BindImmutableModal
    v-model:show="showBindImmutableModalRef"
    :isLoading="loadingBindRef"
    :wallet="userImmutableWallet"
    @confirm="handleBind"
  ></BindImmutableModal>
  <div
    v-if="showToolTips && !isRunning"
    class="fixed pointer-events-none z-4!"
    :style="toolTipStyle"
  >
    <PrizeTooltips :prizeInfo="prizeInfo"></PrizeTooltips>
  </div>
</template>

<style lang="scss" scoped>
.outer-border {
  background: url('src/asset/softPledge/outter_box.webp') no-repeat center /
    100% 100%;
}

.prize-item {
  background-image: url('/src/asset/softPledge/price_bg.webp');
  background-repeat: no-repeat;
  background-position-y: 36px;
  background-size: 100% 100%;
}

.btn_draw {
  background: url('/src/asset/softPledge/btn_draw.webp') no-repeat center / 100%
    100%;
  transform: scale(1);
}

.btn_drawed {
  background: url('/src/asset/softPledge/btn_drawed.webp') no-repeat center /
    100% 100%;
  transform: scale(0.95);
}

.arrow {
  background: url('/src/asset/softPledge/arrow.webp') no-repeat center / 100%
    100%;
}

.triangle {
  width: 10.5px;
  height: 9px;
  margin-bottom: 3px;
  clip-path: polygon(50% 0, 100% 100%, 0 100%);
  background-color: #a85234;
}
</style>
