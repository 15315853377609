<script setup>
import { NSpin } from 'naive-ui'
import { reactive } from 'vue'
const state = reactive({
  show: false,
  description: '加载中...',
  rotate: true,
  size: 'medium',
  stroke: '#0052d9',
  maskBackground: 'rgba(255, 255, 255, 0.5)',
  textColor: '#0052d9',
  loadingBoxStyle: {},
})
// 更新loading信息
const updateLoading = loadingInfo => {
  const {
    show,
    description,
    maskBackground,
    size,
    rotate,
    textColor,
    loadingBoxStyle,
    stroke,
  } = loadingInfo
  state.show = show || state.show
  // false： 不显示  true、不传：显示默认
  state.description =
    description === false
      ? false
      : description === true || !!description === false
        ? state.description
        : description

  state.size = size || state.size
  state.rotate = rotate || state.rotate
  state.stroke = stroke || state.stroke
  state.textColor = textColor || state.textColor
  state.maskBackground = maskBackground || state.maskBackground
  state.loadingBoxStyle = loadingBoxStyle || state.loadingBoxStyle
}

defineExpose({
  updateLoading,
})
</script>
<template>
  <div v-if="state.show" class="loading-box" :style="state.loadingBoxStyle">
    <div class="mask" :style="{ background: state.maskBackground }"></div>
    <div class="loading-content-box">
      <NSpin
        v-if="state.show"
        :size="state.size"
        :rotate="state.rotate"
        :stroke="state.stroke"
      >
        <template #description>
          <div
            v-if="state.description !== false"
            :style="{ color: state.textColor }"
            class="tip"
          >
            {{ state.description }}
          </div>
        </template>
      </NSpin>
    </div>
  </div>
</template>
<style scoped lang="scss">
.loading-box {
  position: absolute;
  inset: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .n-spin {
    color: #ccc;
  }

  .mask {
    width: 100%;
    height: 100%;
  }

  .loading-content-box {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .tip {
    margin-top: 8px;
    font-size: 14px;
  }
}
</style>
