<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { gsap } from 'gsap'
import DownloadBOGX from 'src/components/DownloadBOGX'
import RedPopover from 'src/components/RedPopover'
import SocializeLinks from 'src/components/SocializeLinks'
import { useMusicStore } from 'src/stores/music'
import { useSoftPledge } from 'src/pages/softPledge/index'
import FloatingArrow from 'src/components/FloatingArrow'
import { scrollTriggerBottom } from '../constant.js'
import { PFP_MINT_NAME } from 'src/constant'
import EventsBox from 'src/pages/home/components/EventsBox'
import TestModal from 'src/pages/softPledge/components/TestModal'
const emit = defineEmits(['about'])
const props = defineProps(['scrollTriggerDom'])
const pfpShow = import.meta.env.VITE_PFP_SHOW
const { buttonClickAudioPlay, buttonCaptureAudioPlay } = useMusicStore()
const { testModalShowRef, openTest } = useSoftPledge()
const xLogoDomRef = ref(null)
const eventsBoxShowRef = ref(false)
const downloadShowRef = ref(false)
const linksShowRef = ref(false)
const aboutShowRef = ref(false)
const aboutTranslateYRef = ref(-50)
let tween, aboutTween
function initScroll() {
  const scrollTrigger = {
    ...scrollTriggerBottom,
    trigger: props.scrollTriggerDom,
  }
  // 底部logo
  tween = gsap.to(xLogoDomRef.value, {
    y: '165%',
    scrollTrigger,
  })
}
function initAboutTween() {
  aboutTween = gsap.to(aboutTranslateYRef, {
    paused: true,
    duration: 0.4,
    value: 30,
    onComplete() {
      if (aboutShowRef.value) {
        this.reverse()
      }
    },
    onReverseComplete() {
      if (aboutShowRef.value) {
        this.play()
      }
    },
  })
}
function handleAbout() {
  buttonClickAudioPlay()
  emit('about')
}
watch(aboutShowRef, value => {
  if (value) {
    aboutTween?.play()
  } else {
    aboutTween?.reverse()
  }
})
onMounted(() => {
  initScroll()
  initAboutTween()
})
onUnmounted(() => {
  tween?.kill()
  aboutTween?.kill()
})
</script>

<template>
  <div ref="xLogoDomRef" class="x_logo gray-color z-[2] flex">
    <div class="line"></div>
    <EventsBox
      @getRewardStatus="openTest"
      v-model:show="eventsBoxShowRef"
      :show-arrow="false"
    >
      <FloatingArrow
        v-slot="{ activate }"
        :show="eventsBoxShowRef"
        color="#E9B356"
        class="mx-5"
        :manual="true"
      >
        <span
          :style="{ color: activate ? '#E9B356' : 'inherit' }"
          class="gray-color mouse_pointer"
          @mouseenter="buttonCaptureAudioPlay"
          @click="buttonClickAudioPlay"
          >EVENTS</span
        >
      </FloatingArrow>
    </EventsBox>
    <div class="line"></div>
    <DownloadBOGX v-model:show="downloadShowRef" :show-arrow="false">
      <FloatingArrow
        v-slot="{ activate }"
        class="mx-5"
        :show="downloadShowRef"
        :manual="true"
      >
        <div class="mouse_pointer relative">
          <div
            class="hover_triangle left-50%"
            :style="`transform: translate(-50%, -50%) rotate(${
              downloadShowRef ? 360 : 180
            }deg); border-top-color: ${
              downloadShowRef ? '#bcc6cd' : '#757a7e'
            }`"
          ></div>
          <span
            :style="{ color: activate ? '#fff' : 'inherit' }"
            @mouseenter="buttonCaptureAudioPlay"
            @click="buttonClickAudioPlay"
            >DOWNLOAD</span
          >
        </div>
      </FloatingArrow>
    </DownloadBOGX>
    <div class="line"></div>
    <RedPopover>
      <template #trigger>
        <span
          class="red mouse_pointer mx-5"
          @mouseenter="buttonCaptureAudioPlay"
          @click="buttonClickAudioPlay"
          >MARKETPLACE</span
        >
      </template>
      <div>Coming Soon ......</div>
    </RedPopover>
    <div class="line"></div>
    <img
      src="/src/asset/x_logo.webp"
      alt=""
      class="mx-5 h-[87.5px] w-[241.5px]"
    />
    <div class="line"></div>
    <FloatingArrow v-slot="{ activate }" class="mx-5" v-if="pfpShow">
      <router-link
        :to="{ name: PFP_MINT_NAME }"
        target="_blank"
        :style="{ color: activate ? '#fff' : 'inherit' }"
        class="gray-color mouse_pointer"
        @mouseenter="buttonCaptureAudioPlay"
        @click="buttonClickAudioPlay"
        >MINT</router-link
      >
    </FloatingArrow>
    <RedPopover v-else>
      <template #trigger>
        <span
          class="red mouse_pointer mx-5"
          @mouseenter="buttonCaptureAudioPlay"
          @click="buttonClickAudioPlay"
          >MINT</span
        >
      </template>
      <div>Coming Soon ......</div>
    </RedPopover>
    <div class="line"></div>
    <SocializeLinks v-model:show="linksShowRef">
      <FloatingArrow
        v-slot="{ activate }"
        class="mx-5"
        :manual="true"
        :show="linksShowRef"
      >
        <div class="mouse_pointer relative">
          <div
            class="hover_triangle left-50%"
            :style="`transform: translate(-50%, -50%) rotate(${
              linksShowRef ? 360 : 180
            }deg); border-top-color: ${linksShowRef ? '#bcc6cd' : '#757a7e'}`"
          ></div>
          <span
            class="mouse_pointer"
            :style="{ color: activate ? '#fff' : 'inherit' }"
            @mouseenter="buttonCaptureAudioPlay"
            @click="buttonClickAudioPlay"
            >LINKS</span
          >
        </div>
      </FloatingArrow>
    </SocializeLinks>
    <div class="line"></div>
    <FloatingArrow class="mx-5" v-model:show="aboutShowRef">
      <div class="mouse_pointer relative">
        <span
          class="mouse_pointer"
          :style="{ color: aboutShowRef ? '#fff' : 'inherit' }"
          @mouseenter="buttonCaptureAudioPlay"
          @click="handleAbout"
          >ABOUT</span
        >
        <div
          v-show="aboutShowRef"
          class="hover_triangle left-50%"
          :style="`transform: translate(-50%, ${aboutTranslateYRef}%) rotate(360deg); border-top-color: #E9CF88`"
        ></div>
      </div>
    </FloatingArrow>
    <div class="line"></div>
    <TestModal v-model:show="testModalShowRef"></TestModal>
  </div>
</template>

<style scoped lang="scss">
.x_logo {
  @apply fixed bottom-10 items-center w-[110vw] translate-x-[-50%] left-50% 4xl:left-47%;

  .hover_triangle {
    @apply absolute;
    @include triangle;
  }

  .line {
    flex: 1;
    height: 1px;

    @extend .gray-bg;

    &:last-child,
    &:first-child {
      @apply grow-2 2xl:grow-6;
    }
  }

  span,
  a {
    @apply relative;
    @extend .fz-3;

    &.red {
      @extend .red-color;
    }

    // &:not(.red):hover {
    //   @apply text-white;

    //   &::after,
    //   &::before {
    //     @apply absolute;
    //     @extend .fz-2;
    //   }

    //   &::after {
    //     content: '>';
    //     transform: translateX(50%);
    //   }

    //   &::before {
    //     content: '<';
    //     transform: translateX(-160%);
    //   }
    // }
  }
}
</style>
