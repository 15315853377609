import { ReceiveJobSelectMap } from 'src/constant'

export function getReceiveJobName(ReceiveJob, Name) {
  return `${ReceiveJobSelectMap[ReceiveJob]}-${Name}`
}

export function getType(id, type) {
  // if (id < 10000000) {
  switch (type) {
    case 4:
      return 'Fashion'
    case 10:
      return 'Special Props'
    case 11:
      return 'Soul Card'
    case 16:
      return 'Identity'
    default:
      return 'Other'
  }
  // } else {
  //   return 'Equipment'
  // }
}

export function tokenIdFormat(text) {
  if (typeof text === 'string') {
    return text.slice(0, 8)
  }
  return text
}

export const selectTheme = {
  peers: {
    InternalSelection: {
      heightMedium: '27px',
    },
  },
}

export function getBtnName(data) {
  const { responseWait, type } = data || {}
  let name = ''
  switch (type) {
    case 1:
      name = 'Mint'
      break
    case 2:
      name = 'Unlock'
      break
    case 3:
      name = 'Deliver to Game'
      break
  }
  const ingText = responseWait ? 'ing...' : ''
  return name + ingText
}
export function getSoulToknDataId(data) {
  return data?.mint?.tokenid
}
export function idsToListTokenIdOne(ids, list) {
  const id = Array.isArray(ids) ? ids[0] : ids
  const data = list.find(item => item?.Id === id)
  return getSoulToknDataId(data)
}

// const statusMessageList = {
//   1: 'Already locked',
//   2: 'Unlocking...',
//   3: 'Unlocked',
//   4: 'Locking...',
// }
// export function getStateMessage(state) {
//   return statusMessageList[state]
// }

export function getResponseWaitState(state) {
  return [-1, 1, 3, 10, 15].includes(state)
}
