<script setup></script>
<template>
  <div class="relative flex flex-col flex-items-center">
    <div
      class="left-triangle text-center mt-14px mb-4px bg-#BCC6CD w-15px h-15px"
    ></div>
    <div
      class="p-8.5px flex flex-col justify-between w-431px bg-#141C21 border border-solid border-#BAE3EF rounded-5px py-11px px-14px text-18px text-#fff"
    >
      <span class="text-center text-18px">About Check-in</span>
      <div class="w-full h-0.5px bg-#8397A0/50 mt-10px mb-18px"></div>
      <span class="text-#9EA6AB fz-2"
        >Daily Check-in Refreshes daily at
        <span class="text-#fff">00:00 (UTC-8)</span>. Earn
        <span class="text-#BD84F8">lucky points</span> with each check-in.</span
      >
    </div>
  </div>
</template>

<style lang="scss" scoped>
.left-triangle {
  clip-path: polygon(50% 50%, 100% 100%, 0 100%);
}
</style>
