<script setup>
import BaseList from 'src/components/BaseList'
import Item from '../Item/index.vue'
const emit = defineEmits(['detail'])
const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
})
function handleClick(item) {
  emit('detail', item)
}
</script>
<template>
  <BaseList :list="list" :xGap="26" :yGap="26">
    <template #item="item">
      <Item
        :id="item.id"
        :name="item.viewName"
        :image="item.image"
        @click="handleClick(item)"
      ></Item>
    </template>
  </BaseList>
</template>
