<script setup>
import Modal from 'src/components/Modal'
import prompt_arrow from 'src/asset/mint/prompt_arrow.webp'
import eggImg from 'src/asset/PFP/egg.webp'

const props = defineProps({
  show: Boolean,
  list: {
    type: Array,
    default: [],
  },
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="px-64px py-34px flex flex-col flex-items-center bg-#050404 border border-solid border-#483425"
    >
      <div class="fz-6 text-#FF7D31">SUCCESSFUL</div>
      <div class="mt-10px fz-4 text-#F54949">
        It has been sent to the character mailbox, please check
      </div>
      <div class="flex">
        <div
          v-for="item in list"
          class="bg-#1b1208 mr-33px last:mr-0 mt-40px flex flex-col w-299px h-374px border border-solid border-#483425"
        >
          <img class="w-full h-299px" :src="item.image" alt="" />
          <div class="flex-1 flex-center flex-col">
            <!-- <div class="text-#4F6464">{{ item.name }} × {{ item.qty }}</div> -->
            <div class="fz-4 text-#FFE1A1">
              {{ item.name }} <span class="fz-2">×</span>{{ item.qty }}
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-center w-full mt-40px">
        <div
          @click="handleClose"
          class="mouse_pointer btn-confirm fz-3 flex-center bg-#050404 w-186px h-38px border border-solid border-#C9771F rounded-5px"
        >
          Confirm
        </div>
      </div>
    </div>
  </Modal>
</template>
<style lang="scss" scoped>
.btn-confirm {
  background: linear-gradient(90deg, #9b4c29 0%, #a55e2e 100%);

  &:hover {
    background: linear-gradient(90deg, #c36a43 0%, #d0733c 100%);
  }
}
</style>
