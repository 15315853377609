<script setup>
import { validateEmail } from '@BOGX/utils'
import { NFormItem, NInput } from 'naive-ui'
import EmailCode from '../EmailCode'

const props = defineProps(['email', 'countdownKey', 'code', 'codeType'])
const emit = defineEmits(['update:email', 'update:code'])
function handleEmail(value) {
  emit('update:email', value)
}
function handleCode(value) {
  emit('update:code', value)
}
</script>

<template>
  <NFormItem path="mail" type="mail">
    <NInput
      :value="props.email"
      :maxlength="200"
      placeholder="E-mail address"
      @update:value="handleEmail"
    />
  </NFormItem>
  <NFormItem path="verifyCode">
    <EmailCode
      :email="props.email"
      :code-type="props.codeType"
      :value="props.code"
      :countdown-key="props.countdownKey"
      :disabled="!validateEmail(props.email)"
      @update:value="handleCode"
    ></EmailCode>
  </NFormItem>
</template>
