import Cookies from 'js-cookie'
export class CookieStorage {
  static prefix = '_cookieStorage'
  constructor(key, options) {
    this.key = key
    this.options = options
  }

  get storageKey() {
    return this.key + CookieStorage.prefix
  }

  get value() {
    const value = Cookies.get(this.storageKey, this.options)
    try {
      return JSON.parse(value)
    } catch {
      return value
    }
  }

  set value(value) {
    let _value
    try {
      _value = JSON.stringify(value)
    } catch {
      _value = value
    }
    Cookies.set(this.storageKey, _value, this.options)
    return value
  }

  remove() {
    Cookies.remove(this.storageKey, this.options)
    return this
  }
}
