<script setup>
import Dropdown from 'src/components/Dropdown'

import { computed, ref } from 'vue'

const options = [
  {
    label: 'Blade of God X',
    key: '1',
  },
]
const currentValueRef = ref(options[0].key)
const gameNameRef = computed(() => {
  const value = currentValueRef.value
  return options.find(option => value === option.key).label
})
</script>

<template>
  <div class="flex-center">
    MY COLLECTION&ensp;/&ensp;
    <Dropdown v-model:value="currentValueRef" :options="options">
      <div class="inline-block">
        <span class="orange-color mouse_pointer mr-1"> {{ gameNameRef }}</span>
        <img
          src="/src/asset/hollow_down_arrow.webp"
          class="w-[8.5px] md:w-[18px]"
        />
      </div>
    </Dropdown>
  </div>
</template>
