import {
  baseMint,
  bindAddressExist,
  insufficientBalanceErrorText,
} from 'src/sdk/blockchain'
import {
  BaseBlockchain,
  baseBlockchainParams,
  useDecorator,
} from './blockchain'
import {
  blockchainDataClient,
  fetchAuth,
  getImmutableTransport,
} from 'src/sdk/immutable'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { storeToRefs } from 'pinia'
import { getImmutableInfo } from './utils'

export class Immutable extends BaseBlockchain {
  constructor() {
    super()
    const { chainName, chainId } = getImmutableInfo()
    this.chainName = chainName
    this.setChainId(chainId)
  }
  /**
   * 获取个人所有tokenid
   */
  getTokenIds(args) {
    return blockchainDataClient.listNFTsByAccountAddress(args).then(res => {
      return res.result.map(data => data.token_id)
    })
  }
  /**
   * 获取最新的数个tokenid
   */
  getNewTokenIds(args) {
    return this.getTokenIds({
      ...args,
      pageSize: args.pageSize || args.number,
    })
  }
  /**
   * 获取最新的tokenid
   */
  getNewTokenId(args) {
    return this.getTokenIds({
      ...args,
      pageSize: 1,
    })
  }

  /**
   * 获取最新的nft，用于购买成功成功后，弹窗调用
   * @param {object} data {args[address, tokenId]}
   * @returns Array<object>
   */
  request(data) {
    return super.request(data)
  }

  // ownerOf(data) {
  //   return baseMint({
  //     functionName: 'ownerOf',
  //     ...data,
  //   })
  // }

  // getApproved(data) {
  //   return baseMint({
  //     functionName: 'getApproved',
  //     ...data,
  //   })
  // }
}
const ImmutableProty = Immutable.prototype
useDecorator(ImmutableProty, 'getTokenIds', baseBlockchainParams)
useDecorator(ImmutableProty, 'getTokenIds', immutableParams)
// useDecorator(ImmutableProty, 'approve', baseBlockchainParams)
useDecorator(ImmutableProty, 'request', immutableParams)
// useDecorator(ImmutableProty, 'ownerOf', baseBlockchainParams)
// useDecorator(ImmutableProty, 'ownerOf', immutableParams)
// useDecorator(ImmutableProty, 'getApproved', baseBlockchainParams)
// useDecorator(ImmutableProty, 'getApproved', immutableParams)

export function immutableParams(target, name, descriptor) {
  const oldValue = descriptor.value
  descriptor.value = async function (args) {
    const { immutableCurrentBindAccount } = storeToRefs(useImmutableInfoStore())
    const accountAddress = immutableCurrentBindAccount.value
    // const accountAddress = '0x80ae0124507f5419c83907a430041082ac4e0e95'
    if (!accountAddress) {
      throw new Error(insufficientBalanceErrorText)
    }
    return oldValue.call(this, {
      chainName: this.chainName,
      accountAddress,
      transport: getImmutableTransport(),
      ...args,
    })
  }
  return descriptor
}

export function verifyWallet(target, name, descriptor) {
  const oldValue = descriptor.value
  descriptor.value = async function (args) {
    const { accounts } = await fetchAuth()
    const { immutableCurrentBindAccount } = storeToRefs(useImmutableInfoStore())
    const accountAddress = immutableCurrentBindAccount.value
    if (!accountAddress) {
      throw new Error(insufficientBalanceErrorText)
    }
    bindAddressExist(accountAddress, accounts)
    return oldValue.call(this, args)
  }
  return descriptor
}
