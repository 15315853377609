import { computed } from 'vue'
import imxPointsImg from 'src/asset/IMX_Points.webp'
import luckyPointsImg from 'src/asset/Lucky_Points.webp'
import ustcPointsImg from 'src/asset/USTC_Points.webp'
import bogxPointsImg from 'src/asset/BOGX_Points.webp'
import { useAsyncState } from '@vueuse/core'
import { fetchSoftPledgeInfo } from 'src/api'
export function useSoftPledge() {
  const { state: infoRef } = useAsyncState(fetchSoftPledgeInfo)
  const erc20ListComputed = computed(() => {
    return [
      {
        name: 'BOGX Points',
        cover: bogxPointsImg,
        number: infoRef.value?.points?.bogx?.toLocaleString() || 0,
      },
      {
        name: 'IMX Points',
        cover: imxPointsImg,
        number: infoRef.value?.points?.imx?.toLocaleString() || 0,
      },
      {
        name: 'USDC Points',
        cover: ustcPointsImg,
        number: infoRef.value?.points?.ustc?.toLocaleString() || 0,
      },
    ]
  })
  const luckyPointsComputed = computed(() => {
    return {
      name: 'Lucky Points',
      cover: luckyPointsImg,
      number: infoRef.value?.points?.lucky?.toLocaleString() || 0,
    }
  })
  const nftListComputed = computed(() => {
    return (
      infoRef.value?.pledge || []
      // [
      //   {
      //     name: 'asdasd',
      //     type: 1,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 1,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 0,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 1,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 1,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 2,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 0,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 2,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 1,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 4,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 1,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 5,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 1,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 6,
      //   },
      //   {
      //     name: 'asdasd',
      //     type: 0,
      //     time: 'asd asd',
      //     integral: 123,
      //     next_hour: 2,
      //     token: 6,
      //   },
      // ]
    )
  })
  return {
    erc20ListComputed,
    luckyPointsComputed,
    nftListComputed,
  }
}
