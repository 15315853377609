<script setup>
import Modal from 'src/components/Modal'
import closeImg from 'src/asset/close.webp'
const emit = defineEmits(['update:show'])
const props = defineProps({
  title: String,
})
function handleShow(b) {
  emit('update:show', b)
}
</script>
<template>
  <Modal @update:show="handleShow">
    <div
      class="bg-#040405 border border-solid border-#253D48 px-7 pt-4.5 pb-7 w-85vw item_container"
    >
      <div
        class="fz-7 mb-4.5 tracking-1 ml-4 flex items-center justify-between"
      >
        <span>
          {{ title }}
        </span>
        <img
          :src="closeImg"
          @click="handleShow(false)"
          class="w-24px mouse_pointer"
        />
      </div>
      <slot></slot>
    </div>
  </Modal>
</template>
