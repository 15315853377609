<script setup>
import { NInput, useMessage } from 'naive-ui'
import { computed } from 'vue'
import { useCountdown } from 'src/hooks/useCountdown'
import { fetchEmailCode } from 'src/api'
import { useAsyncState } from '@vueuse/core'
import { codeToMessage } from 'src/constant'

const props = defineProps([
  'value',
  'countdownKey',
  'disabled',
  'email',
  'codeType',
])
const emit = defineEmits(['update:value'])
const message = useMessage()
const { initStart, timeRef } = useCountdown(props.countdownKey)
const { isLoading, execute } = useAsyncState(
  async () => {
    await fetchEmailCode({
      mail: props.email,
      codeType: props.codeType,
    })
    initStart(60)
  },
  null,
  {
    immediate: false,
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
  },
)
const disabledRef = computed(
  () => isLoading.value || timeRef.value || props.disabled,
)

function handleChange(value) {
  emit('update:value', value)
}
function handleCountdown() {
  if (disabledRef.value) return
  execute()
}
</script>

<template>
  <div class="w-full flex">
    <NInput
      class="mr-2"
      :value="props.value"
      :maxlength="6"
      placeholder="Code"
      @update:value="handleChange"
    />
    <div
      class="item-btn-gray h-[34px] w-[90.5px] flex-shrink-0 md:h-[38px] md:w-[153px]"
      :class="{ disabled: disabledRef }"
      @click="handleCountdown"
    >
      {{ timeRef || 'Send Code' }}
    </div>
  </div>
</template>
