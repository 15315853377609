<script setup>
import Video from 'src/components/Video'
import Header from './components/Header/index.vue'
import Panel from './components/Panel.vue'
import LuckyWheel from './components/LuckyWheel.vue'
import InventoryBox from './components/InventoryBox.vue'
import CheckinBox from './components/CheckinBox.vue'

const LuckyWheelVideo =
  import.meta.env.VITE_FILE_PATH + '/Lucky-Wheel.mp4'
</script>
<template>
  <div class="wrap bg-#1e1e1e item_container">
    <Video
      class="fixed left-0 top-0 w-full h-full"
      :src="LuckyWheelVideo"
      :controls="false"
      autoplay
      loop
      muted
    ></Video>
    <Header></Header>
    <div class="main flex">
      <Panel></Panel>
      <div class="fixed z-1 flex-center bottom-0 xl:left-19.6% 3xl:left-20.8% 4xl:left-22%">
        <LuckyWheel
        ></LuckyWheel>
      </div>
      <div class="absolute top-58px -right-10px z-1">
        <router-link :to="{ name: 'Inventory' }">
          <InventoryBox></InventoryBox>
        </router-link>
        <CheckinBox class="mt-54px"></CheckinBox>
      </div>
      <img class="fixed right-0 top-0 w-668px opacity-90" src="src/asset/softPledge/right_shawod.webp"></img>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url('/src/asset/softPledge/bg.webp') no-repeat;
  background-size: 100% 100%;

  .main {
    position: relative;
    height: calc(100vh - var(--header-height));
  }
}

.video-js .vjs-tech {
  object-fit: fill !important;
}
</style>
