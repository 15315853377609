import { useStorage, useTimeoutFn } from '@vueuse/core'
import { computed } from 'vue'

export function useCountdown(key, interval = 1000) {
  const endTimeRef = useStorage(`useCountdown_${key}_end`, 0)
  const currentTimeRef = useStorage(`useCountdown_${key}_current`, 0)
  // 剩余时间
  const timeRef = computed(() => {
    return Math.floor((endTimeRef.value - currentTimeRef.value) / interval)
  })
  const { isPending, start, stop } = useTimeoutFn(
    function () {
      // 剩余时间
      const remainingTime = endTimeRef.value - currentTimeRef.value
      if (remainingTime > 0) {
        start()
        currentTimeRef.value += interval
      } else {
        stop()
        initialize()
      }
    },
    interval,
    {
      immediate: false,
    },
  )
  function initialize() {
    currentTimeRef.value = 0
    endTimeRef.value = 0
  }
  // 启动
  function initStart(time) {
    const value = Date.now()
    currentTimeRef.value = value
    endTimeRef.value = value + time * interval
    stop()
    start()
  }
  function init() {
    if (endTimeRef.value - Date.now() > 0) {
      currentTimeRef.value = Date.now()
      start()
    } else {
      endTimeRef.value = 0
      currentTimeRef.value = 0
    }
  }

  init()

  return {
    endTimeRef,
    start,
    initStart,
    isPending,
    timeRef,
    init,
  }
}
