<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useMessage } from 'naive-ui'
import Modal from 'src/components/Modal'
import { ReceiveJobItemMap } from 'src/constant'
import closeImg from 'src/asset/close.webp'
import helaWebp from 'src/asset/immutableIncome/hela.webp'
import chaosWebp from 'src/asset/immutableIncome/chaos.webp'
import estherWebp from 'src/asset/immutableIncome/esther.webp'
import { storeToRefs } from 'pinia'

const emit = defineEmits(['update:show', 'confirm'])
const props = defineProps({
  list: Array,
})
const message = useMessage()

const roleInfoList = [
  {
    name: 'USERNAME',
    flag: 'Name',
  },
  {
    name: 'CLASS',
    flag: 'Job',
  },
  {
    name: 'UID',
    flag: 'UserID',
  },
  {
    name: 'LEVEL',
    flag: 'TopLevel',
  },
  {
    name: 'SERVER',
    flag: 'ServerName',
  },
]

const JobImageMap = {
  1003: helaWebp,
  1006: estherWebp,
  1009: chaosWebp,
}

const activeIndex = ref(-1)
function handleSelect(index) {
  console.log(index)
  activeIndex.value = index
}

function handleShow(b) {
  emit('update:show', b)
  activeIndex.value = -1
}

function handleConfirm(data) {
  if (data === -1) {
    message.warning('Please choose your  hero.')
    return
  }
  emit('confirm', data)
  handleShow(false)
}

const scrollContainer = ref(null)
const isAtStart = ref(true)
const isAtEnd = ref(false)

function handleScrollLeft() {
  const clientWidth = scrollContainer.value.clientWidth
  scrollContainer.value.scrollBy({
    left: -clientWidth - 95,
    behavior: 'smooth',
  })
}

function handleScrollRight() {
  const clientWidth = scrollContainer.value.clientWidth
  scrollContainer.value.scrollBy({ left: clientWidth + 95, behavior: 'smooth' })
}

function handleScroll() {
  const scrollDistance = scrollContainer.value.scrollLeft
  const maxScrollLeft =
    scrollContainer.value.scrollWidth - scrollContainer.value.clientWidth
  if (scrollDistance >= maxScrollLeft) {
    isAtEnd.value = true
  } else {
    isAtEnd.value = false
  }
  if (scrollDistance === 0) {
    isAtStart.value = true
  } else {
    isAtStart.value = false
  }
}
</script>
<template>
  <Modal @update:show="handleShow">
    <div
      class="bg-#050404 relative border border-solid border-#483425 px-8 pt-5 pb-7 md:w-1400px 2xl:w-1632px item_container"
    >
      <div
        class="fz-7 mb-4.5 tracking-1 ml-4 flex items-center justify-between"
      >
        <span class="family_blk text-36px flex-1 flex-center">
          CHOOSE YOUR HERO
        </span>
        <img
          :src="closeImg"
          @click="handleShow(false)"
          class="w-24px mouse_pointer"
        />
      </div>
      <div
        class="w-full h-10px bg-#312D2A border border-solid border-#686561"
      ></div>
      <div
        class="w-full md:px-94px 2xl:px-210px relative md:h-auto 2xl:h-760px 5xl:h-900px pb-20px bg-#1D1815/70 border border-t-0 border-solid border-#5C534D overflow-y-auto"
      >
        <div v-if="list.length > 3">
          <div
            class="mouse_pointer arrow-left absolute left-50px top-38%"
            :class="isAtStart ? 'bg-#201A18' : 'bg-#685E51'"
            @click="handleScrollLeft"
          ></div>
          <div
            class="mouse_pointer arrow-right absolute right-50px top-38%"
            :class="isAtEnd ? 'bg-#201A18' : 'bg-#685E51'"
            @click="handleScrollRight"
          ></div>
        </div>
        <div
          class="md:min-h-650px 2xl:min-h-680px 5xl:min-h-800px flex flex-items-center"
        >
          <ul
            ref="scrollContainer"
            @scroll="handleScroll"
            :class="{ 'justify-center': list.length < 4 }"
            class="md:scale-80 2xl:scale-100 origin-50% scroll-container relative w-full py-20px flex flex-items-center"
          >
            <li
              class="box w-329.5px px-7px pt-10px"
              :class="{ 'mr-97px': index !== list.length - 1 }"
              @click="handleSelect(index)"
              v-for="(item, index) in list"
              :key="item?.id"
            >
              <div
                :class="{ active: index === activeIndex }"
                class="mouse_pointer flex pt-7px -mt-20px flex-col flex-items-center -mt-10px w-full border-1.5px border-solid border-#59443E bg-#090603"
              >
                <img
                  class="mx-7px w-285px h-315.5px border border-solid border-#5F574E"
                  :src="JobImageMap[item.Job]"
                  alt=""
                />
                <div
                  class="bg-item pt-13px pb-20px px-6px fz-3 w-full flex flex-col flex-items-center mt-8px border border-x-0 border-solid border-#59443E"
                >
                  <div
                    :class="{
                      'server-item': roleItem.name === 'SERVER',
                    }"
                    class="w-full flex flex-items-baseline h-26px text-#856E55 border-0 border-b border-solid border-#3B2E22 last:border-b-0"
                    v-for="roleItem in roleInfoList"
                  >
                    <div class="w-50% pl-24px">{{ roleItem.name }}</div>
                    <div
                      :style="{
                        color: roleItem.name === 'CLASS' ? '#fff' : '#FFE1A1',
                      }"
                      class="w-50% pl-24px text-#FFE1A1 ellipsis-1"
                    >
                      {{
                        roleItem.name === 'CLASS'
                          ? ReceiveJobItemMap[item[roleItem.flag]]
                          : item[roleItem.flag]
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div
        @click="handleConfirm(activeIndex)"
        class="btn-confirm mouse_pointer fz-5 flex-center absolute left-50% bottom-90px -translate-x-50% w-276px h-56px rounded-5px border border-solid border-#C9771F"
      >
        Confirm
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.box {
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 100%) 0%,
    rgb(6 4 4 / 63%) 86.43%,
    rgb(22 18 16 / 63%) 100%
  );

  .bg-item {
    background: linear-gradient(180deg, #331e0a 0%, #1b1208 100%);
  }

  .server-item {
    @include bg100('src/asset/immutableIncome/bg_server.webp');
  }
}

.btn-confirm {
  background: linear-gradient(90deg, #9b4c29 0%, #a55e2e 100%);

  &:hover {
    background: linear-gradient(90deg, #c36a43 0%, #d0733c 100%);
  }
}

.arrow-left {
  width: 85px;
  height: 85px;
  clip-path: polygon(100% 0%, 60% 50%, 100% 100%);
}

.arrow-right {
  width: 85px;
  height: 85px;
  clip-path: polygon(0% 0%, 40% 50%, 0 100%);
}

.active {
  @apply border-1.5px border-solid border-#fff;
}

.scroll-container {
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
</style>
