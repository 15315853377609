<script setup>
import { NEmpty } from 'naive-ui'
import { ref, computed } from 'vue'
import Table from './components/Table'
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { useNFTCards, selectTheme } from './index'
import ItemModal from 'src/components/ItemModal'
import { useMessage, NSelect } from 'naive-ui'
const message = useMessage()
const roleListRef = useRoleList()
const { listRef, loadingEditRef, executeBind, executeUnbind } = useNFTCards()
const confirmModelRef = ref(false)
const currentItemRef = ref(null)
async function handleOperate(item) {
  if (!item.roleId) {
    message.warning('Please select a hero.')
    return
  }
  currentItemRef.value = { ...item }
  confirmModelRef.value = true
}
async function handleConfirm() {
  const item = currentItemRef.value
  if (item.operateType) {
    await executeUnbind(0, item.type, item.data.tokenId, item.roleId, item.id)
  } else {
    await executeBind(0, item.type, item.data.tokenId, item.roleId)
  }
  confirmModelRef.value = false
}
const roleListComputed = computed(() => {
  return roleListRef.value.map(item => ({
    label: item.value ? item.label + '-' + item.ServerName : item.label,
    value: item.value,
  }))
})
</script>
<template>
  <div class="md:ml-290px item_tracking">
    <div class="border border-r-0 border-solid border-#DCAC81">
      <Table class="table">
        <thead>
          <tr>
            <th><div class="text-center">NFT OPERATE</div></th>
            <th><div class="text-center">ITEM</div></th>
            <th><div class="text-center">NUMBER</div></th>
            <th><div class="text-center">HERO</div></th>
            <th><div class="text-center">STATE</div></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="item in listRef" :key="item.type + item.data.id">
            <tr>
              <td class="w-150px">
                <div class="flex-center">
                  <img
                    :src="item.data.image"
                    class="w-40px h-40px border border-solid border-#587277"
                    alt=""
                  />
                </div>
              </td>
              <td>
                <div class="text-center">
                  {{ item.name }}
                </div>
              </td>
              <td class="w-150px">
                <div class="text-center">#{{ item.data.tokenId }}</div>
              </td>
              <td class="w-300px">
                <div class="flex-center">
                  <NSelect
                    :show-checkmark="false"
                    v-model:value="item.roleId"
                    :options="roleListComputed"
                    :theme-overrides="selectTheme"
                  ></NSelect>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <span class="text-#A39883" v-if="item.coolingTimeText"
                    >Rights cooling down ({{ item.coolingTimeText }})</span
                  >
                  <span class="text-#52D058" v-else-if="item.operateType"
                    >Rights inuse</span
                  >
                  <span class="text-#FD8E4B" v-else
                    >Rights awaiting redemption</span
                  >
                </div>
              </td>
              <td class="w-154px">
                <div
                  class="w-154px h-19px flex-center"
                  :class="`${item.operateType ? 'unbind_btn' : 'bind_btn'} ${item.coolingTimeText ? 'cursor-no-allowed' : 'mouse_pointer'}`"
                  @click="!item.coolingTimeText && handleOperate(item)"
                >
                  {{ item.operateType ? 'Unbind Rights' : 'Bind Rights' }}
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </Table>
      <div v-if="!listRef.length" class="h-40vh flex-center">
        <NEmpty></NEmpty>
      </div>
    </div>
    <ItemModal
      v-model:show="confirmModelRef"
      @confirm="handleConfirm"
      :loading="loadingEditRef"
    >
      <div class="text-center fz-4 py-8">
        Confirm {{ currentItemRef.operateType ? 'unbinding' : 'Binding' }}?
      </div>
    </ItemModal>
  </div>
</template>

<style scoped lang="scss">
.table {
  ::v-deep {
    th {
      border-bottom: 1px solid #dcac81;
    }
  }
}

.bind_btn {
  @include bg100('src/asset/nftCards/bind_btn.webp');
}

.unbind_btn {
  @include bg100('src/asset/nftCards/unbind_btn.webp');
}
</style>
