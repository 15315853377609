<script setup></script>
<template>
  <div class="box"></div>
</template>

<style scoped lang="scss">
.box {
  @include bg100('src/asset/nftSoftStaking/deck/not/card.webp');
}
</style>
