<script setup>
import Icon_ETH from 'src/asset/PFP/mint/Icon_ETH.webp'
import { storeToRefs } from 'pinia'
import { useMediaStore } from 'src/stores/media'
const { min2XL } = storeToRefs(useMediaStore())
</script>
<template>
  <div class="text-white">
    <img :src="Icon_ETH" class="2xl:w-22px 2xl:h-22px w-20px h-20px" alt="" />
    <div class="text-#fff family_blk inline-block">
      <span
        class="fz-6 ml-2 inline-block leading-none!"
        :class="`${min2XL ? 'fz-6' : 'fz-5'}`"
        >0.1</span
      >
      <span class="fz-4 leading-none!">ETH per NFT</span>
    </div>
  </div>
</template>
