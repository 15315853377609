<script setup>
import { onUnmounted } from 'vue'
import { NLayoutHeader } from 'naive-ui'
import { HOME_PAGE_NAME } from 'src/constant'
import { hasPhone } from 'src/stores/media'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
import UserInfoUtils from 'src/components/user/UserInfoUtils'
import GuideStep from './GuideStep'
import QuitGuid from './QuitGuid'
import { storeToRefs } from 'pinia'
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { startGuide, closeGuide, closeEmptyGuide } = nftSoftPledgeStore
const { guideShow, emptyGuideShow } = storeToRefs(nftSoftPledgeStore)
onUnmounted(() => {
  closeGuide()
})
</script>
<template>
  <NLayoutHeader
    class="header mb:px-6 relative border-0 border-b border-#363D40 border-solid"
    :class="`fz-${hasPhone ? 4 : 5}`"
  >
    <div
      :class="{ 'flex-sb': hasPhone }"
      class="header-box flex mb:border-b-0 md:h-full border-0 border-t border-#363D40 border-dashed pl-2.5 md:bg-#090D10 md:px-6"
    >
      <div
        class="flex items-center md:h-full w-280px border-0 border-r border-solid border-#363D40 md:p-0 py-2"
      >
        <router-link :to="{ name: HOME_PAGE_NAME }" class="flex-center">
          <img
            v-if="!hasPhone"
            src="/src/asset/labs_logo.webp"
            class="mb-7px h-[39px]"
            alt=""
          />
        </router-link>
      </div>
      <div
        class="title px-20px flex flex-items-center md:w-540px 3xl:w-776px h-full"
      >
        <img
          class="ml-10px md:w-360px 3xl:w-516px"
          src="src/asset/nftSoftStaking/title.webp"
          alt=""
        />
        <img
          @click="startGuide"
          class="mouse_pointer btn-guide ml-20px w-51.5px"
          src="src/asset/nftSoftStaking/question_icon.webp"
          alt=""
        />
      </div>
      <div class="flex items-center select-none" v-if="guideShow">
        <GuideStep class="ml-8 mr-16"></GuideStep>
        <QuitGuid @click="closeGuide"></QuitGuid>
      </div>
      <div class="flex items-center select-none" v-if="emptyGuideShow">
        <QuitGuid @click="closeEmptyGuide"></QuitGuid>
      </div>
    </div>
    <UserInfoUtils class="absolute right-30px bottom-3 z-2"></UserInfoUtils>
  </NLayoutHeader>
</template>

<style lang="scss" scoped>
@import './index';

.header {
  @include underMD {
    height: var(--header-phone-height);
  }

  @include minMD {
    height: var(--header-height);
  }

  .title {
    background: url('src/asset/nftSoftStaking/title_bg.webp') no-repeat;
    background-size: 100% 100%;

    .btn-guide {
      @apply hover:scale-110 transition-all;
    }
  }
}
</style>
