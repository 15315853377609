<script setup>
import { middleEllipsis } from 'src/sdk/blockchain'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import ToggleWallet from './ToggleWallet'
import { computed } from 'vue'
import okx from 'src/asset/socializeLinks/okx.webp'

const { userInfo } = storeToRefs(useUserInfoStore())
const addressComputed = computed(() => userInfo.value?.okxWallet)
</script>

<template>
  <ToggleWallet class="w-full" :address="addressComputed">
    <div class="flex-sb">
      <img :src="okx" alt="" class="align-middl h-13px md:h-[20px]" />
      <span class="gray2-color">{{
        addressComputed
          ? middleEllipsis(addressComputed, 10, -10)
          : 'Connect Wallet'
      }}</span>
      <span
        :class="`${
          addressComputed ? 'bg-[#57E077]' : 'bg-[#E73636]'
        } md:w-[8px] md:h-[8px] w-[6px] h-[6px] rounded-[50%]`"
      ></span>
    </div>
  </ToggleWallet>
</template>
