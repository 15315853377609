<script setup>
import ItemModal from 'src/components/ItemModal'

const props = defineProps({
  show: Boolean,
  name: String,
})
const emit = defineEmits(['update:show', 'confirm'])
function handleClose() {
  emit('update:show', false)
}
function handleConfirm() {
  emit('confirm', props.name)
  handleClose()
}
</script>

<template>
  <ItemModal
    :show="props.show"
    confirm-text="Continue"
    @update:show="handleClose"
    @cancel="handleClose"
    @confirm="handleConfirm"
  >
    <div class="pt-4">
      <img
        src="/src/asset/exclamation_mark.webp"
        class="m-a block h-[46px]"
        alt=""
      />
      <div class="fz-4 px-2 py-5 text-center">
        <div class="text-#CFDDE7">Confirm Binding {{ props.name }}?</div>

        <div class="text-red">
          Warning: Once {{ props.name }} is bound, it cannot be unbound.
        </div>
      </div>
    </div>
  </ItemModal>
</template>
