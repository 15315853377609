<script setup>
import { NEmpty } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'
import PreviewModal from 'src/components/PreviewModal'
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import { useSelectData } from 'src/pages/myCollection/hooks/useSelectData'

import { hasPhone } from 'src/stores/media'
import ItemBox from '../components/ItemBox.vue'
import Panel from '../components/Panel.vue'
import TransferModal from '../components/TransferModal'
import Empty from '../components/Empty'
import List from '../components/List'
import ItemDetail from '../components/ItemDetail'
import PanelHedaer from '../components/PanelHedaer'

const { assetsList } = storeToRefs(useUserInfoStore())

const roleListRef = useRoleList()
const {
  selectDataRef,
  itemDetailShowRef,
  singleChoiceDataRef,
  currentUserIDRef,
  previewSrcRef,
  transferShowRef,
  currentSelectDataRef,
  previewShowRef,
  handleSelect,
  handleCancel,
  handleEnlarge,
  handleClose,
  handleValidate,
  handleTransferSuccess,
  handleTransferError,
  handleCheck,
  handleEmptySelect,
  handleItemDetailClose,
} = useSelectData()
</script>

<template>
  <div class="h-full flex">
    <Panel
      v-if="!hasPhone"
      class="flex-shrink-0"
      :select-data="selectDataRef"
      :data="singleChoiceDataRef"
      :role-list="roleListRef"
      @submit="handleValidate"
    ></Panel>
    <PanelHedaer
      v-else
      :select-data="selectDataRef"
      :role-list="roleListRef"
      @empty="handleEmptySelect"
      @submit="handleValidate"
    ></PanelHedaer>
    <List v-if="assetsList.length" :list="assetsList">
      <template #item="item">
        <ItemBox
          :data="item"
          :select-data="selectDataRef"
          :single-choice-data="singleChoiceDataRef"
          :role-list="roleListRef"
          @check="handleCheck"
          @select="handleSelect"
          @cancel="handleCancel"
          @enlarge="handleEnlarge"
          @submit="handleValidate"
        ></ItemBox>
      </template>
    </List>
    <NEmpty v-else class="m-auto"></NEmpty>
    <Empty
      v-if="!hasPhone"
      v-show="selectDataRef.length"
      @click="handleEmptySelect"
    />
  </div>
  <ItemDetail
    v-if="hasPhone"
    v-show="itemDetailShowRef"
    :data="singleChoiceDataRef"
    :role-list="roleListRef"
    @close="handleItemDetailClose"
    @submit="handleValidate"
  ></ItemDetail>
  <PreviewModal
    v-if="!hasPhone"
    :show="previewShowRef"
    :src="previewSrcRef"
    @close="handleClose"
  ></PreviewModal>
  <TransferModal
    v-model:show="transferShowRef"
    :UserID="currentUserIDRef"
    :select-data="currentSelectDataRef"
    @success="handleTransferSuccess"
    @error="handleTransferError"
  ></TransferModal>
</template>

<style scoped lang="scss">
@import '../border';
</style>
