<script setup>
import Header from './components/Header/index.vue'
import { ref, computed, watch } from 'vue'
import { storeToRefs } from 'pinia'
import Panel from './components/Panel/index.vue'
import DeckPoints from './components/DeckPoints/index.vue'
import NFTPanel from './components/NFTPanel/index.vue'
import PFPCombination from './components/PFPCombination/index.vue'
import NFTModal from './components/NFTModal/index.vue'
import PFPModal from './components/NFTModal/PFPModal.vue'
import EmptyModal from './components/NFTModal/EmptyModal.vue'
import Step1 from './components/Guide/Step1.vue'
import Step2Left from './components/Guide/Step2Left.vue'
import Step2Right from './components/Guide/Step2Right.vue'
import Step3 from './components/Guide/Step3.vue'
import {
  useNFTSoftPledgeDomStore,
  useNFTSoftPledgeStore,
} from 'src/stores/softPledge'
import { useLoginStore } from 'src/stores/user'
const loginStore = useLoginStore()
const { open: login } = loginStore
const { hasLogin } = storeToRefs(loginStore)

const {
  deckList,
  activeDeckIndex,
  notDeck,
  guideShow,
  unAddNFTList,
  hasNFT,
  pfpList,
  emptyGuideShow,
  emptyGuideList,
} = storeToRefs(useNFTSoftPledgeStore())
const { mainDom, mainContainerLeftDom } = storeToRefs(
  useNFTSoftPledgeDomStore(),
)

const showNFTModal = ref(false)
const showEmptyModal = ref(false)
const showPFPModal = ref(false)
const mainDomRef = ref(null)
const mainContainerLeftDomRef = ref(null)

const deckData = computed(() => {
  return deckList.value?.[activeDeckIndex.value]
})

const emptyGuideData = computed(() => {
  return emptyGuideList.value?.[0]
})

function getActiveDeck(index) {}
async function handleAdd() {
  if (!hasLogin.value) {
    await login()
  }
  if (!hasNFT.value) {
    showEmptyModal.value = true
  } else {
    showNFTModal.value = true
  }
}

function handleEdit() {
  if (!hasNFT.value) {
    // showEmptyModal.value = true
    showPFPModal.value = true
  } else {
    showPFPModal.value = true
  }
}
watch([mainDomRef, mainContainerLeftDomRef], ([dom, leftDom]) => {
  if (dom) {
    mainDom.value = dom
  }
  if (leftDom) {
    mainContainerLeftDom.value = leftDom
  }
})
</script>
<template>
  <div class="wrap bg-#090D10 item_container">
    <Header></Header>
    <div
      class="main flex relative"
      :class="{ 'pointer-events-none': guideShow }"
    >
      <div class="relative h-full">
        <Panel @add="handleAdd" @active="getActiveDeck"></Panel>
        <Step2Left></Step2Left>
      </div>
      <div
        ref="mainDomRef"
        class="main-container flex relative p-1.37vw pb-0.2vw"
      >
        <div
          class="main-container-left w-61% h-full mr-2.5%"
          ref="mainContainerLeftDomRef"
        >
          <NFTPanel
            :notDeck="notDeck"
            :deckData="emptyGuideShow ? emptyGuideData : deckData"
          ></NFTPanel>
          <PFPCombination
            @edit="handleEdit"
            :list="emptyGuideShow ? emptyGuideData?.pfpList : deckData?.pfpList"
          ></PFPCombination>
        </div>
        <div class="main-container-right flex-1 h-full origin-tl relative">
          <DeckPoints
            :nftData="emptyGuideShow ? emptyGuideData?.nft : deckData?.nft"
            :pointsData="emptyGuideShow ? emptyGuideData?.data : deckData?.data"
          ></DeckPoints>
          <Step2Right></Step2Right>
        </div>
        <Step1></Step1>
      </div>
      <!-- <div v-else class="main-container p-0.5% pl-1% relative">

      </div> -->

      <Step3></Step3>
    </div>
    <NFTModal :list="unAddNFTList" v-model:show="showNFTModal"></NFTModal>
    <PFPModal :list="pfpList" v-model:show="showPFPModal"></PFPModal>
    <EmptyModal v-model:show="showEmptyModal"></EmptyModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .main {
    height: calc(100vh - var(--header-height));

    &-container {
      width: calc(100vw - 304px);
    }
  }
}
</style>
