<script setup>
import ItemModal from 'src/components/ItemModal'

const props = defineProps({
  show: Boolean,
  name: String,
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
function handleConfirm() {
  emit('confirm', props.name)
  handleClose()
}
</script>

<template>
  <ItemModal
    :show="props.show"
    confirm-text="Disconnect"
    @update:show="handleClose"
    @cancel="handleClose"
    @confirm="handleConfirm"
  >
    <div class="pt-4">
      <img
        src="/src/asset/exclamation_mark.webp"
        class="m-a block h-[46px]"
        alt=""
      />
      <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
        Are you sure you want to disconnect from {{ props.name }}?
      </div>
    </div>
  </ItemModal>
</template>
