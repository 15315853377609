import { useEventListener } from '@vueuse/core/index.cjs'
import { ref, onMounted } from 'vue'
export function useHeightFull(domRef) {
  const heightRef = ref(0)
  function onresize() {
    const dom = domRef.value
    if (dom) {
      const { bottom } = dom.getBoundingClientRect()
      heightRef.value = window.innerHeight - bottom
    } else {
      heightRef.value = 0
    }
  }
  useEventListener(window, 'resize', onresize)
  onMounted(onresize)
  return {
    heightRef,
  }
}
