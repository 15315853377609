import { config, passport, checkout, blockchainData } from '@imtbl/sdk'
import {
  createPublicClient,
  formatEther,
  getAddress,
  http,
  defineChain,
  custom,
} from 'viem'
import tBogxAbi from './blockchain/tBogx.abi.json'
import { getImmutable, loginWallet } from './blockchain'
// TODO 1: Passport Configuration
const url = location.origin
const environment =
  import.meta.env.VITE_MODE === 'prod'
    ? config.Environment.PRODUCTION
    : config.Environment.SANDBOX
const passportConfig = {
  clientId: import.meta.env.VITE_IMMUTABLE_CLIENT_ID,
  redirectUri: `${url}/redirect`,
  logoutRedirectUri: `${url}/logout`,
  logoutMode: 'silent',
  scope: 'transact openid offline_access email',
  audience: 'platform_api',
  baseConfig: new config.ImmutableConfiguration({
    environment,
    publishableKey: import.meta.env.VITE_PUBLISHABLE_KEY,
    apiKey: import.meta.env.VITE_APIKEY,
  }),
}
console.log(import.meta.env.VITE_MODE)

const passportInstance = new passport.Passport(passportConfig)
const passportProvider = passportInstance.connectEvm()

const checkoutSDK = new checkout.Checkout({
  baseConfig: {
    environment: environment,
  },
  bridge: { enable: true },
  onRamp: { enable: true },
  swap: { enable: true },
  passport: passportInstance,
  publishableKey: import.meta.env.VITE_PUBLISHABLE_KEY,
})

const blockchainDataClient = new blockchainData.BlockchainData({
  baseConfig: {
    environment: environment,
    publishableKey: import.meta.env.VITE_PUBLISHABLE_KEY,
  },
})

const widgets = await checkoutSDK.widgets({
  config: { theme: checkout.WidgetTheme.DARK, language: 'en' },
})

const connect = widgets.create(checkout.WidgetType.CONNECT, {})
const wallet = widgets.create(checkout.WidgetType.WALLET, {})
const bridge = widgets.create(checkout.WidgetType.BRIDGE, {
  config: { theme: checkout.WidgetTheme.DARK },
})
const swap = widgets.create(checkout.WidgetType.SWAP, {
  config: { theme: checkout.WidgetTheme.DARK },
})
const onramp = widgets.create(checkout.WidgetType.ONRAMP, {
  config: { theme: checkout.WidgetTheme.DARK },
})
const sale = widgets.create(checkout.WidgetType.SALE, {
  config: { theme: checkout.WidgetTheme.DARK },
})

async function fetchAuth() {
  try {
    const accounts = await loginWallet(getImmutable())
    const [userProfile, accessToken] = await Promise.all([
      passportInstance.getUserInfo(),
      passportInstance.getAccessToken(),
    ])
    return {
      userProfile,
      accessToken,
      accounts,
    }
  } catch (error) {
    Promise.reject(error.message)
  }
}

async function loginOut() {
  try {
    await passportInstance.logout()
    await passportInstance.logoutSilentCallback(url)
  } catch (error) {
    console.log(error)
  }
}
// window.loginOut = loginOut
// TODO 3: Initiate Transaction
// async function initiateTransaction() {
//   try {
//     const accounts = await passportProvider.request({
//       method: 'eth_requestAccounts',
//     })

//     const params = {
//       to: accounts[0],
//       data: ethers.utils.hexlify(ethers.utils.toUtf8Bytes('')),
//     }

//     const txnHash = await passportProvider.request({
//       method: 'eth_sendTransaction',
//       params: [params],
//     })
//     console.log(txnHash)

//     return {
//       txnHash,
//       error: null,
//     }
//   } catch (error) {
//     console.log('err', error.message)
//     return {
//       txnHash: null,
//       error: error.message,
//     }
//   }
// }

async function getTBogx({ account, chainId, contractAddress, provider }) {
  const immutableChain = defineChain({
    id: chainId,
    rpcUrls: {
      default: {
        http: [provider],
      },
    },
  })
  try {
    const publicClient = createPublicClient({
      chain: immutableChain,
      transport: http(),
    })
    const params = {
      address: getAddress(contractAddress),
      abi: tBogxAbi,
    }
    const data = await publicClient.readContract({
      ...params,
      functionName: 'balanceOf',
      args: [account],
    })
    return formatEther(data)
  } catch (error) {
    console.log(error)
  }
}

function getImmutableTransport() {
  return custom(passportProvider)
}
export {
  passportInstance,
  passportProvider,
  checkoutSDK,
  connect,
  wallet,
  bridge,
  swap,
  onramp,
  sale,
  widgets,
  fetchAuth,
  loginOut,
  getTBogx,
  getImmutableTransport,
  blockchainDataClient,
}
