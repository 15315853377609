import {
  getCodeModel,
  getCodeRules,
  getEmailModel,
  getEmailRules,
} from '../common'

export function getEmailLoginModel() {
  return {
    ...getEmailModel(),
    ...getCodeModel(),
  }
}

export function getEmailLoginRules() {
  return {
    ...getEmailRules(),
    ...getCodeRules(),
  }
}
