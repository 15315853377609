<script setup>
import header_arrow from 'src/asset/softPledge/inventory/header_arrow.webp'
import red_spot from 'src/asset/softPledge/inventory/red_spot.webp'
import { useRedSpot } from './useRedSpot'
const { handleClick: handleClickRedSpot, showRedSpotRef } = useRedSpot()
</script>
<template>
  <div class="relative box mouse_pointer" @click="handleClickRedSpot">
    <div class="left_box absolute">
      <div class="line_left_animation1">
        <div class="line line1 line_left1"></div>
      </div>
      <div class="line_left_animation2">
        <div class="line line1 line_left2"></div>
      </div>
      <div class="line_left_animation1">
        <div class="line line2 line_left3"></div>
      </div>
      <div class="line_left_animation2">
        <div class="line line2 line_left4"></div>
      </div>
      <div class="line_left_animation1">
        <div class="line line3 line_left5"></div>
      </div>
      <div class="line_left_animation2">
        <div class="line line4 line_left6"></div>
      </div>
    </div>
    <div class="main_box absolute -top-14px">
      <img :src="header_arrow" class="arrow" />
      <img
        :src="red_spot"
        v-if="showRedSpotRef"
        class="w-15px absolute top-19% right-10%"
      />
    </div>
    <div class="right_box absolute">
      <div class="line_right_animation1">
        <div class="line line1 line_right1"></div>
      </div>
      <div class="line_right_animation2">
        <div class="line line1 line_right2"></div>
      </div>
      <div class="line_right_animation1">
        <div class="line line2 line_right3"></div>
      </div>
      <div class="line_right_animation2">
        <div class="line line2 line_right4"></div>
      </div>
      <div class="line_right_animation1">
        <div class="line line3 line_right5"></div>
      </div>
      <div class="line_right_animation2">
        <div class="line line4 line_right6"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.box {
  --main-width: 554px;

  .main_box {
    @include bg100('src/asset/softPledge/inventory/events.webp');
    @apply h-115px;

    width: var(--main-width);
  }

  .arrow {
    position: absolute;
    top: 50%;
    right: 15.5%;
    width: 13px;
    transform: translate(0, -50%);
  }

  .line {
    @apply w-8px h-91px absolute;
  }

  .line1 {
    @apply bg-#A37436 border-#EBAA56 border-solid border;
  }

  .line2 {
    @apply bg-#956F3D border-#CA9148 border-solid border;
  }

  .line3 {
    @apply bg-#836237;
  }

  .line4 {
    @apply bg-#836237 opacity-50;
  }

  .left_box {
    top: -2px;
    left: -23px;

    .line {
      @apply skew-x-55;
    }

    .line_left_animation1 {
      @apply absolute;

      animation: line-left1 2s infinite;
    }

    @keyframes line-left1 {
      0% {
        transform: translate(0);
      }

      50% {
        transform: translate(-30px, -22px);
      }

      100% {
        transform: translate(0);
      }
    }

    .line_left_animation2 {
      @apply absolute;

      transform: translate(-30px, -22px);
      animation: line-left2 2s infinite;
    }

    @keyframes line-left2 {
      0% {
        transform: translate(-30px, -22px);
      }

      50% {
        transform: translate(0);
      }

      100% {
        transform: translate(-30px, -22px);
      }
    }

    .line_left1 {
      top: -25px;
      left: 0;
    }

    .line_left2 {
      top: -25px;
      left: -12px;
    }

    .line_left3 {
      top: -45px;
      left: -52px;
    }

    .line_left4 {
      top: -45px;
      left: -64px;
    }

    .line_left5 {
      top: -65px;
      left: -104px;
    }

    .line_left6 {
      top: -65px;
      left: -116px;
    }
  }

  .right_box {
    top: -2px;
    left: calc(var(--main-width) + 15px);

    .line {
      @apply -skew-x-55;
    }

    .line_right_animation1 {
      @apply absolute;

      animation: line-right1 2s infinite;
    }

    @keyframes line-right1 {
      0% {
        transform: translate(0);
      }

      50% {
        transform: translate(30px, -22px);
      }

      100% {
        transform: translate(0);
      }
    }

    .line_right_animation2 {
      @apply absolute;

      transform: translate(30px, -22px);
      animation: line-right2 2s infinite;
    }

    @keyframes line-right2 {
      0% {
        transform: translate(30px, -22px);
      }

      50% {
        transform: translate(0);
      }

      100% {
        transform: translate(30px, -22px);
      }
    }

    .line_right1 {
      top: -25px;
      left: 0;
    }

    .line_right2 {
      top: -25px;
      left: 12px;
    }

    .line_right3 {
      top: -45px;
      left: 52px;
    }

    .line_right4 {
      top: -45px;
      left: 64px;
    }

    .line_right5 {
      top: -65px;
      left: 104px;
    }

    .line_right6 {
      top: -65px;
      left: 116px;
    }
  }

  &:hover {
    .arrow {
      animation: move 1s infinite;
    }
  }

  @keyframes move {
    0% {
      transform: translate(0, -50%);
    }

    50% {
      transform: translate(30%, -50%);
    }

    100% {
      transform: translate(0, -50%);
    }
  }
}
</style>
