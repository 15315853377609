import { SPECIES, SPECIES_LIST } from 'src/constant'
// {
//   "name": "Froster Hunter #1",
//   "tokenId": 1,
//   "animation_url": "https://bogx-dev.bladeofgod.net//static/nft_video/NFT-LionFace1.mp4",
//   "image": "https://bogx-dev.bladeofgod.net//static/nft_video/NFT-LionFace1.webp",
//   "attributes": [
//     {
//       "trait_type": "rarity",
//       "value": "RARE"
//     }
//   ]
// }
// https://voidlabs.bladeofgod.net/webres/gnft/id
import { ATTRIBUTES, nftList } from 'src/constant/nft'

export function adaptationNFTToWeb(nftData) {
  const webNFT = nftList.find(
    nft => nft.quality.text.toUpperCase() === nftData[ATTRIBUTES][0].value,
  )
  return {
    ...JSON.parse(JSON.stringify(webNFT)),
    ...nftData,
    link: nftData.animation_url,
    // cover: nftData.image,
  }
}
// pfp种族对比
export function hasPFPSpecies(item, speciesType = 0) {
  return item[SPECIES] === SPECIES_LIST[speciesType]
}
