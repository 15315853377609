import { fetchWalletaddr, fetchUnbindWalletaddr } from 'src/api'
import { getBaseWalletSign } from 'src/helpers/blockchain'
import { fetchUserInfoP } from 'src/helpers/user'
import { bindAddressExist } from 'src/sdk/blockchain'
import {
  getOKX,
  loginWallet,
} from '../../sdk/blockchain/newBlockchain/base/walletConnect'
import { hasInstallOKX, walletOKX } from '@abmao/wallet-app'
export class ApiOKX {
  async bindWallet() {
    if (hasInstallOKX()) return
    const accounts = await loginWallet(getOKX())
    if (accounts && accounts.length) {
      const account = accounts[0]
      const web3Signed = await getBaseWalletSign(account)
      await fetchWalletaddr({
        walletaddr: accounts[0],
        web3Signed,
        walletType: walletOKX.name,
      })
      await fetchUserInfoP.fetch()
    }
  }
  async unbindWallet(account) {
    if (hasInstallOKX()) return
    const accounts = await loginWallet(getOKX())
    if (accounts && accounts.length) {
      bindAddressExist(account, accounts)
      const web3Signed = await getBaseWalletSign(account)
      await fetchUnbindWalletaddr({
        walletaddr: account,
        web3Signed,
        walletType: walletOKX.name,
      })
      await fetchUserInfoP.fetch()
    }
  }
}

export const apiOKX = new ApiOKX()
