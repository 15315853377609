import {
  baseTokenStorage,
  requestBase,
  requestWall,
} from 'src/helpers/request/index'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { signatureDataStorage } from 'src/helpers/store'
import { getAddress } from 'viem'
/**
 * 登陆
 * @param {*} params {accountName,password}
 * @returns
 */
export async function fetchLogin(params) {
  const data = await requestWall({
    params: {
      ...params,
      code: 42,
    },
  })
  baseTokenStorage.value = data.token
  return data
}

/**
 * 邮箱登录
 * @param {*} params {mail: 邮箱账号,verifyCode}
 * @returns
 */
export async function fetchEmailLogin(params) {
  const data = await requestWall({
    params: {
      ...params,
      code: 54,
    },
  })
  baseTokenStorage.value = data.token
  return data
}

/**
 * 获取用户信息
 * @param {*} params
 * @returns
 */
export async function fetchUserInfo() {
  const { userInfo } = storeToRefs(useUserInfoStore())
  const data = await requestWall({
    params: {
      code: 50,
    },
  })
  // if (!data.wallet) delete data.wallet
  userInfo.value = data
  return data
}

/**
 * 根据token请求登录验证
 * @param {*} params {AccountId}
 * @returns
 */
export function fetchValidToken(params) {
  return requestBase({
    params: {
      ...params,
      code: 43,
    },
  })
}

/**
 * 注册
 * @param {*} params {accountName,password,partnerid}
 * @returns
 */
export function fetchRegister(params) {
  return requestBase({
    params: {
      accountType: 'PGSoul_YouKe',
      version: '1.5.1',
      gameName: 'gof',
      isYouke: 0,
      ...params,
      code: 45,
    },
  })
}

/**
 * 改密码
 * @param {*} params {AccountId,pwd}
 * @returns
 */
export function fetchChangePassword(params) {
  return requestBase({
    params: {
      ...params,
      code: 46,
    },
  })
}
/**
 * 绑定&替换钱包
 * @param {*} params {AccountId,walletaddr,walletType: 'okx'}
 * @returns
 */
export function fetchWalletaddr(params) {
  return requestBase({
    params: {
      ...params,
      code: 44,
    },
  })
}
/**
 * 解绑
 * @param {*} params {AccountId}
 * @returns
 */
export function fetchUnbindWalletaddr(params) {
  return requestBase({
    params: {
      ...params,
      code: 55,
    },
  }).then(res => {
    signatureDataStorage.removeItem(getAddress(params.walletaddr))
    return res
  })
}

/**
 * 账号注册、绑定、登录 邮箱发送验证码
 * @param {*} params {mail: 邮箱账号,codeType:1 注册,2 登录,3 绑定}
 * @returns
 */
export function fetchEmailCode(params) {
  return requestBase({
    params: {
      ...params,
      code: 52,
    },
  })
}

/**
 * 绑定邮箱
 * @param {*} params {mail: 邮箱账号,codeType:1 注册,2 登录,3 绑定}
 * @returns
 */
export function fetchBindEmail(params) {
  return requestBase({
    params: {
      ...params,
      code: 53,
    },
  })
}

/**
 * immutable授权登录
 * @param {*} params {token}
 * @returns
 */
export async function fetchImmutableLogin(params) {
  const data = await requestWall({
    method: 'post',
    data: {
      ...params,
      channel_name: 'bogxweb',
      third_type: 'bogximx',
      type: 'ThirdPartyLogin',
    },
  })
  baseTokenStorage.value = data.token
  return data
}

/**
 * immutable绑定
 * @param {*} params {token}
 * @returns
 */
export function fetchImmutableBind(params) {
  return requestBase({
    method: 'post',
    data: {
      third_type: 'bogximx',
      ...params,
      channel_name: 'bogxweb',
      type: 'AccountBinding',
    },
  })
}
