export const HOME_NAME = 'Home'
export const NOT_404_NAME = '404'
export const COLLECTION_NAME = 'collection'
export const MY_COLLECTION_NAME = 'myCollection'
export const MY_COLLECTION_ALL_NAME = 'myCollectionAll'
export const MY_COLLECTION_ASSETS_NAME = 'myCollectionAssets'
export const MY_COLLECTION_NFTS_NAME = 'myCollectionNFTs'
export const MY_COLLECTION_TRANSFER_NAME = 'myCollectionTransferHistory'
export const IMMUTABLE_COLLECTION_NAME = 'immutableCollection'
export const IMMUTABLE_COLLECTION_ASSETS_NAME = 'immutableCollectionAssets'
export const IMMUTABLE_COLLECTION_MARKETPLACE_NAME =
  'immutableCollectionMarketplace'
export const MINT_NAME = 'mint'
export const PFP_NAME = 'PFP'
export const PFP_MINT_NAME = 'PFPMint'
export const PFP_WHITELIST_NAME = 'PFPMintWhiteList'
export const PFP_ETHEREAL_NAME = 'PFPEthereal'
export const BOSS_INTRODUCE_NAME = 'bossIntroduce'
export const REDIRECT_NAME = 'Callback'
export const SOFT_PLEDGE = 'softPledge'
export const INVENTORY = 'Inventory'
export const BIND_WALLET = 'BindWallet'
export const NFT_SOFT_STAKING = 'nftSoftStaking'
export const NFT_CARDS = 'nftCards'
export const SOUL_CARD_SALE = 'soulCardSale'
export const SOUL_CARD_MINT = 'soulCardMint'
export const IMMUTABLE_INCOME = 'immutableIncome'
export const GAME_BANEFITS_NAME = 'gameBanefits'
export const NFT_BANEFITS = 'nftBanefits'
export const MARKET_NAME = 'market'
export const WEB3_ROUTERS = [
  MY_COLLECTION_ALL_NAME,
  MY_COLLECTION_NFTS_NAME,
  MINT_NAME,
  MY_COLLECTION_ASSETS_NAME,
  MY_COLLECTION_TRANSFER_NAME,
]
export const PFP_ROUTERS = [PFP_MINT_NAME, PFP_ETHEREAL_NAME]
