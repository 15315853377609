<script setup>
import { onUnmounted, ref, watch } from 'vue'
import { useMessage } from 'naive-ui'
import Modal from './Modal.vue'
import Search from './Search.vue'
import WalletItem from './WalletItem.vue'
import { getPFPMintInfo } from '../index'
import { watchAccount } from '@wagmi/core'
import { accountRef, hasConnectWalletRef } from 'src/sdk/blockchain'
import { isAddress } from 'viem'

const searchValueRef = ref('')
const genesisRef = ref(false)
const mintInfo = ref(null)

watch(
  hasConnectWalletRef,
  val => {
    if (val) {
      searchValueRef.value = accountRef.value?.address
    } else {
      searchValueRef.value = ''
    }
  },
  {
    immediate: true,
  },
)

const watchAccountStop = watchAccount(() => {
  searchValueRef.value = accountRef.value?.address
})

function handleSearch(val) {
  searchValueRef.value = val
}
const message = useMessage()
async function checkWhiteList() {
  if (!isAddress(searchValueRef.value)) {
    message.warning('Please enter a valid wallet address')
    return
  }
  const wallet = searchValueRef.value
  const { mintInfoRef, currentPhaseWLComputed } = await getPFPMintInfo(wallet)
  if (!currentPhaseWLComputed.value) {
    message.warning('You are not on the whitelist.')
    return
  }
  mintInfo.value = mintInfoRef.value
  genesisRef.value = true
}
onUnmounted(() => {
  watchAccountStop()
})
</script>

<template>
  <div class="relative flex flex-col">
    <img class="w-650px" src="/src/asset/mint/bogx.webp" alt="" />
    <img class="w-697px -mt-40px" src="/src/asset/mint/ethereal.webp" alt="" />
    <div class="family_blk text-#fff -mt-50px text-35px">
      COLLECTION WHITELIST CHECKER
    </div>
    <div v-if="hasConnectWalletRef" class="flex-sb item-li mouse_pointer">
      <WalletItem></WalletItem>
    </div>
    <Search
      v-else
      :value="searchValueRef"
      @input="handleSearch"
      class="mt-40px h-38px"
    ></Search>
    <div
      @click="checkWhiteList"
      class="mouse_pointer mt-50px w-696px h-38px flex-center bg-#FF7D31 border border-solid border-#FFC662 rounded-5px"
    >
      Check Your Whitelist for the Ethereal Collection
    </div>
  </div>
  <Modal v-model:show="genesisRef" :data="mintInfo"></Modal>
</template>

<style lang="scss" scoped>
.item-li {
  @apply mt-40px h-38px px-2 border-1 border-solid border-[#212224] bg-[#212224] rounded-5px;

  @include minMD {
    &:not(.item-li-not-hover):hover {
      @apply border-[#616568] text-white;
    }
  }
}
</style>
