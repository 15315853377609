<script setup>
import googleIcon from 'src/asset/logo/google_icon.webp'
import googleActivateIcon from 'src/asset/logo/google_activate_icon.webp'
import applyIcon from 'src/asset/logo/apply_icon.webp'
import applyActivateIcon from 'src/asset/logo/apply_activate_icon.webp'
import discordIcon from 'src/asset/logo/discord_icon.webp'
import discordActivateIcon from 'src/asset/logo/discord_activate_icon.webp'
import twitterIcon from 'src/asset/logo/twitter_icon.webp'
import twitterActivateIcon from 'src/asset/logo/twitter_activate_icon.webp'
import twitchIcon from 'src/asset/logo/twitch_icon.webp'
import twitchActivateIcon from 'src/asset/logo/twitch_activate_icon.webp'
import linkIcon from 'src/asset/logo/link_icon.webp'
import linkActivateIcon from 'src/asset/logo/link_activate_icon.webp'
import { ref, computed } from 'vue'
import OneLineModal from './OneLineModal'
import DiscoonectLineModal from './DiscoonectLineModal'
import { thirdPartyDiscord } from 'src/sdk/thirdParty'
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
const oneLineShowRef = ref(false)
const discoonectLineShowRef = ref(false)
const itemRef = ref({})
const { userInfo } = storeToRefs(useUserInfoStore())
const listComputed = computed(() => {
  return [
    // {
    //   icon: googleIcon,
    //   activateIcon: googleActivateIcon,
    //   name: 'Google',
    //   connect: true,
    // },
    // {
    //   icon: applyIcon,
    //   activateIcon: applyActivateIcon,
    //   name: 'Apply',
    // },
    {
      icon: discordIcon,
      activateIcon: discordActivateIcon,
      name: 'Discord',
      userName: userInfo.value?.bogximx_dicode?.username,
      bind: () => thirdPartyDiscord.goToCodeLink(),
      connect: !!userInfo.value?.bogximx_dicode,
    },
    // {
    //   icon: twitterIcon,
    //   activateIcon: twitterActivateIcon,
    //   name: 'Twitter',
    // },
    // {
    //   icon: twitchIcon,
    //   activateIcon: twitchActivateIcon,
    //   name: 'Twitch',
    // },
  ]
})
const loadingRef = ref(false)
function handleLinkAccount(item) {
  itemRef.value = item
  if (!item.connect) {
    oneLineShowRef.value = true
  }
  // if (thirdPartyDiscord.hasLogin) {
  //   oneLineShowRef.value = true
  // } else {
  //   discoonectLineShowRef = true
  // }
}
async function handleBind(name) {
  const thirdParty = listComputed.value.find(item => item.name === name)
  if (thirdParty) {
    loadingRef.value = true
    await thirdParty.bind()
    loadingRef.value = false
  }
}
</script>

<template>
  <div class="px-1 py-2" v-if="listComputed.length">
    <div class="fz-3 text-center">Link Accounts</div>
    <div class="fz-2 mb-1.5 text-center text-#7E858F">
      Choose the Social Networks you want to link with
    </div>
    <ul class="item-ul">
      <template v-for="item in listComputed" :key="item.name">
        <li
          class="flex-sb item-li rounded-6!"
          :class="{ activateLi: item.connect }"
          @click="handleLinkAccount(item)"
        >
          <img
            class="w-[22px]"
            :src="item.connect ? item.activateIcon : item.icon"
          />
          <span class="fz-3">{{
            item.connect ? item.userName : `Connect ${item.name}`
          }}</span>
          <img
            class="w-[22px]"
            :src="`${item.connect ? linkActivateIcon : linkIcon}`"
          />
        </li>
      </template>
    </ul>
  </div>
  <OneLineModal
    v-model:show="oneLineShowRef"
    :name="itemRef.name"
    @confirm="handleBind"
  ></OneLineModal>
  <DiscoonectLineModal
    v-model:show="discoonectLineShowRef"
    :name="itemRef.name"
  ></DiscoonectLineModal>
</template>

<style scoped lang="scss">
.item-ul {
  li {
    @apply md:h-[38px] h-31px md:leading-[38px] leading-[31px] md:px-2 px-1.5 m-0 rounded text-#CFDDE7;
    @extend .mouse_pointer;

    &.item-li {
      @apply border-1 border-solid border-[#6B7B87] bg-[#1D2226] rounded-9;
    }

    &.activateLi,
    &:hover {
      @apply bg-white text-#000000;
    }
  }

  &:not(:first-child) {
    @apply mt-1.5;
  }
}
</style>
