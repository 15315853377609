import { ref } from 'vue'

export function useFilterList() {
  const filterItemListRef = ref([])
  const searchValueRef = ref('')
  function handleSearch(value) {
    searchValueRef.value = value
    handleFilterItem(
      {
        id: 0,
        type: 'input',
        name: value,
        remove: () => (searchValueRef.value = ''),
      },
      !!value,
    )
  }
  function handleFilterItem(item, b) {
    const index = filterItemListRef.value.findIndex(
      filter => item.id === filter.id,
    )
    const hasExist = index !== -1
    if (b) {
      if (hasExist) {
        filterItemListRef.value[index] = item
      } else {
        filterItemListRef.value.push(item)
      }
    } else if (hasExist) {
      const filterItem = filterItemListRef.value[index]
      filterItemListRef.value.splice(index, 1)
      filterItem.remove()
    }
  }
  return {
    searchValueRef,
    handleFilterItem,
    handleSearch,
    filterItemListRef,
  }
}
