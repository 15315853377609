<template>
  <ul class="text_box">
    <li>
      <span>1.&nbsp;</span>
      <div>
        <span class="text-#BD84F8">Lucky, BOGX, IMX</span>
        <span>, and </span>
        <span class="text-#BD84F8">USDC points</span>
        <span> are all types of points in your </span>
        <span class="text-#BD84F8">Void Labs </span>
        <span class="text-#99A7B1">account</span>
        <span>.</span>
      </div>
    </li>
    <li>
      <span>2.&nbsp;</span>
      <div>
        <span class="text-#BD84F8">Earn Lucky points</span>
        <span> through events like the </span>
        <span class="text-#57C478">Lucky Wheel</span>
        <span> and </span>
        <span class="text-#57C478">daily check-ins</span>
        <span>.</span>
      </div>
    </li>
    <li>
      <span>3.&nbsp;</span>
      <div>
        <span>In the future, </span>
        <span class="text-#BD84F8">exchange points</span>
        <span> for </span>
        <span class="text-#57C478">real tokens.</span>
        <span> Details will be announced by </span>
        <span class="text-#57C478"> Void Labs</span>
        <span>.</span>
      </div>
    </li>
    <li>
      <span>4.&nbsp;</span>
      <div>
        <span>Participants in </span>
        <span class="text-#57C478">game testing</span>
        <span> and </span>
        <span class="text-#57C478">official events</span>
        <span> will receive initial </span>
        <span class="text-#BD84F8">lucky points</span>
        <span> if they engage in </span>
        <span class="text-#fff">daily gameplay</span>
        <span>.</span>
      </div>
    </li>
    <li>
      <span>5.&nbsp;</span>
      <div>
        <span>Use </span>
        <span class="text-#BD84F8">Lucky points</span>
        <span> to redeem </span>
        <span class="text-#57C478">treasure chests</span>
        <span> with rewards.</span>
      </div>
    </li>
  </ul>
</template>

<style scoped lang="scss">
@import './text';
</style>
