<script setup>
import { NEmpty } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useSelectData } from 'src/pages/myCollection/hooks/useSelectData'
import { useWeb3Store } from 'src/stores/web3'
import PreviewModal from 'src/components/PreviewModal'
import { hasPhone } from 'src/stores/media'
import NFTBox from '../components/NFTBox.vue'
import List from '../components/List'
import Panel from '../components/Panel.vue'
import ItemDetail from '../components/ItemDetail'

const { nftList } = storeToRefs(useWeb3Store())
const {
  itemDetailShowRef,
  singleChoiceDataRef,
  previewSrcRef,
  previewShowRef,
  handleEnlarge,
  handleClose,
  handleCheck,
  handleItemDetailClose,
} = useSelectData()
</script>

<template>
  <div class="h-full flex">
    <Panel
      v-if="!hasPhone"
      class="flex-shrink-0"
      :data="singleChoiceDataRef"
      :read-only="true"
    ></Panel>
    <List v-if="nftList.length" :list="nftList">
      <template #item="item">
        <NFTBox
          :data="item"
          :single-choice-data="singleChoiceDataRef"
          @enlarge="handleEnlarge"
          @check="handleCheck"
        ></NFTBox>
      </template>
    </List>
    <NEmpty v-else class="m-auto"></NEmpty>
  </div>
  <ItemDetail
    v-if="hasPhone"
    v-show="itemDetailShowRef"
    :data="singleChoiceDataRef"
    :role-list="roleListRef"
    @close="handleItemDetailClose"
  ></ItemDetail>
  <PreviewModal
    v-if="!hasPhone"
    :show="previewShowRef"
    :src="previewSrcRef"
    :type="2"
    @close="handleClose"
  ></PreviewModal>
</template>

<style scoped lang="scss">
@import '../border';
</style>
