<script setup>
import ModelBox from '../ModelBox'
import Table from './Table'
import { useAsyncState } from '@vueuse/core'
import { watch, ref, useAttrs } from 'vue'
import { fetchTestReward } from 'src/api'
import { storeToRefs } from 'pinia'
import { useLoginStore } from 'src/stores/user'
const props = defineProps({
  headerText: String,
  type: Number, //1日常记录； 2软质押记录
  rows: {
    type: Number,
    default: 20,
  },
})
const { hasLogin } = storeToRefs(useLoginStore())
const attrs = useAttrs()
const listRef = ref([])
const { execute: executeList } = useAsyncState(fetchList, null, {
  immediate: false,
})
async function fetchList() {
  const { beta_test_list } = await fetchTestReward()
  listRef.value = Object.keys(beta_test_list).map(key => ({
    id: key,
    ...beta_test_list[key],
  }))
}

watch(
  () => attrs.show,
  value => {
    if (value) {
      executeList()
    }
  },
)
watch(hasLogin, value => {
  if (!value) {
    listRef.value = []
  }
})
</script>
<template>
  <ModelBox title="TEST REWARD" headerText="ELIGIBILITY DETAIL">
    <Table :list="listRef"></Table>
  </ModelBox>
</template>
