<script setup>
import { defineEmits, defineProps, watch, ref } from 'vue'
import { useElementBounding } from '@vueuse/core'
const props = defineProps({
  show: Boolean,
  downloadModalDom: Array,
})

const emit = defineEmits(['update:show'])

const list = [
  {
    name: 'PC - Epic Store (Recommend)',
    link: 'https://store.epicgames.com/en-US/p/bogx-a639f3',
  },
  {
    name: 'PC - Download File (Backup)',
    link: 'https://voidlabs.bladeofgod.net/client/Bogx_pc_c489_Beta_Test.zip',
  },
  {
    name: 'iOS',
    link: 'https://testflight.apple.com/join/r7gWXcHK',
  },
  {
    name: 'Android',
    link: 'https://voidlabs.bladeofgod.net/client/BOGX_Beta_Test.apk',
  },
]

function handleClose(value = false) {
  emit('update:show', false)
}

const modalBox = ref(null)
const left = ref(0)

function initDownloadModalLeft() {
  const { width } = useElementBounding(modalBox.value)
  const tabWidth = props.downloadModalDom[1]
  const tabX = props.downloadModalDom[0]
  watch(width, dom => {
    left.value = tabX - (dom - tabWidth) / 2
  })
}

watch(
  () => props.show,
  () => {
    initDownloadModalLeft()
  },
)
</script>

<template>
  <div
    v-show="props.show"
    ref="modalBox"
    @mouseenter="emit('update:show', true)"
    @mouseleave.stop="e => handleClose(e)"
    :style="{ left: `${left}px` }"
    class="modal_box -mt-3 relative z-2"
  >
    <div class="py-2.5 md:py-3.5">
      <ul class="rounded">
        <template v-for="item in list" :key="item.name">
          <li class="item2-li">
            <a
              class="relative block h-[38px] w-full rounded bg-#CDD8E0 text-black"
              :href="item.link"
              target="_blank"
              download
            >
              <span class="absolute-center ellipsis-1 w-80% text-center fz-3">{{
                item.name
              }}</span>
              <img
                src="/src/asset/download.webp"
                alt=""
                class="absolute right-[8px] top-[50%] w-[9px] translate-y-[-50%]"
              />
            </a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../ul';

ul {
  li {
    &:not(:last-child) {
      @apply mb-1.5;
    }

    @include minMD {
      a:hover {
        @apply bg-[#ffffff];
      }
    }
  }
}
</style>
