<template>
  <div class="flex">
    <div class="main relative w-full">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.main {
  @include underMD {
    min-height: calc(100vh - var(--header-phone-height));
  }

  @include minMD {
    min-height: calc(100vh - var(--header-height));
  }
}
</style>
