<script setup>
import CthulhuSvg from 'src/asset/PFP/filter/cthulhu.svg'
import { NSwitch } from 'naive-ui'
import { useCthulhuMythos } from './index'
import { useThemeStore } from 'src/stores/theme'
import { storeToRefs } from 'pinia'
const themeStore = useThemeStore()
const { toggleTheme } = themeStore
const { hasThemeGreen } = storeToRefs(themeStore)

const { switchThemeOverridescComputed } = useCthulhuMythos()
</script>
<template>
  <div
    class="flex-sb bg-[--pfp-bg3] h-53px px-24px"
    :class="`${hasThemeGreen ? 'box-green bg_position_animation ' : 'box-normal'}`"
  >
    <div class="flex-center">
      <CthulhuSvg></CthulhuSvg>
      <span
        class="text-#00C523 fz-2 ml-3 bg_position_animation"
        :class="{ cthulhu_text: hasThemeGreen }"
        >THE OLD ONES' EROSION</span
      >
    </div>
    <NSwitch
      class="item_switch"
      size="small"
      :value="hasThemeGreen"
      @update:value="toggleTheme"
      :theme-overrides="switchThemeOverridescComputed"
    ></NSwitch>
  </div>
</template>

<style scoped lang="scss">
@import 'src/pages/PFP/animation';
@import 'src/pages/PFP/positionAnimation';

.item_switch {
  :deep(.n-switch__rail) {
    overflow: visible;
    box-shadow: none !important;
  }

  :deep(.n-switch__button) {
    top: -4px !important;
  }
}

.box-normal {
  @apply border border-solid text-[--pfp-bg3];

  .item_switch {
    :deep(.n-switch__rail) {
      border: 1px solid #373e43;
    }

    :deep(.n-switch__button) {
      border: 1px solid #373e43;
    }
  }
}

.box-green {
  background: linear-gradient(90deg, #0e2f21 0%, #28522b 100%);
  filter: drop-shadow(0 0 2px #63ff4daa);
  background-size: 400%;
  border: 1px solid #0b6d40;

  .item_switch {
    :deep(.n-switch__rail) {
      border: 1px solid #094825;
    }

    :deep(.n-switch__button) {
      border: 1px solid #094825;
    }
  }
}
</style>
