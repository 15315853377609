<script setup>
import Badge1Svg from 'src/asset/PFP/ethereal/badge1.svg?url'
import Badge2Svg from 'src/asset/PFP/ethereal/badge2.svg?url'
import Badge3Svg from 'src/asset/PFP/ethereal/badge3.svg?url'
import Badge4Svg from 'src/asset/PFP/ethereal/badge4.svg?url'
import Badge5Svg from 'src/asset/PFP/ethereal/badge5.svg?url'
import Empty1Png from 'src/asset/PFP/ethereal/empty1.webp'
import Empty2Png from 'src/asset/PFP/ethereal/empty2.webp'
import Empty3Png from 'src/asset/PFP/ethereal/empty3.webp'
import Empty4Png from 'src/asset/PFP/ethereal/empty4.webp'
import { computed } from 'vue'

const props = defineProps({
  topLevelList: {
    type: Array,
    default() {
      return []
    },
  },
})

const svgList = [Badge1Svg, Badge2Svg, Badge3Svg, Badge4Svg, Badge5Svg]
const emptyList = [Empty1Png, Empty2Png, Empty3Png, Empty4Png]

const listComputed = computed(() => {
  props.topLevelList.length = 4
  return props.topLevelList
})
</script>
<template>
  <div
    class="2xl:scale-100 scale-80 transform-origin-top-right box w-486px h-620px bg-#545D65/22 border border-r-0 border-solid border-#6B7B87 rounded-bl-50px rounded-tl-50px"
  >
    <div
      class="family_blk m-26px w-398px h-114px text-#CDD8E0 border-0 border-b border-solid border-#8397A0"
    >
      <div>BADGE</div>
      <ul class="mt-12px flex">
        <li v-for="item in svgList">
          <img class="w-63px mr-1" :src="item" alt="" />
        </li>
      </ul>
    </div>
    <div class="mt-25px">
      <div class="ml-26px">FEATURED</div>
      <div
        class="p-10px absolute left-0 bottom-0 bg-#545D65/60 w-424px h-418px border-0 border-t border-r border-solid border-#6B7B87 rounded-50px"
      >
        <div class="gap-2.5 grid gap-2.5 grid-cols-2 grid-rows-2">
          <div
            class="w-198px h-194px bg-#262729"
            :class="`featured-rounded${index + 1}`"
            v-for="(item, index) in listComputed"
          >
            <img
              v-if="!item?.image"
              class="w-full h-full"
              :src="emptyList[index]"
              alt=""
            />
            <img
              v-else
              class="w-full h-full"
              :class="`featured-rounded${index + 1}`"
              :src="item.image"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.box {
  background-image: url('src/asset/PFP/ethereal/filter.webp');
  background-size: 100% 100%;
  backdrop-filter: blur(2px);

  .featured-rounded1 {
    @apply rounded-tl-50px;
  }

  .featured-rounded2 {
    @apply rounded-tr-50px;
  }

  .featured-rounded3 {
    @apply rounded-bl-50px;
  }

  .featured-rounded4 {
    @apply rounded-br-50px;
  }
}
</style>
