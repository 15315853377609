<script setup>
import { NSelect } from 'naive-ui'
import { computed, ref, watch } from 'vue'
import { hasPhone } from 'src/stores/media'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  selectData: {
    type: Array,
    default() {
      return []
    },
  },
  roleList: {
    type: Array,
    default() {
      return []
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  value: {
    type: [Number, String],
  },
  submitText: {
    type: String,
    default: 'Transfer Item',
  },
})
const emit = defineEmits(['submit'])
const userIdRef = ref()
const hasSelectRef = computed(() => props.selectData.includes(props.data?.Id))
async function handleSubmit() {
  emit('submit', userIdRef.value, [props.data?.Id])
}
watch(
  () => props.value,
  value => {
    if (value !== undefined) {
      userIdRef.value = value
    }
  },
  {
    immediate: true,
  },
)
const selectTheme = {
  peers: {
    InternalSelection: {
      heightMedium: !hasPhone ? '23px' : '25.5px',
    },
  },
}
</script>

<template>
  <div class="box" :class="{ activation: hasSelectRef }">
    <div
      class="mb-4 flex items-center md:mb-0 md:h-[32px] md:bg-#202723 md:pl-2 md:pr-1"
    >
      <span class="mr-3 md:mr-2">HERO</span>
      <NSelect
        :disabled="disabled"
        v-model:value="userIdRef"
        :options="roleList"
        :theme-overrides="selectTheme"
      ></NSelect>
    </div>
    <div>
      <slot name="btn" :handleSubmit="handleSubmit">
        <div
          class="green_button flex-center fz-3 h-[32px] rounded-0!"
          @click="handleSubmit"
        >
          {{ submitText }}
        </div>
      </slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import './btn';

.box {
  bottom: 0;
  left: 1px;
  width: calc(100% - 2px);
  transition: transform 0.2s;
  transform: translateY(101%);

  // &.activation {
  //   transform: translateY(0);
  // }
}
</style>
