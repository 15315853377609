<script setup>
import { NInput } from 'naive-ui'
import Serach from '/src/asset/search.svg'
import { useThemeOverrides } from 'src/pages/PFP/useThemeOverrides'
import { computed } from 'vue'
const emit = defineEmits(['change'])
const { inputThemeOverridescComputed } = useThemeOverrides()
const themeOverridescComputed = computed(() => {
  return {
    ...inputThemeOverridescComputed.value,
    paddingMedium: '0 24px',
  }
})
</script>

<template>
  <NInput
    placeholder="Search by Item Name"
    :theme-overrides="themeOverridescComputed"
  >
    <template #prefix>
      <Serach class="w-20px transform-rotate-y-180 icon color-#818F99"></Serach>
    </template>
  </NInput>
</template>
<style scoped>
.icon :deep(path) {
  fill: var(--pfp-color3);
}
</style>
