import { defineStore, storeToRefs } from 'pinia'
import { ref, watchEffect, computed } from 'vue'
import { loginOut } from 'src/sdk/immutable'
import {
  fetchLockedFlashcardsForAllRolesInAccount,
  fetchTBogxConaumeList,
} from 'src/api'
import { fetchSbtInfo } from 'src/api/web3'
import { useAsyncState } from '@vueuse/core'
import { useRoute } from 'vue-router'
import { getHasRouterActivation } from 'src/helpers/utils'
import { getTBogx } from 'src/sdk/immutable'
import { useUserInfoStore } from 'src/stores/user'
import { attributesToJson, soulCard } from 'src/helpers/blockchain'
import { ATTRIBUTES } from 'src/constant'
import { getResponseWaitState } from 'src/pages/myCollection/utils'

export const useImmutableInfoStore = defineStore(
  'immutableInfo',
  () => {
    const route = useRoute()
    const immutableInfo = ref(null)
    const { userInfo } = storeToRefs(useUserInfoStore())
    const soulCoreInfoLockList = ref([])
    const soulCoreInfoMarketplaceListRef = ref([])
    const soulCoreInfoMarketplaceListComputed = computed(() => {
      return soulCoreInfoMarketplaceListRef.value.map(data => {
        const attributes = attributesToJson(data[ATTRIBUTES])
        const skData = dataToSK(attributes)
        // UserID: '',
        return {
          info: {
            ...data,
            type: 3,
            Id: data.tokenId,
            Name: data.name,
            Icon: data.image,
            Quality: attributes.Quality,
            exp: attributes.Exp,
            Lv: attributes.Lv,
            StarLv: attributes['Star Lv'],
            ProgLv: attributes.ProgLv,
            ItemType: attributes.ItemType,
            Desc: attributes.Desc,
            ...skData,
            mint: {
              tokenid: data.tokenId,
              me: {
                desc: attributes['Mint Attributet'],
              },
            },
          },
        }
      })
    })
    const consumeTbogxList = ref([])
    const tBogxCounts = ref('0')
    const account = computed(
      () => userInfo.value?.bindAccountInfo?.immutableWallet,
    )
    const { execute: executeConsumeTbogxList } = useAsyncState(
      async () => {
        const data = await fetchTBogxConaumeList()
        consumeTbogxList.value = data
      },
      null,
      {
        immediate: false,
      },
    )
    const { execute: executeSoulCoreInfoList } = useAsyncState(
      async () => {
        const data = await fetchLockedFlashcardsForAllRolesInAccount()
        data.SoulCoreInfos.forEach(item => {
          item.info.type = item.info?.mint ? 2 : 1
          item.info.responseWait = getResponseWaitState(item.state)
        })
        soulCoreInfoLockList.value = data.SoulCoreInfos
      },
      null,
      {
        immediate: false,
      },
    )
    const { execute: executeImmutableInfo } = useAsyncState(
      async () => {
        const {
          chainId,
          tBogx: contractAddress,
          provider,
        } = await fetchSbtInfo()
        const data = await getTBogx({
          account: account.value,
          chainId,
          contractAddress,
          provider,
        })
        console.log('tBogxCounts:', data)
        tBogxCounts.value = data ?? '0'
      },
      null,
      {
        immediate: false,
      },
    )
    const { execute: executeSoulCoreMarketplaceTnfoList } = useAsyncState(
      async () => {
        const data = await soulCard.fetchMyNFTList()
        // filter防止没有正确附加元数据的nft导致bug
        soulCoreInfoMarketplaceListRef.value = data
      },
      null,
      {
        immediate: false,
        onError(error) {
          console.log('immutableNFT-Error:', error)
        },
      },
    )
    watchEffect(() => {
      if (getHasRouterActivation(route.matched, 'immutableCollection')) {
        executeConsumeTbogxList()
        executeSoulCoreInfoList()
        if (account.value) {
          executeImmutableInfo()
          executeSoulCoreMarketplaceTnfoList()
        }
      }
      if (getHasRouterActivation(route.matched, 'PFP')) {
        executeConsumeTbogxList()
        executeSoulCoreInfoList()
        if (account.value) {
          executeImmutableInfo()
          // executeSoulCoreMarketplaceTnfoList()
        }
      }
    })
    async function logOut() {
      soulCoreInfoLockList.value = []
      consumeTbogxList.value = []
      soulCoreInfoMarketplaceListRef.value = []
      tBogxCounts.value = '0'
      immutableInfo.value && (await loginOut())
      immutableInfo.value = null
    }
    return {
      immutableInfo,
      soulCoreInfoLockList,
      soulCoreInfoMarketplaceList: soulCoreInfoMarketplaceListComputed,
      consumeTbogxList,
      tBogxCounts,
      immutableCurrentBindAccount: account,
      logOut,
      executeSoulCoreInfoList,
      executeSoulCoreMarketplaceTnfoList,
    }
  },
  {
    persist: true,
  },
)
function dataToSK(data) {
  const result = {}
  let index = 0
  Object.keys(data).forEach(key => {
    const value = data[key]
    const arr = matchSK(value)
    if (arr) {
      result['sk' + ++index] = {
        lv: arr[1],
        lvMax: arr[2],
        name: key,
      }
    }
  })
  return result
}
function matchSK(text) {
  const regex = /Lv\.(\d+) \/ Lv\.(\d+)/
  return text.toString().match(regex)
}
