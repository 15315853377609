<script setup>
// import { NModal } from 'naive-ui'
import BaseList from 'src/components/BaseList'
import Modal from 'src/components/Modal'
import Item from 'src/pages/PFP/components/Content/Item/index.vue'
const emit = defineEmits(['detail', 'update:show'])
const props = defineProps({
  list: {
    type: Array,
    default: () => [],
  },
})
function handleClick(item) {
  emit('detail', item)
}
function handleUpdateShow(show) {
  emit('update:show', show)
}
</script>
<template>
  <Modal>
    <BaseList
      :list="list"
      :xGap="26"
      :yGap="26"
      :number2xl="8"
      :numberMD="6"
      class="theme-normal pt-14 p-6 h-100vh"
      @click="handleUpdateShow(false)"
    >
      <template #item="item">
        <Item
          class="-rotate-y-90 item"
          :id="item.id"
          :name="item.viewName"
          :image="item.image"
          @click.stop="handleClick(item)"
        ></Item>
      </template>
    </BaseList>
  </Modal>
</template>
<style scoped lang="scss">
@import '../../theme';

.item {
  animation: rotate 0.8s 0.8s forwards;
}

@keyframes rotate {
  100% {
    transform: rotate(0);
  }
}
</style>
