<script setup>
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
import { storeToRefs } from 'pinia'
import not_setpImg from 'src/asset/nftSoftStaking/guide/not_setp.webp'
import setpImg from 'src/asset/nftSoftStaking/guide/setp.webp'
const props = defineProps({
  index: Number,
})
const nftSoftPledgeStore = useNFTSoftPledgeStore()
const { guideCurrentIndex } = storeToRefs(nftSoftPledgeStore)
function handleStep() {
  guideCurrentIndex.value = props.index
}
</script>

<template>
  <div class="mouse_pointer flex items-center" @click="handleStep">
    <img
      :src="index === guideCurrentIndex ? setpImg : not_setpImg"
      class="w-12px"
      alt=""
    />
  </div>
</template>
