import { computed } from 'vue'
import { getQuality } from 'src/constant'
import { hasPhone } from 'src/stores/media.js'

export function useBox(props, emit) {
  const hasSelectRef = computed(() => props.selectData.includes(props.data.Id))
  const qualityRef = computed(() => getQuality(props.data.Quality) || {})
  const hasSelectClassRef = computed(
    () => hasSelectRef.value || (!hasPhone && hasSingleChoiceRef.value),
  )
  const hasSingleChoiceRef = computed(
    () => props.singleChoiceData?.Id === props.data.Id,
  )
  const multipleSelectionModeRef = computed(() => props.selectData.length)
  function handleSelect(event) {
    event.stopPropagation()
    const data = props.data
    const index = props.selectData.findIndex(Id => Id === data.Id)
    if (index === -1) {
      emit('select', data.Id, data)
    } else {
      emit('cancel', index, data.Id, data)
    }
  }
  function handleEnlarge(event) {
    event.stopPropagation()
    emit('enlarge', props.data)
  }
  function handleSubmit(...data) {
    emit('submit', ...data)
  }
  function handleStopPropagation(event) {
    event.stopPropagation()
  }
  function handleCheck() {
    if (!multipleSelectionModeRef.value) {
      emit('check', hasSingleChoiceRef.value ? null : props.data)
    }
  }
  return {
    hasSelectRef,
    hasSelectClassRef,
    qualityRef,
    multipleSelectionModeRef,
    handleSelect,
    handleEnlarge,
    handleSubmit,
    handleStopPropagation,
    handleCheck,
  }
}
