<script setup>
import { NSelect, NSpin } from 'naive-ui'
import { computed, ref, watch } from 'vue'
import Countdown from './components/Countdown.vue'
import SuccessModal from './components/SuccessModal'
import TokenHistoryModal from './components/TokenHistoryModal'
import { hasPhone } from 'src/stores/media'
import SearchSvg from 'src/asset/search.svg'
import { useNFTBenefit } from '.'
import { GAME_BANEFITS_NAME } from 'src/constant'

const selectTheme = {
  peers: {
    InternalSelection: {
      heightMedium: !hasPhone ? '23px' : '25.5px',
    },
  },
}
const {
  roleListRef,
  tokenInfoRef,
  roleId,
  benefitsList,
  isOrderLoading,
  showSuccessModal,
  counts,
  tokenCounts,
  showHistoryModal,
  consumeTbogxList,
  statusText,
  executeData,
  handleClaim,
  handleReceive,
} = useNFTBenefit()

const activeIndex = ref(0)
function handleSelect(id) {
  activeIndex.value = id
}

function handleView() {}

function secondsToHhMmSs(seconds) {
  let hours = Math.floor(seconds / 3600)
  let minutes = Math.floor((seconds % 3600) / 60)
  let secs = seconds % 60

  hours = hours < 10 ? '0' + hours : hours
  minutes = minutes < 10 ? '0' + minutes : minutes
  secs = secs < 10 ? '0' + secs : secs

  return `${hours}:${minutes}:${secs}`
}
</script>

<template>
  <div class="wrap item_container">
    <div class="main fz-4">
      <div class="relative h-40px">
        <div
          class="mb-4 flex items-center w-300px md:mb-0 md:h-[40px] md:pl-2 md:pr-1"
        >
          <span class="mx-3 md:mr-2">HERO:</span>
          <NSelect
            v-model:value="roleId"
            :options="roleListRef"
            :theme-overrides="selectTheme"
          ></NSelect>
        </div>
        <div
          @click="showHistoryModal = true"
          class="absolute right-2 top-2 flex items-center mouse_pointer"
        >
          <div
            class="relative bg-#716657 border border-solid border-#48362F mr-1 w-19px h-19px rounded"
          >
            <SearchSvg
              class="w-16px absolute top-0.25 color-#C4B596"
            ></SearchSvg>
          </div>
          <div class="text-##DBD4C0">History</div>
        </div>
      </div>
      <div
        class="flex h-[calc(100vh-40px)] mt-2 border-0 border-t border-solid border-#404753"
      >
        <div
          class="relative px-5 flex flex-col flex-items-center p-10px w-50% border-0 border-r border-solid border-#404753"
        >
          <div class="my-5 fz-5">Benefits</div>
          <router-link :to="{ name: GAME_BANEFITS_NAME }">
            <div
              class="mouse_pointer absolute right-5 top-4 fz-3 flex-center text-#fff bg-#a55e2e w-186px h-38px border border-solid border-#C9771F"
            >
              Game Benefits
            </div>
          </router-link>
          <div></div>
          <ul class="flex w-full">
            <li
              @click="handleSelect(item.id)"
              class="mouse_pointer relative w-25% mr-2 last:mr-0 overflow-hidden"
              v-for="item in benefitsList"
            >
              <div
                class="flex-center bg-#131312 w-full h-12vw"
                :class="{ active: item.id === activeIndex, 'p-10%': item.id }"
              >
                <img
                  :class="{ 'w-110px!': item.id === 3 }"
                  class="w-full"
                  :src="item.image"
                  alt=""
                />
              </div>
              <div
                :style="{ color: item.rate > 0 ? 'green' : 'red' }"
                class="fz-3 text-center"
              >
                <{{ item.rate > 0 ? 'Activating' : 'Not unlocked' }}>
              </div>
              <div
                class="w-40% h-25px absolute left-1px top-1px text-center leading-25px bg-green-8"
              >
                {{ item.rate || 0 }}%
              </div>
            </li>
          </ul>
          <div class="mt-10 mb-5 fz-5">Manage Benefits</div>
          <div
            class="fz-3 p-10 px-18 w-full h-300px bg-#131312 border border-#4A4F51 border-solid"
            v-html="benefitsList[activeIndex]?.desc"
          ></div>
          <div class="w-full flex justify-between">
            <div></div>
            <div
              v-if="activeIndex === 0"
              @click="
                statusText === 'Claim' &&
                  handleReceive(benefitsList[activeIndex].level)
              "
              :class="{ 'bg-#cdd8e0 text-#000!': statusText !== 'Claim' }"
              class="fz-3 bg-#574328 mt-6 mouse_pointer w-180px h-38px flex-center text-#FFE1A1"
            >
              {{ statusText }}
            </div>
          </div>
        </div>
        <div
          class="relative flex flex-col leading-40px flex-items-center px-2% w-50%"
        >
          <div class="absolute px-4% w-full top-4 right-1 flex justify-between">
            <div class="mr-6">
              <span class="flex flex-items-center"
                >$tvBOGX:
                <img
                  class="w-36[px] h-[34px] mx-2"
                  src="/src/asset/bogx_assets.png"
                />
                x{{ tokenInfoRef?.v_bo_gx_count || 0 }}</span
              >
            </div>
            <div class="flex flex-items-center">
              $tBOGX:
              <img
                class="w-36[px] h-[34px] mx-2"
                src="/src/asset/logo/bogx_icon.png"
              />
              x{{ tokenInfoRef?.boGxCount || 0 }}
            </div>
          </div>
          <div class="w-full mt-16">
            <div class="text-center">
              Minting Efficiency:
              {{ tokenInfoRef?.show_related?.overall_efficiency }}%
            </div>
            <div class="w-full my-4 flex justify-between">
              <span
                >Single Coin Minting Time:
                {{
                  tokenInfoRef?.show_related?.single_token_minting_time
                    ? secondsToHhMmSs(
                        tokenInfoRef?.show_related?.single_token_minting_time,
                      )
                    : '00:00:00'
                }}</span
              >
              <span>Mint Ratio $tvBOGX: $tBOGX = 1:1 </span>
            </div>
          </div>
          <img
            class="w-full"
            src="src/asset/nftSoftStaking/hela_gold.webp"
            alt=""
          />
          <div v-if="tokenInfoRef?.v_bo_gx_count">
            {{ tokenInfoRef?.show_related?.coin_status_prompt_copy }}
          </div>
          <div class="text-center" v-else>
            Without $tvBOGX, AI Agent stopped minting.<br />
            Go play game to earn $tvBOGX.
          </div>
          <div class="my-1 flex flex-items-center">
            Mint Successful $tBOGX:<img
              class="w-36[px] h-[34px] mx-2"
              src="/src/asset/logo/bogx_icon.png"
            />
            <span class="text-#FFE1A1 fz-4"
              >x{{ tokenInfoRef?.v_bo_gx_unlock_count || 0 }}</span
            >
          </div>
          <div
            class="flex flex-items-center"
            v-if="tokenInfoRef?.v_bo_gx_count"
          >
            <div>Next needs time:</div>
            <div class="text-center text-#FFE1A1 ml-2">
              <Countdown
                v-if="tokenInfoRef?.unlock_time"
                ref="countdownRef"
                :start-time="tokenInfoRef?.unlock_time"
                :end-time="tokenInfoRef?.unlock_time"
                :current-time="Date.now() / 1000"
                @end="executeData(0, roleId)"
              ></Countdown>
            </div>
          </div>
          <div
            v-if="tokenInfoRef?.v_bo_gx_unlock_count"
            @click="handleClaim"
            class="btn-buy mouse_pointer relative w-250px h-32px mt-20px flex-center text-#FFE1A1"
          >
            Claim
          </div>
        </div>
      </div>
      <div v-if="isOrderLoading" class="absolute top-0 left-0 h-full w-full">
        <NSpin :size="150" class="absolute-center"></NSpin>
      </div>
    </div>
    <SuccessModal
      v-model:show="showSuccessModal"
      :counts="counts"
    ></SuccessModal>

    <TokenHistoryModal
      v-model:show="showHistoryModal"
      :counts="tokenCounts"
      :list="consumeTbogxList"
    >
    </TokenHistoryModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #1e1e1e;

  .active {
    @apply border border-solid border-#fff;
  }

  .btn-buy {
    @include bg100('src/asset/nftSoftStaking/btn_buy_bg.webp');
    @apply hover:bg-[url(src/asset/nftSoftStaking/btn_buy_active_bg.webp)];
  }
}
</style>
