import { emailRegex } from '@BOGX/utils'

export function getEmailModel() {
  return {
    mail: '',
  }
}
export function getEmailRules() {
  return {
    mail: [
      {
        required: true,
        message: 'Email cannot be empty.',
        trigger: ['input', 'blur'],
      },
      {
        pattern: emailRegex,
        message: 'Please enter a valid email.',
        trigger: ['input', 'blur'],
      },
    ],
  }
}
