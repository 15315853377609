<script setup>
import { computed, defineProps, onUnmounted, ref, watchEffect } from 'vue'
import { gsap } from 'gsap'
import Texts from 'src/components/Texts'
import { hasPhone } from 'src/stores/media'

const props = defineProps({
  bigText: {
    type: Array,
    default: () => [''],
  },
  smallText: {
    type: Array,
    default: () => [''],
  },
  scrollTrigger: {
    type: Object,
    default: () => {},
  },
})
let tlBig, tlSmall
const textsRef = ref(null)
const bigDomRef = ref({})
const smallDomRef = ref({})
const endDomRef = computed(() => {
  return [...bigDomRef.value?.endDomRef, ...smallDomRef.value?.endDomRef]
})
function mergeScrollTrigger(options) {
  return {
    scrollTrigger: {
      scrub: true,
      // markers: true,
      ...options,
    },
  }
}
function startScrollTextWave() {
  const bigStartDom = [...bigDomRef.value?.startDomRef].reverse()
  const smallStartDom = [...smallDomRef.value?.startDomRef].reverse()
  scrollWave(bigStartDom, endDomRef.value, smallStartDom)
}
function scrollWave(bigStartDom, endDom, smallStartDom) {
  const startFrom = {
    opacity: 0,
    x: 150,
    stagger: 0.1,
  }
  const bigOptions = mergeScrollTrigger(props.scrollTrigger.big)
  tlBig = gsap.timeline(bigOptions)
  tlBig.from(bigStartDom, startFrom).to(endDom, {
    opacity: 0,
    x: 20,
    y: -50,
    delay: 1,
    stagger: 0.005,
  })
  const smallOptions = mergeScrollTrigger(props.scrollTrigger.small)
  tlSmall = gsap.timeline(smallOptions)
  tlSmall.from(smallStartDom, startFrom)
}
function startScrollTextReverse() {
  scrollReverse(bigDomRef.value.textsRef, smallDomRef.value.textsRef)
}
function scrollReverse(bigDom, smallDom) {
  const bigOptions = mergeScrollTrigger(props.scrollTrigger.big)
  tlBig = gsap.timeline(bigOptions)
  const { smallEndNumber, startNumber } = props.scrollTrigger.number
  const startPhone = 0.5
  const endPhone = 11
  const smallOptions = mergeScrollTrigger({
    ...props.scrollTrigger.small,
    start: `${startNumber + startPhone}% ${startNumber + startPhone}%`,
    end: `${smallEndNumber + endPhone}% ${smallEndNumber + endPhone}%`,
  })
  tlSmall = gsap.timeline(smallOptions)
  const startFrom = {
    opacity: 0,
    rotationX: 100,
    x: 10,
    y: 10,
  }
  const endFrom = {
    opacity: 0,
    x: 20,
    y: -50,
    delay: 1,
  }
  tlBig.from(bigDom, startFrom).to(bigDom, endFrom)
  tlSmall.from(smallDom, startFrom).to(smallDom, endFrom)
}
// function scrollReverse(texts) {
//   const bigOptions = mergeScrollTrigger(props.scrollTrigger.big)
//   const tlBig = gsap.timeline(bigOptions)
//   tlBig
//     .from(texts, {
//       opacity: 0,
//       rotationX: 100,
//     })
//     .to(texts, {
//       opacity: 0,
//       rotation: 20,
//       x: 20,
//       y: -50,
//       delay: 1,
//     })
// }
watchEffect(() => {
  if (props.scrollTrigger.big.trigger && smallDomRef.value?.startDomRef) {
    hasPhone ? startScrollTextReverse() : startScrollTextWave()
  }
})
// watch(
//   () => [
//     props.scrollTrigger.big.trigger,
//     textsRef.value,
//     bigDomRef.value?.startDomRef,
//   ],
//   ([trigger, texts, startDom]) => {
//     if (hasPhone && trigger && texts) {
//       scrollReverse(texts)
//     } else if (!hasPhone && trigger && startDom) {
//       startScrollTextWave()
//     }
//   },
//   {
//     immediate: true,
//   },
// )
onUnmounted(() => {
  tlBig?.kill()
  tlSmall?.kill()
})
</script>

<template>
  <div ref="textsRef" class="pointer-events-none">
    <Texts
      ref="bigDomRef"
      :text-list="bigText"
      :class="`${!hasPhone ? 'fz-8' : 'fz-6'}`"
      class="orange-color md:mb-2"
    ></Texts>
    <Texts
      ref="smallDomRef"
      :text-list="smallText"
      :class="`${!hasPhone ? 'fz-3' : 'fz-2'}`"
      class="orange-gray-color pt-2"
    ></Texts>
  </div>
</template>
