<script setup>
import { onMounted, onUnmounted, reactive, ref } from 'vue'
import { gsap } from 'gsap'
import { useMusicStore } from 'src/stores/music'
import { lineAngleW, lineBottomConnectW } from '../constant'
import { useRouter } from 'vue-router'
import galleryLogo from '/src/asset/gallery_logo.webp'
import galleryActive from '/src/asset/gallery_active.webp'
const { buttonClickAudioPlay, buttonCaptureAudioPlay } = useMusicStore()
const hornDomRef = ref(null)
const galleryRef = ref(null)
const router = useRouter()
const domAttributeReactive = reactive({
  opacityLine: 1,
  lineBottomRect: 0,
  lineAngleRect: lineAngleW,
})

let hoverIng = false
let hornXTl, hornOpacityTL, bottomLineTl, obliqueLineTl, opacityTl

function initHover() {
  const options = {
    ease: 'none',
    paused: true,
  }
  hornOpacityTL = gsap.to(hornDomRef.value, {
    ...options,
    opacity: 1,
    duration: 0.3,
  })
  hornXTl = gsap.to(hornDomRef.value, {
    ...options,
    x: '-20%',
    duration: 0.5,
    onComplete() {
      if (hoverIng) {
        hornXTl.reverse()
      }
    },
    onReverseComplete() {
      if (hoverIng) {
        hornXTl.play()
      }
    },
  })
  bottomLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineBottomRect: lineBottomConnectW,
    duration: 0.4,
  })
  obliqueLineTl = gsap.to(domAttributeReactive, {
    ...options,
    lineAngleRect: 0,
    duration: 0.1,
    onComplete() {
      bottomLineTl.play()
    },
  })
  opacityTl = gsap.to(domAttributeReactive, {
    opacityLine: 0,
    duration: 0.3,
    ...options,
    onComplete() {
      bottomLineTl.progress(0).pause()
      obliqueLineTl.progress(0).pause()
      opacityTl.progress(0).pause()
    },
  })
}
function handleMouseenter() {
  hoverIng = true
  hornOpacityTL.play()
  hornXTl.play()
  obliqueLineTl.play()
  galleryRef.value.style.backgroundImage = `url(${galleryActive})`
  buttonCaptureAudioPlay()
}
function handleMouseleave() {
  hoverIng = false
  hornOpacityTL.reverse()
  hornXTl.reverse()
  opacityTl.play()
  galleryRef.value.style.backgroundImage = `url(${galleryLogo})`
}

function handleOpenGallery() {
  router.push('/pfp')
}
onMounted(() => {
  initHover()
})
onUnmounted(() => {
  hornXTl?.kill()
  hornOpacityTL?.kill()
  bottomLineTl?.kill()
  obliqueLineTl?.kill()
  opacityTl?.kill()
})
</script>

<template>
  <div
    class="mouse_pointer fixed right-0 top-0 z-2"
    @mouseenter="handleMouseenter"
    @mouseleave="handleMouseleave"
    @click="handleOpenGallery"
  >
    <div
      class="relative z-2 pr-8 pt-3 flex justify-items-start flex-items-center"
    >
      <img
        ref="hornDomRef"
        src="/src/asset/top_right_jiao.webp"
        class="relative -ml-2 w-[11.5px] h-12px opacity-0"
        alt=""
      />
      <span class="fz-3 mr-1.5">GALLERY</span>
      <div ref="galleryRef" class="gallery-logo active w-[23px] h-[18px]"></div>
    </div>
    <div
      class="line z-2 w-full translate-x-[-10%]"
      :style="{
        clip: `rect(auto, ${domAttributeReactive.lineBottomRect}px, auto, auto)`,
        width: `${lineBottomConnectW}px`,
        opacity: domAttributeReactive.opacityLine,
      }"
    ></div>
    <div
      class="line w-26px oblique"
      :style="{
        clip: `rect(auto, auto, auto, ${domAttributeReactive.lineAngleRect}px)`,
        opacity: domAttributeReactive.opacityLine,
        width: `${lineAngleW}px`,
      }"
    ></div>
  </div>
</template>

<style scoped>
.line {
  @apply absolute border-dotted h-[1px] border-0 border-b-1;
}

.oblique {
  bottom: 22%;
  left: -22%;
  transform: rotate(-45deg);
}

.gallery-logo {
  background: url('/src/asset/gallery_logo.webp');
  background-size: 100% 100%;
  transition: background-image ease-in-out 0.2s;

  /* @apply hover:bg-[url(/src/asset/gallery_logo.webp)]; */
}

.active {
  @apply hover:bg-[url(/src/asset/gallery_logo.webp)];
}
</style>
