<script setup>
import { computed, defineProps } from 'vue'
import { hasPhone } from 'src/stores/media'
import { mergeStrings, scrollTextList } from '../constant.js'
import SportsText from './SportsText'

const props = defineProps(['scrollTriggerDom'])
const scrollPositionList = [25, 50, 75]
const sportsRef = computed(() => {
  const bigEnd = 15
  const smallEnd = 4
  const trigger = props.scrollTriggerDom
  return scrollPositionList.map(value => {
    const start = `${value}% ${value}%`
    const bigEndValue = bigEnd + value
    const smallEndValue = smallEnd + value
    const baseOptions = {
      start,
      trigger,
    }
    return {
      big: {
        ...baseOptions,
        end: `${bigEndValue}% ${bigEndValue}%`,
      },
      small: {
        ...baseOptions,
        end: `${smallEndValue}% ${smallEndValue}%`,
      },
      number: {
        startNumber: value,
        bigEndNumber: bigEndValue,
        smallEndNumber: smallEndValue,
      },
    }
  })
})
const scrollTextListSmall0Ref = computed(() =>
  !hasPhone ? scrollTextList[0].small : mergeStrings(scrollTextList[0].small),
)
const scrollTextListSmall1Ref = computed(() =>
  !hasPhone ? scrollTextList[1].small : mergeStrings(scrollTextList[1].small),
)
const scrollTextListSmall2Ref = computed(() =>
  !hasPhone ? scrollTextList[2].small : mergeStrings(scrollTextList[2].small),
)
</script>

<template>
  <!-- 内容1 -->
  <div class="y-center absolute md:right-70!">
    <SportsText
      :big-text="scrollTextList[0].big"
      :small-text="scrollTextListSmall0Ref"
      :scroll-trigger="sportsRef[0]"
      class="golden_text md:mb-2"
    ></SportsText>
  </div>
  <!-- 内容2 -->
  <div class="y-center absolute md:left-70!">
    <SportsText
      :big-text="scrollTextList[1].big"
      :small-text="scrollTextListSmall1Ref"
      :scroll-trigger="sportsRef[1]"
      class="golden_text md:mb-2"
    ></SportsText>
  </div>
  <!-- 内容3 -->
  <div class="y-center absolute md:right-70!">
    <SportsText
      :big-text="scrollTextList[2].big"
      :small-text="scrollTextListSmall2Ref"
      :scroll-trigger="sportsRef[2]"
      class="golden_text md:mb-2"
    ></SportsText>
  </div>
</template>

<style scoped>
.y-center {
  @apply md:top-[50%] md:translate-y-[-50%] bottom-[80px] px-[30px] left-0 md:bottom-a md:left-a z-2;
}
</style>
