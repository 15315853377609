<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import common_arrow from 'src/asset/nftSoftStaking/guide/step/1/common_arrow.webp'
import arrow from 'src/asset/nftSoftStaking/guide/step/1/arrow.webp'
import brackets from 'src/asset/nftSoftStaking/guide/step/1/brackets.webp'
import { useNFTSoftPledgeDomStore } from 'src/stores/softPledge'
import ModelBox from './ModelBox'
import { useNFTSoftPledgeStore } from 'src/stores/softPledge'
const { guideShow, guideCurrentIndex } = storeToRefs(useNFTSoftPledgeStore())
const {
  modelGapTop,
  modelPadding,
  setp1NFTAddSize,
  setp1NFT2Size,
  setp1NFT1Size,
} = storeToRefs(useNFTSoftPledgeDomStore())
const guide1StyleComputed = computed(() => {
  return {
    top: modelGapTop.value - 2 + 'px',
  }
})
const bracketsStyleComputed = computed(() => {
  let bottom, top
  // vue的bug，nftDomListRef数组没有按顺序来，明明key都是不一样的
  if (setp1NFT2Size.value.bottom > setp1NFT1Size.value.bottom) {
    bottom = setp1NFT2Size.value.bottom
    top = setp1NFT1Size.value.top
  } else {
    bottom = setp1NFT1Size.value.bottom
    top = setp1NFT2Size.value.top
  }
  return {
    height: bottom - top + 'px',
  }
})
const guide2StyleComputed = computed(() => {
  return {
    bottom:
      Math.max(
        0,
        window.innerHeight -
          setp1NFTAddSize.value.bottom +
          modelPadding.value +
          setp1NFTAddSize.value.height / 2.5,
      ) + 'px',
  }
})
</script>
<template>
  <ModelBox v-show="guideShow && guideCurrentIndex === 0">
    <div class="family_blk_bold fz-5 absolute -left-8 whitespace-nowrap h-full">
      <div class="absolute flex items-center" :style="guide1StyleComputed">
        <img :src="brackets" alt="" :style="bracketsStyleComputed" />
        <div class="relative ml-2.5">
          <div class="flex items-center">
            <img :src="common_arrow" alt="" class="w-71px mr-14px" />
            <span class="text-#69F093">DECK LIST</span>
          </div>
          <span class="text-#fff absolute ml-85px">
            Players can freely create and delete decks. <br />
            The number of decks depends on the number of <br />
            NFTs held in the wallet.
          </span>
        </div>
      </div>
      <div class="absolute" :style="guide2StyleComputed">
        <div class="text-#69F093 relative translate-x-188px mb-1.5">
          ADD DECK
        </div>
        <img :src="arrow" alt="" class="w-193px" />
      </div>
    </div>
  </ModelBox>
</template>
