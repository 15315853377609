import {
  fetchDiscordRevokeAccessToken,
  fetchDiscordToken,
} from 'src/api/thirdParty'
import { discordBaseURL } from 'src/helpers/request'
import { DiscordCodeDataStore, DiscordTokenDataStore } from 'src/helpers/store'
export const discordRedirectUri = location.origin + '/redirect/discord'
export function getDiscordOAth2Link() {
  const url = new URL(discordBaseURL)
  url.pathname = '/oauth2/authorize'
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: getClientData().client_id,
    // state: '15773059ghq9183habn',
    redirect_uri: discordRedirectUri,
    prompt: 'consent',
    integration_type: '0',
    permissions: '2147485696',
    scope: 'identify',
  })
  url.search = params.toString()
  return url.toString()
}
export function getClientData() {
  return {
    client_id: import.meta.env.VITE_DISCORD_APPLICATION_ID,
    client_secret: import.meta.env.VITE_DISCORD_CLIENT_SECRET,
  }
}
class ThirdPartyDiscord {
  codeData = DiscordCodeDataStore
  tokenData = DiscordTokenDataStore
  get hasLogin() {
    return !!this.tokenData.value?.access_token
  }
  setCodeData(data) {
    this.codeData.value = data
  }
  setTokenData(data) {
    this.tokenData.value = data
  }
  goToCodeLink() {
    const href = getDiscordOAth2Link()
    window.location.href = href
  }
  async fetchToken() {
    const data = await fetchDiscordToken({
      code: this.codeData.value.code,
      redirect_uri: discordRedirectUri,
      grant_type: 'authorization_code',
      ...getClientData(),
    })
    this.setTokenData(data)
    return data
  }
  loginOut() {
    const result = fetchDiscordRevokeAccessToken()
    this.tokenData.remove()
    this.codeData.remove()
    return result
  }
}
export const thirdPartyDiscord = new ThirdPartyDiscord()
