<script setup>
import { useUserInfoStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import Collection from './Collection'
import { hasPhone } from 'src/stores/media'
const { transferList } = storeToRefs(useUserInfoStore())
</script>
<template>
  <Collection class="2xl:mr-10 mr-8"></Collection>
  <router-link
    :to="{ name: 'myCollectionTransferHistory' }"
    :class="{ 'fz-h5': !hasPhone }"
    class="gray-color router-link 2xl:mr-10 relative md:mr-8"
  >
    <span>Transfer History</span>
    <span class="text-length">{{ `<${transferList.length}>` }}</span>
  </router-link>
</template>

<style scoped lang="scss">
@import './index';
</style>
