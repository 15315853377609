import { fetchUnlockImmutableSoulCard } from 'src/api'
import { useAsyncState } from '@vueuse/core'
import { useImmutableInfoStore } from 'src/stores/immutable'
import { getBaseWalletSign, getImmutableInfo } from 'src/helpers/blockchain'
import { fetchAuth } from 'src/sdk/immutable'
import { storeToRefs } from 'pinia'

export function useUnlockImmutableSoulCard() {
  const { immutableCurrentBindAccount } = storeToRefs(useImmutableInfoStore())
  const { isLoading, execute } = useAsyncState(
    async itemToken => {
      await fetchAuth()
      const { chainId } = getImmutableInfo()
      // TODO 先关掉校验
      // const web3Signed = await getBaseWalletSign(
      //   immutableCurrentBindAccount.value,
      //   chainId,
      // )
      return fetchUnlockImmutableSoulCard({
        itemToken,
        // web3Signed,
      })
    },
    null,
    {
      immediate: false,
      onError(error) {
        throw error
      },
    },
  )
  return {
    isLoading,
    execute,
  }
}
