import { baseMint } from 'src/sdk/blockchain'
import {
  useDecorator,
  baseBlockchainParams,
  BaseBlockchain,
} from './blockchain'
import abi from 'src/sdk/blockchain/abi/NftPoolAbi.json'

export class NFTPool extends BaseBlockchain {
  constructor() {
    super()
    this.setAbi(abi)
    this.setContractAddress('0xBA42C78169Fe9E8E27D8F1526f6E948a3AdDAC52')
  }
  lock(data) {
    return baseMint({
      functionName: 'lock',
      ...data,
    })
  }
  unlock(data) {
    return baseMint({
      functionName: 'unlock',
      ...data,
    })
  }
}

const NFTPoolProty = NFTPool.prototype
useDecorator(NFTPoolProty, 'lock', baseBlockchainParams)
useDecorator(NFTPoolProty, 'unlock', baseBlockchainParams)
