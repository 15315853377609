import axios from 'axios'
export const discordBaseURL = 'https://discord.com'
export const requestDiscord = axios.create({
  // withCredentials: true,
  baseURL: discordBaseURL + '/api/v10',
})

requestDiscord.interceptors.request.use(config => {
  config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  return config
})

requestDiscord.interceptors.response.use(async response => {
  if (response.status === 200) {
    return response.data
  } else {
    return Promise.reject(response)
  }
})
