<script setup>
import { storeToRefs } from 'pinia'
import { useMediaStore } from 'src/stores/media'
const emit = defineEmits(['change', 'update:number'])
const props = defineProps({
  number: Number,
  maxNumber: Number,
  minNumber: {
    type: Number,
    default: 0,
  },
})
function add() {
  emitChange(Math.min(props.number + 1, props.maxNumber))
}
function minus() {
  emitChange(Math.max(props.number - 1, props.minNumber))
}
function emitChange(number) {
  emit('change', number)
  emit('update:number', number)
}
const { min2XL } = storeToRefs(useMediaStore())
</script>
<template>
  <div class="flex" :class="`${min2XL ? 'box_2xl' : 'box_md'}`">
    <div
      class="minus box relative 2xl:right-[-16px] md:right-[-13.6px] mouse_pointer"
      @click="minus"
    ></div>
    <div class="number fz-7 flex-center">{{ number }}</div>
    <div
      class="add box relative 2xl:left-[-16px] md:left-[-13.6px] mouse_pointer"
      @click="add"
    ></div>
  </div>
</template>
<style lang="scss" scoped>
.number {
  @apply w-75px h-56px;
  @include bg100('src/asset/pfp/mint/quantity_bg.webp');
}

.box_2xl {
  .box {
    @apply w-71px h-56px;
  }

  .number {
    @apply w-75px h-56px;
  }
}

.box_md {
  .box {
    @apply w-60px h-48px;
  }

  .number {
    @apply w-64px h-48px;
  }
}

.add {
  @include bg100('src/asset/pfp/mint/add.webp');
}

.minus {
  @include bg100('src/asset/pfp/mint/minus.webp');
}
</style>
