import {
  getCodeModel,
  getCodeRules,
  getConfirmPasswordModel,
  getConfirmPasswordRules,
  getEmailModel,
  getEmailRules,
} from '../common'

export function getChangePasswordModel() {
  return {
    ...getEmailModel(),
    ...getCodeModel(),
    ...getConfirmPasswordModel(),
  }
}

export function getChangePasswordRules(modelData, passwordMinLength) {
  return {
    ...getEmailRules(),
    ...getCodeRules(),
    ...getConfirmPasswordRules(modelData, passwordMinLength),
  }
}
