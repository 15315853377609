import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { HOME_PAGE_NAME } from 'src/constant'
import { useRoute } from 'vue-router'

export const useMusicStore = defineStore('music', () => {
  const pauseBGMRef = ref(import.meta.env.VITE_BGM_PAUSE)
  const oneOpenBGM = ref(true)
  const historyBgmAudioRef = ref(pauseBGMRef.value)
  const bgmAudioRef = ref(null)
  const progressLoadingCompletedAudioRef = ref(null)
  const buttonCaptureAudioRef = ref(null)
  const buttonClickAudioRef = ref(null)
  const stop = watch(pauseBGMRef, value => {
    if (!value) {
      oneOpenBGM.value = false
      stop?.()
    }
  })
  const route = useRoute()
  watch(
    () => route.name,
    name => {
      if (name === HOME_PAGE_NAME) {
        pauseBGMRef.value = historyBgmAudioRef.value
      } else {
        historyBgmAudioRef.value = pauseBGMRef.value
      }
    },
  )
  function bgmAudioPlay() {
    bgmAudioRef.value?.play()
  }
  function bgmAudioPause() {
    bgmAudioRef.value?.pause()
  }
  function pause() {
    if (buttonCaptureAudioRef.value) {
      buttonCaptureAudioRef.value.pause()
      buttonCaptureAudioRef.value.currentTime = 0
    }
    if (buttonClickAudioRef.value) {
      buttonClickAudioRef.value.pause()
      buttonClickAudioRef.value.currentTime = 0
    }
  }
  function pauseProgress() {
    if (progressLoadingCompletedAudioRef.value) {
      progressLoadingCompletedAudioRef.value.pause()
      progressLoadingCompletedAudioRef.value.currentTime = 0
    }
  }
  function progressLoadingCompletedAudioPlay() {
    pause()
    pauseProgress()
    progressLoadingCompletedAudioRef.value?.play()
  }
  function buttonCaptureAudioPlay() {
    pause()
    buttonCaptureAudioRef.value?.play()
  }
  function buttonClickAudioPlay() {
    pause()
    buttonClickAudioRef.value?.play()
  }
  return {
    pauseBGMRef,
    oneOpenBGM,
    historyBgmAudioRef,
    bgmAudioRef,
    progressLoadingCompletedAudioRef,
    buttonCaptureAudioRef,
    buttonClickAudioRef,
    pause,
    bgmAudioPlay,
    bgmAudioPause,
    progressLoadingCompletedAudioPlay,
    buttonCaptureAudioPlay,
    buttonClickAudioPlay,
  }
})
