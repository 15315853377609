import { ref, watch, computed, watchEffect } from 'vue'
import { useMessage } from 'naive-ui'
import { useAsyncState } from '@vueuse/core'
import {
  fetchTokenInfo,
  fetchTokenOrderDraw,
  fetchTokenOrderInfo,
  fetchNFTPropsSend,
  fetchTBogxConaumeList,
} from 'src/api'
import { codeToMessage } from 'src/constant'
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
import benefitImage3 from 'src/asset/nftBenefit/benefit3.webp'
import benefitImage4 from 'src/asset/nftBenefit/benefit4.webp'

const benefitImage1 = `${import.meta.env.VITE_FILE_PATH}/nft_video/NFT-LionFace2.webp`
const benefitImage2 = `${import.meta.env.VITE_WEBRES_PATH}/pfp/img/deity_male/17.webp`

export function useNFTBenefit() {
  const roleListRef = useRoleList()
  const message = useMessage()
  const tokenInfoRef = ref()
  const roleId = ref('')
  const isOrderLoading = ref(false)
  const showSuccessModal = ref(false)
  const showHistoryModal = ref(false)
  const counts = ref(0)
  const tokenCounts = ref(0)
  const consumeTbogxList = ref([])
  const benefitsList = computed(() => {
    return [
      {
        id: 0,
        rate: tokenInfoRef.value?.show_related?.genesis_magnification?.replace(
          /[^0-9]/g,
          '',
        ),
        name: 'nft',
        image: benefitImage1,
        level: tokenInfoRef.value?.nft_level,
        hasReceive: roleId.value,
        isActive: tokenInfoRef.value?.is_nft_get_reward,
        statusText: 'Not unlocked',
        desc: `Extremely valuable privilege series with many privileges when owned.<br>
        Including: Unlock exclusive illusions, gain 400% more Soul Gem, increase the rate of casting $tBOGX by 1400%, and more privileges are in development.<br>
        Valuable privilege series with many privileges when owned.`,
      },
      {
        id: 1,
        image: benefitImage2,
        rate: tokenInfoRef.value?.show_related?.pfp_magnification?.replace(
          /[^0-9]/g,
          '',
        ),
        name: 'pfp',
        statusText: 'Not unlocked',
        desc: `Including: Fully unlock AI Agent features, participate in the hunt for Treasure, gain Soul Gem increased by 300%, and forge $tBOGX rate increased by 1000%.`,
      },
      {
        id: 2,
        image: benefitImage3,
        rate: tokenInfoRef.value?.show_related?.third_nft_magnification?.replace(
          /[^0-9]/g,
          '',
        ),
        name: 'third',
        statusText: 'Not unlocked',
        desc: `When you have the privilege of a selected partner, you get special privileges: 200% more Soul Gem and 500% more $tBOGX casting.`,
      },
      {
        id: 3,
        image: benefitImage4,
        rate: tokenInfoRef.value?.show_related?.acceleration_magnification?.replace(
          /[^0-9]/g,
          '',
        ),
        name: 'accelerate',
        statusText: 'Not yet open',
        desc: 'Not yet open',
      },
    ]
  })

  const statusText = computed(() => {
    if (tokenInfoRef.value?.is_nft_get_reward) {
      return 'Claimed'
    } else {
      if (tokenInfoRef.value?.nft_level > 0) {
        return 'Claim'
      } else {
        return 'Not unlocked'
      }
    }
  })
  const { execute: executeData } = useAsyncState(
    async roleId => {
      const data = await fetchTokenInfo({
        char_id: roleId,
      })
      if (data.order) {
        executeOrderData(0, data.order)
      }
      executeConsumeTbogxList()
      tokenInfoRef.value = data
      tokenCounts.value = data.boGxCount
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )
  const { execute: executeOrderData } = useAsyncState(
    async orderId => {
      isOrderLoading.value = true
      const data = await fetchTokenOrderInfo({
        char_id: roleId.value,
        order: orderId,
      })
      if (data.order_status !== 0) {
        isOrderLoading.value = false
        if (data.order_status === 2) {
          showSuccessModal.value = true
          counts.value = data.count
        }
        executeData(0, roleId.value)
      } else {
        setTimeout(() => {
          executeOrderData(0, orderId)
        }, 2000)
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  const { execute: executeTokenOrderDraw } = useAsyncState(
    async params => {
      const data = await fetchTokenOrderDraw({
        ...params,
      })
      const orderId = data.order
      executeOrderData(0, orderId)
    },
    null,
    {
      immediate: false,
      onError(error) {
        message.error(codeToMessage(error?.data?.code))
        console.log('error', error)
      },
    },
  )

  const { execute: executeNFTPropsSend } = useAsyncState(
    async params => {
      const data = await fetchNFTPropsSend({
        ...params,
      })
      message.success('Claim successfully')
      executeData(0, roleId.value)
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  const { execute: executeConsumeTbogxList } = useAsyncState(
    async () => {
      const data = await fetchTBogxConaumeList()
      consumeTbogxList.value = data
    },
    null,
    {
      immediate: false,
    },
  )

  function handleClaim() {
    const currentItem = roleListRef.value.find(
      item => item.UserID === roleId.value,
    )
    const params = {
      char_id: roleId.value,
      serverId: currentItem.SERVERID,
      count: tokenInfoRef.value.v_bo_gx_unlock_count,
    }
    executeTokenOrderDraw(0, params)
  }

  function handleReceive(level) {
    const params = {
      char_id: roleId.value,
      level,
    }
    executeNFTPropsSend(0, params)
  }

  // watch(roleId, id => {
  //   roleId.value = id
  //   executeData(0, id)
  // })
  watchEffect(() => executeData(0, roleId.value))

  return {
    roleListRef,
    tokenInfoRef,
    roleId,
    isOrderLoading,
    showSuccessModal,
    showHistoryModal,
    counts,
    tokenCounts,
    consumeTbogxList,
    benefitsList,
    statusText,
    executeData,
    handleClaim,
    handleReceive,
  }
}
