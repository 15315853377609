import { SOFT_PLEDGE } from 'src/constant'
import SoftPledge from 'src/pages/softPledge/index.vue'
const turntableShow = import.meta.env.VITE_TURNTABLE_SHOW

const turntableReouter = []
if (turntableShow) {
  turntableReouter.push({
    path: '/softPledge',
    component: SoftPledge,
    name: SOFT_PLEDGE,
  })
}
export { turntableReouter }
