import { dispatchEventProvider, setConfig } from '@abmao/wallet-proxy'
import { getWallets } from '@abmao/wallet-store'
export function walletInit() {
  setConfig({
    onChangeWallet(wallet) {
      console.log('eip6963:requestProvider', wallet)
      getWallets().push(wallet)
    },
  })
  dispatchEventProvider()
}
