<script setup>
import { NInput } from 'naive-ui'

const props = defineProps(['value'])
const emit = defineEmits(['update:value'])
function handleChange(value) {
  emit('update:value', value)
}
</script>

<template>
  <NInput
    :value="props.value"
    type="password"
    :maxlength="30"
    show-password-on="click"
    placeholder="Password"
    @update:value="handleChange"
  >
    <template #password-visible-icon>
      <img src="/src/asset/eye.webp" class="w-[29px]" />
    </template>
    <template #password-invisible-icon>
      <img src="/src/asset/close_eye.webp" class="w-[29px]" />
    </template>
  </NInput>
</template>
