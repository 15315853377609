<script setup>
import SvgIcon from 'src/components/SvgIcon'
import RedPopover from 'src/components/RedPopover'
import { gsap } from 'gsap'
import { onMounted, onUnmounted, ref } from 'vue'
const emit = defineEmits(['refresh'])
const props = defineProps({
  total: {
    type: Number,
    default: 0,
  },
})
let refreshTl
const activeTab = ref(0)

function initAnimation() {
  const options = {
    ease: 'linear',
    paused: true,
    duration: 0.3,
  }
  refreshTl = gsap.to('.pfp-refresh', {
    rotate: 180,
    ...options,
  })
}

function handleRefresh() {
  refreshTl?.restart()
  emit('refresh')
}

onMounted(() => {
  initAnimation()
})

onUnmounted(() => {
  refreshTl?.kill()
})
</script>

<template>
  <div class="w-full h-44px relative flex flex-items-center">
    <div class="mr-11 flex fz-5">
      <div
        :class="`${activeTab === 0 ? 'active' : 'static'}`"
        class="item hover w-163px"
      >
        ORISOLS
        <SvgIcon
          v-if="activeTab === 0"
          name="PFP-WAVE"
          width="256px"
          height="3px"
          class="absolute bottom-0 text-[--pfp-border10]"
        ></SvgIcon>
      </div>
      <RedPopover>
        <template #trigger>
          <div
            :class="`${activeTab === 1 ? 'active' : 'static'}`"
            class="item transition-color transition-duration-250 transition-ease-in-out ml-5 w-198px"
          >
            CODE VOID
            <SvgIcon
              v-if="activeTab === 1"
              name="PFP-WAVE"
              width="256px"
              height="3px"
              class="absolute bottom-0 text-[--pfp-border10]"
            ></SvgIcon>
          </div>
        </template>
        <div>Coming Soon ......</div>
      </RedPopover>
    </div>
    <div
      class="flex flex-1 h-44px justify-end border-0 border-b-1px border-solid border-[--pfp-border12]"
    >
      <div class="flex flex-items-center mb-1 text-[--pfp-color7]">
        <div class="fz-5">{{ total }}</div>
        <div
          @click="handleRefresh"
          class="mouse_pointer transition-all hover:bg-[--pfp-bg9] hover:text-[--pfp-color2] w-32px h-32px ml-2.5 bg-[--pfp-bg7] flex-center border border-solid border-[--pfp-border5] rounded-4px"
        >
          <SvgIcon name="PFP-FRESH" width="20px" class="pfp-refresh"></SvgIcon>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.hover {
  @apply hover:text-[--pfp-color2];
}

.item {
  @extend .flex-center;
  @extend .mouse_pointer;
  @apply relative h-41px border border-solid rounded-tr rounded-bl-5px;

  &.active {
    @apply bg-[--pfp-bg14] text-[--pfp-color2] border-[--pfp-border10] transition-all transition-ease-in-out transition-duration-200;
  }

  &.static {
    @apply bg-[--pfp-bg15] text-[--pfp-color12] border-[--pfp-border11];
  }
}
</style>
