<script setup>
import { NForm, NFormItem, NInput } from 'naive-ui'
import { ref } from 'vue'
import { getRegisterModel, getRegisterRules } from 'src/model/user'
import PasswordInput from '../PasswordInput'
import EmailCodeInput from './EmailCodeInput'

const props = defineProps(['disabled'])
defineExpose({
  handleValidate,
})
const modelData = getRegisterModel()

const formRef = ref(null)
const modelRef = ref(modelData)
function handleValidate(e) {
  e.preventDefault()
  const data = { ...modelRef.value }
  delete data.confirmPassword
  return formRef.value?.validate().then(() => data)
}
</script>

<template>
  <NForm
    ref="formRef"
    :model="modelRef"
    :rules="getRegisterRules(modelRef)"
    :disabled="props.disabled"
    :show-label="false"
  >
    <NFormItem path="accountName">
      <NInput v-model:value="modelRef.accountName" placeholder="Username" />
    </NFormItem>
    <EmailCodeInput
      v-model:email="modelRef.mail"
      v-model:code="modelRef.verifyCode"
      countdown-key="Register"
      :code-type="1"
    ></EmailCodeInput>
    <NFormItem path="password">
      <PasswordInput v-model:value="modelRef.password"></PasswordInput>
    </NFormItem>
    <NFormItem path="confirmPassword">
      <PasswordInput
        v-model:value="modelRef.confirmPassword"
        placeholder="Confirm Password"
      ></PasswordInput>
    </NFormItem>
    <!-- <NFormItem path="phoneNum">
      <NInput
        v-model:value="modelRef.phoneNum"
        :maxlength="11"
        pattern="[0-9]"
      />
    </NFormItem> -->
  </NForm>
</template>
