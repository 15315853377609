<script setup>
import { ref, computed } from 'vue'
import prompt_arrow from 'src/asset/mint/prompt_arrow.webp'
import { hasPhone } from 'src/stores/media'
import { useRouter } from 'vue-router'
import MoreModal from './MoreModal'
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'

const props = defineProps({
  supply: Number,
})

// const total = computed(() => props.supply)
const { min2XL } = storeToRefs(useMediaStore())
const router = useRouter()
const moreShowRef = ref(false)
function handleMoreOpen() {
  moreShowRef.value = true
}
function goToBoss() {
  router.push({ name: 'bossIntroduce' })
}
</script>

<template>
  <div>
    <div
      class="mt-7px 2xl:w-603px 2xl:h-528px w-512.55px h-449px bg-#000000/80 flex flex-col flex-items-center"
    >
      <div class="2xl:w-548px w-466px w-187px 2xl:h-220px relative -mt-7px">
        <img
          class="2xl:w-511px w-434px"
          src="/src/asset/mint/bogx.webp"
          alt=""
        />
        <img
          class="2xl:w-548px w-466px 2xl:-mt-40px -mt-34px"
          src="/src/asset/mint/ethereal.webp"
          alt=""
        />
        <div class="family_blk text-#fff 2xl:-mt-36px -mt-30px fz-8">
          COLLECTION
        </div>
        <div
          class="family_blk 2xl:w-323px w-274.55px 2xl:h-42px h-35px 2xl:mt-10px mt-8.5px flex flex-items-center text-#fff 2xl:pl-21px pl-18px border border-solid border-#696B6B"
          :class="`fz-${min2XL ? 5 : 4}`"
        >
          TOTAL SUPPLY:<span class="text-#F8A52F ml-1">{{ props.supply }}</span>
        </div>
        <div class="2xl:mt-31px mt-14px text-#9A9A9A">
          The Ethereal Collection for Blade of God X serves as both a unique
          identity and the golden key to the BOGX ecosystem. <br />Featuring 6
          races and over 400 traits, this collection aims to introduce a
          distinctive and recognizable PFP for the web3 gaming community.
          <br />The Ethereal Collection is the core NFT designed for composite
          staking in combination with Orisols and has tangible utilities in the
          game and the entire BOGX ecosystem. ......<span
            class="mouse_pointer text-#F8A52F"
            >Read More...
            <img
              :src="prompt_arrow"
              class="ml-1 w-8px md:ml-2 md:w-13px"
              alt=""
            />
          </span>
        </div>
        <div class="mt-30px text-#FF7D31">
          <div>Designed by</div>
          <img class="mt-8px w-132px" src="/src/asset/labs_logo.webp" alt="" />
        </div>
      </div>
    </div>

    <MoreModal v-if="!hasPhone" v-model:show="moreShowRef"></MoreModal>
  </div>
</template>
