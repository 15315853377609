<script setup>
import { NPopover } from 'naive-ui'
import tokentrove from 'src/asset/socializeLinks/tokenTroveText.webp'
import sphere from 'src/asset/socializeLinks/sphereText.webp'
import okx from 'src/asset/socializeLinks/okx.webp'
import { OPEN_SEA_PATH, OKX_PATH, ELEMENT_PATH } from 'src/constant'
const list = [
  {
    img: okx,
    link: OKX_PATH,
  },
  {
    img: tokentrove,
    link: OPEN_SEA_PATH,
  },
  {
    img: sphere,
    link: ELEMENT_PATH,
  },
]
</script>

<template>
  <NPopover
    style="
      padding: 0.375rem;

      --n-color: #343a40;
    "
  >
    <template #trigger>
      <slot></slot>
    </template>
    <div>
      <ul>
        <template v-for="item in list" :key="item.name">
          <li>
            <a
              class="flex-center bg-#141516 md:hover:bg-#757a7e block md:h-[38px] h-51px w-243px md:rounded rounded-2 md:w-180px"
              :href="item.link"
              target="_blank"
            >
              <img :src="item.img" alt="" class="md:h-24px h-29px" />
            </a>
          </li>
        </template>
      </ul>
    </div>
  </NPopover>
</template>

<style scoped lang="scss">
ul {
  li {
    &:not(:last-child) {
      @apply mb-1.5;
    }
  }
}
</style>
