export function getRoleCodeModel() {
  return {
    UserID: null,
  }
}
export function getRoleCodeRules() {
  return {
    UserID: [
      {
        required: true,
        message: 'Please select a hero.',
        trigger: ['change', 'blur'],
      },
    ],
  }
}
