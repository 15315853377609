<script setup></script>
<template>
  <div class="btn fz-2 flex-center">Check Test Reward Eligibility</div>
</template>

<style scoped lang="scss">
.btn {
  @include bg100('src/asset/nftSoftStaking/test/card.webp');
  @apply text-#83F09D px-3 h-34px;

  &:hover {
    @include bg100('src/asset/nftSoftStaking/test/card_active.webp');
    @apply text-#fff;
  }
}
</style>
