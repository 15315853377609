<script setup>
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'

const { userInfo } = storeToRefs(useUserInfoStore())
</script>

<template>
  <div class="relative">
    <img
      src="/src/asset/account.webp"
      alt=""
      class="h-[13px] align-sub md:h-[20px]"
    />
    <span class="absolute-center color-#DDAD68">{{ userInfo?.accname }}</span>
  </div>
</template>
