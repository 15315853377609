import { computed, ref } from 'vue'
import { getAccount } from '@wagmi/core'
import { getTransport, hasConnectWallet } from './walletConnect'
import { middleEllipsis } from './utils'
import { createWalletClient } from 'viem'
export const accountRef = ref({})
export const addressesRef = ref([])
export const hasConnectWalletRef = computed(hasConnectWallet)
// TODO 考虑多账号签名共存
export function logout() {
  updateAccount()
}
export const addressEllipsisRef = computed(() => {
  return middleEllipsis(accountRef.value?.address)
})
export async function updateAccount() {
  accountRef.value = getAccount()
  const walletClient = createWalletClient({
    transport: getTransport(),
  })
  addressesRef.value = await walletClient.getAddresses()
}
