import { defineStore, storeToRefs } from 'pinia'
import { computed, ref } from 'vue'
import { fetchPFPMintInfo } from 'src/api'
import { useUserInfoStore } from './user'
import { pfpNFT } from 'src/helpers/blockchain'
import { useSinglePromise } from 'src/hooks/useSinglePromise'
import { PFP_ROUTERS } from 'src/constant'
import { SinglePromise } from 'src/helpers/singlePromise'
let fetchPFPMint
export const fetchPFPMintP = new SinglePromise(() => fetchPFPMint?.())
export const usePFPMintStore = defineStore('pfpMint', () => {
  const { userInfo } = storeToRefs(useUserInfoStore())
  const mintInfoRef = ref(null)

  async function getPFPMintInfo() {
    const data = await fetchPFPMintInfo(userInfo.value?.wallet)
    pfpNFT.setChainId(data.chainId)
    pfpNFT.setContractAddress(data.contract)
    mintInfoRef.value = data
    // mintInfoRef.value = {
    //   ...data,
    //   serverTime: Date.now() / 1000,
    //   phase: getPhase(Date.now() / 1000),
    //   timeline,
    // }
    await getPFPMintAdditionalInfo()
    console.log('pfpNFT', pfpNFT.chainId)
    return data
  }
  // const timeline = [
  //   Date.now() / 1000 + 10,
  //   Date.now() / 1000 + 20,
  //   Date.now() / 1000 + 30,
  // ]
  // function getPhase(serverTime) {
  //   let i
  //   for (i = 0; i < timeline.length; i++) {
  //     if (serverTime < timeline[i]) {
  //       console.log('getPhase', i)
  //       return i
  //     }
  //   }
  //   console.log('getPhase', i)
  //   return i
  // }
  async function getPFPMintAdditionalInfo() {
    const [totalMintCount, genesisTokens] = await Promise.all([
      pfpNFT.getTotalMintCount(),
      pfpNFT.getGenesisNFTList(),
    ])
    console.log('genesisTokens', genesisTokens, totalMintCount)

    mintInfoRef.value = {
      ...mintInfoRef.value,
      totalMintCount: Number(totalMintCount),
      isGenesis: !!genesisTokens.length,
    }
  }
  fetchPFPMint = getPFPMintInfo
  const phaseComputed = computed(() => mintInfoRef.value?.phase)
  // 白名单
  const currentPhaseWLComputed = computed(() => {
    const phase = phaseComputed.value
    if (phase === 1) {
      return mintInfoRef.value?.phase1WL || 0
    } else if (phase > 1) {
      return mintInfoRef.value?.phase2WL || 0
    } else {
      return 0
    }
  })

  // 总的活动时间，1: 未开始，2：进行中，3：已结束
  const wholeActivityStatusComputed = computed(() => {
    const phase = phaseComputed.value
    if (phase === 3) {
      return 3
    } else if (phase === 0) {
      return 1
    } else {
      return 2
    }
  })

  const totalMintCountComputed = computed(
    () => mintInfoRef.value?.totalMintCount || 0,
  )
  const supplyComputed = computed(() => mintInfoRef.value?.supply || 0)

  const mintProgressComputed = computed(() => {
    return Math.min(
      totalMintCountComputed.value / supplyComputed.value ? 0 : data,
      1,
    )
  })
  const mintCompleteComputed = computed(() => mintProgressComputed.value === 1)
  // 需要接口数据，返回可以敲蛋的时间
  let eggEndComputed
  const isProd = import.meta.env.VITE_MODE === 'prod'
  if (isProd) {
    eggEndComputed = computed(() => mintInfoRef.value?.openTime * 1000)
  } else {
    eggEndComputed = computed(() => Date.now() + 10 * 1000)
  }

  const serverTimeComputed = computed(
    () => mintInfoRef.value?.serverTime * 1000,
  )
  useSinglePromise({
    promiseFn: () => fetchPFPMintP.fetch(),
    routerNameRanges: PFP_ROUTERS,
  })
  return {
    mintInfo: mintInfoRef,
    wholeActivityStatusComputed,
    phaseComputed,
    currentPhaseWLComputed,
    getPFPMintInfo,
    mintProgressComputed,
    mintCompleteComputed,
    totalMintCountComputed,
    supplyComputed,
    eggEndComputed,
    serverTimeComputed,
  }
})
