<script setup>
import Modal from 'src/components/Modal'
import prompt_arrow from 'src/asset/mint/prompt_arrow.webp'
import share from 'src/asset/mint/share.webp'
import eggImg from 'src/asset/PFP/egg.webp'
import { useRouter } from 'vue-router'
import { PFP_ETHEREAL_NAME } from 'src/constant'

const props = defineProps({
  show: Boolean,
  mintList: {
    type: Array,
    default: [],
  },
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}

const router = useRouter()
function handleView() {
  router.push({
    name: PFP_ETHEREAL_NAME,
  })
}

function shareOnTwitter() {
  const url = location.href // 你希望分享的链接
  const text = `Just minted my Ethereal NFT for Blade of God X! This unique PFP isn't just a cool avatar - it's my golden key to the BOGX ecosystem. Can't wait to explore composite staking with Orisols and unlock in-game utilities. Which of the 6 races did you get? ` // 你希望分享的文本
  const hashtags = 'BOGX,web3gaming' // 相关标签，用逗号分隔
  const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}&hashtags=${encodeURIComponent(hashtags)}`

  window.open(twitterUrl, '_blank', 'width=600,height=500')
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="px-64px py-34px flex flex-col flex-items-center bg-#0E0E0F border border-solid border-#696B6B rounded-5px"
    >
      <div class="fz-6 text-#FF7D31">MINT SUCCESSFUL</div>
      <div class="flex">
        <div
          v-for="item in props.mintList"
          class="bg-#2E2E2E mr-33px last:mr-0 mt-75px flex flex-col w-299px h-374px border border-solid border-#515E60 rounded-8px"
        >
          <img class="w-full h-299px" :src="eggImg" alt="" />
          <div class="flex-1 flex-center flex-col">
            <div class="text-#4F6464">ETHEREAL</div>
            <div class="fz-4 text-#73ABAB">NO.{{ item?.id }}</div>
          </div>
        </div>
      </div>
      <div
        @click="shareOnTwitter"
        class="mouse_pointer flex mt-113px flex-items-center"
      >
        <img class="w-14px h-13.5px mr-1" :src="share" alt="" />
        Share on Twitter
        <img :src="prompt_arrow" class="w-12px h-9.5px" alt="" />
      </div>
      <div class="flex mt-20px">
        <div
          @click="handleClose"
          class="mouse_pointer mr-37px flex-center text-#8397A0 bg-#171A1C w-186px h-38px border border-solid border-#6B7B87 rounded-5px"
        >
          Cancel
        </div>
        <div
          @click="handleView"
          class="mouse_pointer flex-center text-#fff bg-#FF7D31 w-186px h-38px border border-solid border-#FFC662 rounded-5px"
        >
          Go to view
        </div>
      </div>
    </div>
  </Modal>
</template>
