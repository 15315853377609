<script setup>
import { NInput } from 'naive-ui'
const emit = defineEmits(['change'])

const border = '1px dashed #FF7D31'
const themeOverrides = {
  borderActive: border,
  borderFocus: border,
  borderHover: border,
  paddingMedium: '0 24px',
  textAlign: 'center',
}
</script>

<template>
  <NInput
    class="n-input"
    :theme-overrides="themeOverrides"
    placeholder="Please enter wallet address"
  ></NInput>
</template>

<style lang="scss" scoped>
.n-input {
  ::v-deep {
    .n-input__input-el,
    .n-input__placeholder {
      text-align: center;
    }
  }
}
</style>
