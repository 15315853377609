<script setup>
import ItemModal from 'src/components/ItemModal'
import WalletBox from 'src/components/WalletBox.vue'
import { useMint } from './index'
import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import { NSpin } from 'naive-ui'
import { useMediaStore } from 'src/stores/media'

const emit = defineEmits(['success', 'error'])
const props = defineProps({
  quantity: {
    type: Number,
    default: 0,
  },
})
const { wholeActivityStatusComputed } = storeToRefs(usePFPMintStore())
const { loading, handleMint, mintNotWhiteListShowRef, btnDomRef } = useMint(
  props,
  emit,
)
const { min2XL } = storeToRefs(useMediaStore())
defineExpose({
  btnDom: btnDomRef,
})
</script>

<template>
  <div class="relative" :class="`${min2XL ? 'box_2xl' : 'box_md'}`">
    <div>
      <WalletBox :cd="() => handleMint(0, quantity)">
        <div class="base_btn_box">
          <div
            ref="btnDomRef"
            class="base_btn"
            :class="`${wholeActivityStatusComputed === 2 ? 'mint_btn' : 'out_btn'}`"
          ></div>
        </div>
      </WalletBox>
      <div v-if="loading" class="absolute h-full w-full bottom-40px">
        <NSpin :size="100" class="absolute-center"></NSpin>
      </div>
    </div>

    <!-- 你不在白名单 -->
    <ItemModal
      v-model:show="mintNotWhiteListShowRef"
      width="435px"
      @confirm="mintNotWhiteListShowRef = false"
    >
      <div class="px-2 py-5 text-#C16868 text-center">
        You are not on the whitelist
      </div>
    </ItemModal>
  </div>
</template>

<style scoped lang="scss">
.box_2xl {
  @apply bottom-[-75px];

  .base_btn_box {
    @apply h-224px w-755px relative;
  }
}

.box_md {
  @apply bottom-[-64px];

  .base_btn_box {
    @apply h-190px w-642px relative;
  }
}

.base_btn {
  @apply h-full w-full;

  &.out_btn {
    @include bg100('src/asset/PFP/mint/mintout.webp');
  }

  &.mint_btn {
    @extend .mouse_pointer;
    @include bg100('src/asset/PFP/mint/Mint_Button.webp');

    &:hover {
      @include bg100('src/asset/PFP/mint/Mint_Button_hover.webp');
    }
  }
}

.modal-content {
  @apply px-4 md:px-0;
}
</style>
