<script setup>
import { ref } from 'vue'
import Video from 'src/components/Video'
import { getAppropriateSize, minSize } from '@BOGX/utils'
import ArrowBack from 'src/components/ArrowBack'
import { useScreenSizeChange } from 'src/hooks/useScreenSizeChange'
import { storeToRefs } from 'pinia'
import { hasPhone, useMediaStore } from 'src/stores/media'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const { min2XL } = storeToRefs(useMediaStore())
const videoSizeRef = ref({})

function handleClose() {
  emit('update:show', false)
}
const videoSrc = `${import.meta.env.VITE_FILE_PATH}/trailer_pv.mp4`
useScreenSizeChange(() => {
  if (hasPhone) {
    videoSizeRef.value.height = `${minSize() * 0.8}px`
    videoSizeRef.value.width = 'auto'
  } else {
    videoSizeRef.value = getAppropriateSize(
      1920,
      1080,
      min2XL.value ? 0.72 : 0.55,
    )
  }
  console.log(
    'videoSizeRef.value-t',
    videoSizeRef.value.width,
    videoSizeRef.value.height,
  )
})
</script>

<template>
  <div>
    <ArrowBack v-if="hasPhone" class="mb-3" @click="handleClose" />
    <div class="flex-center relative">
      <Video
        :width="videoSizeRef.width"
        :height="videoSizeRef.height"
        :src="videoSrc"
      ></Video>
      <div v-if="!hasPhone" class="btn" @click="handleClose"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn {
  position: absolute;
  top: 1%;
  right: -4%;
  background: url('src/asset/close.webp') no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);

  @apply w-[25px] h-[30px] bottom-[55px];
  @extend .mouse_pointer;
}
</style>
