import { getPromiseState } from '@BOGX/utils'

export class SinglePromise {
  promise = null
  constructor(pFn) {
    this.pFn = pFn
  }

  async run() {
    // 防止同一时间频繁调用
    if (this.promise) {
      const status = await getPromiseState(this.promise)
      // 报错或者结束
      if (status !== 'pending') {
        return this.fetch()
      } else {
        // 还在执行，返回当前请求，不重新调用
        return this.promise
      }
    } else {
      // 没有调用过
      return this.fetch()
    }
  }

  fetch() {
    return (this.promise = this.pFn())
  }

  clear() {
    this.promise = null
  }
}
