<script setup></script>
<template>
  <div
    class="panel-box w-304px h-full p-40px border-0 border-r border-solid border-#5d4435"
  >
    <div>
      <div class="text-#FFFFFF">HOW TO PLAY</div>
      <p class="text-#F8B874 overflow-y-auto text-14px max-h-850px m-0 mt-30px">
        1. Event Duration: Ongoing. Specific end time will be announced.<br /><br />
        2. Daily Check-in: Refreshes at
        <span class="text-#fff">0:00 (UTC-8)</span>, no stacking. Earn
        <span class="text-#BD84F8">lucky points</span> upon successful
        check-in.<br /><br />
        3. Daily Turntable: Refreshes at
        <span class="text-#fff">00:00 (UTC-8)</span>, no stacking. Usage
        deducted upon successful spin.<br /><br />
        4. Rewards: Based on current configuration. Any changes will be
        announced in advance.<br /><br />
        5. Points & Rewards: View in
        <span class="text-#57C478">inventory</span>. Redeem game items using
        <span class="text-#57C478">reward codes</span> from
        <span class="text-#57C478">inventory history</span>.<br /><br />
        6. Authorization: Required to connect to the
        <span class="text-#57C478">immutable account</span> before spinning and
        check-in.
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.panel-box {
  background: linear-gradient(180deg, #2f190c 0%, #130906 100%);
}
</style>
