<script setup>
import { storeToRefs } from 'pinia'
import Icon_Genesis_Holder from 'src/asset/PFP/mint/Icon_Genesis_Holder.webp'
import { usePFPMintStore } from 'src/stores/pfp'
import { computed } from 'vue'
const { mintInfo } = storeToRefs(usePFPMintStore())
const contentML = computed(() => {
  if (!mintInfo.value?.isGenesis) {
    return 'text_ml'
  }
})
</script>
<template>
  <div class="flex items-center">
    <img
      :src="Icon_Genesis_Holder"
      class="2xl:mr-3 md:mr-2 2xl:w-77px md:w-65px"
      alt=""
      v-if="mintInfo?.isGenesis"
    />
    <div class="inline-block" :class="contentML">
      <div class="fz-4 2xl:mb-2 md:mb-1">
        <span
          class="genesis_text bg_position_animation"
          v-if="mintInfo?.isGenesis"
          >GENESIS HOLDER</span
        >
        <span v-else class="color-#CDD8E0">MINT STAGES</span>
      </div>
      <p class="color-#8397A0 m-0 leading-6">
        Blade of God X serves as both a unique identity and the golden key to
        the BOGX ecosystem.
      </p>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'src/pages/PFP/positionAnimation';

.genesis_text {
  background: linear-gradient(
    45deg,
    #fe6363ff,
    #ed6fedff,
    #8889e5ff,
    #51f0f0ff,
    #79f079ff,
    #f3eb6fff
  );
  background-clip: text;
  background-size: 200% 200%;
  -webkit-text-fill-color: transparent;
}

.text_ml {
  @apply ml-4;
}
</style>
