<script setup>
import { useRouter } from 'vue-router'
import { PFP_ETHEREAL_NAME } from 'src/constant'

const router = useRouter()

function handleCollection() {
  router.push({
    name: PFP_ETHEREAL_NAME,
  })
}
</script>

<template>
  <div
    class="mouse_pointer py-1 hover px-3 border border-solid border-[rgba(0,0,0,0.01)] transition-all"
    @click="handleCollection"
  >
    MY COLLECTION
  </div>
</template>

<style scoped>
.hover {
  @apply hover:border hover:border-solid hover:rounded-5px hover:border-[--pfp-border9] hover:bg-[--pfp-bg11];
}
</style>
