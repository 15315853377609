import { usePFPMintStore } from 'src/stores/pfp'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'

export function usePhaseStatus(immutablePhaseValue) {
  const { phaseComputed } = storeToRefs(usePFPMintStore())
  const phaseStatusComputed = computed(() => {
    if (immutablePhaseValue.value > phaseComputed.value) {
      return 1
    } else if (immutablePhaseValue.value === phaseComputed.value) {
      return 2
    } else {
      return 3
    }
  })
  const ingComputed = computed(() => phaseStatusComputed.value === 2)
  return {
    phaseStatusComputed,
    ingComputed,
  }
}
