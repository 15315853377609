import { useRoute } from 'vue-router'
import { fetchUserInfoP } from 'src/helpers/user.js'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'
import { watch } from 'vue'
import { watchNetwork } from '@wagmi/core'

export function useSinglePromise({ routerNameRanges, promiseFn }) {
  const { bindAddressComputed } = storeToRefs(useUserInfoStore())
  const route = useRoute()
  function nftRange(name) {
    return routerNameRanges.includes(name)
  }
  // 保证先请求用户信息
  fetchUserInfoP.run().finally(() => {
    let stop
    let one = false
    stop = watch(
      () => route.name,
      value => {
        // 只有nft范围内才执行
        if (nftRange(value)) {
          console.log('useSinglePromise')
          if (stop) {
            stop()
            stop = []
          }
          if (one) {
            return
          }
          one = true
          // 先初始化nft相关信息
          promiseFn().finally(() => {
            // 绑定下相关触发逻辑
            watch(bindAddressComputed, () => {
              if (nftRange(route.name)) {
                promiseFn()
              }
            })
            watch(
              () => route.name,
              name => {
                if (nftRange(name)) {
                  promiseFn()
                }
              },
            )
            watchNetwork(() => {
              if (nftRange(route.name)) {
                promiseFn()
              }
            })
          })
        }
      },
      {
        immediate: true,
      },
    )
  })
}
