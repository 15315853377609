<script setup>
import openSea from 'src/asset/socializeLinks/openSea.webp'
import element from 'src/asset/socializeLinks/element.webp'
import blur from 'src/asset/socializeLinks/blur.webp'
import okx from 'src/asset/socializeLinks/okx.webp'
import { OPEN_SEA_PATH, OKX_PATH, BLUR_PATH, ELEMENT_PATH } from 'src/constant'

const props = defineProps({
  isMaxHeight: {
    type: Boolean,
    default: false,
  },
  logoHeight: {
    type: Number,
    default: 27,
  },
})

const list = [
  {
    img: okx,
    link: OKX_PATH,
    height: 1.406,
    maxHeight: 37,
  },
  {
    img: openSea,
    link: OPEN_SEA_PATH,
    height: 1.72,
    maxHeight: 45,
  },
  {
    img: blur,
    link: BLUR_PATH,
    height: 1.927,
    maxHeight: 50,
  },
  {
    img: element,
    link: ELEMENT_PATH,
    height: 1.692,
    maxHeight: 43.5,
  },
]
</script>

<template>
  <ul>
    <template v-for="item in list" :key="item.name">
      <li>
        <a
          :style="{ height: isMaxHeight ? '60px' : '2.34vw' }"
          class="flex-center bg-#15110E/80 block w-full rounded-5px"
          :href="item.link"
          target="_blank"
        >
          <img
            :src="item.img"
            alt=""
            :style="{
              height: isMaxHeight ? `${item.maxHeight}px` : `${item.height}vw`,
            }"
          />
        </a>
      </li>
    </template>
  </ul>
</template>

<style scoped lang="scss">
ul {
  li {
    &:not(:last-child) {
      @apply mb-1.5;
    }
  }
}
</style>
