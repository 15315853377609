import { getPasswordModel, getPasswordRules } from './index'

export function getConfirmPasswordModel() {
  return {
    ...getPasswordModel(),
    confirmPassword: '',
  }
}

export function getConfirmPasswordRules(modelData, passwordMinLength) {
  return {
    ...getPasswordRules(passwordMinLength),
    confirmPassword: [
      {
        required: true,
        message: 'Please enter the confirmation password.',
        trigger: ['input', 'blur'],
      },
      {
        trigger: ['input', 'blur'],
        validator(rule, value) {
          if (value && value !== modelData.password)
            return new Error('The two passwords entered do not match.')

          return true
        },
      },
    ],
  }
}
