<script setup>
import { ref, computed } from 'vue'
import prompt_arrow from 'src/asset/mint/prompt_arrow.webp'
import { hasPhone } from 'src/stores/media'
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'

const props = defineProps({
  supply: Number,
})

// const total = computed(() => props.supply)
const { min2XL } = storeToRefs(useMediaStore())
</script>

<template>
  <div class="left-mask pl-107px pt-44px">
    <div class="flex flex-col flex-items-start">
      <img
        class="w-483px"
        src="/src/asset/soulCardMint/bogx_title.webp"
        alt=""
      />
      <img
        class="w-556px -mt-60px"
        src="/src/asset/soulCardMint/Logo.webp"
        alt=""
      />
      <div class="family_blk mt-9px text-#fff fz-8">COLLECTION</div>
      <div
        class="family_blk mt-10px pl-8px w-300px h-42px text-#fff bg-#0E0E0F border border-solid border-#696B6B/60"
        :class="`fz-${min2XL ? 5 : 4}`"
      >
        TOTAL SUPPLY:<span class="text-#9668F4 leading-45px ml-1.5">{{
          props.supply
        }}</span>
      </div>
      <div class="w-488px mt-13.5px text-#9A9A9A">
        Soul Cards are vital assets in the game, influencing both your soul
        power and skill effects. They offer unique experiences and enhance
        gameplay in distinctive ways.
        <br />Soul Cards are vital assets in the game, influencing both your
        soul power and skill effects. They offer unique experiences and enhance
        gameplay in distinctive ways. They offer unique experiences and enhance
        gameplay in distinctive ways...
      </div>

      <div class="round-bg px-10px pt-1 mt-50px w-492px h-89px text-#fff">
        <div>How can I transfer USDC to Immutable zkEVM?</div>
        <div class="text-#AA81FF">
          Please use the Immutable Bridge or CEX Deposit to transfer USDC from
          other EVM chains or exchanges to Immutable zkEVM.
        </div>
      </div>
      <div class="mt-30px text-#AA81FF">
        <div>Designed by</div>
        <img class="mt-8px w-132px" src="/src/asset/labs_logo.webp" alt="" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.left-mask {
  @include bg100('/src/asset/soulCardMint/Left_mask.webp');
}

.round-bg {
  @include bg100('/src/asset/soulCardMint/Round_BG.webp');
}
</style>
