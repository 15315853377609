import { useEventListener } from '@vueuse/core'
import { storeToRefs } from 'pinia'
import { useMediaStore } from 'src/stores/media'
import { watch } from 'vue'

export function useScreenSizeChange(cd) {
  const { width, height } = storeToRefs(useMediaStore())
  watch([width, height], cd, {
    immediate: true,
  })
  useEventListener(
    window,
    'pageshow',
    e => {
      if (e.persisted) {
        cd()
      }
    },
    { passive: true },
  )
}
