<script setup>
import { NSpin } from 'naive-ui'
import ItemModal from 'src/components/ItemModal'
import { watch } from 'vue'

const props = defineProps({
  show: Boolean,
  isLoading: Boolean,
  wallet: String,
})
const emit = defineEmits(['update:show', 'confirm'])
function handleClose() {
  emit('update:show', false)
}
function handleConfirm() {
  emit('confirm')
  // emit('update:show', false)
}

watch(
  () => props.wallet,
  val => {
    if (val) {
      emit('update:show', false)
    }
  },
)
</script>

<template>
  <ItemModal
    :show="props.show"
    confirm-text="Continue"
    @cancel="handleClose"
    @confirm="handleConfirm"
  >
    <div class="pt-4 relative">
      <img
        src="/src/asset/exclamation_mark.webp"
        class="m-a block h-[46px]"
        alt=""
      />
      <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
        <div>Immutable Account Binding</div>
        <div>
          Unbinding is not supported at this time. Please proceed with caution.
        </div>
      </div>
      <div v-if="isLoading">
        <NSpin :size="48" class="absolute-center"></NSpin>
      </div>
    </div>
  </ItemModal>
</template>
