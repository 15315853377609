<script setup>
import {
  NConfigProvider,
  NMessageProvider,
  NNotificationProvider,
  darkTheme,
  NLoadingBarProvider,
} from 'naive-ui'
import { ptr } from 'src/sdk/media'

import ContainerVue from './Container'
import { hasPhone } from './stores/media'

const playBorder = '#BCC6CD'
const border = '1px dashed'
const error = '#ea3d3d'
const inputColor = '#040505'
const borderColor = '#3D3E3E'
const primaryColor = '#9E4FE8'
const placeholderColor = '#757B7E'
const colorActive = '#fff'
const optionColorActive = '#141516'
const inputHeight = hasPhone ? '35px' : undefined
const borderErrorColor = '#e98b8b'
const themeOverrides = {
  common: {
    // bodyColor: '#000',
    baseColor: colorActive,
    errorColor: error,
    primaryColor,
    primaryColorHover: primaryColor,
  },

  Dialog: {
    closeMargin: ptr(18),
  },
  Input: {
    caretColor: playBorder,
    color: inputColor,
    colorFocusError: inputColor,
    border: `${border} ${borderColor}`,
    borderActive: `${border} ${playBorder}`,
    borderFocus: `${border} ${playBorder}`,
    borderHover: `${border} ${playBorder}`,
    borderError: `${border} ${error}`,
    colorFocus: inputColor,
    borderFocusError: `${border} ${borderErrorColor}`,
    borderHoverError: `${border} ${borderErrorColor}`,
    placeholderColor,
    colorActive: inputColor,
    heightMedium: inputHeight,
  },
  Select: {
    peers: {
      InternalSelection: {
        caretColor: playBorder,
        color: inputColor,
        colorFocusError: inputColor,
        border: `${border} ${borderColor}`,
        borderActive: `${border} ${playBorder}`,
        borderFocus: `${border} ${playBorder}`,
        borderHover: `${border} ${playBorder}`,
        borderError: `${border} ${error}`,
        borderFocusError: `${border} ${borderErrorColor}`,
        borderHoverError: `${border} ${borderErrorColor}`,
        placeholderColor,
        colorActive: inputColor,
        colorFocus: inputColor,
      },
      InternalSelectMenu: {
        optionTextColorActive: colorActive,
        optionColorActive,
        optionColorActivePending: optionColorActive,
        optionColorPending: optionColorActive,
        optionTextColor: placeholderColor,
        color: '#343A40',
        optionTextColorPressed: colorActive,
      },
    },
  },
  Form: {
    feedbackPadding: !hasPhone ? undefined : '2px 0 0 1px',
    feedbackHeightMedium: !hasPhone ? undefined : '20px',
    feedbackFontSizeMedium: !hasPhone ? undefined : '12px',
    blankHeightMedium: inputHeight,
  },
}
</script>

<template>
  <NConfigProvider :theme="darkTheme" :theme-overrides="themeOverrides">
    <NLoadingBarProvider>
      <NMessageProvider>
        <NNotificationProvider>
          <ContainerVue></ContainerVue>
        </NNotificationProvider>
      </NMessageProvider>
    </NLoadingBarProvider>
  </NConfigProvider>
</template>
