export const backersInfoMap = {
  1: {
    title: 'LongHash Ventures',
    link: 'https://www.longhash.vc/',
    desc: `For anyone still doubting that Web3 has good games,<br> Look no further @BladeofgodX is the most polished mobile game we have seen in the web3 space.<br><br> And the AI agent product is the closest to production among the many who are chasing this narrative. <br><br>This is made possible because of the perfectly complementary background from Amber and Tnise, <br>covering both crypto natives and traditional gaming.`,
  },
  2: {
    title: 'OKX Ventures',
    link: 'https://www.okx.com/',
    desc: `We are excited to announce our investment in @BladeofgodX.<br><br>Looking forward to witnessing the evolution of interactive entertainment and gaming innovation.`,
  },
  3: {
    title: 'BreederDAO',
    link: 'https://www.breederdao.io/',
    desc: `BreederDAOs is proud to support @BladeofgodX alongside these industry titans!<br><br>We are always committed to championing groundbreaking projects steered by top-tier teams.<br><br>With @0xAmberBella and her team leading this vision, great innovations are definitely coming.`,
  },
  4: {
    title: 'Yield Guild Games',
    link: 'https://www.yieldguild.io/',
    peopleImg: 'people.webp',
    name: 'Gabby Dizon',
    job: 'Founder of Yield Guild Games (YGG)',
    desc: `We are excited to back @BladeofgodX with a stellar group of investors - very excited about this game!<br><br> @0xAmberBella and her team have been great to work with and I'm excited to see them bring BOGX to the rest of the world.`,
  },
  5: {
    title: 'Delphi Digital',
    link: 'https://delphidigital.io/',
    name: 'JACL',
    job: 'Head of Gaming Research at Delphi Digital',
    desc: `One of the more practical implementations of AI agents.<br><br>Train & fine-tune during typical gameplay sessions to unlock new AFK features.`,
  },
  6: {
    title: 'Newman Capital',
    link: 'https://newmancapital.com/',
    desc: `Congratulations to @BladeofgodX!<br><br>We're thrilled to support Blade of God X,<br>
        An innovative project merging Action Role-Playing Game with AI Agent training.<br><br>
        Exciting times ahead for this game-changing venture!`,
  },
  7: {
    title: 'King River Capital',
    link: 'https://kingriver.co/',
    desc: ``,
  },
  8: {
    title: 'Eden Ventures',
    link: 'https://www.ed3n.ventures/',
    desc: `BOGX will be the leading game that showcases how AI can complement Triple A Gameplay.`,
  },
  9: {
    title: '071LABS',
    link: 'https://www.071labs.io/',
    desc: `We are excited to announce that we have supported Blade of godx,<br> which we hope will become a revolution in the world of Web3 gaming,<br> starting with a medium for human interaction with virtual worlds.`,
  },
  10: {
    title: 'GiulioX',
    link: 'https://x.com/GiulioXdotEth/status/1787837587455201741',
    name: 'CEO of Pixelmon',
    job: 'Co-Founder of MON Protocol,<br> LiquidX Studio, and Zalora',
    desc: `Very glad I was able to support these guys - seeing some of if not the best mobile development teams coming out of mainland China this year.<br><br> The tier 1 investor support and sector (blockchain gaming + Ai) made this a no brainer already - but what really got me was the art and care they put in developing the IP as a whole.`,
    isPerson: 1,
  },
}
