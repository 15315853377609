import {
  getAccountNameModel,
  getAccountNameRules,
  getCodeModel,
  getCodeRules,
  getConfirmPasswordModel,
  getConfirmPasswordRules,
  getEmailModel,
  getEmailRules,
} from '../common'

export function getRegisterModel() {
  return {
    ...getAccountNameModel(),
    ...getEmailModel(),
    ...getCodeModel(),
    ...getConfirmPasswordModel(),
  }
}

export function getRegisterRules(
  modelData,
  accountMinLength,
  passwordMinLength,
) {
  return {
    ...getAccountNameRules(accountMinLength),
    ...getEmailRules(),
    ...getCodeRules(),
    ...getConfirmPasswordRules(modelData, passwordMinLength),
  }
}
