import abi from 'src/sdk/blockchain/abi/pfpAbi.json'
import { NFT } from '../nft'
import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import { useUserInfoStore } from 'src/stores/user'
import { fetchPFPList, fetchPFPSig } from 'src/api'
import { parseUnits } from 'viem'
class PFPNFT extends NFT {
  constructor() {
    super()
    this.setAbi(abi)
  }
  async mint(args, number) {
    const { bindAddressComputed } = storeToRefs(useUserInfoStore())
    const { phaseComputed, currentPhaseWLComputed, mintInfo } =
      storeToRefs(usePFPMintStore())
    const phase = phaseComputed.value
    const signature = await fetchPFPSig({
      address: bindAddressComputed.value,
      phase,
    })
    const isProd = import.meta.env.VITE_MODE === 'prod'
    // const value = !isProd
    //   ? parseUnits(String(mintInfo.value?.price * number))
    //   : parseUnits(String(1 * number))
    const value = isProd
      ? parseUnits(String(mintInfo.value?.price * number))
      : parseUnits(String(1 * number))

    await super.mint({
      args: [phase, number, currentPhaseWLComputed.value, signature],
      value,
      ...args,
    })
    return this.fetchNewPFPList(number)
  }
  /**
   *
   * @param {Array<bigInt | string | number>} tokenIds
   * @returns Array<object>
   */
  async tokenIdsToNFTList(tokenIds) {
    const list = await fetchPFPList()
    return tokenIds.map(id => list[Number(id) - 1])
  }
  /**
   * 获取仓库的pfp列表
   * @returns Array<object>
   */
  async fetchMyPFPList() {
    const tokenIds = await this.getTokenIds()
    return this.tokenIdsToNFTList(tokenIds)
  }
  //
  /**
   * 获取最新的数个pfp，用于mint成功后，弹窗调用
   * @param {string | number} number
   * @returns Array<object>
   */
  async fetchNewPFPList(number) {
    const tokenIds = await this.getNewTokenIds({ number })
    console.log('tokenIds', tokenIds)
    return this.tokenIdsToNFTList(tokenIds)
  }
}

export const pfpNFT = new PFPNFT()
