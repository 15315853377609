export const MUSIC_PATH = `${import.meta.env.VITE_FILE_PATH}/music/`

// 外部链接
export const DISCORD_PATH = 'https://discord.gg/bogx'
export const TWITTER_PATH = 'https://twitter.com/BladeofgodX'
export const XTERIO_PATH = 'https://xter.io/games/5b933434e2e9'
export const EPIC_STORE_PATH = 'https://store.epicgames.com/en-US/p/bogx-a639f3'
export const OPEN_SEA_PATH =
  'https://opensea.io/collection/bogx-genesis-orisols'
export const OKX_PATH =
  'https://www.okx.com/web3/marketplace/nft/collection/eth/bogx-genesis-orisols'
export const BLUR_PATH = 'https://blur.io/collection/bogx-genesis-orisols'
export const ELEMENT_PATH =
  'https://element.market/collections/bogx-genesis-orisols'
