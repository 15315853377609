import { ref, computed, watch } from 'vue'
import { getFiltersData } from './data'
import { shuffle, throttle } from 'lodash-es'
import { fetchPFPList } from 'src/api'
import { ATTRIBUTES, NFP_TYPE, NFP_VALUE } from 'src/constant'
import { storeToRefs } from 'pinia'
import { useThemeStore } from 'src/stores/theme'
import { hasPFPSpecies } from 'src/helpers/nft'
import { useSortList } from './hooks/useSortList'
export function useList(pageSizeRef, distanceRef) {
  const { hasThemeGreen } = storeToRefs(useThemeStore())
  // 列表
  const listRef = ref([])
  const unitRef = ref(15)
  const { sortListComputed } = useSortList(listRef, unitRef)
  // 显示列表
  const viewListRef = ref([])
  // 左侧栏
  const filterListRef = ref([])
  const filterRef = ref(null)
  const pageRef = ref(0)
  fetchPFPList().then(list => {
    filterListRef.value = getFiltersData(list)
    listRef.value = list
  })

  // 左侧栏组件
  const filterListComputed = computed(
    () => filterRef.value?.filterItemList || [],
  )
  // 组合 type 并集
  const filterTypeListComputed = computed(() => {
    const map = new Map()
    filterListComputed.value.forEach(filter => {
      if (hasFilterInput(filter)) {
        map.set(filter.type, filter)
      } else {
        const arr = map.get(filter.type) || []
        arr.push(filter)
        map.set(filter.type, arr)
      }
    })
    return Array.from(map.entries()).map(([type, filters]) => filters)
  })
  // 符合条件的列表
  const listFilterComputed = computed(() => {
    let list = sortListComputed.value
    const filterList = filterTypeListComputed.value
    // 克苏鲁，旧日支配者
    const oldOnes = hasThemeGreen.value
    if (filterList.length) {
      return list.filter(item => {
        const oldOnesB = oldOnes ? hasPFPSpecies(item) : true
        if (!oldOnesB) {
          return false
        }
        const filterB = filterList.every(filters => {
          if (hasFilterInput(filters)) {
            return inputFilter(filters, item)
          } else {
            return attributesSome(filters, item)
          }
        })
        return filterB
      })
    } else {
      return oldOnes ? list.filter(item => hasPFPSpecies(item)) : list
    }
  })
  function hasFilterInput(filter) {
    return filter.type === 'input'
  }
  function attributesSome(filters, item) {
    const attribute = item[ATTRIBUTES].find(
      attribute => attribute[NFP_TYPE] === filters[0].type,
    )
    return filters.some(filter => attribute?.[NFP_VALUE] === filter.name)
  }
  function inputFilter(filter, item) {
    const filterName = filter.name.toLocaleUpperCase()
    return item[ATTRIBUTES].some(attribute =>
      attribute[NFP_VALUE].toLocaleUpperCase().includes(filterName),
    )
  }
  function handleFilterClose(item) {
    filterRef.value.handleFilterItem(item, false)
  }
  function getPageData(page) {
    viewListRef.value = listFilterComputed.value.slice(
      0,
      pageSizeRef.value * (page + 1),
    )
  }
  function nextPage() {
    if (viewListRef.value.length < listFilterComputed.value.length) {
      getPageData(++pageRef.value)
    }
  }

  function handleRandom() {
    listRef.value = shuffle(listRef.value)
    getPageData(pageRef.value)
  }
  function handleListScroll(event) {
    if (
      event.srcElement.scrollTop + event.srcElement.clientHeight >=
      event.srcElement.scrollHeight - distanceRef.value
    ) {
      nextPage()
    }
  }
  const throttleListScroll = throttle(handleListScroll, 100)
  watch(
    listFilterComputed,
    () => {
      pageRef.value = 0
      getPageData(0)
    },
    {
      immediate: true,
    },
  )
  return {
    filterRef,
    filterListComputed,
    filterListRef,
    handleFilterClose,
    viewListRef,
    nextPage,
    handleRandom,
    throttleListScroll,
    listFilterComputed,
  }
}

export function useDetail() {
  const detailShowRef = ref(false)
  const detailRef = ref({})
  function handleDetail(item) {
    detailShowRef.value = true
    const leftList = []
    const rightList = []
    const species = {
      Mortal: 'gray',
      'Frost Giant': 'blue',
      Dragon: 'orange',
      Elf: 'pink',
      Deity: 'black',
      'Old Ones': 'green',
    }
    item.attributes.forEach((item, index) => {
      const data = {
        name: item[NFP_TYPE].toLocaleUpperCase(),
        icon: 'PFP-filter-' + item[NFP_TYPE].toLocaleUpperCase(),
        value: item.value,
      }
      if (index % 2 === 0) {
        leftList.push(data)
      } else {
        rightList.push(data)
      }
    })
    detailRef.value = {
      ...item,
      color: species[item.Species],
      leftList,
      rightList,
    }
  }
  return {
    detailShowRef,
    handleDetail,
    detailRef,
  }
}
