import { ref } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { getProducts } from 'src/api'
import { errorCodeToMessage } from 'src/helpers/utils'

export function useImmutableIncome() {
  const walletaddr = ref('')
  const productsList = ref([])
  const { execute: executeList } = useAsyncState(
    async () => {
      const data = await getProducts()
      console.log(data)
      productsList.value = data
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )
  executeList()
  return {
    walletaddr,
    productsList,
    executeList,
  }
}
