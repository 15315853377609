<script setup>
import { fetchSoftPledgeHistory } from 'src/api'
import ModelBox from './ModelBox'
import HistoryTable from './HistoryTable'
import Pagination from './Pagination'
import { useAsyncState } from '@vueuse/core'
import { watch, ref, useAttrs } from 'vue'
const props = defineProps({
  headerText: String,
  type: Number, //1日常记录； 2软质押记录
  rows: {
    type: Number,
    default: 20,
  },
  hasNFT: Boolean,
})
const attrs = useAttrs()
const listRef = ref([])
const totalRef = ref(0)
const pageRef = ref(1)
const { execute: executeList, isLoading: listLoadingRef } = useAsyncState(
  fetchList,
  null,
  {
    immediate: false,
  },
)
async function fetchList(page) {
  const { title } = await fetchSoftPledgeHistory({
    page,
    rows: props.rows,
    record_type: props.type,
  })
  pageRef.value = page
  listRef.value = title.rows
  totalRef.value = title.total
}
function handleChange(page) {
  if (listLoadingRef.value) return
  executeList(0, page)
}
watch(
  () => attrs.show,
  value => {
    if (value) {
      listRef.value = []
      handleChange(1)
    }
  },
  {
    immediate: true,
  },
)
</script>
<template>
  <ModelBox title="HISTORY" :headerText="headerText">
    <HistoryTable :list="listRef" :hasNFT="hasNFT"></HistoryTable>
    <Pagination
      @update:page="handleChange"
      :page="pageRef"
      :total="totalRef"
      :rows="rows"
      class="absolute right-8 bottom-4"
    ></Pagination>
  </ModelBox>
</template>
