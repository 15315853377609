import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from 'lenis'
import { hasPhone } from 'src/stores/media'

export class SmoothScrolling extends Set {
  requestAnimationFrameId
  constructor(options) {
    super()
    this.add(handleGsapScrroll)
    // this._scrollFn = this.scrollFn.bind(this)
    this._handleScroll = this.handleScroll.bind(this)
    this._tickerFunction = this.tickerFunction.bind(this)
    this.lenis = new Lenis({
      ...options,
      lerp: 0.1, // 值越小平滑效果越明显
      smoothWheel: true, // 为鼠标滚轮事件启用平滑滚动
    })
    gsap.ticker.add(this._tickerFunction)

    gsap.ticker.lagSmoothing(0)
  }

  get isScroll() {
    return !!this.lenis?.velocity
  }

  init() {
    // 每次用户滚动时更新ScrollTrigger
    this.lenis.on('scroll', this._handleScroll)
    // 启用动画帧
    // this.requestAnimationFrameId = requestAnimationFrame(this._scrollFn)
  }

  // scrollFn(time) {
  //   // 每一帧动画执行
  //   this.lenis.raf(time) // lenis中requestAnimationFrame方法
  //   this.requestAnimationFrameId = requestAnimationFrame(this._scrollFn) // 递归调用
  // }

  handleScroll(event) {
    this.forEach(item => {
      item(event)
    })
    return this
  }

  unload() {
    // this.lenis.off('scroll', this._handleScroll)
    cancelAnimationFrame(this.requestAnimationFrameId)
    gsap.ticker.remove(this._tickerFunction)
    gsap.ticker.lagSmoothing()
    this.lenis.destroy()
    return this
  }

  tickerFunction(time) {
    this.lenis.raf(time * 1000)
  }
}

function handleGsapScrroll(event) {
  ScrollTrigger.update(event)
}

let scrollSingleton = null
export function initSmoothScrolling(options) {
  // if (hasPhone) return
  if (!scrollSingleton) {
    scrollSingleton = new SmoothScrolling(options)
    scrollSingleton.init()
  }
}
export function scrollingDestruction() {
  // if (hasPhone) return
  if (scrollSingleton) {
    scrollSingleton.unload()
    scrollSingleton = null
  }
}
export function getSmoothScrolling() {
  // if (hasPhone) return
  return scrollSingleton
}

export function scrollLock(has) {
  if (hasPhone) return
  if (has) {
    getSmoothScrolling()?.lenis?.stop()
  } else {
    getSmoothScrolling()?.lenis?.start()
  }
}
