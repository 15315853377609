<script setup>
import { ReceiveJobItemMap } from 'src/constant'
import { hasPhone } from 'src/stores/media.js'
import { getType } from '../utils.js'
import TransferItem from './TransferItem.vue'
import { useBox } from '../hooks/useBox'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  selectData: {
    type: Array,
    default() {
      return []
    },
  },
  roleList: {
    type: Array,
    default() {
      return []
    },
  },
  singleChoiceData: {
    type: Object,
    default() {
      return []
    },
  },
})
const emit = defineEmits(['select', 'cancel', 'enlarge', 'submit', 'check'])

const {
  hasSelectRef,
  qualityRef,
  hasSelectClassRef,
  multipleSelectionModeRef,
  handleSelect,
  handleEnlarge,
  handleSubmit,
  handleStopPropagation,
  handleCheck,
} = useBox(props, emit)
</script>

<template>
  <div
    class="item overflow-hidden bg-#0E0E0F"
    :class="{ select: hasSelectClassRef }"
    @click="handleCheck"
  >
    <div class="box_item">
      <div
        class="cover_bg m-a h-full w-full"
        :style="{
          backgroundImage: `url(${data.Icon})`,
        }"
      ></div>
      <img
        v-if="!hasPhone"
        src="/src/asset/enlarge.webp"
        class="mouse_pointer absolute bottom-1 right-1 w-[24px]"
        @click="handleEnlarge"
      />
    </div>
    <div
      class="fz-3 border_style footer relative border-0 border-t px-1.5 py-1"
    >
      <div>
        <div class="flex-sb">
          <div class="flex-center">
            <span class="ellipsis-1 mr-2">{{ data.Name }}</span>
            <span v-if="!hasPhone" class="flex-shrink-0 text-#C0C5CC"
              >BOGX {{ data.Id }}</span
            >
          </div>
          <div
            v-if="!data.ReceiveUid"
            class="flex-center ml-1 h-[15px] w-[15px]"
            @click="handleSelect"
          >
            <div
              class="mouse_pointer relative h-[12px] w-[12px] border border-#A0A3A5 border-solid md:h-[15px] md:w-[15px]"
            >
              <img
                v-show="hasSelectRef"
                src="/src/asset/white_tick.webp"
                class="w-[9px] align-super md:w-[12px]"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="flex items-center tracking-1px">
          <span class="gray-color mr1">Type: </span>
          <span>{{ getType(data.ItemId, data.ItemType) }}</span>
        </div>
        <div class="box_item_rarity tracking-1px">
          <div class="flex items-center pt-0.5">
            <span class="gray-color mr1">Rarity: </span>
            <span :style="{ color: qualityRef.color }">{{
              qualityRef.text
            }}</span>
          </div>
          <span>{{ ReceiveJobItemMap[data.ReceiveJob] }}</span>
        </div>
      </div>
      <TransferItem
        v-if="!hasPhone && !data.ReceiveUid"
        class="absolute"
        :class="{ come_out: !multipleSelectionModeRef }"
        :data="data"
        :select-data="selectData"
        :role-list="roleList"
        @click="handleStopPropagation"
        @submit="handleSubmit"
      ></TransferItem>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../border';

.select {
  .cover_bg {
    transform: scale(1.1);
  }
}

.footer {
  &:hover {
    .come_out {
      transform: translateY(-1px);
    }
  }
}
</style>
