import { ElasticRolling } from 'src/helpers/elasticRolling'

let elasticRolling = null
export function initElasticRolling(...options) {
  // if (hasPhone) return
  if (!elasticRolling) {
    elasticRolling = new ElasticRolling(...options)
  }
}
export function scrollingDestruction() {
  // if (hasPhone) return
  if (elasticRolling) {
    // elasticRolling.unload()
    elasticRolling = null
  }
}
export function getElasticRolling() {
  // if (hasPhone) return
  return elasticRolling
}
