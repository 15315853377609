<script setup>
import Modal from 'src/components/Modal'

const props = defineProps({
  show: Boolean,
  mintList: {
    type: Array,
    default: [],
  },
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="px-64px py-34px flex flex-col flex-items-center bg-#0E0E0F border border-solid border-#696B6B rounded-5px"
    >
      <img class="w-156px" src="src/asset/PFP/mint/mint_fail.webp" alt="" />
      <div class="flex">
        <div
          v-for="item in props.mintList"
          class="bg-#2E2E2E mr-33px last:mr-0 mt-5px flex flex-col w-299px h-374px border border-solid border-#515E60 rounded-5px"
        >
          <img
            class="w-full h-299px rounded-t-5px"
            src="/src/asset/mint/bad.webp"
            alt=""
          />
          <div class="flex-1 flex-center fz-5">miss</div>
        </div>
      </div>
      <div class="fz-4 mt-28px text-#FFBF6C text-center">
        <div>Oops! Looks like something went wrong…</div>
        <div>We hit a snag! Try again later!</div>
      </div>
      <div
        @click="handleClose"
        class="mouse_pointer mt-60px flex-center text-#000000 bg-#91A2AC w-186px h-38px border border-solid border-#6B7B87 rounded-5px"
      >
        confirm
      </div>
    </div>
  </Modal>
</template>
