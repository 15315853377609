import { watch } from 'vue'
import { useMusicStore } from 'src/stores/music'
import { storeToRefs } from 'pinia'

export function useRestBGM(restRef) {
  const store = useMusicStore()
  const { bgmAudioPause, bgmAudioPlay } = store
  const { pauseBGMRef, historyBgmAudioRef } = storeToRefs(store)
  watch(restRef, value => {
    if (value) {
      // 不加定时器会影响到全局点击播放逻辑
      setTimeout(() => {
        historyBgmAudioRef.value = pauseBGMRef.value
        bgmAudioPause()
      }, 30)
    } else {
      historyBgmAudioRef.value ? bgmAudioPause() : bgmAudioPlay()
    }
  })
}
