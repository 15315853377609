<script setup>
import Panel from './Panel'
import Header from './Header'
import Price from './Price.vue'
import Description from './Description.vue'
import { useTheme } from './useTheme'
import { useTime } from 'src/pages/PFP/hooks/time'
import { storeToRefs } from 'pinia'
import { usePFPMintStore } from 'src/stores/pfp'
import Countdown from '../Countdown.vue'
import { computed } from 'vue'
const props = defineProps({
  phase: Number,
  lineTargeDom: Object,
})
const { phaseComputed, mintInfo } = storeToRefs(usePFPMintStore())
const { classComputed } = useTheme(props)
const {
  serverTimeComputed,
  startTimeComputed,
  endTimeComputed,
  handleTimeEnd,
} = useTime()
const wlComputed = computed(() =>
  props.phase === 1 ? mintInfo.value?.phase1WL : mintInfo.value?.phase2WL,
)
</script>
<template>
  <Panel
    :phase="phase"
    class="box"
    :class="classComputed"
    :lineTargeDom="lineTargeDom"
  >
    <Header :phase="phase"></Header>
    <Price></Price>
    <div class="text-color 2xl:mb-1.5 md:mb-1">
      You can mint {{ wlComputed }} BOGX Ethereal NFTs in this phase
    </div>
    <Description :phase="phase" class="2xl:mb-4 md:mb-2.5"></Description>
    <Countdown
      v-if="phase === phaseComputed"
      :start-time="startTimeComputed"
      :end-time="endTimeComputed"
      :current-time="serverTimeComputed"
      @end="handleTimeEnd"
    ></Countdown>
  </Panel>
</template>
<style scoped lang="scss">
@import './themen';
</style>
