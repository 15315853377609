import { formatEther, getAddress, getContract } from 'viem'
import abi from './abi/genesisAbi.json'
import { accountRef, createClient } from '.'

export async function getUserContractData({
  chainId,
  contractAddress,
  accountAddress = accountRef.value?.address,
}) {
  const { publicClient } = await createClient(chainId, accountAddress)
  const params = {
    address: getAddress(contractAddress),
    abi,
  }
  const [totalMintCount, balance20] = await Promise.all([
    publicClient.readContract({
      ...params,
      functionName: 'totalSupply',
    }),
    publicClient.readContract({
      ...params,
      functionName: 'balanceOf',
      args: [accountAddress],
    }),
  ])
  console.log('totalMintCount, balance20', totalMintCount, balance20)
  const nftCount = formatEther(balance20)

  return {
    totalMintCount,
    nftCount,
  }
}
export async function freeMintNFT(
  { chainId, contractAddress, accountAddress = accountRef.value?.address },
  sig,
) {
  const { publicClient, walletClient } = await createClient(
    chainId,
    accountAddress,
  )
  const { request } = await publicClient.simulateContract({
    address: getAddress(contractAddress),
    abi,
    functionName: 'freeMint',
    // args: [toBytes(sig)],
    args: [sig],
    account: accountAddress,
  })

  const txhash = await walletClient.writeContract(request)

  console.log(`txHash${txhash}`)

  const transaction = await publicClient.waitForTransactionReceipt({
    hash: txhash,
  })
  console.log(transaction)
}
// 获取最新的nft id，mint完成展示
export async function getGenesisNewTokenId({
  chainId,
  contractAddress,
  accountAddress = accountRef.value?.address,
}) {
  const list = await fetchGenesisNFTList({
    chainId,
    contractAddress,
    accountAddress,
  })
  const tokenId = list.at(-1)
  return tokenId
}
// 获取nft list
export async function fetchGenesisNFTList({
  chainId,
  contractAddress,
  accountAddress = accountRef.value?.address,
}) {
  const { publicClient } = await createClient(chainId, accountAddress)
  const contract = getContract({
    address: contractAddress,
    abi,
    publicClient,
  })
  const list = await contract.read.tokensOfOwner([accountAddress])
  return list
}
