<script setup>
import { ref } from 'vue'
const domRef = ref(null)
defineExpose({
  dom: domRef,
})
</script>
<template>
  <div class="model" ref="domRef">
    <div class="box"><slot></slot></div>
  </div>
</template>

<style scoped>
.model {
  @apply absolute h-full w-full top-0 left-0 p-4 pb-2 z-1;
}

.box {
  background: rgb(6 13 9 / 92%);

  @apply h-full w-full border-#235042 border border-solid;
}
</style>
