<script setup>
import { NInput } from 'naive-ui'
import SvgIcon from 'src/components/SvgIcon'
import { useFilterItem } from './index.js'
import Container from './Container'
import CheckBoxItem from './CheckBoxItem'
import { useThemeOverrides } from 'src/pages/PFP/useThemeOverrides'
const emit = defineEmits(['change'])
const props = defineProps({
  data: {
    type: Object,
    default() {
      return {
        childrens: [],
      }
    },
  },
})
const {
  showRef,
  inputValueRef,
  containerRef,
  containerScrollRef,
  checkListComputed,
  checkBoxListRef,
  showToggle,
  handleCheck,
  iconColorComputed,
  selectComputed,
} = useFilterItem(props.data, emit)
const { inputThemeOverridescComputed } = useThemeOverrides()
</script>
<template>
  <div class="box-border" :class="{ show_box: selectComputed }">
    <div
      class="flex-sb px-24px h-53px mouse_pointer hover:bg-[--pfp-bg2] bg-[--pfp-bg3] rounded-1.25"
      @click="showToggle"
      :class="{ show_box_header: selectComputed }"
    >
      <div class="flex items-center">
        <SvgIcon
          :name="data.icon"
          size="22px"
          class="mr-2"
          :color="iconColorComputed"
        ></SvgIcon>
        <div class="fz-2 text-[--pfp-color4]">
          {{ data.name.toLocaleUpperCase() }}
        </div>
      </div>
      <div class="triangle" :class="{ triangle_up: showRef }"></div>
    </div>
    <div class="container_box bg-#131516">
      <Transition>
        <Container
          v-show="showRef"
          class="overflow-y-auto content"
          ref="containerRef"
        >
          <div :class="{ 'mr-2': containerScrollRef }">
            <NInput
              class="item_box"
              v-model:value="inputValueRef"
              :theme-overrides="inputThemeOverridescComputed"
              placeholder="Sort by serial..."
            ></NInput>
            <template v-for="item in checkListComputed" :key="item.id">
              <CheckBoxItem
                ref="checkBoxListRef"
                class="item_box"
                @change="(...args) => handleCheck(item, ...args)"
                :name="item.name"
                :number="item.number"
              ></CheckBoxItem>
            </template>
          </div>
        </Container>
      </Transition>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../index';

.show_box {
  border: solid #6b7b87 1px;
}

.container_box {
  border-radius: 0 0 5px 5px;
}

.content {
  max-height: 300px;
}

.box-border.show_box {
  .show_box_header {
    @apply text-[--pfp-color] bg-[--pfp-bg2];
  }
}

.triangle {
  @include triangle($color: var(--pfp-color4));
}

.v-enter-active,
.v-leave-active {
  transition: max-height 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  max-height: 0;
}
</style>
