<script setup>
import ItemModal from 'src/components/ItemModal'

const props = defineProps({
  show: Boolean,
  text: String,
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <ItemModal
    :show="props.show"
    :loading="submitLoading"
    width="425px"
    @update:show="handleClose"
    @cancel="handleClose"
  >
    <div class="pt-4">
      <img
        src="/src/asset/exclamation_mark.webp"
        class="m-a block h-[46px]"
        alt=""
      />
      <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
        {{ text }}
      </div>
    </div>
  </ItemModal>
</template>
