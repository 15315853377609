<script setup>
import addIcon from 'src/asset/nftSoftStaking/deck/pfp/add_icon.webp'
import { NPopover } from 'naive-ui'
import { constantCase } from 'change-case'
import { useRouter } from 'vue-router'
import { PFP_MINT_NAME } from 'src/constant'
import {
  useNFTSoftPledgeDomStore,
  useNFTSoftPledgeStore,
} from 'src/stores/softPledge'
import { ref, onMounted, onUnmounted } from 'vue'
import { storeToRefs } from 'pinia'
const router = useRouter()
const { pfpBoxDom } = storeToRefs(useNFTSoftPledgeDomStore())
const { emptyGuideShow } = storeToRefs(useNFTSoftPledgeStore())

const props = defineProps({
  list: Array,
})
const emit = defineEmits(['edit'])

const typeDict = {
  OLD_ONES: '#73FC90',
  FROST_GIANT: '#73FCF4',
  DEITY: '#73D7FC',
  DRAGON: '#FCA073',
  ELF: '#FFF799',
  MORTAL: '#c0c0c0',
}

const isCopyed = ref(false)
let timer
function copyAddress() {
  copy('0x3e0361c7E8C3C87B2fa55cC042648BeC9990fCD6')
  isCopyed.value = !isCopyed.value
  timer = setTimeout(() => {
    isCopyed.value = false
  }, 2000)
}

const pfpBoxDomRef = ref(null)
function handleClick() {
  router.push({ name: PFP_MINT_NAME })
}

function handleEdit() {
  emit('edit')
}
onMounted(() => {
  pfpBoxDom.value = pfpBoxDomRef.value
})

onUnmounted(() => {
  clearTimeout(timer)
})
</script>

<template>
  <div class="relative mt-0.52vw pt-0.52vw">
    <div class="flex h-2.24vw relative flex-items-end justify-between">
      <div class="mb-0.3125vw">
        <template v-if="!list && !list?.length">
          <img
            class="w-8.54vw mr-17px"
            src="src/asset/nftSoftStaking/ethereal.webp"
            alt=""
          />
          <span class="family_blk_bold text-#D54055 5xl:text-24px"
            >COMING SOON .....</span
          >
        </template>
        <div class="flex flex-items-end" v-else>
          <img
            class="w-8.54vw mr-0.88vw"
            src="src/asset/nftSoftStaking/ethereal1.webp"
            alt=""
          />
          <div class="ml-0px mouse_pointer">
            <div v-if="!isCopyed">
              <NPopover class="BrownPopover" placement="bottom" raw>
                <template #trigger>
                  <img
                    @click="copyAddress"
                    class="mouse_pointer w-1.40vw"
                    src="src/asset/nftSoftStaking/copy_icon.webp"
                    alt=""
                  />
                </template>
                <div
                  class="mouse_pointer flex-center px-0.21vw h-1.35vw rounded-6px color-#F89B58 bg-#15110E border border-solid border-#804D2C text-18px"
                >
                  copy contract address
                </div>
              </NPopover>
            </div>
            <img
              v-else
              :style="{ opacity: isCopyed ? 1 : 0 }"
              class="w-0.937vw"
              src="src/asset/nftSoftStaking/copyed_icon.webp"
              alt=""
            />
          </div>
        </div>
      </div>
      <div
        v-if="list && list?.length"
        @click="handleClick"
        class="btn-mint absolute right-0 flex-center mouse_pointer w-11.82vw h-2.24vw text-1.25vw"
      >
        GO MINT
      </div>
    </div>
    <div
      class="w-full h-21.45vw relative py-1.04vw pb-0.73vw px-1.35vw bg-#0E1512 border border-solid border-#31633F"
    >
      <ul class="flex relative justify-between" ref="pfpBoxDomRef">
        <template v-if="!list && !list?.length">
          <li
            v-for="item in 5"
            :style="{ marginRight: item !== 5 ? '1.14vw' : '0' }"
          >
            <img
              class="w-7.76vw"
              src="src/asset/nftSoftStaking/empty_pfp.webp"
              alt=""
            />
          </li>
        </template>
        <template v-else>
          <li
            v-for="(item, index) in list"
            :style="{ marginRight: index !== list.length - 1 ? '1.14vw' : '0' }"
            class="flex relative z-1 flex-col"
          >
            <img
              v-if="item"
              @click="handleEdit"
              :style="{
                borderColor: item && typeDict[constantCase(item?.Species)],
              }"
              class="mouse_pointer w-7.76vw border border-solid overflow-hidden"
              :src="item?.image"
              alt=""
            />
            <img
              v-else
              @click="handleEdit"
              :src="addIcon"
              class="mouse_pointer w-7.76vw"
              alt=""
            />

            <template v-if="item">
              <div
                class="flex justify-between flex-items-start h-1.14vw text-0.78vw"
              >
                <div
                  class="w-5.2vw h-1.14vw flex-center family_blk text-0.73vw md:tracking-0.2px 3xl:tracking-0.4px"
                  :class="`bg-${constantCase(item?.Species)}`"
                >
                  {{ item?.Species?.toUpperCase() }}
                </div>
                <div class="text-0.625vw tracking-1px h-1.14vw leading-1.14vw">
                  #{{ item?.id }}
                </div>
              </div>
              <div
                class="flex-center h-1.04vw bg-#192520 border border-solid border-#293F36"
              >
                <span class="text-#52D058 text-0.9375vw mr-1">+15 </span>
                <span class="text-0.9375vw">
                  (<img
                    class="w-0.3125vw"
                    src="src/asset/nftSoftStaking/deck/pfp/arrow_top.webp"
                  />
                  50%)</span
                >
              </div>
            </template>
          </li>
          <div
            :style="{ opacity: list?.length >= 5 && list[4] ? 1 : 0 }"
            class="line absolute top-40% left-50% -translate-50% w-94% h-1px z-0 transition-all"
          ></div>
        </template>

        <!-- 指引 -->
        <div
          v-if="emptyGuideShow"
          class="absolute z-1 w-104% h-120% -top-10% -left-2% border border-solid border-#235042"
        >
          <div
            class="mt-10.93vw family_blk_bold px-0.94vw md:text-base 2xl:text-2xl"
          >
            <img
              class="w-1.7vw ml-0.67vw"
              src="src/asset/nftSoftStaking/guide/step/4/arrow1.webp"
              alt=""
            />
            <div class="text-#69F093">ETHEREAL NFT SLOTS</div>
            <span class="text-#fff"
              >Players can freely configure decks based on the bonuses.</span
            >
          </div>
        </div>
      </ul>
      <div
        v-if="!list && !list?.length"
        class="family_blk_bold p-2.08vw flex-center w-full text-#3E5C4F text-center text-1.56vw leading-2.34vw"
      >
        We will soon be launching the<br />
        staking system for Ethereal NFTs.<br />
        Stay tuned for updates!
      </div>
      <div v-else class="w-full relative">
        <div
          class="px-0.57vw py-0.41vw mt-0.938vw w-80% h-9.11vw text-#83A697 text-0.9375vw leading-1.35vw"
        >
          <div>
            1. The base staking points for each Ethereal placed in slot:
            <span class="text-#fff">3.2/hour</span>
          </div>
          <div>
            2. When the Ethereals in slot meets the following conditions, the
            deck's
          </div>
          <div class="ml-1.14vw">
            <span class="text-#FFFFFF">Genesis</span> and
            <span class="text-#FFFFFF">Ethereals</span> receive additional
            bonuses:
          </div>
          <div class="ml-1.14vw flex flex-items-center">
            <div
              class="mr-0.52vw w-0.47vw h-0.47vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
            ></div>
            <div>Ethereals' races form a specific set</div>
          </div>
          <div class="ml-1.14vw flex flex-items-center">
            <div
              class="mr-0.52vw w-0.47vw h-0.47vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
            ></div>
            <div>
              Specific number of
              <span class="text-#FFFFFF">Deity/Old Ones</span> race Avatars
            </div>
          </div>
          <div class="ml-1.14vw flex flex-items-center">
            <div
              class="mr-0.52vw w-0.47vw h-0.47vw bg-#708A8D border-2px border-solid border-#AEBEC0 rotate-45"
            ></div>
            <div>
              Ethereal rarity is <span class="text-#FFFFFF">Epic</span> or
              <span class="text-#FFFFFF">Legendary </span>
            </div>
          </div>
        </div>
        <div class="absolute bottom-1.25vw right-0 text-1.25vw text-#89B393">
          <div
            class="btn-bg mouse_pointer w-9.11vw h-1.77vw mb-30px flex-center"
          >
            View Deck
          </div>
          <div class="btn-bg mouse_pointer w-9.11vw h-1.77vw flex-center">
            Remove All
          </div>
        </div>
      </div>
    </div>

    <!-- 指引 -->
    <div
      v-if="emptyGuideShow"
      class="absolute w-102% h-full top-0 -left-1% bg-#060D09/80 border border-solid border-#235042"
    ></div>
  </div>
</template>

<style lang="scss" scoped>
.btn-mint {
  @include bg100('src/asset/nftSoftStaking/btn_mint_bg.webp');
  @apply hover:bg-[url(src/asset/nftSoftStaking/btn_mint_active_bg.webp)];

  transition: all 0.1s;
}

.btn-bg {
  @include bg100('src/asset/nftSoftStaking/btn_common_bg.webp');
  @apply hover:bg-[url(src/asset/nftSoftStaking/btn_common_active_bg.webp)] hover:text-#83F09D;

  transition: all 0.1s;
}

.bg-OLD_ONES {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/OLD_ONES.webp');
}

.bg-FROST_GIANT {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/FROST_GIANT.webp');
}

.bg-DEITY {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/DEITY.webp');
}

.bg-DRAGON {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/DRAGON.webp');
}

.bg-ELF {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/ELF.webp');
}

.bg-MORTAL {
  @include bg100('src/asset/nftSoftStaking/deck/pfp/MORTAL.webp');
}

.line {
  background: linear-gradient(
    90deg,
    #e9a5a5 0%,
    #b8c1c0 25%,
    #65c0e0 49.88%,
    #aea2db 75%,
    #81c1d9 100%
  );
}
</style>
