<script setup>
import { computed } from 'vue'
const emit = defineEmits(['update:page'])
const props = defineProps({
  rows: Number,
  page: Number,
  total: Number,
})
const pagesComputed = computed(() => Math.ceil(props.total / props.rows) || 1)
function handlePrev() {
  emit('update:page', props.page - 1)
}
function handleNext() {
  emit('update:page', props.page + 1)
}
</script>
<template>
  <div class="flex items-center">
    <span
      v-show="page !== 1"
      class="text-#E8C176 mr-2 mouse_pointer"
      @click="handlePrev"
      ><<</span
    >
    <span>{{ page }}</span>
    <span class="text-#819FA7">/{{ pagesComputed }}</span>
    <span
      v-show="page !== pagesComputed"
      class="text-#E8C176 ml-2 mouse_pointer"
      @click="handleNext"
      >>></span
    >
  </div>
</template>
