<script setup>
import { gsap } from 'gsap'
import Modal from 'src/components/Modal'
import SvgIcon from 'src/components/SvgIcon'
import { nextTick, watch } from 'vue'

const props = defineProps({
  show: Boolean,
  theme: String,
  data: Object,
})
const emit = defineEmits(['update:show'])

let leftListTL, rightListTL
function initAnimation() {
  const options = {
    ease: 'power1.out',
    paused: true,
    stagger: 0.1,
    opacity: 1,
  }
  leftListTL = gsap.from('.pfp-dialog-left', {
    x: -200,
    ...options,
    onStart() {
      rightListTL?.play()
    },
  })
  rightListTL = gsap.from('.pfp-dialog-right', {
    x: 200,
    ...options,
  })
}
watch(
  () => props.show,
  value => {
    if (value) {
      nextTick(() => {
        initAnimation()
        leftListTL?.play()
      })
    } else {
      leftListTL?.kill()
      rightListTL?.kill()
    }
  },
)

function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      :class="theme"
      class="flex-center 4xl:scale-120 4xl:origin-[50%_50%]! 4xl:ease-linear! 4xl:duration-200!"
    >
      <div class="flex">
        <div
          class="bogx-bg bg-[var(--pfp-dialog-border)] relative h-[638px] w-[1408px]"
        >
          <div
            class="inner-box text-[var(--pfp-dialog-color)] flex absolute w-1406px h-636px translate-x-[-50%] translate-y-[-50%] bg-[var(--pfp-dialog-bg)] top-50% left-50%"
          >
            <div
              class="relative pb-26px pt-32px flex-1 flex flex-col flex-items-center"
            >
              <div class="relative">
                <SvgIcon
                  name="PFP-TITLEBG"
                  width="324px"
                  height="52px"
                  class="mb-3.5"
                ></SvgIcon>
                <div class="absolute left-4.5 top-0.5">
                  <div
                    class="text-[var(--pfp-dialog-xs-color)] text-xs -mb-2px"
                  >
                    ORISOLS
                  </div>
                  <div class="family_blk fz-6">{{ data?.id }}</div>
                </div>
                <SvgIcon
                  v-if="false"
                  class="absolute top-6px right-10px text-[var(--pfp-dialog-owned)]"
                  name="PFP-OWNED"
                  width="40.5px"
                  height="39px"
                ></SvgIcon>
              </div>
              <div
                :class="{ active: item.isActive }"
                class="pfp-dialog-left flex flex-items-center mb-2.5 w-324px h-60.5px bg-[var(--pfp-dialog-card-bg)] border border-solid border-[var(--pfp-dialog-border-card)]"
                v-for="item in data?.leftList"
                :key="item.name"
              >
                <div class="px-4 flex flex-items-center">
                  <SvgIcon :name="item.icon" size="22px" class="mr-2"></SvgIcon>
                  <div class="fz-3 ml-2.5">
                    <div
                      :class="`${
                        item.isActive
                          ? 'text-[var(--pfp-dialog-active-color)]'
                          : 'text-[var(--pfp-dialog-xs-color)]'
                      }`"
                      class="text-xs"
                    >
                      {{ item.name }}
                    </div>
                    <div class="family_blk">{{ item.value }}</div>
                  </div>
                </div>
              </div>
              <div
                class="flex absolute left-50% -translate-x-50% bottom-26px w-324px h-68px flex-items-end border-solid border-0 border-t border-[var(--pfp-dialog-border-top)]"
              >
                <SvgIcon name="PFP-WALLET" size="44px" class="mr-5"></SvgIcon>
                <div class="mb-1">
                  <div class="family_blk">0XA104645DF7028F...2B3CB32</div>
                  <div class="text-xs text-[var(--pfp-dialog-xs-color)]">
                    HELD SINCE OCT. 2023
                  </div>
                </div>
              </div>
            </div>
            <div
              :style="{ 'background-image': `url(${data?.image})` }"
              class="w-642px h-638px border-0 border-x border-solid border-[var(--pfp-dialog-image-color)] bg-no-repeat bg-[length:100%_100%]"
            ></div>
            <div class="flex-1 mt-104px flex-center justify-start! flex-col">
              <div
                class="pfp-dialog-right flex flex-items-center mb-2.5 w-324px h-60.5px bg-[var(--pfp-dialog-card-bg)] border border-solid border-[var(--pfp-dialog-border-card)]"
                v-for="item in data?.rightList"
                :key="item.name"
              >
                <div class="px-4 flex flex-items-center">
                  <SvgIcon :name="item.icon" size="22px" class="mr-2"></SvgIcon>
                  <div class="fz-3 ml-2.5">
                    <div class="text-xs text-[var(--pfp-dialog-xs-color)]">
                      {{ item.name }}
                    </div>
                    <div class="family_blk">{{ item.value }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.bogx-bg,
.inner-box {
  clip-path: polygon(
    0 0,
    100% 0,
    100% 30px,
    100% calc(100% - 30px),
    calc(100% - 30px) 100%,
    30px 100%,
    0 calc(100% - 30px),
    0 30px
  );
}

.active {
  clip-path: polygon(
    0 0,
    calc(100% - 14px) 0,
    100% 14px,
    100% 100%,
    14px 100%,
    0 calc(100% - 14px)
  );
  color: var(--pfp-dialog-active-color);
  background-image: url('src/asset/PFP/active_bg.webp');
  background-size: 100% 100%;

  // background: var(--pfp-dialog-active-card-bg);
  border: none;
}
</style>
