import { codeToMessage } from 'src/constant'
import { getBlockchainNeedErrorMessage } from 'src/sdk/blockchain'

export function getHasRouterActivation(matched, name) {
  return matched.some(data => data.name === name)
}

export function errorCodeToMessage(error) {
  let message
  if (error?.data?.code) {
    message = codeToMessage(error?.data?.code)
  } else {
    message = getBlockchainNeedErrorMessage(error)
  }
  window.$message.error(message)
}
