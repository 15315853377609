import { ref, onMounted, onUnmounted } from 'vue'

export function useScrollTop() {
  const isVisible = ref(false)

  const handleScroll = () => {
    isVisible.value = window.scrollY > 200
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  onMounted(() => {
    window.addEventListener('scroll', handleScroll)
  })

  onUnmounted(() => {
    window.removeEventListener('scroll', handleScroll)
  })

  return {
    isVisible,
    handleScroll,
    scrollToTop,
  }
}
