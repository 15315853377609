<script setup>
import { computed } from 'vue'
import { ReceiveJobItemMap, getQuality } from 'src/constant'
import Video from 'src/components/Video'
import { getSoulToknDataId, tokenIdFormat } from '../utils.js'
import TransferItem from './TransferItem.vue'
import { useMintIng } from '../hooks/useMintIng'
import { getBtnName } from '../utils'

const props = defineProps({
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  roleList: {
    type: Array,
    default() {
      return []
    },
  },
  hidenNFTVideo: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['close', 'submit'])
const VideoSrc = `${import.meta.env.VITE_FILE_PATH}/title_animation.mp4`
const dataComputed = computed(() => props.data)
const { hasMintComputed, hasMintIngComputed } = useMintIng(dataComputed)
function handleSubmit(...data) {
  emit('submit', ...data)
}
const qualityRef = computed(() => {
  return getQuality(props.data?.Quality) || {}
})

const isSupreme = computed(() => {
  let strNumber = props.data?.DataId?.toString()
  let lastDigit = strNumber?.charAt(strNumber?.length - 1)
  return lastDigit === '1'
})

const bottomContentRef = computed(() => !props.data?.ReceiveUid)

const soulSkill = computed(() => {
  const arrays = []
  for (let i = 1; i <= 6; i++) {
    if (props.data && props.data[`sk${i}`]?.name) {
      arrays.push(props.data[`sk${i}`])
    }
  }
  return arrays
})

const storyHtml = computed(() => {
  return props.data?.Desc
})
</script>

<template>
  <div
    v-if="data"
    class="fixed left-0 top-0 pb-5 z-3 h-full w-full bg-#131514 overflow-auto"
  >
    <div
      class="px-3 relative flex justify-between flex-items-center w-full h-7.5"
    >
      <Video
        v-if="!hasMintComputed"
        class="absolute! top-0 bottom-0 left-0 right-0 w-full h-7.5"
        :src="VideoSrc"
        :controls="false"
        autoplay
        loop
        muted
      ></Video>

      <img
        src="/src/asset/arrow_back_white.png"
        class="w-12.5px z-2"
        @click="() => emit('close')"
      />
      <span class="ellipsis-1 relative z-2"
        >{{ data?.Name }}&nbsp;&nbsp;#{{
          tokenIdFormat(getSoulToknDataId(data))
        }}</span
      >
      <img
        v-if="!hasMintComputed"
        class="w-[24px] relative z-2"
        src="src/asset/passport_logo.svg?url"
        alt=""
      />
    </div>
    <div class="px-7 pt-2.5">
      <div>
        <div class="h-[310px]">
          <div v-if="data?.Icon" class="cover_bg h-full w-full">
            <img
              :class="{ mint_ing_img: hasMintIngComputed }"
              :src="data?.Icon"
              alt=""
              :style="{ width: '100%', height: '100%' }"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="bottomContentRef" class="px-8 pt-3">
      <TransferItem
        class="translate-y-0!"
        :data="data"
        :select-data="[]"
        :role-list="roleList"
        :disabled="data?.UserID"
        :value="data?.UserID"
        @submit="handleSubmit"
      >
        <template #btn="{ handleSubmit: handleTransferSubmit }">
          <slot name="btn" :handleSubmit="handleTransferSubmit">
            <div
              class="fz-3 flex-center h-[32px] rounded-0!"
              :class="`${data.responseWait ? 'item_btn_disabled' : 'green_button'}`"
              @click="!data.responseWait && handleTransferSubmit()"
            >
              {{ getBtnName(data) }} Item
            </div>
          </slot>
        </template>
      </TransferItem>
    </div>
    <div class="px-8 mt-2 pt-2 border-0 border-t border-solid border-#464A4E">
      <ul>
        <li
          class="px-2.5 mb-2.5 h-[36px] flex justify-between flex-items-center border border-dotted bg-#191E20 fz-3"
        >
          <span class="gray-color">Role ID:</span>
          <div class="-ml-5 flex items-center">
            <span class="gray-color"
              >{{ ReceiveJobItemMap[data?.ReceiveJob] }}&nbsp;/&nbsp;</span
            >
            <span>{{ data?.ReceiveName }}</span>
          </div>
          <span></span>
        </li>
        <li
          class="px-2.5 mb-2.5 flex justify-between flex-items-center h-[36px] border border-dotted bg-#262B2E fz-3"
        >
          <span class="gray-color">Rarity:</span>
          <span class="-ml-5" :style="{ color: qualityRef.color }">{{
            qualityRef.text
          }}</span>
          <span></span>
        </li>
        <li
          class="px-2.5 mb-2.5 bg-#AA672B flex justify-between flex-items-center h-[36px] border border-solid border-#F3AE42 fz-3"
        >
          <span class="text-#F7D57B">Mint:</span>
          <span class="-ml-5">{{ data?.mint?.me?.desc }}</span>
          <span></span>
        </li>
        <li
          class="px-2.5 mb-2.5 flex justify-center flex-items-center h-[36px] border border-solid fz-3"
          :class="`${isSupreme ? 'text-#FFDD94 bg-#CD6A12 border-#F3AE42' : 'text-#EADDBB bg-#4D4A47 border-#68645E'}`"
        >
          <span class="text-center">{{
            isSupreme ? 'SUPREME' : 'NORMAL'
          }}</span>
        </li>
        <li
          class="px-2.5 mb-2.5 flex justify-center flex-items-center h-[36px] border border-dotted border-#93A1AF bg-#262B2E fz-3"
        >
          <span class="gray-color"> Advance: </span>
          <span class="text-center">&nbsp;Lv.{{ data?.ProgLv }}</span>
          <span></span>
        </li>
        <li
          class="px-2.5 mb-2.5 flex justify-center flex-items-center h-[36px] border border-dotted border-#93A1AF bg-#262B2E fz-3"
        >
          <span class="gray-color"> Star:</span>
          <span class="text-center">&nbsp;Lv.{{ data?.StarLv }}</span>
          <span></span>
        </li>
        <li
          class="px-2.5 mb-2.5 flex justify-center flex-items-center h-[36px] border border-dotted border-#93A1AF bg-#262B2E fz-3"
        >
          <span class="gray-color">Exp:</span>
          <span class="text-center">&nbsp;Lv.{{ data?.Lv }}</span>
          <span></span>
        </li>
      </ul>
    </div>
    <div
      class="mx-8 pt-2 mt-4 bg-#141719 border border-solid border-#464A4E p-3.5"
    >
      <p class="text-center my-0">SOUL SKILL</p>
      <ul class="mt-2 w-full">
        <li
          v-for="item in soulSkill"
          class="flex-center fz-3 mb-2.5 w-full h-[33px] bg-#262B2E"
        >
          <span class="gray-color mr-1">{{ item.name }}:</span>
          <span class="text-#FFFFFF"
            >Lv.{{ item.lv }} / Lv.{{ item.lvMax }}</span
          >
        </li>
      </ul>
    </div>
    <div class="mx-8 pt-4 fz-3 gray-color">
      <div class="mb-2 text-center">Story</div>
      <div class="text-center" v-html="storyHtml"></div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import './btn';
@import './mint';

.cover_bg {
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgb(153 216 225 / 100%) 0%,
    rgb(215 177 218 / 100%) 100%
  );
  background-repeat: no-repeat;
  background-size: cover;
}

.item_collapse {
  ::v-deep {
    .n-collapse-item-arrow {
      font-size: 10px !important;
    }

    .n-collapse-item__content-inner {
      padding: 0 !important;
    }
  }
}

.border-gray {
  @apply border-#4A4F51 border-solid;
}

::-webkit-scrollbar {
  display: none;
}

.video-js .vjs-tech {
  object-fit: cover !important;
}
</style>
