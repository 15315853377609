<script setup>
import { computed, defineEmits, defineProps } from 'vue'
import Modal from 'src/components/Modal'
import { useRestBGM } from 'src/hooks/useRestBGM'
import TrailerSubContent from './TrailerSubContent'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const showRef = computed(() => props.show)
useRestBGM(showRef)

function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" transform-origin="left" @update:show="handleClose">
    <div class="flex-center item-bg h-[98vh] w-[100vw]">
      <TrailerSubContent
        :show="props.show"
        @update:show="handleClose"
      ></TrailerSubContent>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.btn {
  position: absolute;
  top: 1%;
  right: -4%;
  background: url('src/asset/close.webp') no-repeat;
  background-size: 100% 100%;
  transform: translateX(-50%);

  @apply w-[25px] h-[30px] bottom-[55px];
  @extend .mouse_pointer;
}
</style>
