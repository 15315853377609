import * as THREE from 'three'
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js'
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js'
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js'

export class EffectFilter {
  outlinePass
  constructor(scene, camera, renderer) {
    this.composer = new EffectComposer(renderer)

    this.renderPass = new RenderPass(scene, camera)
    this.composer.addPass(this.renderPass)

    this.outputPass = new OutputPass()
    this.composer.addPass(this.outputPass)

    this.scene = scene
    this.camera = camera
    this.renderer = renderer
  }

  init(color) {
    this.outlinePass = new OutlinePass(
      new THREE.Vector2(window.innerWidth, window.innerHeight),
      this.scene,
      this.camera,
    )
    this.outlinePass.edgeStrength = 1 // 宽度
    this.outlinePass.edgeGlow = 10 // 辉光
    this.outlinePass.edgeThickness = 1 // 清晰度
    this.outlinePass.pulsePeriod = 3 // 脉冲周期
    this.outlinePass.visibleEdgeColor = new THREE.Color(color)

    this.composer.addPass(this.outlinePass)
  }

  adapt() {
    this.composer.setSize(window.innerWidth, window.innerHeight)
  }

  unload() {
    this.composer?.dispose?.()
    this.renderPass?.dispose?.()
    this.outputPass?.dispose?.()
    this.outlinePass?.dispose?.()
  }
}
