<script setup></script>

<template>
  <div class="fixed left-0 top-0 h-full w-full">
    <div class="footer_bg_bottom absolute bottom-0 left-0 h-50vh w-full"></div>
    <div class="footer_bg_top absolute top-0 h-30vh w-full"></div>
    <div class="footer_bg_left absolute left-0 top-0 h-full w-30vw"></div>
    <div class="footer_bg_right absolute right-0 top-0 h-full w-30vw"></div>
    <div class="absolute bottom-[14.5px] left-0 w-full">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
$color: rgb(0 0 0 / 24%);

.footer_bg_top {
  background: linear-gradient($color, rgb(0 0 0 / 0%));
}

.footer_bg_bottom {
  background: linear-gradient(rgb(0 0 0 / 0%), rgb(0 0 0 / 87.1%));
}

.footer_bg_left {
  background: linear-gradient(to right, $color, rgb(0 0 0 / 0%));
}

.footer_bg_right {
  background: linear-gradient(to left, $color, rgb(0 0 0 / 0%));
}
</style>
