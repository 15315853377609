<script setup>
import SetupPanel from 'src/pages/home/components/SetupPanel.vue'
import { useLoginStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())
const setupModalShowRef = ref(false)

function handleClick() {
  if (hasLogin.value) {
    setupModalShowRef.value = true
  } else {
    // 登录
    login()
  }
}
</script>

<template>
  <div class="tab text-#8B97A1">
    <div @click="handleClick">MENU</div>
    <SetupPanel v-model:show="setupModalShowRef"></SetupPanel>
  </div>
</template>

<style lang="scss" scoped>
.tab {
  @apply hover:text-#fff hover:decoration-underline;
}
</style>
