<script setup>
import { NPopover } from 'naive-ui'
import { defineEmits, defineProps } from 'vue'
import { hasPhone } from 'src/stores/media'

const props = defineProps({
  options: {
    default() {
      return []
    },
  },
  value: {
    type: [Number, String],
  },
})
const emit = defineEmits(['update:value', 'select'])
function handleSelect(key, item) {
  if (props.key !== key) {
    emit('update:value', key)
    emit('select', key, item)
  }
}
const themeOverrides = {
  padding: 0,
  color: '#8d4040',
}
</script>

<template>
  <NPopover
    :theme-overrides="themeOverrides"
    :show-arrow="false"
    :style="{ minWidth: `${!hasPhone ? 180 : 119}px` }"
  >
    <template #trigger>
      <slot></slot>
    </template>
    <div class="popover fz-3">
      <template v-for="item in props.options" :key="item.key">
        <div
          class="option flex-center"
          :class="{
            option_activate: item.key === props.value,
            option_disabled: item.disabled,
          }"
          @click="handleSelect(item.key, item)"
        >
          {{ item.label }}
        </div>
      </template>
    </div>
  </NPopover>
</template>

<style scoped lang="scss">
.popover {
  background-color: #343a40;

  @apply rounded p-1;

  .option {
    @apply rounded py-1 px-2;
    @extend .gray-color;
    @extend .mouse_pointer;

    &:not(:last-child) {
      @apply mb-1;
    }

    // &.option_disabled {
    //   @extend .gray-color;
    // }

    &.option_activate {
      @apply bg-#141516 text-white;
    }

    @include minMD {
      &:not(.option_disabled):hover {
        @apply bg-#141516;
      }
    }
  }
}
</style>
