import { gsap } from 'gsap'
import { debounce } from 'lodash-es'
import { getSmoothScrolling } from 'src/helpers/smoothScrolling'
import { hasPhone } from 'src/stores/media'

export class ControlDirection {
  applyMousePosition = {
    x: 0,
    y: 0,
  }

  mouseLookAtX = 0
  mouseLookAtY = 0
  amplitude = 45
  baseBeta = 80
  hasMouseDone = false
  mouseLookAtXTween
  mouseLookAtYTween
  flag = false
  constructor() {
    this.createHandle()
  }

  createHandle() {
    if (hasPhone) {
      this._handleEventListener = debounce(
        this.handleDeviceorientation.bind(this),
      )
    } else {
      this._handleEventListener = debounce(this.handleMousemove.bind(this))
    }
    return this
  }

  handleDeviceorientation({ beta, gamma }) {
    // beta：表示设备在x轴上的旋转角度，范围为-180~180。它描述的是设备由前向后旋转的情况；
    // gamma：表示设备在y轴上的旋转角度，范围为-90~90。它描述的是设备由左向右旋转的情况。
    const x = window.innerWidth * this.limitAmplitude(gamma)
    const y = window.innerHeight * this.limitAmplitude(beta - this.baseBeta)
    this.handleMousemove({ x, y })
    return this
  }

  limitAmplitude(number) {
    let value
    if (number > this.amplitude) {
      value = this.amplitude
    } else if (number < -this.amplitude) {
      value = -this.amplitude
    } else {
      value = number
    }
    return (value + this.amplitude) / this.amplitude / 2
  }

  handleMouse(_mouseLookAtX, _mouseLookAtY) {
    if (this.mouseLookAtX !== _mouseLookAtX) {
      this.mouseLookAtX = _mouseLookAtX
      this.mouseLookAtXTween?.kill?.()
      this.mouseLookAtXTween = gsap.to(this.applyMousePosition, {
        x: this.mouseLookAtX,
        duration: 0.4,
      })
    }
    if (this.mouseLookAtY !== _mouseLookAtY) {
      this.mouseLookAtY = _mouseLookAtY
      this.mouseLookAtYTween?.kill()
      this.mouseLookAtYTween = gsap.to(this.applyMousePosition, {
        y: this.mouseLookAtY,
        duration: 0.4,
      })
    }
    return this
  }

  handleMousemove({ x, y }) {
    if (!getSmoothScrolling()?.isScroll) {
      const _mouseLookAtX = this.hasMouseDone
        ? ((window.innerWidth / 2 - x) / window.innerWidth) * 8
        : 0
      const _mouseLookAtY = this.hasMouseDone
        ? ((window.innerHeight / 2 - y) / window.innerHeight) * 4
        : 0
      this.hasMouseDone = true
      this.handleMouse(_mouseLookAtX, _mouseLookAtY)
      return this
    }
  }

  killAll() {
    this.mouseLookAtXTween?.kill?.()
    this.mouseLookAtYTween?.kill?.()
    return this
  }

  unload() {
    return this.killAll()
  }
}

export const controlDirection = new ControlDirection()
