<script setup>
import ItemModal from 'src/components/ItemModal'
import { NForm, NFormItem, NInput, useMessage } from 'naive-ui'
import { getEmailModel, getEmailRules } from 'src/model/common'
import { fetchEmailCode } from 'src/api'
import { useAsyncState } from '@vueuse/core'
import { desensitizationEmail } from '@BOGX/utils'
import { useChangePasswordLinkStore } from 'src/stores/user'
import { storeToRefs } from 'pinia'
import { ref, watchEffect } from 'vue'
import { codeToMessage } from 'src/constant'

const store = useChangePasswordLinkStore()
const { success, errorClose } = store
const { show } = storeToRefs(store)
const formRef = ref(null)
const modelRef = ref(getEmailModel())
const message = useMessage()
const isReadyRef = ref(false)
const { isLoading, execute } = useAsyncState(
  async () => {
    await fetchEmailCode({
      mail: modelRef.value.mail,
      codeType: 4,
      email: modelRef.value.mail,
    })
  },
  null,
  {
    immediate: false,
    onSuccess() {
      isReadyRef.value = true
    },
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
  },
)

function handleClose() {
  errorClose()
}

async function handleConfirm() {
  await formRef.value?.validate()
  execute()
}
function handleBack() {
  success()
}
watchEffect(() => {
  if (show) {
    isReadyRef.value = false
    modelRef.value = getEmailModel()
  }
})
</script>

<template>
  <ItemModal
    :show="show"
    :confirm-text="isReadyRef ? 'Back to login' : 'Continue'"
    :loading="isLoading"
    width="425px"
    @update:show="handleClose"
    @cancel="handleClose"
    @confirm="isReadyRef ? handleBack() : handleConfirm()"
  >
    <div class="fz-3 pl-17px pr-17px pt-21px text-center">
      <template v-if="isReadyRef">
        <span class="fz-4 mb-16px block md:mb-21px">EMAIL SENT</span>
        <div class="mb-18px px-12">
          <span class="mb-21px text-#99A7B0">
            We have sent a password reset email to your address
          </span>
          <!-- <span>123**@gmail.com</span> -->
          <span>{{ desensitizationEmail(modelRef.mail) }}</span>
          <span class="text-#99A7B0">.</span>
        </div>

        <span class="mb-40px inline-block text-#99A7B0">
          Please check your inbox.
        </span>
      </template>
      <template v-else>
        <span class="fz-4 mb-16px block md:mb-21px">FORGET PASSWORD</span>
        <span class="mb-16px block px-6 text-#99A7B0"
          >Enter your email address and we will send anemail with a link to
          reset your login password.</span
        >
        <NForm
          ref="formRef"
          class="text-left"
          :model="modelRef"
          :rules="getEmailRules()"
          :disabled="isLoading"
          :show-label="false"
        >
          <NFormItem path="mail" type="mail">
            <NInput
              v-model:value="modelRef.mail"
              :maxlength="200"
              placeholder="E-mail address"
            />
          </NFormItem>
        </NForm>
      </template>
    </div>
  </ItemModal>
</template>

<style lang="scss" scoped>
@import './common';
</style>
