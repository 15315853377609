<script setup>
import SphereLogo from 'src/asset/soulCardMint/Sphere.webp'
import TokentroveLogo from 'src/asset/soulCardMint/Tokentrove.webp'
import OkxLogo from 'src/asset/soulCardMint/Okx.webp'
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'
const { min2XL } = storeToRefs(useMediaStore())

const linkList = [
  {
    name: 'Sphere',
    logo: SphereLogo,
    link: '',
    width: 75,
  },
  {
    name: 'Tokentrove',
    logo: TokentroveLogo,
    link: '',
    width: 90.5,
  },
  {
    name: 'Okx',
    logo: OkxLogo,
    link: '',
    width: 141,
  },
]
</script>
<template>
  <div class="mt-30px">
    <div class="color-#9E7FDF 2xl:mb-5 md:mb-4 fz-3">
      After minting, if you still want a Soul Card, you can click on the
      following trading market.
    </div>
    <ul class="flex">
      <template v-for="item in linkList" :key="item.name">
        <li class="mouse_pointer mr-2 last:mr-0">
          <a class="flex" :href="item.link" target="_blank">
            <img
              :style="{ width: `${item.width}px` }"
              :src="item.logo"
              alt=""
            />
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>
