<script setup>
import Header from './components/Header/index.vue'
import Card from './components/Card/index'
import RolesModal from './components/RolesModal/index'
import CheckoutModal from './components/CheckoutModal/index'
import SuccessModal from './components/SuccessModal/index'
import History from './components/History/index.vue'
import HistoryModal from './components/History/HistoryModal.vue'
import { ref, onMounted, watch, computed } from 'vue'
import { storeToRefs } from 'pinia'
import { useImmutableIncome } from '.'
import { useMessage } from 'naive-ui'
import { useLoginStore } from 'src/stores/user'
import { useImmutableIncomeStore } from 'src/stores/immutableIncome'
import { useRoleList } from 'src/pages/myCollection/hooks/useRoleList'
const { open: login } = useLoginStore()
const { hasLogin } = storeToRefs(useLoginStore())
const { walletAddress } = storeToRefs(useImmutableIncomeStore())

const showRoleModal = ref(false)
const showCheckoutModal = ref(false)
const showSuccessModal = ref(false)

const widgetType = ref('')
const orderData = ref({})
const { productsList, walletaddr } = useImmutableIncome()
const message = useMessage()
const roleListRef = useRoleList()
const roleList = computed(() => roleListRef.value?.slice(2))

function handleConnect() {
  if (walletAddress.value) {
    widgetType.value = 'wallet'
  } else {
    widgetType.value = 'connect'
  }
  showCheckoutModal.value = true
}

function handleBuy(data) {
  if (!hasLogin.value) {
    login()
    return
  }
  if (!walletAddress.value) {
    widgetType.value = 'connect'
    showCheckoutModal.value = true
    return
  }
  const { count, id } = data
  productsList.value.find(item => {
    if (item.id === id) {
      item.qty = count
      orderData.value = item
    }
  })
  if (!roleList.value?.length) {
    message.warning('your dont have hero')
    return
  }
  showRoleModal.value = true
}

function handleConfirm(data) {
  widgetType.value = 'sale'
  showCheckoutModal.value = true
}

const successList = ref([])
function handleSuccess() {
  console.log(orderData.value)
  const list = [
    {
      name: orderData.value.name,
      image: orderData.value.metadata.image,
      qty: orderData.value.qty,
    },
  ]
  successList.value = list
  showSuccessModal.value = true
}

function getWalletAddress(wallet) {
  walletaddr.value = wallet
  walletAddress.value = wallet
}

const showHistoryModal = ref(false)
function openHistoryModal() {
  showHistoryModal.value = true
}
</script>

<template>
  <div class="wrap item_container">
    <Header @handleConnect="handleConnect"></Header>
    <div class="main flex justify-center pt-6% relative">
      <Card
        @buy="handleBuy"
        :productData="item"
        :key="item.id"
        v-for="item in productsList"
      ></Card>
    </div>
    <History @click="openHistoryModal"></History>
    <RolesModal
      :list="roleList"
      @confirm="handleConfirm"
      v-model:show="showRoleModal"
    ></RolesModal>
    <CheckoutModal
      :widgetType="widgetType"
      :orderData="orderData"
      v-model:show="showCheckoutModal"
      @getWalletAddress="getWalletAddress"
      @success="handleSuccess"
    ></CheckoutModal>
    <SuccessModal
      :list="successList"
      v-model:show="showSuccessModal"
    ></SuccessModal>
    <HistoryModal
      v-model:show="showHistoryModal"
      headerText="SOUL JADE STORE"
      hasNFT
      :type="2"
    ></HistoryModal>
  </div>
</template>

<style lang="scss" scoped>
.wrap {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .main {
    height: calc(100vh - var(--header-height));

    @include bgAuto('src/asset/immutableIncome/bg.webp');
  }
}
</style>
