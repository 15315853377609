<script setup>
import { useMouseInElement } from '@vueuse/core'
import { ref } from 'vue'

const domRef = ref(document.documentElement)

const { x, y, isOutside } = useMouseInElement(domRef)
</script>

<template>
  <!-- <span></span> -->
  <span
    v-show="!isOutside"
    class="absolute translate-x-[-50%] translate-y-[-80%] whitespace-nowrap text-center"
    :style="{ top: `${y}px`, left: `${x}px` }"
    @click="bgmAudioPlay"
    >Click anywhere to enable the <br />
    sound</span
  >
</template>
