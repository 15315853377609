import { getStatusAndTime } from '@BOGX/utils'
import { useIntervalFn } from '@vueuse/core'
import { ref, watch } from 'vue'

export function useTimeSlotCountDown(
  immutableServerTimeValue,
  immutableStartTimeValue,
  immutableEndTimeValue,
  { onEnd, interval = 1000 } = {
    interval: 1000,
  },
) {
  const currentTimeRef = ref(Date.now())
  const statusRef = ref(3)
  const timeListRef = ref('00,00,00,00')
  const { pause, resume } = useIntervalFn(
    () => {
      /* your function */
      const { status, formattedTime } = getStatusAndTime(
        immutableStartTimeValue.value,
        immutableEndTimeValue.value,
        currentTimeRef.value,
      )
      timeListRef.value = formattedTime
      if (statusRef.value !== status) {
        statusRef.value = status
        if (status === 3) {
          onEnd?.()
        }
      }
      if (status === 3) {
        pause()
      } else {
        currentTimeRef.value += interval
      }
    },
    interval,
    {
      immediate: false,
    },
  )
  watch(
    immutableServerTimeValue,
    value => {
      if (value) {
        currentTimeRef.value = value
        resume()
      }
    },
    { immediate: true },
  )
  return {
    currentTimeRef,
    timeListRef,
    statusRef,
  }
}
