<script setup>
import Modal from 'src/components/Modal'

import { useRestBGM } from 'src/hooks/useRestBGM'
import { computed } from 'vue'
import SoulsContent from './SoulsContent'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const showRef = computed(() => props.show)
useRestBGM(showRef)
function handleClose(value) {
  emit('update:show', value)
}
</script>

<template>
  <Modal :show="props.show" transform-origin="left" @update:show="handleClose">
    <div class="item-bg w-[100vw] p-5 md:h-[98vh]">
      <SoulsContent
        :show="props.show"
        @update:show="handleClose"
      ></SoulsContent>
    </div>
  </Modal>
</template>
