<script setup>
import Modal from 'src/components/Modal'

const props = defineProps({
  show: Boolean,
  data: Object,
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div
      class="pt-30px pb-15px w-525px h-269px flex flex-items-center justify-between flex-col bg-#0E0E0F border border-solid border-#696B6B rounded-5px"
    >
      <div v-if="props.data?.isGenesis" class="flex flex-items-center flex-col">
        <img class="w-86px" src="/src/asset/mint/genesis_avatar.webp" alt="" />
        <div class="text-linear fz-4">GENESIS HOLDER</div>
      </div>
      <div
        :class="props.data?.isGenesis ? 'mt-5px' : 'mt-55px'"
        class="text-center"
      >
        <div>Congratulations! You're on the whitelist with</div>
        <div class="family_blk text-#F8A52F">
          {{ props.data?.phase1WL }} GTD spots and
          {{ props.data?.phase2WL }} FCFS spots.
        </div>
      </div>
      <div
        @click="handleClose"
        class="mouse_pointer flex-center bg-#FF7D31 w-186px h-38px border border-solid border-#FFC662 rounded-5px"
      >
        confirm
      </div>
    </div>
  </Modal>
</template>

<style lang="scss" scoped>
.text-linear {
  color: transparent;
  background-image: linear-gradient(
    139.19deg,
    #fe6363 0%,
    #ed6fed 19.68%,
    #8889e5 37.04%,
    #51f0f0 56.47%,
    #79f079 78.25%,
    #f3eb6f 100%
  );
  background-clip: text;
}
</style>
