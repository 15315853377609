<script setup>
import Modal from 'src/components/Modal'
import lucky_points_icon from 'src/asset/Lucky_Points.webp'

const props = defineProps({
  show: Boolean,
  point: Number,
})
const emit = defineEmits(['update:show'])
function handleClose() {
  emit('update:show', false)
}
</script>

<template>
  <Modal class="mask" :show="props.show" @update:show="handleClose">
    <div
      class="p-18px bg-#0E0E0F border border-solid border-#696B6B rounded-5px"
    >
      <div
        class="w-391px h-147.5px bg-#2A2D31 rounded-5px flex-center text-18px leading-18px text-#CFDDE7"
      >
        <img class="w-25px" :src="lucky_points_icon" alt="" />
        <span class="mx-10px">Lucky Points </span>
        <span class="text-#39ED63">+{{ point }}</span>
      </div>
      <div
        class="mt-27px m-auto item-btn-gray h-[38px] w-[186px]"
        @click="handleClose"
      >
        confirm
      </div>
    </div>
  </Modal>
</template>
