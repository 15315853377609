<script setup>
import { useHandleWallet } from 'src/hooks/useHandleWallet'
import { useUserInfoStore } from 'src/stores/user'
import { ref } from 'vue'
import { useMessage } from 'naive-ui'
import { codeToMessage } from 'src/constant'
import WalletConfirmModal from './WalletConfirmModal'
import ItemModal from './ItemModal'
import { storeToRefs } from 'pinia'
const props = defineProps(['cd'])

const message = useMessage()
const { userInfo } = storeToRefs(useUserInfoStore())

const walletConfirmShowRef = ref(false)
const notWalletShowRef = ref(false)
const handleWallet = useHandleWallet({
  unbind() {
    notWalletShowRef.value = true
  },
  notBound() {
    walletConfirmShowRef.value = true
  },
  pass: props.cd,
})
function handleNewBind() {
  message.success('Wallet address binding successful.')
  walletConfirmShowRef.value = false
}

function handleUnbind() {
  notWalletShowRef.value = false
}
function handleAddressError(error) {
  message.error(codeToMessage(error?.data?.code))
}
</script>

<template>
  <div @click="handleWallet">
    <slot></slot>
    <!-- 确认绑定钱包地址 -->
    <WalletConfirmModal
      v-model:show="walletConfirmShowRef"
      text="Confirm wallet address binding."
      @success="handleNewBind"
      @error="handleAddressError"
    ></WalletConfirmModal>
    <!-- 当前钱包没有找到账号地址 -->
    <ItemModal v-model:show="notWalletShowRef" @confirm="handleUnbind">
      <div class="fz-4 px-2 py-5 text-center text-#CFDDE7">
        We regret to inform you that the address associated with your account
        does not align with the wallet address currently active.
        <div class="orange-color break-all">{{ userInfo?.wallet }}</div>
      </div>
    </ItemModal>
  </div>
</template>
