<script setup>
import { ref } from 'vue'

const containerDomRef = ref(null)
defineExpose({
  containerDomRef,
})
</script>
<template>
  <div class="scroll mx-2.5 my-2" ref="containerDomRef">
    <slot></slot>
  </div>
</template>

<style scoped>
.scroll {
  &::-webkit-scrollbar {
    /* 滚动条整体样式 */
    width: 7.5px; /* 高宽分别对应横竖滚动条的尺寸 */
    height: 7.5px;
    margin-right: 4px;
  }

  &::-webkit-scrollbar-thumb {
    /* 滚动条里面小方块 */
    border-radius: 5px;

    @apply bg-[--pfp-bg5];
  }

  &::-webkit-scrollbar-track {
    /* 滚动条里面轨道 */
    background: #07080a;
    border-radius: 0;
  }
}
</style>
