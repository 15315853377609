export class ElasticRolling {
  currentLifeCycle = lifeCycle.interactiveScrolling
  currentVirtualScrollEvent
  constructor(
    scroll,
    { getTotalScroll, getQuitingMinHeight, getEnteringMinHeight },
  ) {
    this.scroll = scroll
    this.getTotalScroll = getTotalScroll
    this.getQuitingMinHeight = getQuitingMinHeight
    this.getEnteringMinHeight = getEnteringMinHeight
    // scroll.lenis.virtualScroll.on('scroll', event => {
    //   this.currentVirtualScrollEvent = event
    //   console.log('this.lenis.emitter.on', event.event.type)
    // })
    this.init()
  }
  init() {
    this.scroll.add(this.lifeCyclePlugin.bind(this))
    this.scroll.add(this.elasticPlugin.bind(this))
  }
  lifeCyclePlugin() {
    if (!this.scroll.isScroll) {
      this.currentLifeCycle = lifeCycle.interactiveScrolling
    }
  }
  elasticPlugin(event) {
    if (this.currentLifeCycle !== lifeCycle.interactiveScrolling) return
    if (
      event.targetScroll >= this.getEnteringMinHeight() &&
      event.animatedScroll === event.targetScroll
    ) {
      this.scroll.lenis.scrollTo(this.getTotalScroll())
    } else if (
      event.targetScroll > this.getQuitingMinHeight() &&
      event.targetScroll < this.getEnteringMinHeight() &&
      event.animatedScroll === event.targetScroll
    ) {
      this.scroll.lenis.scrollTo(this.getQuitingMinHeight())
    }
  }
  goToBottom() {
    this.currentLifeCycle = lifeCycle.scrollToTheBottom
    this.scroll.lenis.scrollTo(this.getTotalScroll())
  }
}

const lifeCycle = {
  interactiveScrolling: 1, // 滚动
  scrollToTheBottom: 2, // 滚动到底部
}
