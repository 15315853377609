import * as THREE from 'three'

/** 创建光源 */
export function initLight(scene, isHelp) {
  const hemiLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 1)
  hemiLight.position.set(0, 50, 0)
  scene.add(hemiLight)
  // 阴影
  const d = 8.25
  const dirLightShadow = new THREE.DirectionalLight(0xffffff, 0.5)
  dirLightShadow.position.set(-8, 12, 8)
  dirLightShadow.castShadow = true
  dirLightShadow.shadow.mapSize = new THREE.Vector2(1024, 1024)
  dirLightShadow.shadow.camera.near = 0.1
  dirLightShadow.shadow.camera.far = 1500
  dirLightShadow.shadow.camera.left = d * -1
  dirLightShadow.shadow.camera.right = d
  dirLightShadow.shadow.camera.top = d
  dirLightShadow.shadow.camera.bottom = d * -1
  scene.add(dirLightShadow)
  // 左蓝光
  const dirLightLeft = new THREE.DirectionalLight(0x50b2df, 3)
  // dirLightLeft.position.set(-7, 0, 3)
  const targetLeftObject = new THREE.Object3D()
  // targetLeftObject.position.set(0, 0, -3)
  dirLightLeft.target = targetLeftObject
  if (isHelp) {
    const helperLeft = new THREE.DirectionalLightHelper(dirLightLeft, 1)
    scene.add(helperLeft)
  }
  scene.add(targetLeftObject)
  scene.add(dirLightLeft)
  // 右黄光
  const dirRightFront = new THREE.DirectionalLight(0xf5b768, 10)
  // dirRightFront.position.set(7, 5, 0)
  const targetRightObject = new THREE.Object3D()
  // targetRightObject.position.set(0, 5, 2)

  dirRightFront.target = targetRightObject
  if (isHelp) {
    const helperRight = new THREE.DirectionalLightHelper(dirRightFront, 1)
    scene.add(helperRight)
  }
  scene.add(targetRightObject)
  scene.add(dirRightFront)
  // 正面光
  const dirLightFront = new THREE.DirectionalLight(0xeebe8f, 5)
  dirLightFront.position.set(5, 7, 5)
  const targetFrontObject = new THREE.Object3D()
  targetFrontObject.position.set(2, 7, 0)

  dirLightFront.target = targetFrontObject
  if (isHelp) {
    const helperFront = new THREE.DirectionalLightHelper(dirLightFront, 1)
    scene.add(helperFront)
  }
  scene.add(targetFrontObject)
  scene.add(dirLightFront)
  // 背面光
  const dirLightVerso = new THREE.DirectionalLight(0x50b2df, 1)
  dirLightVerso.position.set(0, 4, -8)
  const targetVersoObject = new THREE.Object3D()
  targetVersoObject.position.set(6, 4, 0)

  dirLightVerso.target = targetVersoObject
  if (isHelp) {
    const helperVerson = new THREE.DirectionalLightHelper(dirLightVerso, 1)
    scene.add(helperVerson)
  }
  scene.add(targetVersoObject)
  scene.add(dirLightVerso)
  return {
    setLightLeft({ position, lookAt }) {
      dirLightLeft.position.set(position.x, position.y, position.z)
      targetLeftObject.position.set(lookAt.x, lookAt.y, lookAt.z)
    },
    setLightRight({ position, lookAt }) {
      dirRightFront.position.set(position.x, position.y, position.z)
      targetRightObject.position.set(lookAt.x, lookAt.y, lookAt.z)
    },
    dispose() {
      hemiLight?.dispose()
      dirLightShadow?.dispose()
      dirLightLeft?.dispose()
      dirRightFront?.dispose()
      dirLightFront?.dispose()
      dirLightVerso?.dispose()
    },
  }
}
