import { storeToRefs } from 'pinia'
import { getUserBindAddress } from './utils'
import { useUserInfoStore } from 'src/stores/user'
import {
  baseGetBalance,
  baseGetContractItemData,
  baseMint,
  hasTestChainID,
  insufficientBalanceErrorText,
} from 'src/sdk/blockchain'
import { formatEther } from 'viem'
export class BaseBlockchain {
  chainId
  contractAddress
  abi
  setData({ chainId, contractAddress, abi }) {
    return this.setChainId(chainId)
      .setContractAddress(contractAddress)
      .setAbi(abi)
  }
  setChainId(data) {
    this.chainId = data
    return this
  }
  setContractAddress(data) {
    this.contractAddress = data
    return this
  }
  setAbi(data) {
    this.abi = data
    return this
  }
  /**
   * 获取合约某个可读属性
   */
  getContractItemData(args) {
    return baseGetContractItemData(args)
  }
  // 获取余额wei
  getBalance(args) {
    return baseGetBalance(args)
  }

  request(data) {
    return baseMint(data)
  }
  // 获取余额eth
  async getBalanceETH(args) {
    const balance = await this.getBalance(args)
    return formatEther(balance)
  }
}

const BaseBlockchainProty = BaseBlockchain.prototype
useDecorator(BaseBlockchainProty, 'getContractItemData', baseBlockchainParams)
useDecorator(BaseBlockchainProty, 'getBalance', baseBlockchainParams)
useDecorator(BaseBlockchainProty, 'request', baseBlockchainParams)
export function useDecorator(target, name, decorator) {
  Object.defineProperty(
    target,
    name,
    decorator(target, name, Object.getOwnPropertyDescriptor(target, name)),
  )
}
export function commonBlockchainParams(target, name, descriptor) {
  var oldValue = descriptor.value
  descriptor.value = function (args) {
    const { bindAddressComputed } = storeToRefs(useUserInfoStore())
    const _args = {
      accountAddress: bindAddressComputed.value,
      ...args,
    }
    return oldValue.call(this, _args)
  }
  return descriptor
}

export function baseBlockchainParams(target, name, descriptor) {
  var oldValue = descriptor.value
  descriptor.value = function (args) {
    const _args = {
      chainId: this.chainId,
      contractAddress: this.contractAddress,
      abi: this.abi,
      ...args,
    }
    return oldValue.call(this, _args)
  }
  return descriptor
}

export function blockchainBindAddress(target, name, descriptor) {
  var oldValue = descriptor.value
  descriptor.value = async function (args) {
    const accountAddress = await getUserBindAddress()
    return oldValue.call(this, {
      chainId: this.chainId,
      contractAddress: this.contractAddress,
      abi: this.abi,
      accountAddress,
      ...args,
    })
  }
  return descriptor
}
export function blockchainBalanceFees(target, name, descriptor) {
  var oldValue = descriptor.value
  descriptor.value = async function (args) {
    const balance = await this.getBalance(args)
    const value = args.value || 0n
    if (value > balance) {
      throw new Error(insufficientBalanceErrorText)
    }
    // TODO正式的时候再看
    // if (!hasTestChainID(args.chainId)) {
    //   try {
    //     const publicClient = newCreatePublicClient(args.chainId)
    //     const gasEstimate = await publicClient.estimateContractGas({
    //       account: args.accountAddress,
    //       abi: args.abi,
    //       functionName: name,
    //       args: args.args,
    //       value: args.value,
    //     })
    //     if (gasEstimate + value > balance) {
    //       throw new Error(insufficientBalanceErrorText)
    //     }
    //   } catch (error) {
    //     console.log(error)
    //     if (error?.message === insufficientBalanceErrorText) {
    //       throw error
    //     }
    //   }
    // }
    return oldValue.call(this, { ...{ functionName: name }, ...args })
  }
  return descriptor
}
