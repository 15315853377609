import { computed, onBeforeUnmount, ref, watch } from 'vue'
import { hasScrollbar, fuzzySearch, getSubArrayIndices } from '@BOGX/utils'
import { useUpdateCssVar } from 'src/pages/PFP/useThemeOverrides'
export function useFilterItem(data, emit) {
  const showRef = ref(false)
  const inputValueRef = ref('')
  const containerRef = ref(null)
  const containerScrollRef = ref(false)
  const checkBoxListRef = ref([])
  const { valueRef: iconActiveColorRef } = useUpdateCssVar('--pfp-color5')
  const { valueRef: iconDefaultColorRef } = useUpdateCssVar('--pfp-color4')
  const iconColorComputed = computed(() => {
    return showRef.value ? iconActiveColorRef.value : iconDefaultColorRef.value
  })
  const checkNameListComputed = computed(() =>
    data.childrens.map(item => item.name),
  )
  const checkBoxSelectListComputed = computed(() =>
    checkBoxListRef.value.filter(item => item.checked),
  )
  const selectComputed = computed(
    () => showRef.value || checkBoxSelectListComputed.value.length,
  )
  const checkListComputed = computed(() => {
    const value = inputValueRef.value
    if (value) {
      const checkNameList = checkNameListComputed.value
      const nameSearchList = fuzzySearch(value, checkNameList)
      const indexList = getSubArrayIndices(checkNameList, nameSearchList)
      return indexList.map(index => data.childrens[index])
    } else {
      return data.childrens
    }
  })
  let timeoutScroll
  function showToggle() {
    showRef.value = !showRef.value
  }
  function handleCheck(item, check, setChecked) {
    emit(
      'change',
      {
        ...item,
        remove: () => setChecked(false),
      },
      check,
    )
  }
  watch(showRef, value => {
    if (value) {
      // css动画
      timeoutScroll = setTimeout(() => {
        containerScrollRef.value = hasScrollbar(
          containerRef.value.containerDomRef,
        )
      }, 300)
    }
  })
  onBeforeUnmount(() => {
    clearTimeout(timeoutScroll)
  })
  return {
    showRef,
    inputValueRef,
    containerRef,
    containerScrollRef,
    checkListComputed,
    iconColorComputed,
    checkBoxListRef,
    selectComputed,
    showToggle,
    handleCheck,
  }
}
