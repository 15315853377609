import { fetchLockImmutableSoulCard } from 'src/api'
import { useAsyncState } from '@vueuse/core'
import { immutableNFTPool, soulCard } from 'src/helpers/blockchain'
import { fetchAuth } from 'src/sdk/immutable'
export function useLockImmutableSoulCard() {
  const { isLoading, execute } = useAsyncState(
    async (itemToken, UserID) => {
      await fetchAuth()
      const data = await fetchLockImmutableSoulCard({
        itemToken,
        UserID,
      })
      await soulCard.request({
        functionName: 'approve',
        args: [immutableNFTPool.contractAddress, itemToken],
      })

      await immutableNFTPool.lock({
        args: [soulCard.contractAddress, itemToken, UserID],
      })
      return data
    },
    null,
    {
      immediate: false,
      onError(error) {
        throw error
      },
    },
  )
  return {
    isLoading,
    execute,
  }
}
