import Layout from 'src/layout/index.vue'
import MyLayout from 'src/layout/myIndex.vue'
import NotFound from 'src/pages/404.vue'
import Callback from 'src/pages/Callback.vue'
import home from 'src/pages/home/index.vue'
import ImmutableIncome from 'src/pages/ImmutableIncome/index.vue'
import soulCardMint from 'src/pages/soulCardMint/index.vue'
import nftBenefit from 'src/pages/nftBenefit/index.vue'
import Market from 'src/pages/market/index.vue'
// import BossIntroduce from 'src/pages/mint/bossIntroduce.vue'
// import Mint from './pages/mint/index.vue'

import BIndWallet from 'src/pages/BIndWallet.vue'

import {
  COLLECTION_NAME,
  HOME_PAGE_NAME,
  MY_COLLECTION_ALL_NAME,
  REDIRECT_NAME,
  NOT_404_NAME,
  BIND_WALLET,
  SOUL_CARD_SALE,
  IMMUTABLE_INCOME,
  SOUL_CARD_MINT,
  NFT_BANEFITS,
  MARKET_NAME,
} from 'src/constant'
import { collectionRouter } from './collection'
import { pfpRouter } from './pfp'
import { softPledgeRouter } from './softPledge'
import { turntableReouter } from './turntable'

const routes = [
  {
    path: '/404',
    component: NotFound,
    name: NOT_404_NAME,
    hidden: true,
  },
  {
    path: '/',
    redirect: { name: HOME_PAGE_NAME },
    component: Layout,
    children: [
      {
        path: '/',
        component: home,
        name: HOME_PAGE_NAME,
      },
    ],
  },
  {
    path: '/collection',
    redirect: { name: MY_COLLECTION_ALL_NAME },
    name: COLLECTION_NAME,
    component: MyLayout,
    children: collectionRouter,
  },
  {
    path: '/immutableIncome',
    name: IMMUTABLE_INCOME,
    component: ImmutableIncome,
  },
  {
    path: '/soulCardMint',
    name: SOUL_CARD_MINT,
    component: soulCardMint,
  },
  {
    path: '/nftBenefit',
    name: NFT_BANEFITS,
    component: nftBenefit,
  },
  {
    path: '/market',
    name: MARKET_NAME,
    component: Market,
  },
  // {
  //   path: '/mint',
  //   component: Mint,
  //   name: MINT_NAME,
  // },
  // {
  //   path: '/bossIntroduce',
  //   component: BossIntroduce,
  //   name: BOSS_INTRODUCE_NAME,
  // },
  {
    path: '/redirect/:type?',
    component: Callback,
    name: REDIRECT_NAME,
  },
  {
    path: '/bind_wallet/:name',
    component: BIndWallet,
    name: BIND_WALLET,
  },
  ...pfpRouter,
  ...softPledgeRouter,
  ...turntableReouter,
  {
    path: '/:pathMatch(.*)*',
    redirect: { path: '/' },
  },
]

export { routes }
