import { useMessage } from 'naive-ui'
import { storeToRefs } from 'pinia'
import { useAsyncState } from '@vueuse/core'
import { useLoginStore } from 'src/stores/user'
import { getProducts, fetchSoulCardMintInfo } from 'src/api'
import { errorCodeToMessage } from 'src/helpers/utils'
import {
  getBlockchainErrorCode,
  getBlockchainNeedErrorMessage,
} from 'src/sdk/blockchain'
import { usePFPMintStore } from 'src/stores/pfp'
import { computed, ref } from 'vue'

export function useMint() {
  const pfpStore = usePFPMintStore()
  const { open: login } = useLoginStore()
  const { hasLogin } = storeToRefs(useLoginStore())
  const message = useMessage()
  const { currentPhaseWLComputed } = storeToRefs(pfpStore)
  const quantityRef = ref(0)
  const mintRef = ref(null)
  const quantityMaxComputed = computed(() =>
    currentPhaseWLComputed.value > 5 ? 5 : currentPhaseWLComputed.value,
  )
  const successShowRef = ref(false)
  const errorShowRef = ref(false)
  const checkoutModalRef = ref(false)
  const mintListRef = ref([])
  const widgetTypeRef = ref('')
  const orderDataRef = ref({})
  const walletAddress = ref('')
  const productsListRef = ref([])

  const { execute: executeList } = useAsyncState(
    async () => {
      const data = await getProducts()
      console.log(data)
      productsListRef.value = data
      orderDataRef.value = data?.[0]
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )

  const { execute: executeProducts } = useAsyncState(
    async () => {
      const data = await fetchSoulCardMintInfo()
      const extraInfo = {
        qty: 1,
        id: data.metadata_id,
        metadata: {
          image: 'https://voidlabs.bladeofgod.net//webres/img/icon/431040.png',
        },
      }
      orderDataRef.value = {
        ...data,
        ...extraInfo,
      }
      console.log(data, orderDataRef.value)
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )

  function handleConnect() {
    if (walletAddress.value) {
      widgetTypeRef.value = 'wallet'
    } else {
      widgetTypeRef.value = 'connect'
    }
    checkoutModalRef.value = true
  }

  function getWalletAddress(wallet) {
    walletAddress.value = wallet
  }

  function handleMint() {
    if (!hasLogin.value) {
      login()
      return
    }
    if (!walletAddress.value) {
      widgetTypeRef.value = 'connect'
      checkoutModalRef.value = true
      return
    }
    orderDataRef.value.qty = quantityRef.value
    widgetTypeRef.value = 'sale'
    checkoutModalRef.value = true
  }

  function handleMintSuccess(list) {
    successShowRef.value = true
    mintListRef.value = list
    quantityRef.value = 0
    console.log('handleMintSuccess', list)
  }
  function handleMintError(list, error) {
    const code = getBlockchainErrorCode(error)
    if (code === -1) {
      errorShowRef.value = true
      mintListRef.value = list
    } else {
      message.error(getBlockchainNeedErrorMessage(error))
    }
    console.log('handleMintError', list, error, code)
  }

  executeProducts()

  return {
    quantityRef,
    checkoutModalRef,
    successShowRef,
    errorShowRef,
    mintListRef,
    mintRef,
    productsListRef,
    widgetTypeRef,
    orderDataRef,
    quantityMaxComputed,
    handleMintSuccess,
    handleMintError,
    handleConnect,
    handleMint,
    getWalletAddress,
  }
}
