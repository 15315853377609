import { AppHttpCode } from 'src/constant'
import {
  creasteRequest,
  getResponseData,
  requestSuccess,
  tokenInvalidLogin,
  validateSuccess,
} from './utils'
import { signatureDataStorage } from 'src/helpers/store'

export const requestBase = creasteRequest()
let refreshTokenRequestP
// export fu
requestBase.interceptors.response.use(async response => {
  const code = response.data.code
  if (validateSuccess(code)) {
    return getResponseData(response)
  } else {
    switch (code) {
      case AppHttpCode.TOKEN_EXPIRED:
        if (!refreshTokenRequestP) {
          refreshTokenRequestP = tokenInvalidLogin()
        }
        try {
          await refreshTokenRequestP
          return await requestBase(response.config)
        } catch (error) {
          return Promise.reject(error)
        } finally {
          refreshTokenRequestP = null
        }
      case AppHttpCode.WALLET_ADDR_SIGNED_FAIL:
        signatureDataStorage.removeItem()
        return Promise.reject(response)
      default:
        return Promise.reject(response)
    }
  }
})

requestBase.interceptors.request.use(async config => {
  if (refreshTokenRequestP) await refreshTokenRequestP
  return requestSuccess(config)
})
