<script setup>
import { computed } from 'vue'
const props = defineProps({
  // 在src/assets/svg目录下svg图标的名字
  name: {
    type: String,
    required: true,
  },
  // 前缀，和vite.config.ts中配置的名称一致
  prefix: {
    type: String,
    default: 'icon',
  },
  // 图标的大小
  size: {
    type: String,
    default: '16px',
  },
})

const symbolId = computed(() => `#${props.prefix}-${props.name}`)
</script>

<template>
  <svg aria-hidden="true" :width="size" :height="size">
    <use :xlink:href="symbolId" />
  </svg>
</template>

<style>
.svg-icon {
  width: 18px;
  height: 18px;
  color: #333;
  fill: currentcolor;
}
</style>
