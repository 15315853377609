<script setup>
import { useMediaStore } from 'src/stores/media'
import { storeToRefs } from 'pinia'
const { min2XL } = storeToRefs(useMediaStore())
</script>

<template>
  <div class="absolute" :class="`${min2XL ? 'box_2xl' : 'box_md'}`">
    <div class="absolute bg_box"></div>
    <div class="absolute content">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.bg_box {
  @include bg100('src/asset/PFP/mint/FrostedGlassPanel.webp');

  backdrop-filter: blur(3px);
}

.box_2xl {
  .bg_box {
    @apply w-744px h-651px right-[-132px] top-20px;
  }

  .content {
    @apply w-510px right-75px top-45px;
  }
}

.box_md {
  .bg_box {
    @apply w-569.5px h-553px right-[-112px] top-17px;
  }

  .content {
    @apply w-420px right-20px top-38px;
  }
}
</style>
