<script setup>
import Modal from 'src/components/Modal'
import { NForm, NFormItem, NInput, useMessage } from 'naive-ui'
import { getAccountCodeModel, getAccountCodeRules } from 'src/model'
import { ref } from 'vue'
import { fetchRedeemCodeToAccountInventory } from 'src/api'
import { codeToMessage } from 'src/constant'
import { useAsyncState } from '@vueuse/core'
import { fetchUserInfoP } from 'src/helpers/user'

const props = defineProps({
  show: Boolean,
})
const emit = defineEmits(['update:show'])
const message = useMessage()
const formRef = ref(null)
const modelData = getAccountCodeModel()
const modelRef = ref(modelData)
const { isLoading: submitLoading, execute: handleSubmit } = useAsyncState(
  async () => {
    await fetchRedeemCodeToAccountInventory(modelRef.value)
    fetchUserInfoP.run()
    handleClose()
  },
  null,
  {
    immediate: false,
    onError(error) {
      message.error(codeToMessage(error?.data?.code))
    },
    onSuccess() {
      // 兑换码兑换成功
      message.success('Redemption code successfully redeemed.')
    },
  },
)
function handleClose() {
  emit('update:show', false)
}
function handleValidate(e) {
  e.preventDefault()
  formRef.value?.validate(errors => {
    if (!errors) handleSubmit()
  })
}
</script>

<template>
  <Modal :show="props.show" @update:show="handleClose">
    <div class="box item-bg fz-3">
      <div class="item_title_bar h-[43px] tracking-1px">REDEEM CODE</div>
      <NForm
        ref="formRef"
        :model="modelRef"
        :rules="getAccountCodeRules()"
        :disabled="submitLoading"
        @keyup.enter="handleValidate"
      >
        <NFormItem path="cdkey" class="my-[14px]">
          <NInput
            v-model:value="modelRef.cdkey"
            placeholder="Enter redeem code"
          />
        </NFormItem>
        <div class="flex-sb">
          <div class="item-btn-black btn" @click="handleClose">Cancel</div>
          <div class="item-btn-gray btn" @click="handleValidate">Confirm</div>
        </div>
      </NForm>
    </div>
  </Modal>
</template>

<style scoped lang="scss">
.box {
  @apply w-[335px] p-2;

  .btn {
    @apply h-[38px] w-[154px];
  }
}
</style>
