<script setup>
import { NTable } from 'naive-ui'
import { computed } from 'vue'
const props = defineProps({
  themeOverrides: Object,
})
const tablethemeOverridesComputed = computed(() => {
  const thColor = '#101216'
  const tdColor = '#181a1f'
  // const padding = 0
  return {
    borderColor: 'transparent',
    thColor,
    thColorModal: thColor,
    borderColorModal: thColor,
    tdColorStriped: thColor,
    tdColorModal: tdColor,
    tdColor,
    // tdPaddingSmall: padding,
    ...props.themeOverrides,
  }
})
</script>
<template>
  <NTable striped size="small" :theme-overrides="tablethemeOverridesComputed">
    <slot></slot>
  </NTable>
</template>
