export function middleEllipsis(text, start = 3, end = -4) {
  return `${text.slice(0, start)}...${text.slice(end)}`
}

export async function waitForTransactionReceipt(publicClient, params) {
  const transaction = await publicClient.waitForTransactionReceipt(params)
  console.log('waitForTransactionReceipt', transaction)
  if (transaction.status === 'success') {
    return transaction
  } else {
    throw transaction
  }
}
