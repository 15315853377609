import { getAddress } from 'viem'
import { inconsistentBindAddressErrorText } from '../message'

export function bindAddressExist(bindAddress, addresses) {
  const _bindAddress = getAddress(bindAddress)
  const account = addresses.find(
    account => getAddress(account) === _bindAddress,
  )
  if (!account) {
    throw new Error(inconsistentBindAddressErrorText)
  }
}
