<script setup>
import { NModal } from 'naive-ui'
import closeImg from 'src/asset/close.webp'
import { ref, watchEffect } from 'vue'
import { getAppropriateSize, getImageSize, minSize } from '@BOGX/utils'
import Video from 'src/components/Video'
import { hasPhone } from 'src/stores/media'

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  src: {
    type: String,
    default: '',
  },
  type: {
    type: Number,
    // 1是图片，2是视频
    default: 1,
  },
  poster: {
    type: String,
    default: '',
  },
  square: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:show', 'close'])
const widthRef = ref('')
const heightRef = ref('')
function handleClose() {
  emit('update:show', false)
  emit('close')
}
watchEffect(() => {
  if (props.src) {
    getImageSize(props.src).then(({ width, height }) => {
      const size = getAppropriateSize(width, height)
      widthRef.value = size.width
      heightRef.value = size.height
    })
  }
})
</script>

<template>
  <NModal :show="show" @update:show="handleClose">
    <div class="relative">
      <div v-if="src" class="border border-white border-solid bg-#0e0e0f">
        <template v-if="type === 1">
          <img
            :src="src"
            alt=""
            :style="{ width: widthRef, height: heightRef }"
          />
        </template>
        <template v-if="type === 2">
          <Video
            :width="minSize() * (!hasPhone ? 0.8 : 0.96)"
            :height="square ? minSize() * (!hasPhone ? 0.8 : 0.96) : 'auto'"
            :src="src"
            :controls="false"
            :poster="poster"
            autoplay
            loop
            muted
          ></Video>
        </template>
      </div>
      <img
        v-if="!hasPhone"
        :src="closeImg"
        class="mouse_pointer absolute right-[-25px] top-1px w-19px"
        @click="handleClose"
      />
    </div>
  </NModal>
</template>
