<script setup>
import { NModal } from 'naive-ui'
import closeImg from 'src/asset/close.webp'
import {
  defineEmits,
  defineProps,
  reactive,
  ref,
  watchEffect,
  computed,
} from 'vue'
import { getAppropriateSize, getImageSize, minSize } from '@BOGX/utils'
import Video from 'src/components/Video'
import { hasPhone } from 'src/stores/media'
import { getType, tokenIdFormat } from '../utils.js'
import { ReceiveJobItemMap, getQuality } from 'src/constant'
const VideoSrc = `${import.meta.env.VITE_FILE_PATH}/title_animation.mp4`

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  data: {
    type: Object,
    default() {
      return {}
    },
  },
  src: {
    type: String,
    default: '',
  },
  type: {
    type: Number,
    // 1是图片，2是视频
    default: 1,
  },
  poster: {
    type: String,
    default: '',
  },
  square: {
    type: Boolean,
    default: false,
  },
})
const emit = defineEmits(['update:show', 'close'])

const qualityRef = computed(() => getQuality(props.data.Quality) || {})
const isSupreme = computed(() => {
  const DataId = props.data.DataId
  if (!DataId) return false
  let strNumber = DataId.toString()
  let lastDigit = strNumber.charAt(strNumber.length - 1)
  return lastDigit === '1'
})
const soulSkill = computed(() => {
  const arrays = []
  for (let i = 1; i <= 6; i++) {
    if (props.data[`sk${i}`]?.name) {
      arrays.push(props.data[`sk${i}`])
    }
  }
  return arrays
})

const widthRef = ref('')
const heightRef = ref('')

function handleClose() {
  emit('update:show', false)
  emit('close')
}
watchEffect(() => {
  if (props.src) {
    getImageSize(props.src).then(({ width, height }) => {
      const size = getAppropriateSize(width, height)
      widthRef.value = size.width
      heightRef.value = size.height
    })
  }
})
</script>

<template>
  <NModal class="modal" :show="show" @update:show="handleClose">
    <div
      class="relative flex 4xl:scale-120 4xl:origin-[50%_50%]! 4xl:ease-linear! 4xl:duration-200!"
    >
      <div v-if="data.Icon" class="soul_select mr-9 text-0 w-[772px] h-[742px]">
        <template v-if="type === 1">
          <img
            :src="data.Icon"
            alt=""
            :style="{ width: '100%', height: '100%' }"
          />
        </template>
        <template v-if="type === 2">
          <Video
            :width="minSize() * (!hasPhone ? 0.8 : 0.96)"
            :height="square ? minSize() * (!hasPhone ? 0.8 : 0.96) : 'auto'"
            :src="src"
            :controls="false"
            :poster="poster"
            autoplay
            loop
            muted
          ></Video>
        </template>
      </div>
      <div class="w-[713px]">
        <div
          class="px-3 relative flex justify-between flex-items-center w-full h-[47px]"
        >
          <Video
            class="absolute! top-0 bottom-0 left-0 right-0 w-full h-[47px]"
            :src="VideoSrc"
            :controls="false"
            autoplay
            object-fit:
            cover
            loop
            muted
          ></Video>
          <span class="fz-2 relative z-2">{{
            getType(data.ItemId, data.ItemType)
          }}</span>
          <span class="fz-5 relative z-2"
            >{{ data.Name }}&nbsp;&nbsp;#{{
              tokenIdFormat(data.tokenId || data.mint.tokenid)
            }}</span
          >
          <img
            class="w-[24px] relative z-2"
            src="src/asset/passport_logo.svg?url"
            alt=""
          />
        </div>
        <div
          class="pt-5 px-2.5 w-full h-[695px] border border-[#4D575C] border-solid border-t-0 bg-#0f0f11"
        >
          <div
            class="pb-2.5 flex w-full justify-center border-0 border-b-1 border-#2E363B border-solid"
          >
            <ul>
              <li
                class="px-2.5 mb-2.5 flex justify-between flex-items-center w-[320px] h-[36px] border border-dotted bg-#191E20 fz-2"
              >
                <span class="gray-color">Role ID:</span>
                <div class="-ml-5 flex items-center">
                  <span class="gray-color"
                    >{{ ReceiveJobItemMap[data.ReceiveJob] }}&nbsp;/&nbsp;</span
                  >
                  <span>{{ data.ReceiveName }}</span>
                </div>
                <span></span>
              </li>
              <li
                class="px-2.5 mb-2.5 flex justify-between flex-items-center w-[320px] h-[36px] border border-dotted bg-#262B2E fz-2"
              >
                <span class="gray-color">Rarity:</span>
                <span class="-ml-5" :style="{ color: qualityRef.color }">{{
                  qualityRef.text
                }}</span>
                <span></span>
              </li>
              <li
                class="px-2.5 mb-2.5 bg-#AA672B flex justify-between flex-items-center w-[320px] h-[36px] border border-solid border-#F3AE42 fz-2"
                v-if="data.mint"
              >
                <span class="text-#F7D57B">Mint:</span>
                <span class="-ml-5">{{ data.mint.me.desc }}</span>
                <span></span>
              </li>
              <li
                class="px-2.5 mb-2.5 flex justify-center flex-items-center w-[320px] h-[36px] border border-solid fz-2"
                :class="`${isSupreme ? 'text-#FFDD94 bg-#CD6A12 border-#F3AE42' : 'text-#EADDBB bg-#4D4A47 border-#68645E'}`"
              >
                <span class="text-center">{{
                  isSupreme ? 'SUPREME' : 'NORMAL'
                }}</span>
              </li>
              <li
                class="px-2.5 mb-2.5 flex justify-center flex-items-center w-[320px] h-[36px] border border-dotted border-#93A1AF bg-#262B2E fz-2"
              >
                <span class="gray-color"> Advance: </span>
                <span class="text-center">&nbsp;Lv.{{ data.ProgLv }}</span>
                <span></span>
              </li>
              <li
                class="px-2.5 mb-2.5 flex justify-center flex-items-center w-[320px] h-[36px] border border-dotted border-#93A1AF bg-#262B2E fz-2"
              >
                <span class="gray-color"> Star:</span>
                <span class="text-center">&nbsp;Lv.{{ data.StarLv }}</span>
                <span></span>
              </li>
              <li
                class="px-2.5 mb-2.5 flex justify-center flex-items-center w-[320px] h-[36px] border border-dotted border-#93A1AF bg-#262B2E fz-2"
              >
                <span class="gray-color">Exp:</span>
                <span class="text-center">&nbsp;Lv.{{ data.Lv }}</span>
                <span></span>
              </li>
            </ul>
            <div
              class="ml-5 w-[320px] h-[314px] bg-#141719 border border-solid border-#464A4E p-3.5"
            >
              <p class="text-center my-0">SOUL SKILL</p>
              <ul class="mt-4 w-full">
                <li
                  v-for="item in soulSkill"
                  class="flex-center fz-2 mb-2.5 w-full h-[33px] bg-#262B2E"
                >
                  <span class="gray-color mr-1">{{ item.name }}:</span>
                  <span class="text-#FFFFFF"
                    >Lv.{{ item.lv }} / Lv.{{ item.lvMax }}</span
                  >
                </li>
              </ul>
            </div>
          </div>

          <div class="w-full h-[336px] gray-color fz-2 pt-5 pb-5 overflow-auto">
            <div class="mb-4 text-center">Story</div>
            <div class="text-center px-20" v-html="data.Desc"></div>
          </div>
        </div>
      </div>
      <img
        v-if="!hasPhone"
        :src="closeImg"
        class="mouse_pointer absolute right-[-28px] top-14px w-20px"
        @click="handleClose"
      />
    </div>
  </NModal>
</template>

<style lang="scss" scoped>
.soul_select {
  width: 772px;
  height: 742px;
  padding: 1px;
  background: linear-gradient(
    180deg,
    rgb(153 216 225 / 100%) 0%,
    rgb(215 177 218 / 100%) 100%
  );
}

.video-js .vjs-tech {
  object-fit: cover !important;
}
</style>
