<script setup>
import { defineEmits, defineProps, ref, watch } from 'vue'
import { useLoginStore } from 'src/stores/user'
import { hasPhone } from 'src/stores/media'
import { useRouter } from 'vue-router'
import DownloadBOGX from 'src/components/DownloadBOGX'
import { storeToRefs } from 'pinia'
import { useElementBounding } from '@vueuse/core'
import AccountCodeModal from '../../../../home/components/AccountCodeModal.vue'
import WalletItem from '../../../../home/components/WalletItem.vue'
import ProfileModal from '../../../../home/components/Profile/ProfileModal.vue'
import UserAccName from '../../../../home/components/UserAccName.vue'
import { PFP_ETHEREAL_NAME } from 'src/constant'

const props = defineProps({
  show: Boolean,
  profileModalDom: Array,
})
const emit = defineEmits(['update:show'])
const router = useRouter()
const loginStore = useLoginStore()
const { hasLogin } = storeToRefs(loginStore)

const accountCodeShowRef = ref(false)
const profileShowRef = ref(false)
const downloadShowRef = ref(false)

function handleClose(event) {
  const className = event.relatedTarget.className
  if (className.includes('n-popover')) return
  emit('update:show', false)
}

function handleAccountCodeShow() {
  accountCodeShowRef.value = true
}
function handleProfileShow() {
  profileShowRef.value = true
}

function handleCollection() {
  router.push({
    name: PFP_ETHEREAL_NAME,
  })
}

const modalBox = ref(null)
const left = ref(0)

function initProfileModalLeft() {
  const { width } = useElementBounding(modalBox.value)
  const tabWidth = props.profileModalDom[1]
  const tabX = props.profileModalDom[0]
  watch(width, dom => {
    left.value = tabX - (dom - tabWidth) / 2
  })
}

watch(
  () => props.show,
  () => {
    initProfileModalLeft()
  },
)
</script>

<template>
  <transition name="fade">
    <div
      v-show="props.show"
      ref="modalBox"
      @mouseenter="emit('update:show', true)"
      @mouseleave.stop="e => handleClose(e)"
      class="modal_box relative z-2 -mt-3"
      :style="{ left: `${left}px` }"
    >
      <div class="py-2.5 md:py-3.5">
        <ul class="item-ul px-1.5 md:px-2">
          <template v-if="hasLogin">
            <li class="item-li">
              <UserAccName></UserAccName>
            </li>
            <li class="flex-sb item-li bg-#323C44/70!">
              <WalletItem></WalletItem>
            </li>
            <li class="item2-li" @click="handleProfileShow">Profile</li>

            <li class="item2-li" @click="handleCollection">My Collection</li>
            <li class="item2-li" @click="handleAccountCodeShow">Redeem Code</li>
          </template>
          <DownloadBOGX
            v-model:show="downloadShowRef"
            :placement="!hasPhone ? 'right' : 'top'"
            :show-arrow="false"
          >
            <li class="item2-li hover-triangle-li relative">
              Download BOGX
              <span
                v-if="!hasPhone"
                class="triangle absolute"
                :style="`transform: translate(105%, -50%) rotate(${
                  downloadShowRef ? -270 : -90
                }deg)`"
              ></span>
            </li>
          </DownloadBOGX>
        </ul>
      </div>
    </div>
  </transition>
  <AccountCodeModal v-model:show="accountCodeShowRef"></AccountCodeModal>
  <ProfileModal v-model:show="profileShowRef"></ProfileModal>
</template>

<style scoped lang="scss">
@import '../ul';

.triangle {
  top: 50%;
  right: 0;
  z-index: 2011;

  @include triangle;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
