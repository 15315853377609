export function getCodeModel() {
  return {
    verifyCode: '',
  }
}

export function getCodeRules(codeLength = 6) {
  return {
    verifyCode: [
      {
        required: true,
        message: 'Please enter the email verification code.',
        trigger: ['input', 'blur'],
      },
      {
        min: codeLength,
        message: `Email verification code must be ${codeLength} digits long.`,
        trigger: ['input', 'blur'],
      },
    ],
  }
}
